import { Component, EventEmitter, Output, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import {
  HomeCard,
  RoundedIconButtonInterface,
} from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-home-card',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent],
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss'],
})
export class HomeCardComponent {
  @Input() cardData: HomeCard = {
    headerIconUrl: 'assets/img/icon/play.svg',
    title: 'title',
    footerText: 'GO TO CHAT',
    classCard: '',
    titleClass: '',
    footerClass: '',
  };

  @Input() buttonData: RoundedIconButtonInterface = {
    iconUrl: 'assets/img/icon/arrow-green.svg',
    isDotVisible: false,
    btnClass: '',
    iconClass: '',
  };

  @Input() bgImageUrl: string = '';

  @Output() btnClick = new EventEmitter<void>();

  onBtnClick() {
    this.btnClick.emit();
  }
}
