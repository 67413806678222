import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SelectFormComponent } from '../select-form/select-form.component';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';

@Component({
  standalone: true,
  imports: [CommonModule, SelectFormComponent, RoundedIconButtomComponent],
  selector: 'app-sort-view',
  templateUrl: './sort-view.component.html',
  styleUrls: ['./sort-view.component.scss']
})
export class SortViewComponent {
  @Input() default: string = "Newest"
  @Input() items: any[] = [{ value: 'A001', name: 'Alphabetical' },
  { value: 'A001', name: 'Popularity' },
  { value: 'A001', name: 'Oldest' },
  { value: 'A001', name: 'Rating' }];
}
