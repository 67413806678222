<div class="md:flex my-8 gap-4 relative max-w-full min-w-[190%] md:min-w-[130%] {{home?'text-white':'text-[#4A4C57]'}}">
  <div class="pt-10 px-10 md:p-0 ">
    <div class="md:text-[3.5rem] text-[2.5rem]  font-bold">
      <span class="">Yield</span
      ><span class="text-[#2CC784]">vestor</span> <br />
      <span class="uppercase font-normal block" style="margin-top: -1.5rem"
        >Videos</span
      >
    </div>

    <div>
      <div class="italic md:w-3/4 text-[18px] font-[500]">
        <span
          class="text-[#B3B3B3] bg-gradient-to-r from-[#978EF540] to-[#978EF54D] rounded-lg h-[1.813rem] pl-2 pr-2 border-2 border-[#978EF54D] me-[.5rem]"
          >Learn how to invest</span
        >
        <span class="text-[#B3B3B3]">and interact with your assistant</span>
      </div>
      <div class="md:flex hidden gap-10 md:-px-10 my-10">
        <app-rounded-icon-buttom
          (btnClick)="slickModal.slickPrev()"
          [buttonData]="{
            iconUrl: 'assets/img/icon/right-arrow.svg',
            btnClass:
              'border border-[#4A4C57]' + home
                ? ' border border-[#4A4C57] !rotate-180'
                : ''
          }"
        />
        <app-rounded-icon-buttom
          (btnClick)="slickModal.slickNext()"
          [buttonData]="{
            iconUrl: 'assets/img/icon/right-arrow.svg',
            btnClass: !home ? '!bg-yv' : ''
          }"
        />
      </div>
    </div>
  </div>

  <ngx-slick-carousel
    class="carousel md:absolute py-10 md:m-0 p-0 -translate-x-[10%] md:translate-x-0"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    (init)="slickInit($event)"
    (breakpoint)="breakpoint($event)"
    (afterChange)="afterChange($event)"
    (beforeChange)="beforeChange($event)"
  >
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <app-video-card [photo]="slide.img" [home]="home" />
    </div>
  </ngx-slick-carousel>
</div>
