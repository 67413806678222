<div class="block bg-[#181C31] rounded-[1.5rem]" style="
padding-top: 1.2rem;
padding-bottom: 1.2rem;
padding-right: 1.5rem;
padding-left: 1.5rem;
">
    <div *ngFor="let facialsInfo of creditInfos; let i = index"
        class="flex justify-evenly lg:justify-between lg:mt-[1rem] py-[1rem] rounded-md {{i%2!=0?'bg-[#F9F9F91A]':''}}">
        <div class="flex lg:items-center justify-evenly lg:justify-between lg:gap-[1rem] lg:pl-[2rem] flex-grow">
            <div class="flex lg:justify-between lg:items-center gap-[0.75rem] ">
                <app-rounded-icon-buttom
                    [buttonData]="{iconUrl:facialsInfo.cardIcon,btnClass:'!bg-white h-[1.4rem] w-[2.5rem] lg:h-[1.7rem] lg:w-[3rem] !rounded'}" />
                <span class="grid gap-0">
                    <span class="font-bold text-[0.7rem] sm:text-[0.8rem] lg:text-[1.05rem]">{{facialsInfo.number}}</span>
                    <span class="text-[0.7rem] sm:text-[0.8rem] mt-[-1rem] lg:hidden ">{{facialsInfo.expiration}}</span>
                </span>
            </div>
            <div [ngClass]="{'hidden lg:block': 'm-0'}" class="lg:pr-[6rem]">{{facialsInfo.expiration}}</div>
            <span class="lg:pr-[10rem]">
                <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/trash.svg',btnClass:'!p-0'}" />
            </span>
        </div>



    </div>


    <div class="pb-[2%]">
        <div class="lg:flex lg:justify-between">
            <div class=" pt-[1.25rem]" (click)="handlePayment()">
                <app-button
                    [buttonData]="{textButton:'New payment method',classButton:'!bg-[#978EF51A] !border-[#181C31] !border-[0.1rem] w-full',classText:'!text-[#978EF5]'}" />
            </div>
        </div>

    </div>
    <ng-container [ngSwitch]="selectedTab">
        <div *ngSwitchCase="'paymentMethod'"
            class="block bg-[#181C31] pb-[1.25rem] lg:w-[35%] rounded-[1.5rem] max-w-screen-md mx-auto">
            <ng-container [ngSwitch]="paymentStep">
                <div class="w-full py-6 max-w-screen-md mx-auto ">
                    <div class="flex item">
                        <div *ngFor="let step of steps, let i = index" class="text-xs md:text-base w-[50%] py-6">
                            <div class="relative">
                                <div
                                    class="{{current >= i?'font-[300] text-[20px]':'font-[300] text-[14px] text-[#B3B3B3]'}}">
                                    {{i+1 | number: '2.0' }}.</div>
                                <div
                                    class="{{current >= i?'font-[600] text-[16px]':'font-[400] text-[14px] text-[#B3B3B3]'}}">
                                    {{step.title}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="w-[50%]" *ngFor="let step of steps, let i = index">
                            <div class="relative mb-2">
                                <div
                                    class="w-[18px] h-[16px] {{step.checked?'bg-[#2CC784]': 'bg-transparent' }} rounded-full text-lg text-white flex items-center">
                                    <span class="text-center text-white w-full z-10">
                                        <svg *ngIf="step.checked" width="18" height="7" viewBox="0 0 10 7" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.57199 1L3.8577 5.5L1.71484 3.45455" stroke="white"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                </div>

                                <div class="absolute"
                                    style="width: calc(100% - 0.5rem); top: 8px; transform: translate(0%, -50%)">
                                    <div class="">
                                        <div class="w-0 {{step.checked?'bg-[#2CC784] py-1': 'bg-[#B3B3B3] h-[1px]' }} rounded"
                                            style="width: 100%;"></div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>


                <div id="cardData" *ngSwitchCase="'cardData'">
                    <div class=" border-t-[0.5px] border-[#B3B3B3] max-w-lg mx-auto my-[4%]"></div>
                    <div class="">
                        <app-input-form [inputData]="{label:'Card Number',inputType: 'text',placeholder:'2654 5654 5565 5564'}"></app-input-form>
                    </div>
                    <div class="flex justify-between pt-[1.75rem]">
                        <app-input-form [inputData]="{label:'Exp Date',inputType: 'text',placeholder:'25/07',inputFormClass:'pr-[1rem]'}"></app-input-form>
                        <app-input-form [inputData]="{label:'CCV',inputType: 'text',placeholder:'***',inputFormClass:'pl-[1rem]'}"></app-input-form>
                    </div>
                    <div class=" border-t-[0.5px] border-[#B3B3B3] max-w-lg mx-auto my-[4%]"></div>
                    <div class="max-w-lg mx-auto">
                        <div class="flex gap-8 items-center justify-between py-[2%]">
                            <app-rounded-icon-buttom (btnClick)="prev()"
                                [buttonData]="{ iconUrl: 'assets/img/icon/right-arrow.svg', btnClass: paymentStep != 'cardData' ? 'bg-[#4A4C57]' : '!bg-transparent', iconClass: paymentStep != 'cardData' ? 'rotate-180' : '!invisible' }" />

                            <app-rounded-icon-buttom (btnClick)="next()"
                                [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass:'!bg-[#2CC784]'}" />

                        </div>
                    </div>
                </div>
                <div id="address" *ngSwitchCase="'address'">
                    <div class=" border-t-[0.5px] border-[#B3B3B3] max-w-lg mx-auto my-[4%]"></div>
                    <div class="">
                        <app-input-form [inputData]="{label:'Address',inputType: 'text',placeholder:'Address'}"></app-input-form>
                        <app-select-form [selectData]="{label:'Country',selectFormClass: 'py-[1.5rem]'}"></app-select-form>
                    </div>
                    <div class="flex justify-between">
                        <app-select-form [selectData]="{label:'City',selectFormClass: ''}"></app-select-form>
                        <app-input-form [inputData]="{label:'Post Code',inputType: 'text',placeholder:'Post Code',inputFormClass:'pl-[1rem]'}"></app-input-form>
                    </div>
                    <div class=" border-t-[0.5px] border-[#B3B3B3] max-w-lg mx-auto my-[4%]"></div>
                    <div class="max-w-lg mx-auto">
                        <div class="flex gap-8 items-center justify-between py-[2%]">
                            <app-rounded-icon-buttom (btnClick)="prev()"
                                [buttonData]="{ iconUrl: 'assets/img/icon/right-arrow.svg', btnClass: paymentStep != 'cardData' ? 'bg-[#4A4C57]' : '!bg-transparent', iconClass: paymentStep != 'cardData' ? 'rotate-180' : '!invisible' }" />

                            <div class=" pt-[1.25rem]" (click)="handlePayment()">
                                <app-button
                                    [buttonData]="{textButton:'Create',classButton:'!bg-[#6761A8] !border-[#181C31] !border-[0.1rem] ',classText:'!text-[white]'}" />
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>



        </div>
    </ng-container>
</div>