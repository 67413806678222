<app-user-layout>

    <div class="flex flex-col justify-center items-center">

        <p class="font-regular text-[22px] pt-10 max-w-lg mx-auto text-center">Based on our assessment your are most suited for the below portfolio:</p>
        <p class=" text-[13px] text-[#B3B3B3] mt-2 max-w-lg mx-auto text-center">With the answers you provided, we have made this recommendation.</p>
        <app-gouge-chart-indicator [value]='calculatePercentage(getInfo()?.impliedRiskTolerance)' [desc]="getInfo()?.riskProfile || 'No Profile'"></app-gouge-chart-indicator>
        <p class=" text-[13px] text-[#FFFFFF] my-8  max-w-lg mx-auto text-center">You are looking to maximize your growth potential over a long period willing to take higher risks. You are less likely to take back your investment in case of its decreasing in value</p>
        <span class="mb-8 text-[12px]">Not yours? <span (click)="redoRisk()" class="text-[#2CC784] text-[12px] cursor-pointer">Redo risk profile</span></span>
        <app-button (click)="handleContinue()"
        [buttonData]="{textButton:'Continue',  classButton:'!bg-[#6761A8] !h-[2.5rem]',classText:'!text-[#FFFFFF] !text-[13px] !font-[400]'}" />

    
    
    </div>

</app-user-layout>