<app-user-layout>

    <div class="bg-yv text-white min-h-screen">
        <div class="text-start pt-[2.5rem]">
            <span class="font-normal text-[2.188rem] md:text-[3.5rem] " style="font-family: 'Poppins'">User
                Profile</span>
            <div class="font-normal text-[1.125rem]"></div>
        </div>
        <div class="flex lg:justify-between lg:overflow-auto overflow-scroll gap-[1rem]">
            <span class=" flex">
                <app-tabs [tabs]="profileMenu"></app-tabs>

            </span>
            <app-tabs [tabs]='profileLogOutButton' [tabsData]="profileLogOut" />



        </div>
        
          
        <ng-container [ngSwitch]="selectedTab">
            <app-personal-information name='{{name}}' email='{{email}}' *ngSwitchCase="'personalInformation'"></app-personal-information>
            <app-documents *ngSwitchCase="'documents'"></app-documents>
            <app-payment-method *ngSwitchCase="'paymentMethod'"></app-payment-method>
            <app-settings *ngSwitchCase="'settings'"></app-settings>
        </ng-container>
    </div>
</app-user-layout>