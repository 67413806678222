import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';

@Component({
  selector: 'app-upload-photo',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent],
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss'],
})
export class UploadPhotoComponent {}
