<span class="w-full max-w-screen-sm inline-flex items-center mt-[-1.5rem]">
    <div class="me-5">

        <app-rounded-icon-buttom [buttonData]="{
            iconUrl:'assets/img/icon/logo_alt.svg', 
            btnClass: '!bg-yv-5%-gray'
            , iconClass:'w-1/2'}"></app-rounded-icon-buttom>
    </div>

    <span class="typed-text" [innerHTML]="typedContent"><!-- {{ typedContent }} --></span>
</span>