import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DetailsData } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit{
  ngOnInit(): void {
      console.log(this.details,"data")
  }
  @Input() listClass?: string;
  @Input() aiChat?: boolean =false;
  @Input() valueClass?: string;
  @Input() details?: DetailsData[] = [
    { key: 'Instrument type', value: ' Sovereign Bond' },
    { key: 'Maturity', value: '2025' },
    { key: 'Interest rate', value: '8.75%' },
    { key: 'Yield', value: '5.99%' },
  ];
}
