<app-mobile-menu [dark]="true"></app-mobile-menu>
<app-side-bar [orders]="orders" #sidebar></app-side-bar>
<div class="bg-yv overflow-hidden justify-center flex z-30 min-h-screen">
  <div
    class="fixed hidden float-right p-1 w-full bg-[#0C1026] md:flex items-center top-0 justify-end gap-8 px-[88px] pl-[172px]  max-w-screen-2xl z-20">
    <div class="flex-1 p-4">
    </div>
    <app-rounded-icon-buttom *ngFor="let item of navMenu" (btnClick)="item.action()" [buttonData]="item"></app-rounded-icon-buttom>
    <span [routerLink]="'/user/profile'" class="flex">
      <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/person.svg',iconClass:'h-[1.5rem]'}"></app-rounded-icon-buttom>
      <app-rounded-icon-buttom
        [buttonData]="{iconUrl:'assets/img/icon/select_arrow_clear.svg',iconClass:'mr-[1rem]',btnClass:'!bg-transparent w-[2rem]'}"></app-rounded-icon-buttom>
    </span>
  </div>
  <div
    class="w-[88px] h-screen hidden fixed left-0 top-0 bg-yv-rounded-icon md:flex flex-col pt-8 gap-16 items-center z-20">
    <div *ngFor="let item of sideMenu; let i = index" class="flex flex-col items-center gap-4 cursor-pointer"
      (click)="onMenuClick(i)">
      <img *ngIf="item.route != url" [src]="item.baseIcon" alt="">
      <img *ngIf="item.route == url" [src]="item.activeIcon" alt="">
      <div [ngClass]="item.route == url ? 'text-[#978EF5]' : 'text-[#F9F9F9]'" class="text-[14px] font-normal">
        {{ item.name }}
      </div>
    </div>
    <!-- Adicionar o botão de logout aqui -->
    <div class="mt-auto flex flex-col items-center gap-4 cursor-pointer mb-5">
      <button class="text-sm" (click)="logout()">
        <img src="assets/img/logout.svg" alt="Logout">
        Logout
      </button>
    </div>
  </div>
  <div class="md:pl-[172px] md:pt-[88px] md:pr-[88px] p-5 w-full max-w-screen-2xl mx-auto text-black">
    <ng-content></ng-content>
    <div class="md:w-1/4 text-[14px] !text-[#B3B3B3]">
      <app-footer *ngIf="chatFooter"></app-footer>
    </div>
  </div>
  <div></div>
</div>
<div class="fixed w-screen top-0 left-0 h-screen bg-repeat pointer-events-none"></div>
