<app-user-layout>
    <div class="grid place-content-center text-[#F9F9F9] text-center min-h-screen p-10   ">
        <div class="px-[1rem] text-[2rem] md:text-[2.8rem] lg:text-[4rem] font-bold  z-10" >Ask a question below.</div>
        <div class="text-[#B3B3B3] font-normal text-[1.125rem] z-10" >Here are some examples and inspiration:</div>
        <div class="z-10" >
            <app-recent-prompts />
        </div>

        <div class="py-10  " >
            <app-input-chat btnClass="bg-transparent" iconUrl="assets/img/icon/mic2.svg" [chatData]="{classContainer:'',classInput:'!w-[19rem] lg:!w-[50rem] md:!w-[35rem]'}"
            [(inputValue)]="currentPrompt" (btnClick)="goToChat()" />
        </div>
    </div>
    <div class=" opacity-50 absolute inset-0 bottom-[0rem] sm:bottom-[-2rem] md:bottom-[33rem] lg:bottom-[15rem] md:left-[6rem] lg:left-[5rem] flex items-center justify-center z-0">
        <img class="brightness-75 w-screen md:w-[60%] lg:w-[35%] sm:h-[20rem] xl:h-[26rem]" src="assets/img/assistantBackground4.svg" alt="icon" />
    </div>
   
</app-user-layout>