import { Component, Input } from '@angular/core';
import { Order } from 'src/app/models/Order';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent {
  @Input() table = [
    {period:"1",interest:"250.00",payment:"05/2023"},
    {period:"2",interest:"250.00",payment:"06/2023"},
    {period:"3",interest:"250.00",payment:"07/2023"},
    {period:"4",interest:"250.00",payment:"08/2023"},
    {period:"5",interest:"250.00",payment:"09/2023"},
    {period:"6",interest:"250.00",payment:"10/2023"},
    {period:"7",interest:"250.00",payment:"11/2023"},
    {period:"8",interest:"250.00",payment:"12/2023"},
    {period:"9",interest:"250.00",payment:"01/2024"},
    {period:"10",interest:"250.00",payment:"02/2024"}
  ]
  items: any[] = [
    {title:"Investment Amount",iconUrl:"assets/img/icon/clock.svg",value:"$5,000.00 ",classText:'text-white'},
    {title:"Interest rate",iconUrl:"assets/img/icon/card.svg",value:"5.00%"},
    {title:"Years",iconUrl:"assets/img/icon/calendar.svg",value:"10",classText:'text-white'},
  ]
  constructor(private cartService:CartService){

  }
  addToCart(order:Order | Order[]){
    this.cartService.add(order)
  }

}
