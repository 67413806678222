<div class="flex flex-col md:flex-row mt-8 items-end align-bottom mb-4">
    <div class="w-full md:w-1/2 mr-3">

        <span class="text-[12px] text-[#E1E1E1]">Cashflows</span>
        <app-select-form [default]="'Scenario'" [items]="[ { value: 'A001', name: 'Scenario' }]"
            [selectData]="{selectFormClass:'!bg-none w-full',selectClass:'!bg-[#FFFFFF10] text-[#B3B3B380] !bg-[url(assets/img/icon/input_arrow.svg)] !py-[13.5px] !px-4 !mr-3  !h-auto',label:'', labelClass:'text-[12px] '}" />
    </div>
    <div class="w-full md:w-1/2 ">


        <!--Para Scenario do tipo Non-Parallel Shift-->
        <div class="flex ">
            <div class="w-full md:w-1/2 ">
                <app-select-form [default]="'Scenario'" [items]="[ { value: 'A001', name: 'Scenario' }]"
                    [selectData]="{selectFormClass:'!bg-none w-full',selectClass:'!bg-[#FFFFFF10] text-[#B3B3B380] !bg-[url(assets/img/icon/input_arrow.svg)] !py-[13.5px] !px-4 !mr-3  !h-auto',label:'', labelClass:'text-[12px] '}" />
            </div>
            <div class="w-full md:w-1/2 ">
                <app-select-form [default]="'Magnitude'" [items]="[ { value: 'A001', name: 'Magnitude' }]"
                    [selectData]="{selectFormClass:'!bg-none w-full',selectClass:'!bg-[#FFFFFF10] text-[#B3B3B380] !bg-[url(assets/img/icon/input_arrow.svg)] !py-[13.5px] !px-4 !ml-3  !h-auto',label:'', labelClass:'text-[12px] '}" />
            </div>

        </div>




    </div>
</div>