<div  #chatContainer  *ngFor="let item of content, let i = index" class="{{item.type == 'you'?'flex justify-end':''}}
.run-animation">
<ng-container *ngIf="item.content">
    <app-card-chat
    [content]="content"
    [index]="i"
    [ogText]="item.content"
    [type]="item.type"
        [text]="item.type == 'you' ? item.content : item.type == 'module-list'? 
    'Yieldvestor offers easy-to-follow courses for those interested in learning more about fixed-income investments.': null"
        [prompts]="item?.prompts?.length > 0 ? item.prompts : item?.suggestions?.length > 0?item.suggestions:null"
        [iconRightUrl]="item.type == 'you' ? item.perfil : ''" [CardChatData]="{
      cardClass:
        item.type == 'you' ? '!bg-[#978EF54D] rounded-tr-none my-[2.9rem]' : '' +
        item.type != 'you' && item.type != 'text' && item.type != 'list' &&
        item.type != '2d-table' && item.type != 'bonds' &&
        item.type != 'module-list' && item.type != 'model'
          ? ' md:min-w-[70%] md:!max-w-3xl'
          : 'rounded-tl-none mb-[0.5rem] !bg-yv-5%-gray'
    }"
    >

        <ng-container *ngIf="item.type == 'text'">
            <app-text *ngIf="item.type == 'text'" [content]="item.content.text?item.content.text:item.content" [typed]="item.typed" />
        </ng-container>

        <ng-container *ngIf="item.type == 'html'">
            <div [innerHTML]="item.content.text"></div>
        </ng-container>

        <ng-container *ngIf="item.type == 'bonds'">
            <app-yield-list [content]="item.content.list" [runPrompt]="runPrompt" [addObj]="addObj" [addOrder]="addOrder" />
        </ng-container>

        <ng-container *ngIf="item.type == 'list'">
            <app-list [content]="item.content" [title]="item.title" />
        </ng-container>

        <ng-container *ngIf="item.type == 'comparison'">
            <app-comparison [content]="item.content" />
        </ng-container>

        <ng-container *ngIf="item.type == '2d-table'">
            <app-two-d-table [content]="item.content" />
        </ng-container>

        <ng-container *ngIf="item.type == 'table'">
            <app-table [data]="item.content.data" [columns]="item.content.columns" />
        </ng-container>

        <ng-container *ngIf="item.type == 'education'">
            <div [innerHTML]="item.content.text?item.content.text:item.content"></div>
            <app-button [routerLink]="['/education']" [buttonData]="{textButton:'Join the courses',classButton:'ml-[4rem] mt-5'}" />
        </ng-container>
        <ng-container *ngIf="item.type == 'advisor'">
            
            <app-button [routerLink]="['/robo']" [buttonData]="{textButton:'Get investment advice',classButton:'ml-[4rem] mt-5'}" />
        </ng-container>

        <!-- <ng-container *ngIf="item.type == 'lecture'" >
        <app-lecture [content]="item.content" />
    </ng-container>

    <ng-container *ngIf="item.type == 'quiz'" >
        <app-quiz [content]="item.content" />
    </ng-container> -->

        <ng-container *ngIf="item.type == 'objectives'">
            <app-objectives [content]="item.content" />
        </ng-container>


        <!--CHART VIEWS-->

        <ng-container *ngIf="item.type == 'sceenario'">
            <div class="overflow-auto">
                <div class="min-w-[200%] md:min-w-0">
                    <app-sceenario [content]="item.content" />
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="item.type == 'scatter'">
            <div class="overflow-auto">
                <div class="min-w-[200%] md:min-w-0">
                    <app-scatter-chart></app-scatter-chart>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="item.type == 'model'">
            <app-model></app-model>
        </ng-container>

        <ng-container *ngIf="item.type == 'yield-details'">
            <app-yield-details [content]="item.content" />
        </ng-container>


    </app-card-chat>
</ng-container>
</div>
<div *ngIf="isChatLoading()" class="bg-yv-primary-70 rounded-xl rounded-tl-none p-[1rem] w-[7rem]">

    <img class="" src="assets/img/icon/logoLoading.svg" />
</div>
<div *ngIf="!widget" class="h-[30vh]"></div>