import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'app-advice',
  templateUrl: './advice.component.html',
  styleUrls: ['./advice.component.scss']
})
export class AdviceComponent {
  
  samples:any[] = [];
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.fetchData();
  }
  fetchData() {
    this.http.get<any[]>('assets/advice.json').subscribe(
      data => {
        this.samples = data
        // Access the JSON array
        data.forEach(item => {
          console.log(item);
          console.log(item.age);
          console.log(item.city);
        });
      },
      error => {
        console.log('Error:', error);
      }
    );
  }
}
