import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFormComponent } from '../input-form/input-form.component';

@Component({
  selector: 'app-amount',
  standalone: true,
  imports: [CommonModule,InputFormComponent],
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss']
})
export class AmountComponent {

}
