import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-scatter-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './scatter-chart.component.html',
  styleUrls: ['./scatter-chart.component.scss']
})
export class ScatterChartComponent {

  dynamicId = `component_${Math.floor(Math.random() * 10000)}`


  ngAfterViewInit() {
    this.renderChart();
  }

  renderChart() {

    const getTestData = (x: number) => {
      const off = 0.2 + 0.2 * Math.random();
      return new Array(8).fill(1).map(() => [
        x,
        off + (Math.random() - 0.5) * (Math.random() - 0.5)
      ]);
    };


    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'scatter',
        backgroundColor: 'transparent',
      },

      title: {
        text: 'this.title',
        style: {
          color: '#ffffff99'
        }
      },

      credits: {
        enabled: false
      },

      xAxis: {
        categories: ['BBB+', 'BBB', 'BBB-'],
        gridLineColor: '#ffffff33',
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1.3,
        tickmarkPlacement: 'on', 
        lineColor: '#ffffffcc',
        lineWidth: 2,
        labels: {
          style: {
            color: '#ffffff99' // Set the color of the xAxis categories text
          },
        },
      },

      yAxis: {
        title: {
          text: 'Measurements'
        },
        gridLineColor: '#ffffff33',
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1.3,
        lineColor: '#ffffffcc',
        lineWidth: 2,
        labels: {
          style: {
            color: '#ffffff99' // Set the color of the xAxis categories text
          },
        },
      },

      plotOptions: {
        scatter: {
          showInLegend: false,
          jitter: {
            x: 0.24,
            y: 0
          },
          marker: {
            radius: 8,
            symbol: 'circle'
          },
          tooltip: {
            pointFormat: 'Measurement: {point.y:.3f}'
          }
        }
      },

      series: [
        {
          data: getTestData(0),
          color: '#978EF5'
        },
        {
          data: getTestData(1),
          color: '#2CC784'
        },
        {
          data: getTestData(2),
          color: '#FF6F65'
        }  as any
      ]
    };
    Highcharts.chart(this.dynamicId, chartOptions);
  }

}
