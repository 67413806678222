<div class="w-full">
    <div class="">
      <div class="h-[56px] bg-[#978EF533] rounded-t-2xl md:grid grid-cols-9 hidden">
        <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow col-span-2"> Company</div>
        <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow" *ngFor="let column of columns">{{ column }}</div>
        <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow"></div>
      </div>
    </div>
    <div class="text-[#E1E1E1] font-normal text-[16px]" *ngFor="let row of data,let i = index">
      <div class="md:min-h-[86px] rounded-[8px] md:grid items-center md:grid-cols-9 {{i%2!=0?'md:bg-[#FFFFFF08]':''}}" >
        <div class="col-span-2 grid grid-cols-2 md:flex md:py-4 gap-3">
          <div class="md:hidden p-4 bg-[#978EF51A] rounded-tl-2xl ">Assets</div>
          <div class="p-4  md:p-0 md:px-4 bg-[#978EF51A] md:bg-transparent rounded-tr-2xl">
            <app-bonds-company [detailed]="false"  [bondsCompanyData]="bonds![i]" />
              </div></div>
        <div class="px-4 grid grid-cols-2 md:grid-cols-1 items-center md:place-content-center {{j%2!=0?'bg-[#FFFFFF08] md:bg-transparent':''}}" *ngFor="let column of columns,let j = index">
          <div class="flex md:hidden py-4 h-full items-center ">{{column}}</div>
                <div class=" py-4">{{ row[column] }}</div>
              </div>
              <div class="grid grid-cols-2 col-span-2 items-center p-4 md:p-0">
              <div class="md:hidden">Actions</div>
              <div class="flex gap-5 items-center">
                <div class="flex items-center justify-center">
                    <span class="bg-[#FFFFFF1A] p-4 px-6 rounded-2xl">20%</span>
    
                </div>
                <div class="flex items-center justify-end">
                  <app-rounded-icon-buttom (btnClick)="changeBondsColabs(bonds![i])" [buttonData]="{iconUrl:'assets/img/icon/menu 1.svg',}"></app-rounded-icon-buttom>
                </div>
              <!-- <app-button [buttonData]="{textButton:'details',classButton:'!bg-transparent !p-0',classText:'font-bold !text-[#978EF5]',}" /></div> -->
            </div>
      </div>
    </div>
    <div class="h-[120px] rounded-[8px]  items-center flex gap-8 px-8 bg-[#2F3245]" *ngIf="getBondsColabs(bonds![i])">
      <div class="flex items-center gap-3 cursor-pointer max-w-[25%]" >
        <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/info.svg',}"></app-rounded-icon-buttom>
        What is {{ getBondsName(bonds![i]) }}
      </div>
      <div class="flex items-center gap-3 cursor-pointer">
        <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/star.svg',}"></app-rounded-icon-buttom>
        Valuate Asset
      </div>
      <div class="flex items-center gap-3 cursor-pointer">
        <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/heart.svg',}"></app-rounded-icon-buttom>
        Add to Watchlist
      </div>
      <div class="flex items-center gap-3 cursor-pointer">
        <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/trash.svg',}"></app-rounded-icon-buttom>
        Remove asset From Portfolio
      </div>
    </div>
</div>