import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Order } from '../models/Order';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  orders: Order[] = []
  private ordersSubject: Subject<Order[]> = new Subject<Order[]>();
  

  constructor() { }

  add(order:any){
    if(order.length > 0) this.orders.push(...order)
    else this.orders.push(order)
    this.ordersSubject.next(this.orders);
  }

  getOrders() {
    return this.orders
  }

  onYourOrdersChange(): Observable<Order[]> {
    return this.ordersSubject.asObservable();
  }
}
