<div class="flex flex-col md:flex-row mt-8 items-end align-bottom mb-4">
  <div class="w-full md:w-1/2 mr-3">

    <span class="text-[12px] text-[#E1E1E1]">Scenario</span>
    <app-button (click)="handleScenario()"
      [buttonData]="{textButton:scenarioAnalysis[currentPosition].title ,iconRightUrl:'assets/img/icon/input_arrow.svg',iconRight:true,   classButton:'!bg-yv-primary-70 !h-[3.2rem] w-full ',classText:'!text-[#FFFFFF]  mr-auto !text-[13px] !font-[400]'}" />
  </div>
  <div class="w-full md:w-1/2 ">
    <!--Para Scenario do tipo Non-Parallel Shift-->
    <div *ngIf="currentPosition==0">
      <span class="text-[14px]">BPS</span>
      <app-range-slider [data]="rangeData"></app-range-slider>
      <app-input-form [inputData]="{
        inputType: 'number',
        labelClass: 'hidden',
        inputFormClass: 'mt-4 h',
        label: '',
        placeholder: '0',
      }" />
    </div>

    <!--Para Scenario do tipo Non-Parallel Shift-->
    <div *ngIf="currentPosition==1" class="flex ">
      <div class="w-full md:w-1/2 ">
        <app-select-form [default]="'Scenario'" [items]="[ { value: 'A001', name: 'Scenario' }]"
          [selectData]="{selectFormClass:'!bg-none w-full',selectClass:'!bg-[#FFFFFF10] text-[#B3B3B380] !bg-[url(assets/img/icon/input_arrow.svg)] !py-[13.5px] !px-4 !mr-3  !h-auto',label:'', labelClass:'text-[12px] '}" />
      </div>
      <div class="w-full md:w-1/2 ">
        <app-select-form [default]="'Magnitude'" [items]="[ { value: 'A001', name: 'Magnitude' }]"
          [selectData]="{selectFormClass:'!bg-none w-full',selectClass:'!bg-[#FFFFFF10] text-[#B3B3B380] !bg-[url(assets/img/icon/input_arrow.svg)] !py-[13.5px] !px-4 !ml-3  !h-auto',label:'', labelClass:'text-[12px] '}" />
      </div>

    </div>

    <!--Para Scenario do tipo Credit Spread-->
    <div *ngIf="currentPosition==2">
      <app-range-slider [data]="rangeData"></app-range-slider>
      <app-input-form [inputData]="{
        inputType: 'number',
        labelClass: 'hidden',
        inputFormClass: 'mt-4 h',
        label: '',
        placeholder: '0',
      }" />
    </div>

    <!--Para Scenario do tipo Covid Pandemic, Great Financial Crisis e Dotcom Bubble-->
    <div *ngIf="currentPosition>2 " class="flex  justify-end ">

      <app-button (click)="handleContinue()"
        [buttonData]="{textButton:'Edit Portfolio',iconUrl:'assets/img/icon/edit.svg', IconLeft:true,  classButton:'!bg-transparent !h-[2.5rem]',classText:'!text-[#2CC784] !text-[13px] !font-[400]'}" />

    </div>


  </div>
</div>

<app-performance-card *ngIf="showScenarioStrategie" [cardTitle]="cardTitle" [itenIcons]="true"
  (currentPosition)="curentIndex($event)" [items]="scenarioAnalysis"></app-performance-card>

<div class="flex flex-col md:flex-row mt-8 ">
  <div class="w-full md:w-1/2 ">

    <span class="text-[12px] text-[#E1E1E1]">Select Investment Strategy</span>
    <app-button
      [buttonData]="{textButton:'Laddering',iconRightUrl:'assets/img/icon/input_arrow.svg',iconRight:true,   classButton:'!bg-[#FFFFFF10] !h-[3.2rem] w-full ',classText:'!text-[#FFFFFF]  mr-auto !text-[13px] !font-[400]'}" />
  </div>
  <div class="w-full md:w-1/2 ml-3">
    <span class="text-[12px] text-[#E1E1E1]">Select benchmark</span>
    <app-button
      [buttonData]="{textButton:'US Treasury Bond',iconRightUrl:'assets/img/icon/input_arrow.svg',iconRight:true,   classButton:'!bg-[#FFFFFF10] !h-[3.2rem] w-full ',classText:'!text-[#FFFFFF]  mr-auto !text-[13px] !font-[400]'}" />

  </div>
</div>

<div class="flex flex-col mt-8">

  <app-tabs [tabs]="[{name:'Performance'},{name:'Market Value'},{name:'Yield Curve'}]"
    [tabsData]="{classTab:'bg-transparent border-transparent hover:bg-[#58538F]'}" />

  <app-line-chart></app-line-chart>

  <div class="flex">

    <div class="mr-auto">

      <app-button (click)="handleContinue()"
        [buttonData]="{textButton:'Edit Portfolio',iconUrl:'assets/img/icon/edit.svg', IconLeft:true,  classButton:'!bg-transparent !h-[2.5rem]',classText:'!text-[#2CC784] !text-[13px] !font-[400]'}" />

    </div>

    <!-- Item à direita -->
    <div class="ml-auto">
      <app-button (click)="handleContinue()"
        [buttonData]="{textButton:'Continue',  classButton:'!bg-[#6761A8] !h-[2.5rem]',classText:'!text-[#FFFFFF] !text-[13px] !font-[400]'}" />
    </div>
  </div>


</div>