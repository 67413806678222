import { Component } from '@angular/core';
import { RoundedIconButtonInterface } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-example',
  templateUrl: './example.component.html',
  styleUrls: ['./example.component.scss'],
})
export class ExampleComponent {
  iconButton: RoundedIconButtonInterface = {
    iconUrl: 'assets/img/icon/bell.svg',
    isDotVisible: false,
    btnClass: '',
    iconClass: '',
  };

  handleButtonClick() {
    alert('Acertou!!');
  }

  tableColumns: string[] = ['Name', 'Age', 'Email'];
  tableData: any[] = [
    { Name: 'John Doe', Age: 25, Email: 'john@example.com' },
    { Name: 'Jane Smith', Age: 30, Email: 'jane@example.com' },
    { Name: '<a href="23"></a>', Age: 35, Email: 'bob@example.com' }
  ];
}
