import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RoboService } from 'src/app/services/robo.service';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})
export class PerformanceComponent implements OnInit {

  constructor(private roboService:RoboService){}

  ngOnInit(): void {
    //
    this.roboService.get("").subscribe({
      next:(data)=> {
        this.strategies = data
      },
      error(err) {
        
      },
    })

    //
    this.roboService.get("").subscribe({
      next:(data)=> {
        this.benchmarks = data
      },
      error(err) {
        
      },
    })

  }

  default: string = "Select"

  cardTitle = "Strategies"

  activateScenarioTest: boolean = false
  isCheckScnarioTest: boolean = false
  cardTitle2 = "Benchmark"
  strategies: any[] = [{
    title: "Ladder", icon: "assets/img/icon/ladder_icon.svg"
  }, {
    title: "Basic", icon: "assets/img/icon/basic_icon.svg"
  }, {
    title: "Index", icon: "assets/img/icon/index_icon.svg"
  }]

  benchmarks: any[] = [
    {
      title: "Us Treasury Bond", icon: ""
    },
    {
      title: "Ishares MSCI ACWI EFT", icon: ""
    },
    {
      title: "Invesco QQQ Trust", icon: ""
    },
    {
      title: "Vanguard S&P 500 ETF", icon: ""
    },
  ]

  handleContinue() { }
  reset() { }

  handleCheckScenarioTes() {
    this.isCheckScnarioTest = true;
  }

  showOptions = false;
  showOptionsBenchmark = false;
  handleScenario() {
    this.showOptions = !this.showOptions;
    this.showOptionsBenchmark = false;


  }

  handleBenchmark() {
    this.showOptions = false;
    this.showOptionsBenchmark = !this.showOptionsBenchmark;

  }

  currentPosition: number = 0;

  curentIndex(index: number) {
    this.currentPosition = index;
    this.showOptions = false
    this.activateScenarioTest = true
  }


}
