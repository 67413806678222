import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Lecture from 'src/app/models/Lecture';
import { EducationService } from 'src/app/services/education.service';

interface Module {
  id: string;
  title: string;
  haveAccess: boolean;
  order: string;
  lectures: Lecture[];
  progress: number;
  latestProgress: string;
  lastQuizProgress: any[]; // You might want to define a proper type for this array as well
}

interface ContentData {
  id: string;
  title: string;
  language: string;
  isPublished: boolean;
  modules: Module;
}

@Component({
  selector: 'app-education-layout',
  templateUrl: './education-layout.component.html',
  styleUrls: ['./education-layout.component.scss']
})
export class EducationLayoutComponent implements OnInit {
  @Input() module?: any;
  @Input() lectures?: any[] = [];
  @Input() quizzes: any[] = [];
  @Input() progress?: any[] = [];
  @Input() quizActive?: boolean
  @Input() course?: any = { title: "Nothing" }
  serviceData: any
  completedPercentage: number;
  totalPoints: number = 0;
  menuM = false
  constructor(
    private educationService: EducationService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient) {
    this.completedPercentage = 0
    this.serviceData = this.educationService.getContentData()
    console.log("serviceData EducationLayout",this.serviceData)

  }
  ngOnInit(): void {

    /* setTimeout(() => {
      this.calculateTotalPoints();
    }, 800); */

    this.educationService.getLectureIDObservable().subscribe((newLectureID) => {
      this.lectureID = newLectureID;
    });

    this.educationService.getProgressPercentageObservable().subscribe((progress) => {
      this.lectureProgressPercentage = progress
    });
    
    this.educationService.getProgressArrayObservable().subscribe((progress) => {
      this.progress = progress
    });

    this.educationService.getContentSubjectObservable().subscribe((progress) => {
      this.serviceData = progress
    });
  }
  calculateTotalPoints() {
    this.totalPoints = this.quizzes?.reduce((total, quiz) => total + (quiz.points || 0), 0);
  }
  toggleMenu() {
    this.menuM = !this.menuM;
  }
  goToEducation() {
    this.router.navigate(["/education"])
  }
  getCompletion(clicked: number, lectures: number) {



  }
  clickedIndices: number[] = [];
  clickedQuizIndices: number[] = [];


  // Rest of the component code...

  setClickedIndex(lectureIndex: number, lectures: number, quiz: number) {


    const lectureClickedIndex = this.clickedIndices.indexOf(lectureIndex);
    if (lectureClickedIndex !== -1) {
      // If the clicked lecture index is already in the array, remove it
      this.clickedIndices.splice(lectureClickedIndex, 1);
    } else {
      // If the clicked lecture index is not in the array, add it
      this.clickedIndices.push(lectureIndex);
    }
    let totalIndex = this.clickedIndices.length + this.clickedQuizIndices.length
    let totalData = lectures + quiz
    this.completedPercentage = totalIndex / totalData * 100;
    //console.log("lectures: clicked", totalIndex);
    //console.log("lectures: lectures", totalData);
  }
  setClickedQuizIndex(quizIndex: number, lectures: number, quiz: number) {
    const quizClickedIndex = this.clickedQuizIndices.indexOf(quizIndex);
    if (quizClickedIndex !== -1) {
      // If the clicked quiz index is already in the array, remove it
      this.clickedQuizIndices.splice(quizClickedIndex, 1);
    } else {
      // If the clicked quiz index is not in the array, add it
      this.clickedQuizIndices.push(quizIndex);
    }
    let totalIndex = this.clickedIndices.length + this.clickedQuizIndices.length
    let totalData = lectures + quiz
    this.completedPercentage = totalIndex / totalData * 100;
  }

  // Function to check if a lecture index is clicked or not
  isClicked(lectureIndex: number): boolean {

    return this.clickedIndices.includes(lectureIndex);
  }
  isClickedQuizIndex(quizIndex: number): boolean {
    return this.clickedQuizIndices.includes(quizIndex);
  }
  setCurrentLecture(content: any[]) {

    this.http.get<any[]>('https://dev.yieldvestor.com/fixed-income/api/v1/user_progress/current_progress').subscribe(
      data => { })

    this.educationService.select({
      type: "lecture",
      module: 1,
      id: 1,
      content: content
    })
  }

  @Input() lectureID?: string
  @Input() lectureProgressPercentage: string = "0"

  calculateProgressPercentage(): number {
    let quizLength = this.quizzes?.length
    let quizProgressLength = this.serviceData?.modules?.lastQuizProgress?.length
    let progress = parseInt(this.lectureProgressPercentage)
    if (quizLength === 0 && quizProgressLength===0){quizLength=1}
  
    let total = progress * 0.8 + ((quizProgressLength/quizLength * 100) * 0.2)
  /*   console.log(
      [[quizLength,typeof(quizLength),"TOTAL quizLength"],
      [quizProgressLength,typeof(quizProgressLength),"TOTAL quizProgressLength"],
      [progress,typeof(progress),"TOTAL progress"],
      total,typeof(total),"TOTAL"]
      ) */
  
    return total
  }
  toggleRead(newLectureID: string): void {
    this.educationService.setLectureID(newLectureID);
  }

  @Output()
  closeQuiz = new EventEmitter<boolean>();
  closeQuizAction() {
    this.closeQuiz.emit();
  }

  @Output()
  openQuiz = new EventEmitter<boolean>();
  openQuizAction(lecture: string) {
    this.openQuiz.emit();
    this.toggleRead(lecture)
  }

  quizIsFalse() {
    if (this.quizActive === false) {
      return {
        'background-color': '#FFFFFF',
      };
    } else {
      return {

        'background-color': '#81FFAF'
      }
    }
  }

  getBackgroundColor(lectureId: string) {
    const progressEntry = this.progress?.find(progressItem => progressItem.lectureId === lectureId);
    const selectedEntry = this.lectureID === lectureId
    if (selectedEntry && this.quizActive === false) {
      return {
        'background-color': '#81FFAF',
        "box-shadow": " 0 0 10px rgba(255, 255, 255, 1)",
        "pointer-events": "auto"
      };
    } else {

      if (progressEntry) {
        return {
          'background-color': '#81FFAF',
          "pointer-events": "auto",
        };
      } else {
        return {
          'background-color': '#FFFFFF',
          'pointer-events': 'none'
        };
      }
    }
  }
  shouldApplyCursorPointer(lectureId: string): boolean {
    const backgroundColor = this.getBackgroundColor(lectureId);
    return (
      backgroundColor['background-color'] !== '#FFFFFF' ||
      backgroundColor['pointer-events'] !== 'none'
    );
  }

  getQuizzColor() {
    if (this.quizActive === true) {
      return {
        'background-color': '#81FFAF',
        "box-shadow": " 0 0 10px rgba(255, 255, 255, 1)"
      };
    } else {
      return {

        'background-color': '#FFFFFF'
      }
    }
  }

  updateLectureID(newLectureID: string) {
    this.route.params.subscribe(params => {
      const moduleID = params['moduleID'];
      const language = params['language'];
  
      this.router.navigate([
        'education/lecture',
        moduleID,
        language,
        newLectureID
      ]);
    });
  }
  getLectureID(lecture: string) {
    //this.updateLectureID(lecture)
    this.toggleRead(lecture)
  }
}
