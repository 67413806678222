import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { InputChat } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-input-chat',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent],
  templateUrl: './input-chat.component.html',
  styleUrls: ['./input-chat.component.scss'],
})
export class InputChatComponent {
  @Input() chatData: InputChat = {
    classInput: '',
    classContainer: '',
  };
  @Input() btnClass = "!bg-[#2CC784]"
  @Input() iconUrl = 'assets/img/icon/mic.svg'
  @Input()
  inputValue?: string
  @Output() btnClick = new EventEmitter<void>();

  @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();

  onInputChange(event: Event): void {
    this.inputValue = (event.target as HTMLInputElement).value;
    this.inputValueChange.emit(this.inputValue);

  }

  handelKeyDown(ev: any) {
    if (ev.key === "Enter") {
      this.btnClick.emit();
      (ev.target as HTMLInputElement).value = ""
      console.log(ev);
    }
  }

  onBtnClick() {
    this.btnClick.emit();
    const inputElement = document.querySelector('.input-container input');
    if (inputElement) {
      (inputElement as HTMLInputElement).value = '';
    }
  }
}
