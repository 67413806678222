import { Component } from '@angular/core';

@Component({
  selector: 'app-identity-verification',
  templateUrl: './identity-verification.component.html',
  styleUrls: ['./identity-verification.component.scss']
})
export class IdentityVerificationComponent {

  levels: any[] = [
    { title: "Beginner:", desc: "I have limited or no \n investment\n experience.", icon: "assets/img/icon/chart.svg" },
    { title: "Intermediate:", desc: "I have some experience with different investment products.", icon: "assets/img/icon/like.svg" },
    { title: "Advanced:", desc: "I have extensive experience in investing across various asset classes.", icon: "assets/img/icon/fire.svg" },
  ]
  disclaimersConfirmations: any[] = [
    { title: "yes:", desc: "Yes, I understand." },
    { title: "no:", desc: "No, I'm not aware." },
  ]
  disclaimersInfos: any[] = [
    { title: "", desc: "Get on top of your debt and set aside some rainy day money!", icon: "assets/img/icon/fire.svg" },
    { title: "", desc: "Get on top of your debt and set aside some rainy day money?", icon: "assets/img/icon/fire.svg" },
    { title: "", desc: "Get on top of your debt and set aside some rainy day money.", icon: "assets/img/icon/fire.svg" },
  ]
  objectives: any[] = [
    { title: "A:", desc: "Capital preservation: Seeking to preserve the initial investment amount.", icon: "assets/img/icon/chart.svg" },
    { title: "B:", desc: "Income generation: Seeking regular income from interest payments.", icon: "assets/img/icon/like.svg" },
    { title: "C:", desc: "Total return: Seeking a combination of income and potential capital appreciation.", icon: "assets/img/icon/fire.svg" },
    { title: "D:", desc: "Diversification: Seeking to diversify the investment portfolio.", icon: "assets/img/icon/fire.svg" }
  ]
  phoneConfirmations: any[] = [
    { title: "yes:", desc: "I agree to the Yieldvestor Terms and Conditions and have the YirldTrust Privacy Policy" },
  ]
  signUps: any[] = [
    { title: "Sign up as individual:", desc: "You will need your photo ID to complete your account", icon: "assets/img/icon/chart.svg" },
    { title: "Sign up as company:", desc: "You will need you company number", icon: "assets/img/icon/like.svg" },
  ]
  emailConfirmations: any[] = [
    { title: "yes:", desc: "I wish to recieve promotinal emails from Yieldvestor." },
  ]
  facialsInfos: any[] = [
    { title: "Good lighting", desc: "Please maek sure you are in a well  lit area", icon: "assets/img/icon/fire.svg" },
    { title: "Look Straight", desc: "Hold your phone at eye and look straightat the camera. Have a neutral expression on your face.", icon: "assets/img/icon/fire.svg" },
  ]

  accounts: any[] = [
    { title: "Taxable", desc: "General Investment Account", icon: "assets/img/icon/chart.svg" },
    { title: "Tax-free account", desc: ".", icon: "assets/img/icon/like.svg" },
  ]

  steps = [
    { title: 'Location', checked: false },
    { title: 'Disclaimer', checked: false },
    { title: 'Phone Number', checked: false },
    { title: 'Phone Verification', checked: false },
    { title: 'User Type', checked: false },
    { title: 'Personal Details', checked: false },
    { title: 'Personal Details', checked: false },
    { title: 'Residential Address', checked: false },
    { title: 'Residential Address', checked: false },
    { title: 'Document Verification', checked: false },
    { title: 'Bank Verification', checked: false },
    { title: 'Account Opening', checked: false },
  ]
  currentStep: number
  constructor() {
    this.currentStep = 0
  }
  changeStep(val: number, pos: number) {

    let res = this.currentStep + val
    if ((val >= 0) && pos < this.steps.length) this.steps[pos].checked = true
    else if (val < 0) this.steps[pos].checked = false

    if (res >= 0 && res < this.steps.length && pos < this.steps.length) this.currentStep = res;

  }
}
