<div style="width: 100%; background-color: #0C1026;">
    <div style="width: 800px; background-color: transparent;">
        <app-line-chart></app-line-chart>
        <br>
        <app-area-chart></app-area-chart>
        <br>
        <app-column-range-chart></app-column-range-chart>
        <br>
        <app-scatter-chart></app-scatter-chart>
        <br>
        <app-column-chart-stacking></app-column-chart-stacking>
        <br>
        <app-gauge-chart></app-gauge-chart>
        <br>
        <app-cylinder-chart></app-cylinder-chart>
        <br>
        <app-spline-chart></app-spline-chart>
        <br>
        <br>
        <app-bars-chart category="Average Daily Trade Volume" [markerValue]=20></app-bars-chart>
        <app-bars-chart category="Average No. Daily Trades" [markerValue]=90></app-bars-chart>
        <app-bars-chart category="Average Trade Size" [markerValue]=60></app-bars-chart>
        <app-bars-chart category="Bid-Ask" [markerValue]=10></app-bars-chart>
        <app-bars-chart category="Total Liquidity Score" [markerValue]=50></app-bars-chart>
        <br>
        <br>
    </div>
