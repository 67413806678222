<app-user-layout>
    <div class="md:flex items-center justify-between mt-[2rem]">
        <div class="text-[7vw] md:text-[3rem]">Fixed income education</div>
        
          <div class="bg-transparent rounded-[2rem] flex items-center justify-center gap-[2rem] p-[2rem]">
            <ng-container *ngIf="!showSelect; else selectOption">
                <!-- Show this when badgeIsLoading is true -->
                <div id="selectLanguage" (click)="toggleSelect()" class="text-yv-white-50 bg-yv-primary-70 p-[1rem] rounded-xl hover:cursor-pointer">
                    {{getNameForValue(selectedLanguage)}}
                </div>
            </ng-container>

            <ng-template #selectOption>
                <select (change)="onSelectChange($event)" class="text-yv-white-50 bg-yv-primary-70 p-[1rem] rounded-xl">
                    <option class="text-[black]" [value]="selectedLanguage">{{getNameForValue(selectedLanguage)}}</option>
                    <ng-container *ngFor="let item of items">
                      <option class="text-[black]" *ngIf="item.value !== selectedLanguage" [value]="item.value">
                        {{ item.name }}
                      </option>
                    </ng-container>
                  </select>
                  
            </ng-template>
        </div>
          
        
        <div class="bg-transparent rounded-[2rem] flex items-center justify-center gap-[2rem] p-[2rem]">
            <ng-container *ngIf="isBadgesLoading(badges); else content">
                <!-- Show this when badgeIsLoading is true -->
                <div id="loading" class="w-[7rem]">
                    <img class="" src="assets/img/icon/logoLoading.svg" />
                </div>
            </ng-container>

            <ng-template #content>
                <!-- Show this when badgeIsLoading is false -->
                <app-rounded-icon-buttom
                    [buttonData]="{iconUrl:badges?.status_image,btnClass:' !bg-[#F9F9F91A] !h-[4rem] !w-[4rem] '}" />
                <div id="badgesStatus" class="flex flex-col">
                    <span class="text-[#B3B3B3] text-[1rem]"> My Status</span>
                    <span class="text-[1.5rem]"> {{badges?.title}}</span>
                </div>
                <!--  <div id="totalPoints" class="flex flex-col">
                    <span class=" text-[#B3B3B3] text-[1rem]"> Balance</span>
                    <span class="text-[#978EF5] text-[1.125rem] font-[600]"> 100 points</span>
                </div> -->
            </ng-template>
        </div>

    </div>
    <div *ngIf="loading === false">
        <app-course [content]="courses" />
    </div>
    <div *ngIf="loading === true" class="">

        <img class="" src="assets/img/icon/logoLoading.svg" />
    </div>
</app-user-layout>