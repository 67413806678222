import { Component } from '@angular/core';

@Component({
  selector: 'app-charts-example',
  templateUrl: './charts-example.component.html',
  styleUrls: ['./charts-example.component.scss']
})
export class ChartsExampleComponent {

}
