import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoCarouselComponent } from './video-carousel/video-carousel.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FooterComponent } from './footer/footer.component';
import { YieldvestorComponent } from './yieldvestor/yieldvestor.component';
import { RoundedIconButtomComponent } from '../components/rounded-icon-buttom/rounded-icon-buttom.component';
import { UserLayoutComponent } from './Layouts/user-layout/user-layout.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { VideoCardComponent } from '../components/video-card/video-card.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ButtonComponent } from '../components/button/button.component';
import { ChatBoxComponent } from './welcome/chat-box/chat-box.component';
import { StepperComponent } from './stepper/stepper.component';
import { StepperCounterComponent } from './stepper-counter/stepper-counter.component';
import { BackAndForwardComponent } from '../components/back-and-forward/back-and-forward.component';
import { TableComponent } from './table/table.component';
import { MyBondsTableComponent } from './my-bonds-table/my-bonds-table.component';
import { BondsCompanyComponent } from '../components/bonds-company/bonds-company.component';
import { ComparisonsTableComponent } from './comparisons-table/comparisons-table.component';
import { TwoDTableComponent } from './two-d-table/two-d-table.component';
import { RouterModule } from '@angular/router';
import { MobileMenuComponent } from './Layouts/user-layout/mobile-menu/mobile-menu.component';
import { SideBarComponent } from './Layouts/user-layout/side-bar/side-bar.component';
import { InputFormComponent } from '../components/input-form/input-form.component';
import { SelectFormComponent } from '../components/select-form/select-form.component';
import { FilterComponent } from './filter/filter.component';
import { RangeSliderComponent } from '../components/range-slider/range-slider.component';
import { LevelComponent } from '../components/level/level.component';
import { AmountComponent } from '../components/amount/amount.component';
import { SortViewComponent } from '../components/sort-view/sort-view.component';
import { EducationLayoutComponent } from './Layouts/education-layout/education-layout.component';
import { AnalitcsCardComponent } from './analitcs-card/analitcs-card.component';



@NgModule({
  declarations: [
    VideoCarouselComponent,
    FooterComponent,
    YieldvestorComponent,
    UserLayoutComponent,
    BarChartComponent,
    WelcomeComponent,
    ChatBoxComponent,
    StepperComponent,
    StepperCounterComponent,
    TableComponent,
    MyBondsTableComponent,
    ComparisonsTableComponent,
    TwoDTableComponent,
    MobileMenuComponent,
    SideBarComponent,
    FilterComponent,
    EducationLayoutComponent,
    AnalitcsCardComponent
  ],
  exports: [
    VideoCarouselComponent,
    FooterComponent,
    YieldvestorComponent,
    UserLayoutComponent,
    BarChartComponent,
    WelcomeComponent,
    StepperComponent,
    StepperCounterComponent,
    TableComponent,
    MyBondsTableComponent,
    ComparisonsTableComponent,
    TwoDTableComponent,
    MobileMenuComponent,
    FilterComponent,
    EducationLayoutComponent,
    AnalitcsCardComponent
  ],
  imports: [
    CommonModule,
    SlickCarouselModule,
    RoundedIconButtomComponent,
    VideoCardComponent,
    ButtonComponent,
    BackAndForwardComponent,
    BondsCompanyComponent,
    RouterModule,
    InputFormComponent,
    SelectFormComponent,
    RangeSliderComponent,
    LevelComponent,
    AmountComponent,
    SortViewComponent
  ]
})
export class SharedModule { }
