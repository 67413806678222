<div>
    <div class="lg:flex items-center justify-center md:pl-20">
        <div class="font-normal self-stretch flex-grow">
            <div class="text-[24px] pb-2">Risk Type</div>
            <div class="text-[16px] mb-4">Medium</div>
            <div class="text-[24px] mt-8 mb-2">Description</div>
            <div class="text-[16px] mb-4">Opportunity to target quarterly income payments while mitigating volatility of
                stocks. Opportunity to target quarterly income payments while mitigating volatility of stocks</div>
        </div>
        <div class="max-w-full">
            <app-gauge-chart></app-gauge-chart>
        </div>
    </div>
    <!--START TABLE-->
    <div class="lg:font-normal lg:text-[24px] lg:pl-20">Income schedule</div>
    <div class="lg:px-20 lg:mb-10">
        <div class="lg:my-8"><app-summary [items]="items" /></div>
        <div class=" flex lg:grid lg:grid-cols-3 justify-between lg:gap-2">
            <div class="bg-[#F9F9F91A] rounded-tl-2xl p-4 py-6">Period</div>
            <div class="bg-[#F9F9F91A] p-4 py-6">Interest</div>
            <div class="bg-[#F9F9F91A] lg:rounded-tr-2xl p-4 py-6">Payment date</div>
        </div>

        <div class=" grid gap-2 grid-cols-3 border-b border-[#4A4C57]" *ngFor="let row of table">
            <div class="p-4 py-6">{{row.period | number: '2.0' }}</div>
            <div class="p-4 py-6">{{row.interest}}</div>
            <div class="p-4 py-6">{{row.payment}}</div>
        </div>

    </div>

    <!--END TABLE-->
    <div class="flex justify-center">
        <app-button
            [buttonData]="{classButton:'!bg-transparent',textButton:'Get a new model',classText:'lg:!text-lg !text-sm'}" />
        <app-button
           (click)="
           addToCart([{title:'Yieldvestor Conservative',id:'',income:35,type:'ready'},
           {title:'Yieldvestor Aggressive',id:'',income:65,type:'ready'}])
           "
            [buttonData]="{iconUrl:'assets/img/icon/fund.svg',classButton:'lg:!h-[4rem] !h-[2rem] mt-[1rem] lg:!mt-0', classIcon:'rotate-180',IconLeft:true,textButton:'Invest',classText:'lg:!text-lg !text-sm'}" />
    </div>
</div>