import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { EducationService } from 'src/app/services/education.service';
import { UserService } from 'src/app/services/user.service';
interface NotificationCheckbox {
  email: boolean;
  appPush: boolean;
  sms: boolean;
  serviceNotification: string;
  [key: string]: boolean | string;
}
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  constructor(private userService: UserService, private keycloak: KeycloakService, private http: HttpClient) { }

  badges$?: Observable<any>;
  progress: any;
  settings: any;
  keytoken: string = '';
  ngOnInit(): void {
    this.keycloak.getToken().then((token: string) => {
      this.keytoken = token;
    });
    this.userService.getUserNotificationSettings().subscribe((data: any) => {
      if (Array.isArray(data) && data.length === 0) {
        // If data is an empty array, initialize this.checkboxValues accordingly
        this.checkboxValues = this.notifications.map(notification => ({
          email: false,
          appPush: false,
          sms: false,
          serviceNotification: notification.serviceNotification
        }));
      } else {
        this.checkboxValues = data;
      }
  
      console.log(this.checkboxValues, "settings");
    });
 
  }

  notifications = [
    { title: 'Security Alerts', description: 'Receive important alerts like password reset', serviceNotification: 'SECURITY_ALERT' },
    { title: 'Account activity', description: 'Receive updates about buy, sell and transfer', serviceNotification: 'ACCOUNT_ACTIVITY' },
    { title: 'Price alerts', description: 'Receive price change alerts for your watchlist', serviceNotification: 'MARKET_UPDATES' },
  ];

  checkboxValues: NotificationCheckbox[] = [
    {
      "email": false,
      "appPush": true,
      "sms": true,
      "serviceNotification": "SECURITY_ALERT"
    },
    {
      "email": true,
      "appPush": false,
      "sms": false,
      "serviceNotification": "ACCOUNT_ACTIVITY"
    },
    {
      "email": true,
      "appPush": true,
      "sms": true,
      "serviceNotification": "MARKET_UPDATES"
    }
  ];
  getCheckboxValue(serviceNotification: string, type: string): boolean {
    const checkboxValue = this.checkboxValues.find(
      (checkbox) => checkbox.serviceNotification === serviceNotification
    );
    //console.log(checkboxValue,"checkboxValues")
    return checkboxValue ? (checkboxValue[type] as boolean) : false;
  }
  updateCheckbox(serviceNotification: string, type: string, event: Event): void {
    const target = event.target as HTMLInputElement; // Cast event.target to HTMLInputElement
    const value = target.checked;

    const checkboxValueIndex = this.checkboxValues.findIndex(
      (checkbox) => checkbox.serviceNotification === serviceNotification
      );
      
      if (checkboxValueIndex !== -1) {
        this.checkboxValues[checkboxValueIndex][type] = value;
        
      }
      console.log(this.checkboxValues,"valueRequest")
    this.userService.changeUserNotificationSettings(this.checkboxValues).subscribe((data: any) => {
      this.checkboxValues = data
      console.log(data,"putRequest")
    })
    console.log("serverPost")
  }
}
