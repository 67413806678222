import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-comparisons-table',
  templateUrl: './comparisons-table.component.html',
  styleUrls: ['./comparisons-table.component.scss']
})
export class ComparisonsTableComponent {

  @Input() columns!: any[];
  @Input() assets?: any[];
  @Input() data?: any[];
}
