import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  @Input() current:number = 0
  @Input() tabsData:any = {
    classTab:''
  }
  @Input()
  tabs: any[] = [{name:'Bonds',action:()=> {},iconUrl:'assets/img/icon/clock.svg'},{name:'Invests',action:()=> {}},{name:'Etc',action:()=> {}}]

}
