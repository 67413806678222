<div class="rounded-3xl overflow-hidden justify-between w-auto {{iconRightUrl? 'rounded-tr-none':'rounded-tl-none'}} inline-block max-w-full bg-yv-primary-70 
{{
  !editMessage? CardChatData.cardClass:''
  }}
  {{ iconRightUrl && editMessage ? '!bg-transparent border border-blue-200' : '' }}
  
  animate-in slide-in-from-bottom duration-1000
  md:px-8 md:py-6 p-4
  " style="
    padding-top: 16px;
    padding-bottom: 16px;
  ">
  <div class="w-full max-w-screen-sm inline-flex items-center">
    <div class="me-5 mb-[0.5rem]" *ngIf="type!=='text' && !iconRightUrl && iconLeftUrl">
      <app-rounded-icon-buttom [buttonData]="{
        iconUrl: iconLeftUrl,
        btnClass: iconLeftClass
        , iconClass:'w-1/2'
      }" />
    </div>
    <div *ngIf="!editMessage" class="flex-grow" [innerHTML]="text"></div>
    <div *ngIf="iconRightUrl !== '' && editMessage" class="flex-grow px-4">
      <textarea class="
      text-yv-white-50
      text-base
      overflow-y-auto
      m-0
      resize-none
      border-0 
      bg-transparent
      p-0 
      focus:ring-0 
      focus-visible:ring-0
      focus:outline-none 
      min-w-[10rem]
      md:min-w-[24rem]
      lg:min-w-[28rem]
      min-h-[72px]
      "
      placeholder={{textAreaPlaceholder}}
       [(ngModel)]="text"
        (input)="adjustTextAreaHeight($event)"></textarea>

    </div>

    <div class="ms-5 flex items-center" *ngIf="iconRightUrl !== ''">
      <span id="editButton" *ngIf="iconRightUrl !== '' && !editMessage && !saveMessage" class="hover:cursor-pointer"
        (click)="handleEditMessage()">
        <app-rounded-icon-buttom [buttonData]="{
     iconUrl: 'assets/img/icon/edit.svg',
     btnClass: 'h-[1.6rem] w-[1.6rem] !bg-transparent', 
     iconClass:'w-1/2'
   }" />
      </span>
      <app-rounded-icon-buttom [buttonData]="{ iconUrl: iconRightUrl, btnClass: iconRightClass}" />
    </div>
  </div>

  <ng-content></ng-content>

  <div id="feedBackButtons" class="flex gap-[1rem] mt-[1rem] animate-in slide-in-from-bottom duration-1000">
    <span id="likeButton" *ngIf="iconRightUrl === ''" class="hover:cursor-pointer" (click)="handleVote(index, 'liked')">
      <app-rounded-icon-buttom [buttonData]="{
        iconUrl: content[index]?.vote === 'liked' ? 'assets/img/icon/thumbsUpGlow.svg' : 'assets/img/icon/thumbsUpBlank.svg',
      btnClass: 'h-[1.6rem] w-[1.6rem] !bg-transparent', 
      iconClass:'w-1/2'
    }" />
    </span>
    <span id="dislikeButton" *ngIf="iconRightUrl === ''" class="hover:cursor-pointer"
      (click)="handleVote(index, 'disliked')">
      <app-rounded-icon-buttom [buttonData]="{
        iconUrl: content[index]?.vote === 'disliked' ? 'assets/img/icon/thumbsUpGlowRed.svg' : 'assets/img/icon/thumbsUpBlank.svg',
      btnClass: 'rotate-180 h-[1.6rem] w-[1.6rem] !bg-transparent', 
      iconClass:'w-1/2 mt-[-0.3rem]'
    }" />
    </span>
    <span id="saveButton" *ngIf="iconRightUrl !== '' && editMessage && !saveMessage">
      <app-button (click)="handleSaveMessage(index,text)"
        [buttonData]="{textButton:'Save',classButton:'!bg-[#978EF51A] !h-[2.5rem]',classText:'!text-[#2CC784] !font-[400]'}" />
    </span>
    <span id="cancelButton" *ngIf="iconRightUrl !== '' && editMessage && !saveMessage">
      <app-button (click)="handleEditMessage()"
        [buttonData]="{textButton:'Cancel',classButton:'!bg-[#978EF51A] !h-[2.5rem]',classText:'!text-[#FFFFFF] !font-[400]'}" />
    </span>
    <span itemid="savedMessage">
      <app-button *ngIf="iconRightUrl !== '' && saveMessage"
        [buttonData]="{textButton:'Saved successfully',classButton:'!bg-[#98F2B90D] h-[2.5rem] rounded-xl',classText:'!text-[#2CC784] !font-[400]'}" />
    </span>

  </div>
</div>
<div class="flex flex-wrap gap-[1rem] max-w-screen-md pt-[0.25rem] pb-[0.75rem] animate-in slide-in-from-bottom duration-1000" *ngIf="isResponseTriggered">
  <div *ngFor="let p of prompts; let i = index" class="">
    <div class="text-lef border-[#FFFFFF1A] border max-w-sm rounded-xl p-[0.5rem] min-h-[3rem] flex items-center cursor-pointer">
      <span>
        {{p.prompt}}
      </span>
    </div>
  </div>
</div>
