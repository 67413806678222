import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { BondsCompany } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-bonds-company',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent],
  templateUrl: './bonds-company.component.html',
  styleUrls: ['./bonds-company.component.scss'],
})
export class BondsCompanyComponent {
  @Input() detailed:boolean = true; 
  @Input() bondsCompanyData: BondsCompany = {
    logo: 'assets/img/temp/bondsLogoAlaska.svg',
    bondsCompanyClass: '',
    iconClass: '',
    firstText: 'Alaska, 5.342% 08/2027',
    firstTextClass: '',
    secondText: 'US011770U778',
    secondTextClass: '',
  };
}
