import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { BondsCompanyComponent } from '../bonds-company/bonds-company.component';
import { BondsWatchlistAssets } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-bonds-watchlist-assets',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent, BondsCompanyComponent],
  templateUrl: './bonds-watchlist-assets.component.html',
  styleUrls: ['./bonds-watchlist-assets.component.scss'],
})
export class BondsWatchlistAssetsComponent {
  @Input() bondsData: BondsWatchlistAssets = {
    iconHeader: 'assets/img/icon/watchlist-icon.svg',
    iconHeaderClass: 'w-[20px]',
    firstTextHeader: 'Most trated this month',
    secondTextHeader: '1025 Investments',
    firstTextHeaderClass: '',
    secondTextHeaderClass: '',
    iconBonds: 'assets/img/icon/logo-two.svg',
    firstTextBonds: 'Brazil, Federative Republic 8.75% 05/25',
    secondTextBonds: 'US105756BF62',
    firstTextBondsClass: '',
    secondTextBondsClass: '',
  };

  @Output() btnLogoClick = new EventEmitter<void>();
  @Output() btnLikeClick = new EventEmitter<void>();
  @Output() btnDetailsClick = new EventEmitter<void>();
  @Output() btAddToCartClick = new EventEmitter<void>();

  onAddToCartClick() {
    this.btAddToCartClick.emit();
  }

  onDetailsClick() {
    this.btnDetailsClick.emit();
  }

  onLikeClick() {
    this.btnLikeClick.emit();
  }
}
