<div class="md:flex justify-center items-center">
    <div class="chart md:w-[400px] w-full items-center justify-center" [id]="dynamicId"></div>
    <div class="contend md:w-[400px] w-full">
        <div class="first-item">
            <h3>Yieldvestor Conservative</h3>
            <p>35%</p>
            <div class="track">
                <div [ngStyle]="{'width.%': width1}" class="fill-1"></div>
            </div>
        </div>
        <div class="second-item">
            <h3>Yieldvestor Aggressive</h3>
            <p><span>65%</span></p>
            <div class="track">
                <div [ngStyle]="{'width.%': width2}" class="fill-2"></div>
            </div>
        </div>
    </div>
</div> 