<div class="flex flex-col gap-10 max-w-full">
    <div class="bg-yv-5%-gray rounded-2xl p-8">
        <div class="flex justify-between">
            <div class="md:py-8"><app-bonds-company [bondsCompanyData]="{ 
                firstText: content?.firstText,
                logo:content?.logo,
                secondText:content?.codeText
            }" /></div>
            <div class="flex flex-col items-center font-bold text-[#98F2B9] md:text-[40px]">
                $116.36
                <app-button
                    [buttonData]="{textButton:'Order',classButton:'!py-3 !px-4 !h-auto',classText:'text-sm !py-0'}" />
            </div>
        </div>
        <div class="">
            <app-tabs [tabs]="[{name:'Basic'},{name:'Advanced'},{name:'Premium'}]" />
            <app-summary [items]="[
  { title: 'Issue date', iconUrl: 'assets/img/icon/clock.svg', value: content ? getContentValue('Issue Date') : 'N/A', classText: '!text-[#B3B3B3]' },
  { title: 'Issue Amount', iconUrl: 'assets/img/icon/clock.svg', value: content ? getContentValue('Issue Amount') : '15,000,000' },
  { title: 'Interest rate', iconUrl: 'assets/img/icon/clock.svg', value: content ? getContentValue('Interest rate') : '5,34%' },
  { title: 'Yield', iconUrl: 'assets/img/icon/arrow-base.svg', value: content ? getContentValue('Yield') : '4.66%', btnClass: '!bg-[#98F2B9]', classText: 'text-white' },
  { title: 'Issuer', iconUrl: 'assets/img/icon/clock.svg', value: content ? getContentValue('Issuer') : 'Alaska' },
  { title: 'Instrument', iconUrl: 'assets/img/icon/clock.svg', value: content ? getContentValue('Instrument type') : 'Municipal Bonds' }
]"></app-summary>

        </div>
    </div>
    <div>
        <app-tabs
            [tabsData]="{active:'!bg-[#978EF5]',class:'gap-0 bg-[#FFFFFF11] !p-1 mb-9 rounded-full',classTab:'border-0'}"
            [tabs]="chartTabs" />
        <div class="">
            <ng-container [ngSwitch]="currentTab">
                <div class="overflow-auto">
                    <div class="min-w-[200%] md:min-w-0">
                        <app-area-chart *ngSwitchCase="'h'"></app-area-chart>
                        <app-column-chart-stacking *ngSwitchCase="'s'"></app-column-chart-stacking>
                        <app-column-chart-stacking *ngSwitchCase="'ba'"></app-column-chart-stacking>
                        <div *ngSwitchCase="'li'" class="flex flex-col">
                            <app-bars-chart category="Average Daily Trade Volume" [markerValue]=20></app-bars-chart>
                            <app-bars-chart category="Average No. Daily Trades" [markerValue]=90></app-bars-chart>
                            <app-bars-chart category="Average Trade Size" [markerValue]=60></app-bars-chart>
                            <app-bars-chart category="Bid-Ask" [markerValue]=10></app-bars-chart>
                            <app-bars-chart category="Total Liquidity Score" [markerValue]=50></app-bars-chart>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>