<div id="widget" class=" fixed bottom-0 right-0 justify-center bg-[#F9F9F91A] mr-10 px-5 max-h-[50vh] max-w-sm overflow-auto duration-500">
    <div [ngClass]="{'rounded-t-2xl transform translate-y-0 bg-white duration-500 px-4 ': open,
    'translate-y-full duration-00':!open}">
    <app-responses [content]="getSamples()" [widget]="true" />



    <div class="flex justify-end pr-5 z-50">
        
        <app-input-chat 
        *ngIf="open" [(inputValue)]="currentPrompt" (btnClick)="onSend(currentPrompt)" 
        [chatData]="{classInput:''}"
        />
    </div>
  </div>
</div>
<div class="{{!open?'fixed bottom-0 right-0 pb-10 pr-10':'fixed'}}">
<app-rounded-icon-buttom
    [buttonData]="{
      iconUrl: 'assets/img/icon/chat.svg',
      btnClass: !open? '!bg-[#2CC784] h-20 w-20 shadow-[0px_0px_50px_20px_#2CC784] duration-500 scale-100 ':'scale-0 duration-500'
    }"
    (btnClick)="handleToggle()"
  ></app-rounded-icon-buttom>
</div>