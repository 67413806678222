import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent {
  @Input() name: string = "John Braun II"
  @Input() email: string = "shishovadiz@gmail.com"
}
