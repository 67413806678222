import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExampleComponent } from './views/example/example.component';
import { HomeComponent } from './views/home/home.component';
import { ChatComponent } from './views/assistant/chat/chat.component';
import { ChartsExampleComponent } from './views/charts-example/charts-example.component';
import { AuthGuard } from './services/guard';
import { PortfolioComponent } from './views/portfolio/portfolio.component';
//import { PerformanceComponent } from './components/performance/performance.component';

const routes: Routes = [
  // { path: '', component: LandpageComponent },

  //View Components
  { path: 'example', component: ExampleComponent },
  { path: 'charts', component: ChartsExampleComponent },

  // //Auth
  // { path: 'auth/login', component: LoginComponent },
  // { path: 'auth/register', component: RegisterComponent },
  // { path: 'auth/identity-verification', component: IdentityVerificationComponent },
  // //START
  // { path: 'start', component: FirstStepsComponent },
  //Pessoal Area
  { path: '', component: HomeComponent, canActivate: [AuthGuard]  },
  { path: 'auth', loadChildren: () => import('./views/auth/auth.module').then((m) => m.AuthModule) },
  // { path: 'user/profile', component: ProfileComponent, canActivate: [AuthGuard]  },
  // { path: 'assistant', component: AssistantComponent, canActivate: [AuthGuard]  },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard]  },
//   { path: 'assistant/chatdemo', component: ChatComponent, canActivate: [AuthGuard]  },
//   { path: 'robo', component: OnboardingComponent, canActivate: [AuthGuard]  },
//   { path: 'robo/result', component: ResultComponent, canActivate: [AuthGuard]  },
//   { path: 'robo/performance', component: PerformanceComponent, canActivate: [AuthGuard]  },
   { path: 'portfolio', component: PortfolioComponent, canActivate: [AuthGuard]  },
//   { path: 'education', component: EducationComponent, canActivate: [AuthGuard]  },
//   { path: 'education/lecture/:moduleID/:language/:lectureID', component: LecturePageComponent, canActivate: [AuthGuard] }

 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
