import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Highcharts from 'highcharts';
import HC_3D from 'highcharts/highcharts-3d';
import Cylinder from 'highcharts/modules/cylinder';

HC_3D(Highcharts);

Cylinder(Highcharts);

@Component({
  selector: 'app-cylinder-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cylinder-chart.component.html',
  styleUrls: ['./cylinder-chart.component.scss']
})
export class CylinderChartComponent {

  dynamicId = `component_${Math.floor(Math.random() * 10000)}`


  ngAfterViewInit(): void {
    this.renderChart();
  }

 /*  data = [169339, 115321]
  value = this.data[0] - this.data[1]; */
  data = [100,66]
  value = 33.4;

  renderChart() {

    const chartOptions: Highcharts.Options = {



      chart: {
        type: 'cylinder',
        backgroundColor: 'transparent',
        options3d: {
          enabled: true,
          alpha: 5,
          beta: 0,
          depth: 180,
          viewDistance: 500
        }
      },
      title: {
        text: '',
      },

      credits: {
        enabled: false
      },

      legend: {
        enabled: false
      },

      xAxis: {
        //categories: ['Current', 'Model', 'Confirmed'],
        categories:[""],
        gridLineColor: '#ffffff33',
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1.3,
        lineColor: '#ffffffcc',
        lineWidth: 2,
        labels: {
          style: {
            color: '#F9F9F9',
            fontWeight: 'bold',
            fontSize: '16px',
            fontFamily: 'Poppins',
          }
        }
      },
      yAxis: {
        gridLineColor: '#ffffff33',
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1.3,
        lineColor: '#ffffffcc',
        lineWidth: 2,
        min: 0,
        title: {
          text: ''
        },
        labels: {
          enabled: false
        }
      },
      tooltip: {
        //headerFormat: '<b>Age: {point.x}</b><br>'
        headerFormat: '<b>{point.x}</b><br>'
      },
      plotOptions: {

        cylinder: {
          depth: 180,
          // colorByPoint: true,
          // colors : ['#6761A899', '#FF936599'],
          // colors : ['#FF0000', '#FFFF00', '#0000FF'],            // colors : ['#FF0000', '#FFFF00', '#0000FF'],

          stacking: 'normal',
        }
      },

      // series: [{
      //     data: [this.data[0], this.data[1], this.value ],
      //     name: 'Cases',
      //     showInLegend: false
      // } as any]

      series: [{
        name: 'current',
        data: [this.data[0]],
        color: '#6761A899',
      },
      {
        data: [this.value],
        stack: 'Deaths',
        color: '#FF936533',
        dataLabels: {
          enabled: true,
          inside: false,
          align: 'center',
          verticalAlign: 'top',
          y: -50,
          style: {
            color: '#ffffff',
            fontSize: '24px',
            fontFamily: 'Poppins',
            fontWeight: '600',
          }
        }
      },
      {
        name: 'Model',
        data: [this.data[1]],
        stack: 'Deaths',
        color: '#FF936599',
      } as any]


    }

    Highcharts.chart(this.dynamicId, chartOptions);

  }

}
