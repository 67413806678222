<div class="w-[280.75px] h-[418px] px-6 py-10 bg-white bg-opacity-5 rounded-2xl flex-col justify-start items-start gap-6 inline-flex">
    <div class="flex justify-between w-full items-center">
    <div class="text-neutral-200 text-lg font-normal font-['Poppins'] leading-normal">{{title}}</div>
    
    <div class="flex gap-2">
        <app-rounded-icon-buttom
        (btnClick)="step = 0"
        [buttonData]="{ iconUrl: 'assets/img/icon/pie-chart.svg'}" ></app-rounded-icon-buttom>
        <app-rounded-icon-buttom
        (btnClick)="step = 1"
        [buttonData]="{ iconUrl: 'assets/img/icon/sale.svg'}"></app-rounded-icon-buttom>
    </div>
    </div>

<div class="w-full" *ngIf="step == 1" >
    <ng-container *ngFor="let item of list, let i = index">
    <div class="flex justify-between w-full p-4" >
    <div class="flex-grow">{{item.key}}</div>
    <div class="text-green-500">{{item.value}}</div> 
    </div>
    <hr *ngIf="i +1 < list.length" class="border-yv-5%-gray" />
    </ng-container>
</div>

<div class="w-full" *ngIf="step == 0">
    CHART NOT IMPLETENTED
    <img src="assets/img/temp/Frame 1000005129.svg" />
    
</div> 
</div>