<app-user-layout>

    <app-stepper-vertical [current]="currentStep" [steps]="steps" [onboardingQuestions]="onboardingQuestions"
        (nextClick)="changeStep(1,currentStep)" (prevClick)="changeStep(-1,currentStep)">
        <ng-container *ngFor="let qa of qAs,let i = index" >
            <ng-container *ngIf="currentStep == i" >
                <ng-container *ngFor="let q of qa.questions">
        <div *ngIf="q.type =='text'" class="max-w-screen-md mx-auto">
            <p class="font-regular text-[22px] p-10 max-w-lg mx-auto text-center">
                {{q.question}}</p>
            <div class="flex items-center justify-center">
                <input autofocus type="number"
                (change)="buildPayload({
                    categoryID:qa.categoryId,
                    event:$event,
                    id:q.questionId

                })"
                    class="remove-arrow text-gradient bg-gradient-to-r from-[#564CBD] to-[#CB74EC] caret-shape-bar  text-transparent caret-[#CB74EC]  bg-clip-text bg-transparent border-transparent focus:border-transparent focus:outline-none outline-0  m-1 w-24 text-center content-center text-[48px] "
                    placeholder="0" name="money" />
                <span class="text-[#FFFFFF70] text-[32px]"> 
                    {{qa.categoryId == 1?"years":""}}
                    {{qa.categoryId == 3?"%":""}}</span>
            </div>
        </div>

        <div *ngIf="q.type =='mcq' && qa.categoryId ==2" class="max-w-screen-md mx-auto">
            <p class="font-regular text-[22px] pt-10 max-w-lg mx-auto text-center">
                {{q.question}}</p>
            <p class=" text-[13px] text-[#FFFFFF60] mt-2 max-w-lg mx-auto text-center">Choose the option that is the
                most relevant to you.</p>


            <div class="flex items-center justify-center mt-16">
                <svg viewBox="0 0 120 120" class="transform -rotate-90 w-52 h-52">
                    <circle [attr.r]="radius" cx="60" cy="60" stroke-width="10" class="progress__meter" />
                    <circle [style.strokeDasharray]="circumference" [style.strokeDashoffset]="dashoffset"
                        [attr.r]="radius" cx="60" cy="60" fill="transparent" stroke-width="10" stroke-linecap="round"
                        stroke="url(#gradient)" />

                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" style="stop-color:#564CBD" />
                            <stop offset="100%" style="stop-color:#CB74EC" />
                        </linearGradient>
                    </defs>
                </svg>
                <div class="absolute flex flex-col items-center justify-center">
                    <span class="text-[24px]">{{currentPercentage.title}}</span>
                    <span class="text-[18px] text-[#FFFFFF60] mt-2">investment</span>
                </div>
                
            </div>

            <div class="grid grid-cols-3 gap-6 mt-8">
                <div *ngFor="let percentage of q.responseList, let i = index">
                    <button
                        class="text-[#E1E1E1]  rounded p-1 px-4 py-2 text-[14px] text-gray-100 transition bg-[#FFFFFF05] rounded-md h-14 w-44 border-solid border hover:border-[#978EF5]"
                        [ngClass]="percentage.answer==currentPercentage?.answer ? '!border-[#564CBD] shadow-lg font-semibold': 'border-transparent'"
                        (click)="changePercentage(percentage);buildPayload({
                            categoryID:qa.categoryId,
                            response:percentage.answer,
                            responseID:percentage.answerId,
                            id:q.questionId
    
                        })">{{percentage.answer}}</button>
                    </div>
            </div>


        </div>

        <!-- <div *ngIf="currentStep == 2" class="max-w-screen-md mx-auto">
            <p class="font-regular text-[22px] pt-10 max-w-lg mx-auto text-center">
                {{onboardingQuestions.at(currentStep)}}</p>
            <p class=" text-[13px] text-[#FFFFFF60] mt-2 max-w-lg mx-auto text-center">Please enter a percentage, not
                greater than 6%.</p>


            <div class="flex items-center justify-center mt-4">
                <input type="number"
                    class="remove-arrow text-gradient !appearance-none bg-gradient-to-r from-[#564CBD] to-[#CB74EC] caret-shape-bar  text-transparent caret-[#CB74EC]  bg-clip-text bg-transparent border-transparent focus:border-transparent focus:outline-none outline-0  m-1 w-[90px] text-center content-center text-[48px] "
                    placeholder="0" name="percentage"  />
                <span class="text-[#FFFFFF70] text-[32px]">%</span>

                
            </div>


        </div> -->

        <div *ngIf="q.type =='mcq' && qa.categoryId == 4" class="max-w-screen-md mx-auto">
            <p class="font-regular text-[22px] pt-10 max-w-lg mx-auto text-center">
                {{q.question}}</p>
            <p class=" text-[13px] text-[#FFFFFF60] mt-2 max-w-lg mx-auto text-center">Choose the option that is the
                most relevant to you.</p>


            <div class="grid grid-cols-3 gap-6 mt-8">
                <div *ngFor="let risk of q.responseList, let i = index">

                    <div (click)="changeRisk(risk);buildPayload({
                        categoryID:qa.categoryId,
                        response:risk.answer,
                        responseID:risk.answerId,
                        id:q.questionId

                    })"
                        class="px-4 py-8 flex flex-col justify-center items-center  text-gray-100 transition bg-[#FFFFFF05] rounded-md  w-[192px] border-transparent border-solid border hover:border-[#978EF5]"
                        [ngClass]="risk.answer==currentRisk?.answer ? '!border-[#564CBD] shadow-lg': 'border-transparent'">
                        <img height="90" mt-4 [src]="levelofRisk[i].icon" alt="risk.title"
                        
                         />
                        <p class="text-[13px] mt-4 text-gray-100 transition ">{{risk.answer}}</p>

                    </div>

                </div>
            </div>


        </div>

        <div *ngIf="q.type =='mcq' && qa.categoryId != 2  && qa.categoryId != 4" class="max-w-screen-md mx-auto">
            <p class="font-regular text-[22px] pt-10 max-w-lg mx-auto text-center">
                {{q.question}}</p>
            <p class=" text-[13px] text-[#FFFFFF60] mt-2 max-w-lg mx-auto text-center">Choose the option that is the
                most relevant to you.</p>


            <div class="grid grid-cols-3 gap-6 mt-8">
                <div *ngFor="let objective of q.responseList, let j = index">

                    <div (click)="changeObjetive(objective);buildPayload({
                        categoryID:qa.categoryId,
                        response:objective.answer,
                        id:q.questionId,
                        responseID:objective.answerId

                    })"
                        class="px-4 py-8 flex flex-col justify-center items-center  text-gray-100 transition bg-[#FFFFFF05] rounded-md  w-[192px] border-transparent border-solid border hover:border-[#978EF5]"
                        [ngClass]="objective.answer==currentObjective?.answer ? '!border-[#564CBD] shadow-lg': 'border-transparent'">
                        <img height="90" mt-4 [src]="invertmenetObjective[j].icon" alt="objective.title" />
                        <p class="text-[13px] mt-4 text-gray-100 transition ">{{objective.answer}}</p>

                    </div>

                </div>
            </div>


        </div>
    </ng-container>
    </ng-container>
    </ng-container>
    </app-stepper-vertical>
</app-user-layout>