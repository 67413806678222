import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { EducationService } from 'src/app/services/education.service';
import Module from "src/app/models/Module";
import StudentProgress from 'src/app/models/StudentProgress';
import { HttpClient } from '@angular/common/http';
import { SelectItems } from 'src/app/models/ComponentInput';
@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  courses$?: Observable<any>;
  modules$?: Observable<any>;
  progress$?: Observable<any>;
  badges$?: Observable<any>;
  progress: any;
  badges: any;
  quizProgress: any;
  module: any[] = [];
  courses: any[] = [];
  keytoken: string = '';
  userId: string = '';
  tokenUrl: string = '';
  badgeID?: string = "BADGE_ZERO"
  loading: boolean = true
  badgeIsLoading: boolean = true
  items: SelectItems[] = [
    { value: 'en', name: 'English' },
    { value: 'pt', name: 'Portuguese' },
  ]
  selectedLanguage: string = "en"

  onSelectChange(event: Event) {
    this.isLoading(true)
    const value = (event.target as HTMLSelectElement).value;
    this.selectedLanguage = value;
    console.log(this.selectedLanguage, "selectedLanguage");
    sessionStorage.setItem('educationSelectedLanguage', this.selectedLanguage);
    this.toggleSelect()
    this.refreshData()
  }
  

  constructor(private educationService: EducationService, private keycloak: KeycloakService, private http: HttpClient) { }

  ngOnInit(): void {
    // this.educationService.login(() => { });

    const storedLanguage = sessionStorage.getItem('educationSelectedLanguage');
    this.selectedLanguage = storedLanguage || 'en';
    this.refreshData()
    this.educationService.getStudentBadges().subscribe((data) => {
      this.getBadge(data)

      console.log(data, "YieldLevelBadge")
    });
    this.keycloak.getToken().then((token: string) => {
      this.keytoken = token;
      console.log('Bearer ', this.keytoken);

      // Get the user ID (subject) from the Keycloak token
      const jwtData = token.split('.')[1];
      const decodedJwt = JSON.parse(atob(jwtData));
      this.userId = decodedJwt.sub; // 'sub' represents the user ID in the JWT payload
      console.log('User ID:', this.userId);

      // Get the token URL from the token details (if available)
      const tokenDetails = this.keycloak.getKeycloakInstance().tokenParsed;
      this.tokenUrl = tokenDetails?.iss || ''; // Use a default empty string if the token URL is undefined
         console.log('Token URL:', this.tokenUrl);
    });
  }
  refreshData() {
    this.educationService.getCoursesByStudent(this.selectedLanguage).subscribe((data) => {
      this.courses = data;
      // Fetch modules for each course using their id
      this.courses?.forEach((course) => {
        this.educationService?.getStudentModules(course.id, course.language).subscribe((modulesData) => {
          course.modules = modulesData;
          course.modules.forEach((module: Module) => {
            if (module.haveAccess) {
              this.educationService.getStudenProgressByModule(module.id).subscribe((progressData) => {
                this.progress = progressData;
                //console.log("educationComponent progressData", progressData)
                if (progressData.length > 0) {
                  this.educationService.setProgressPercentage(this.progress[0].progressPercentage)
                  let completionPercentage = this.progress[0].progressPercentage
                  if (completionPercentage > 100) {
                    completionPercentage = 100;
                  }
                  module.progress = completionPercentage
                  module.latestProgress = this.progress[this.progress.length - 1].lectureId
                  this.educationService.getStudentQuizAnswerByModule(module.id).subscribe((progressData) => {
                    module.lastQuizProgress = progressData
                  })
                  const hasTargetValue = this.progress.some((obj: StudentProgress) => obj.completed === true);
                  if (hasTargetValue === true) {
                    this.educationService.getStudentQuiz(module.id, course.language).subscribe((progressData) => {
                      if (progressData) {
                        module.quiz = progressData
                      }
                    })

                  }
                } else {
                  this.educationService.setProgressPercentage("0")
                  module.latestProgress = ""
                  module.lastQuizProgress = []
                  module.total = "0"
                }
              });

            } else { module.total = "0" }
          });
          course.modules.sort((a: Module, b: Module) => a.order - b.order);
          console.log(`course ${course.title}:`, course);
        });
        this.isLoading(false)
      });
    });
  }
  getBadge(string: string) {
    switch (string) {
      case 'BADGE_ZERO':
        //Learning Newbie
        this.educationService.getStudentBadgesByID("85").subscribe((data) => {
        //console.log(data, "CSMBadges")
          this.badges = data
        });

        break;
      case 'BADGE_ONE':
        //Learning Grower
        this.educationService.getStudentBadgesByID("87").subscribe((data) => {
        //console.log(data, "CSMBadges")
          this.badges = data
        });
        break;
      case 'BADGE_TWO':
        //Learning Adventurer
        this.educationService.getStudentBadgesByID("91").subscribe((data) => {
        //console.log(data, "CSMBadges")
          this.badges = data
        });
        break;
      case 'BADGE_THREE':
        //Learning Explorer
        this.educationService.getStudentBadgesByID("84").subscribe((data) => {
        //console.log(data, "CSMBadges")
          this.badges = data
        });
        break;
      case 'BADGE_FOUR':
        //Learning Star
        this.educationService.getStudentBadgesByID("86").subscribe((data) => {
        //console.log(data, "CSMBadges")
          this.badges = data
        });
        break;
      case 'BADGE_FIVE':
        //Learning Superstar
        this.educationService.getStudentBadgesByID("88").subscribe((data) => {
        //console.log(data, "CSMBadges")
          this.badges = data
        });
        break;
      case 'BADGE_SIX':
        //Learning Master
        this.educationService.getStudentBadgesByID("89").subscribe((data) => {
        //console.log(data, "CSMBadges")
          this.badges = data
        });
        break;
      case 'BADGE_SEVEN':
        //Learning Grandmaster
        this.educationService.getStudentBadgesByID("90").subscribe((data) => {
        //console.log(data, "CSMBadges")
          this.badges = data
        });
        break;
      default:

        break;
    }
  }
  isLoading(loadingStatus: boolean) {
    this.loading = loadingStatus
  }
  isBadgesLoading(badges: any) {
    if (typeof (badges) === "object" && badges.status_image) {
      this.badgeIsLoading = false
      return this.badgeIsLoading
    } else {
      this.badgeIsLoading = true
      return this.badgeIsLoading
    }


  }

  showSelect = false;

  toggleSelect() {
    console.log(this.showSelect,"selectedLanguage")
    this.showSelect = !this.showSelect;
  }

  // Function to get the selected language's name
  getNameForValue(value: string): string {
    const selectedItem = this.items.find(item => item.value === value);
    return selectedItem ? selectedItem.name : '';
  }


}
