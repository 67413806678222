import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Answer from 'src/app/models/Answer';
import { EducationService } from 'src/app/services/education.service';
interface contenta {
  title: string,
  checked: boolean | null,
  questions: any[]
}
interface content {
  title: string,
  quizID: string,
  order: string,
  checked: boolean | null,
  answers: any[]
}



@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  studentQuizAnswered: Answer[] = [];
  @Input()
  content: any[] = []
  contenta = [
    {
      title: "What is fixed income?",
      questions: [
        {
          text: "An investment that provides a fixed return?",
          isCorrect: true,
          reason: "Correct! Fixed income investments indeed provide a fixed return, usually in the form of interest payments over time, and the principal amount is returned at maturity.",
          answered: false
        },
        {
          text: "A type of equity investment",
          isCorrect: false,
          reason: "Incorrect. Fixed income investments are different from equity investments. In fixed income, investors are lending money and receiving fixed returns, whereas in equity, they become partial owners of the company and are subject to the company's performance.",
          answered: false
        },
        {
          text: "An investment in real estate",
          isCorrect: false,
          reason: "Incorrect. Fixed income investments do not necessarily involve real estate. They primarily pertain to lending money to issuers such as governments or corporations in exchange for fixed returns.",
          answered: false
        },
        {
          text: "None of the above",
          isCorrect: false,
          reason: "Incorrect. The first option, 'An investment that provides a fixed return,' is correct as fixed income investments indeed provide a fixed return.",
          answered: false
        },
      ], checked: null
    },
    {
      title: "What is the meaning of principal in fixed income?",
      questions: [
        {
          text: "The interest payment received from a bond",
          isCorrect: false,
          reason: "Incorrect! The interest payment received from a bond is part of the fixed income and is usually paid to the bondholder periodically.",
          answered: false
        },
        {
          text: "The initial investment amount in a bond",
          isCorrect: true,
          reason: "Correct! The principal in fixed income refers to the initial amount of money that an investor lends to the issuer of a bond.",
          answered: false
        },
        {
          text: "The maturity date of a bond",
          isCorrect: false,
          reason: "Incorrect. The maturity date of a bond is the date when the principal amount is repaid to the bondholder in full.",
          answered: false
        },
        {
          text: "The credit rating assigned to a bond",
          isCorrect: false,
          reason: "Incorrect. The credit rating of a bond indicates the creditworthiness of the issuer but is not related to the meaning of principal in fixed income.",
          answered: false
        }
      ],
      "checked": null
    },
    {
      title: "What is the risk-return profile of fixed income securities compared to equities?",
      questions: [
        {
          text: "Higher risk, higher return",
          isCorrect: false,
          reason: "Incorrect. The risk-return profile of fixed income securities is typically lower risk and lower return compared to equities.",
          answered: false
        },
        {
          text: "Higher risk, lower return",
          isCorrect: true,
          reason: "Correct! Fixed income securities generally have a lower risk compared to equities, but they also offer lower returns.",
          answered: false
        },
        {
          text: "Lower risk, higher return",
          isCorrect: false,
          reason: "Incorrect. Equities (stocks) are known for their higher return potential but come with higher risk compared to fixed income securities.",
          answered: false
        },
        {
          text: "Lower risk, lower return",
          isCorrect: false,
          reason: "Incorrect. While fixed income securities are considered less risky, they also offer lower returns.",
          answered: false
        }
      ],
      checked: null
    },
    {
      title: "What is diversification in the context of fixed income investments?",
      questions: [
        {
          text: "Concentrating investments in a single security",
          isCorrect: false,
          reason: "Incorrect. Diversification is the opposite of concentrating investments in a single security.",
          answered: false
        },
        {
          text: "Investing in real estate properties",
          isCorrect: false,
          reason: "Incorrect. Diversification in the context of fixed income investments is not related to investing in real estate properties.",
          answered: false
        },
        {
          text: "Spreading investments across different asset classes",
          isCorrect: true,
          reason: "Correct! Diversification in the context of fixed income investments involves spreading investments across different fixed income assets to reduce risk.",
          answered: false
        },
        {
          text: "None of the above",
          isCorrect: false,
          reason: "Incorrect. The first option, 'Spreading investments across different asset classes,' is the correct definition of diversification in the context of fixed income investments.",
          answered: false
        }
      ],
      checked: null
    },
  ]
  studentQuizAnswers: any[] = []
  toggleAnswered(question: any, content: contenta) {
    // Set 'wrong' and 'wrongText' to their initial state
    this.wrong = false;
    this.wrongText = "";
    this.wrongTitle = null
    // Iterate through all questions in the contenta array
    content.questions.forEach(q => {
      // Set the 'answered' property of all questions to false except the selected question
      if (q !== question) {
        q.answered = false;
      }
    });

    // Toggle the 'answered' property of the selected question
    question.answered = !question.answered;

    // Update 'wrong' and 'wrongText' properties based on the selected question's correctness
    if (!question.isCorrect && question.answered) {
      this.wrong = true;
      this.wrongText = question.reason;
      this.wrongTitle = false

    } else { this.wrong = false, this.wrongTitle = true }

    if (!question.answered) { this.wrongTitle = null }
    console.log("wrong", this.contenta)
    console.log("wrong", this.score)
  }
  initializeComponent() {
    if (this.content && this.content?.length === 0) {
      // Logic for an empty content array
      this.steps = this.contenta.map(item => {
        return {
          title: item.title,
          checked: item.checked
        };
      });
    } else {
      this.steps = (this.content ?? []).map(item => {
        return {
          title: item.title,
          checked: item.checked
        };
      });
    }
  }
  moduleID: string = ""

  ngOnInit() {

    this.educationService.getQuizArrayObservable().subscribe((progress) => {
      console.log("ARRAY_FROM_progress", progress)
      this.content = progress
      console.log("ARRAY_FROM_progress Content", this.content)
    });

    this.route.params.subscribe((params) => {
      const ModuleID = params['moduleID']
      this.moduleID = params['moduleID'];
      this?.educationService.getStudentQuizAnswerByModule(ModuleID).subscribe((data) => {
        //const studentAnswer = data
        console.log(data,"getStudentQuizAnswerByModule")
        { this.studentQuizAnswered = data }
        const resultMap = new Map(this.studentQuizAnswered.map(result => [result.id, result]));
        for (const contentItem of this.content) {
          for (const answer of contentItem.answers) {
            const matchingResult = resultMap.get(answer.id);
            if (matchingResult) {
              answer.answered = true;
              answer.isCorrect = matchingResult.isCorrect;
              break; // Break out of the inner loop
            }
          }
        }


        console.log(this.studentQuizAnswered, "studentQuizAnswered onInit")

        console.log(this.content.length, this.content, "content onInit")
      })
    })


    setTimeout(() => {
      /*  if (this.content.length === 0) {
         this.educationService.getQuizArrayObservable().subscribe((progress) => {
           this.content = progress
         });
       } */
    }, 900);
    this.initializeComponent();
    console.log(this.content.length, this.content, "COuntends")

  }
  updateContentArray(): void {
    for (const answer of this.studentQuizAnswers) {
      const contentItem = this.content.find(item => item.id === answer.yourAnswer.id);

      if (contentItem) {
        contentItem.isCorrect = answer.yourAnswer.isCorrect;
        contentItem.answered = true;
      }
    }
  }
  score: number = 0;
  percentageScore: number = 0;

  wrong: boolean = false
  wrongTitle: boolean | null = null

  wrongText: string = ""
  serviceData:any
  currentStep: number

  constructor(private educationService: EducationService, private route: ActivatedRoute,
    private router: Router) {
    this.currentStep = 0
    this.serviceData = this.educationService.getContentData()
    console.log("wrong", this.contenta)

  }
  steps = [
    { title: 'Location', checked: null },
    { title: 'Disclaimer', checked: false },
    { title: 'End', checked: false },
  ]
  changeStep(val: number, pos: number) {
    this.wrongTitle = null
    this.wrong = false;
    this.wrongText = ""
    let res = this.currentStep + val
    if ((val >= 0) && pos < this.steps.length) this.steps[pos].checked = true
    else if (val < 0) this.steps[pos].checked = null

    if (res >= 0 && res < this.steps.length && pos < this.steps.length) this.currentStep = res;
    if (res >= 0 && res < this.steps.length && pos < this.steps.length) this.percentageScore = (this.score / this.contenta.length) * 100

  }
  handleAnswers(question: any, content: content, quiz: string, answer: string, index: number) {
    //console.log(question, "question content Index")
    //console.log(content, "content content Index")
    //console.log(quiz, "quiz content Index")
    //console.log(answer, "answer content Index")
    //console.log(index, "index content Index")
    //console.log("handleAnswers ThisContent", this.content)
    this.isLoading = true
    const thisQuizContent = content
    if (this.content !== null) {
      this?.educationService.getStudentQuizAnswerByModule(this.moduleID).subscribe((data) => {
        console.log("studentQuizAnswers", data)
        console.log("thisStudentQuizAnswers", this.studentQuizAnswers)
        if (data) {
          // Check if any question in the content array has 'answered' set to true
          const anyQuestionAnswered = content.answers.some(q => q.answered);
          // Set 'wrong' and 'wrongText' to their initial state
          this.wrong = false;
          this.wrongText = "";
          this.wrongTitle = null;

          // Only toggle 'answered' property if no question is already answered
          if (!anyQuestionAnswered) {
            console.log("not Answered Yet")
            
            const contentToUpdate = this.content[index];
            console.log(contentToUpdate, "contentToUpdate")

            this.route.params.subscribe((params) => {
              const language = params['language'];
              this?.educationService.createStudentAnswer(quiz, answer, language).subscribe((data) => {
                this.studentQuizAnswered.push(data)
                console.log(data, "createStudentAnswer")
                console.log(this.studentQuizAnswered, "updatedStudentQuizAnswers")

                // Update the content array with the new data

                  if (contentToUpdate && contentToUpdate?.quizID === thisQuizContent.quizID) {
                    contentToUpdate.isCorrect = data.isCorrect;
                    contentToUpdate.answered = true;
                  }
                  const savedContent = localStorage.getItem('content');
                  if (savedContent !== null) {
                    const parsedContent = JSON.parse(savedContent);
                    parsedContent.modules.lastQuizProgress.push(data);
                    localStorage.setItem('content', JSON.stringify(parsedContent));
                    this.educationService.saveContent(parsedContent)
                  }

                  question.isCorrect = data.isCorrect;
                  question.answered = !question.answered;
                  this.isLoading = false
              })
            })
          } else {
            this.isLoading = false; console.log("Already Answered") }

        }
      })
    } else {
      this.isLoading = false
      console.log("this.content is null");
      console.log("this.content is null",this.isLoading);
    }



  }
  goToEducation() {
    this.router.navigate(["/education"])
  }
  fontSize = 1;
  changeFontSize(operation: '+' | '-'): void {
    if (operation === '+') {
      if (this.fontSize < 2.0) {
        this.fontSize = Math.min(this.fontSize + 0.1, 2.0); // Increase font size by 0.1 rem, with a maximum of 2.0 rem
      }
    } else if (operation === '-') {
      if (this.fontSize > 1.0) {
        this.fontSize = Math.max(this.fontSize - 0.1, 1.0); // Decrease font size by 0.1 rem, with a minimum of 1.0 rem
      }
    }
  }
  resetFontSize(): void {
    this.fontSize = 1
  }
  getNextTitle(currentStep: number) {
    if (this.currentStep + 1 < this.content.length) {
      let thisStep =this.content[currentStep + 1]?.title
      return thisStep
    } else {
      return "Congratulations"
    }
  }
  getFinalStep() {

    if (this.steps[this.steps.length - 1].checked) {
      return true
    } else {
      return false
    }
  }
 checkAnswerStatus(quizArray: any[], indexToCheck: number): boolean {
    // Check if the index is within the array bounds
    if (indexToCheck >= 0 && indexToCheck < quizArray.length) {
      // Get the quiz object at the specified index
      const quiz = quizArray[indexToCheck];
  
      // Check if the quiz object has an 'answers' array
      if (quiz && Array.isArray(quiz.answers)) {
        // Check if any 'answered' property in the 'answers' array is true
        return quiz.answers.some((answer:any) => answer.answered === true);
      }
    }
  
    // Return false if the index is out of bounds or the structure is not as expected
    return false;
  }
  
  
isLoading: boolean = false;
}
