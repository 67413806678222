import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-performance-card',
  templateUrl: './performance-card.component.html',
  styleUrls: ['./performance-card.component.scss']
})
export class PerformanceCardComponent {
  @Output() btnClick = new EventEmitter<void>();
  @Input() cardTitle:any="Strategies"
  @Input() items: any[] = [];

  @Output() currentPosition = new EventEmitter<number>();
  @Input() itenIcons:boolean = false;
  current:number=0

  onBtnClick(i:any) {
    this.current =i
    this.currentPosition.emit(i);
  }
}
