<div class="w-full lg:flex flex-col md:flex-row justify-between items-center gap-10 mb-10 md:mb-0">
    <div *ngFor="let filter of filters,let i=index" class="w-full">
        <div class="text-[18px] text-md text-[#E1E1E1] font-normal pt-10 md:pt-0">{{filter.title}}</div>
        <!---->
        <div *ngIf="filter.type == 'slider'" class="w-full" ><app-range-slider [data]="filter.data" /> </div>
        <!---->

        <!---->
        <div *ngIf="filter.type == 'level'" ><app-level /> </div>
        <!---->

        <!---->
        <div *ngIf="filter.type == 'amount'" ><app-amount /> </div>
        <!---->

        <!---->
        <div *ngIf="filter.type == 'sort'" class="mt-[2rem] lg:mt-0" ><app-sort-view /> </div>
        <!---->
        <!---->
        <div *ngIf="filter.type == 'select'" ><app-select-form [selectData]="{labelClass:'!hidden',selectFormClass:'w-[20rem]'}" [default]="filter.default" [items]="filter.items"/> </div>
        <!---->
    </div>
</div>