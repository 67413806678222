
<div class="relative items-center justify-center">
    
    
    <div class="chart md:w-[400px] w-full " [id]="dynamicId">
    </div>
    <div  class="absolute md:w-[400px] top-[2.7rem] left-[6rem]  ">
        <img src="assets/img/tick-mark.svg">
    </div>
      
</div> 