import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-analitcs-card',
  templateUrl: './analitcs-card.component.html',
  styleUrls: ['./analitcs-card.component.scss']
})
export class AnalitcsCardComponent {
  step = 0
  @Input() title:string = "Untitled"
  @Input() list:any[] = []

}
