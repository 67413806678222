import { Component } from '@angular/core';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
  items: any[] = [{ value: 'S001', name: 'Alaska, 5.342% 08/2027, USD' }]
  tableColumns: string[] = ['Date','Type'];
  tableData: any[] = [
    {'Date': '23.06.2023','Type':'Individual' },
    {'Date': '23.06.2023','Type':'Individual'},
    {'Date': '23.06.2023','Type':'Common'},
    {'Date': '23.06.2023','Type':'Individual'}
  ];

}
