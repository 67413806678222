<button
  class="relative flex justify-center items-center bg-yv-primary-70 rounded-lg min-w-[172px] w-full h-[42px] {{
    buttonData.buttonClass
  }}"
  style="padding: 12px 24px; gap: 8px"
>
  <img src="asst" alt="" />
  <img
    class=" "
    [src]="buttonData.iconUrl"
    alt="social icon"
    style="
      width: 15.67px;
      height: 16px;
      left: 18.38%;
      right: 18.42%;
      top: 12.5%;
    "
  />
  <span class="text-white font-poppins {{ buttonData.textClass }}">{{
    buttonData.text
  }}</span>
</button>
