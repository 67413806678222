import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';

Highcharts3D(Highcharts);


@Component({
  selector: 'app-column-chart-stacking',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './column-chart-stacking.component.html',
  styleUrls: ['./column-chart-stacking.component.scss']
})
export class ColumnChartStackingComponent {

  dynamicId = `component_${Math.floor(Math.random() * 10000)}`


  ngAfterViewInit() {
    this.renderChart();
  }

  renderChart() {

    const chartOptions: Highcharts.Options = {

      chart: {
        type: 'column',
        backgroundColor: 'transparent',
      },

      legend: {
        align: 'left',
        itemStyle: {
          color: '#ffffff99', 
        }
      },

      title: {
        text: '',
      },

      xAxis: {
        gridLineColor: '#ffffff33',
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1.3,
        lineColor: '#ffffffcc',
        lineWidth: 2,
        labels: {
          style: {
            color: '#ffffff99' 
          },
        },
      },

      yAxis: {
        gridLineColor: '#ffffff33',
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1.3,
        lineColor: '#ffffffcc',
        lineWidth: 2,
        labels: {
          style: {
            color: '#ffffff99' 
          },
        }
      },

      credits: {
        enabled: false
      },

      plotOptions: {
        column: {
          stacking: 'normal',
          pointWidth: 40, 
          borderWidth: 0, 
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '<svg width="40" height="40" viewBox="0 0 40 40">' +
                '<rect x="0" y="0" width="40" height="40" rx="10" fill="' + this.color + '" />' +
              '</svg>';
            },
          },
        }
      },

      series: [
        {
          name: 'Average',
          data: [50],
          color: '#978EF5BF',
        },
        {
          name: 'Max',
          data: [10],
          color: '#FF6F65BF',
        },
        {
          name: 'Min',
          data: [30],
          color: '#FF903EBF',
        },
       /*  {
          name: '',
          data: [70],
          color: '#FF903E26',
        }, */
        {
          name: 'Current',
          data: [20],
          color: '#BBC72CBF',
        } as any]
    }

    Highcharts.chart(this.dynamicId, chartOptions);

  }



}
