<div>
    <div class="flex justify-end gap-[1rem] mr-[5rem]">
        <div class="text-[#978EF5] text-[0.875rem] cursor-pointer" (click)="goToEducation()">My Learnings</div>
        <div class="text-[#B3B3B3] text-[0.875rem]">/</div>
        <div class="text-[#B3B3B3] text-[0.875rem]">{{serviceData?.title}}</div>

    </div>
    <div class="md:flex items-center justify-between mt-[2rem]">
        <div class="">
            <div class="text-[1.375rem] text-[#978EF5]">{{serviceData?.title}}</div>
            <div class="text-[8vw] md:text-[3rem]">{{serviceData?.modules?.title}}</div>
        </div>
       <!--  <div class="bg-[#4946681A] rounded-[2rem] p-[2rem]">
            <span class="text-[1rem] text-[#B3B3B3]"></span>
            <div class="flex justify-between gap-[2rem] items-center">
                <span class="text-[#B3B3B3] text-[1rem]">
                    <span class="text-[#2CC784] text-[2rem]">{{ calculateProgressPercentage()| number:'1.1-1'}}%</span>
                    completed
                </span>
                <span class="text-[#978EF5] text-[1.25rem]"> 0 of {{totalPoints}} points</span>
            </div>

            <div id="progressBarEduLayout">
                <div id="progressLineEduLayout" class="border-t-[0.5px] border-[#B3B3B3] mx-auto mt-20"
                    style="border-color: transparent; margin-top: 0;">
                    <div class="w-full bg-[#F0EDF6] h-[0.5rem] rounded">
                        <div class="w-[2px] bg-[#2CC784] h-full rounded" [style.width]="calculateProgressPercentage() + '%'">
                        </div>
                    </div>
                </div>

            </div>
        </div> -->
    </div>
</div>
<div class="md:flex">
    <div (click)="toggleMenu()"
        class="md:hidden inline-flex items-center mt-8 bg-[#ffffff1a] px-2 pr-4 cursor-pointer rounded-2xl">
        <app-rounded-icon-buttom *ngIf="!menuM"
            [buttonData]="{iconUrl:'assets/img/icon/menu.svg',iconClass:'h-[1.5rem] brightness-100',btnClass:'z-50 !bg-transparent'}" />
        Items Menu
    </div>
    <div
        [ngClass]="{'md:w-1/3 w-full hidden md:block mt-[3rem]':!menuM,'block fixed left-0 top-0 bg-yv z-50 p-8 w-full h-screen':menuM}">
        <span id="close-menu"><app-rounded-icon-buttom (btnClick)="toggleMenu()" *ngIf="menuM"
                [buttonData]="{iconUrl:'assets/img/icon/cross.svg',btnClass:'my-4',iconClass:'brightness-100 invert'}" /></span>
        <div class="text-[1.25rem] font-[600] mb-[0.8rem]">Course summary</div>

        <div id="educationLayoutLecture">
            <div class="ml-[1.8rem] mb-[0.8rem] text-[1rem] font-[600] text-[#FFFFFF]">
                {{ serviceData?.modules?.title }} {{'Lecture' }}
            </div>
        
            <div *ngIf="!quizActive; else quizContent">
                <div class="ml-[1.8rem] mb-[0.8rem] flex flex-col"
                     *ngFor="let lecture of serviceData?.modules?.lectures; let lectureIndex = index"
                     [ngClass]="{'cursor-pointer': shouldApplyCursorPointer(lecture.id)}">
                  <div class="flex items-center text-[0.938rem]" 
                       [style.pointer-events]="getBackgroundColor(lecture.id)['pointer-events']"
                       (click)="getLectureID(lecture?.id)">
                    <div class="bg-white mr-[0.5rem] w-[0.75rem] h-[0.75rem] rounded-full p-1"
                         [ngStyle]="getBackgroundColor(lecture.id)"></div>
                    {{ lecture?.title }}
                    <!-- {{ lecture?.id }} -->
                  </div>
                </div>
              </div>
        
            <ng-template #quizContent>
                <div class="ml-[1.8rem] mb-[0.8rem] flex flex-col" (click)="closeQuizAction()">

                    <div class="flex items-center text-[0.938rem]  cursor-pointer">
                        <div class="bg-white mr-[0.5rem] w-[0.75rem] h-[0.75rem] rounded-full p-1"
                        
                        [ngStyle]="quizIsFalse()"></div>
                        {{ serviceData?.modules?.title }} Lessons
                    </div>
                </div>
            </ng-template>
        </div>
        
        <div *ngIf="serviceData?.modules?.quiz?.length>0 && serviceData?.modules?.quiz[0]?.title" class="ml-[1.8rem] mb-[0.8rem] flex flex-col" (click)="openQuizAction(serviceData?.modules?.lectures[0].id)">
            <div class="flex items-center text-[0.938rem] cursor-pointer">
                <div class="bg-white mr-[0.5rem] w-[0.75rem] h-[0.75rem] rounded-full p-1"
                    [ngStyle]="getQuizzColor()"></div>
                {{ serviceData?.modules?.title}} Quizzes
            </div>
        </div>

        <!-- <div id="quiz">
            <div class="ml-[1.8rem] mb-[0.8rem] text-[1rem] font-[600] text-[#FFFFFF]"> {{module?.title}} Quiz</div>
            <div *ngFor="let quiz of quizzes; let quizIndex = index"
                class="ml-[1.8rem] mb-[0.8rem] flex flex-col">
                <div class="flex items-center text-[0.938rem]">
                    <div class="bg-white mr-[0.5rem] w-[0.75rem] h-[0.75rem] rounded-full p-1"
                        [ngStyle]="{ 'background-color': '#FFFFFF' }"></div>
                    {{quiz?.title}}
                </div>
            </div>
        </div> -->
    </div>


    <div class="md:w-2/3 w-full">
        <ng-content></ng-content>
    </div>
</div>