<div class="rounded-xl p-[0.5rem] lg:p-[2rem] bg-yv-5%-gray">
  <div *ngIf="lectureView ===true" class="w-full py-6 mx-auto">
    <div class="flex item mb-[2rem]">
      <div class="relative flex justify-evenly gap-[1rem] w-full">

        <div class="grid gap-[1rem]">
          <div id="courseTitle"
            class=" md:font-[600] md:text-[1.2rem] lg:text-[1.5rem] text-[#FFFFFF]">
            {{courseTitle}} </div>
          <div class="w-full border-t-[0.5px] border-[#B3B3B3] {{quiz || lecture?'':'max-w-lg'}} mx-auto mt-20"
            style="border-color: transparent; margin-top: 0;">
            <div class="relative">
              <div class="w-0 bg-[#2CC784] py-1 rounded"
                [style.width]="allStepsChecked() ? '100%' : ((current) / (steps!.length )) * 100 + '%'"></div>
              <div class="absolute inset-0 flex align-center items-center align-middle content-center"
                style="top: 8px;">
              </div>
              <div class="absolute inset-0 flex align-center items-center align-middle content-center">
                <div class="w-[full] bg-[#81FFAF33] h-[0.6rem] rounded-xl" style="width: 100%;"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="completedCourses" class="max-w-[15rem]">
          <div class="flex justify-end">
            <span class="font-[400] text-[0.75rem] lg:text-[1rem] text-[#B3B3B3] text-right xl:text-justify">Completed
              Courses</span>
          </div>
          <div class="w-full flex justify-end gap-[0.5rem] mt-[0.75rem]">
            <span class="font-[300] text-[1rem] xl:text-[1.75rem]">{{ courseDone | number:
              '2.0' }} </span>
            <span class="font-[300] text-[1rem] xl:text-[1.75rem]  text-[#6761A8]"> of {{
              courseTotal | number: '2.0'
              }}</span>
          </div>
        </div>
        <div id="fontSize" class="flex lg:flex-row flex-col justify-center items-center gap-4">
          <!-- Order for screens larger than lg -->
          <button
            class="lg:block hidden {{fontSize != 1 ? 'border-[#B3B3B3] text-[#B3B3B3]' : 'border-[#B3B3B366] text-[#B3B3B366]'}} border rounded-full px-[0.7rem] py-[0.55rem]"
            (click)="handleFontDecrease()">A-</button>

          <span class="lg:block hidden cursor-pointer" (click)="handleFontReset()">
            {{fontSize | number:'1.1'}}
          </span>

          <button
            class="lg:block hidden border {{fontSize != 2 ? 'border-[#B3B3B3] text-[#B3B3B3]' : ' border-[#B3B3B366] text-[#B3B3B366]'}}  rounded-full px-[0.7rem] py-[0.6rem]"
            (click)="handleFontIncrease()">A+</button>

          <!-- Order for screens smaller than lg -->
          <button
            class="lg:hidden {{fontSize != 2 ? 'border-[#B3B3B3] text-[#B3B3B3]' : ' border-[#B3B3B366] text-[#B3B3B366]'}}  rounded-full px-[0.7rem] py-[0.6rem]"
            (click)="handleFontIncrease()">A+</button>

          <span class="lg:hidden cursor-pointer" (click)="handleFontReset()">
            {{fontSize | number:'1.1'}}
          </span>

          <button
            class="lg:hidden {{fontSize != 1 ? 'border-[#B3B3B3] text-[#B3B3B3]' : 'border-[#B3B3B366] text-[#B3B3B366]'}} border rounded-full px-[0.7rem] py-[0.55rem]"
            (click)="handleFontDecrease()">A-</button>
        </div>

      </div>

    </div>
    <div class="inset-0 flex align-center items-center align-middle content-center">
      <div class="w-full bg-[#B3B3B3] h-[1px] rounded"></div>
    </div>

  </div>
  <div *ngIf="lectureView ===false" class="w-full py-6 mx-auto">
    <div class="flex item">
      <div class="text-xs md:text-base w-1/4 py-6">
        <div class="relative {{quiz || lecture?'':'left-[18%] md:left-[68%]'}}">
          <span class="font-[300] text-[28px] ">{{ (current+1) | number: '2.0' }}</span>
          <span class="font-[300] text-[14px]  text-[#6761A8]"> / {{steps!.length}}</span>
          <div *ngIf="quiz">
            <div *ngIf="quizAnswer">WellDone</div>
            <div *ngIf="quizAnswer == false">Incorrect</div>
            <div *ngIf="quizAnswer == null"></div>


          </div>
          <div *ngIf="!quiz" class="font-normal text-[19px] text-[#FFFFFF] whitespace-nowrap">{{steps![current].title}}
          </div>
        </div>
      </div>
    </div>
    <div class="w-full border-t-[0.5px] border-[#B3B3B3] {{quiz || lecture?'':'max-w-lg'}} mx-auto mt-20"
      style="border-color: transparent; margin-top: 0;">
      <div class="relative">
        <div class="w-0 bg-[#2CC784] py-1 rounded"
          [style.width]="allStepsChecked() ? '100%' : ((current) / (steps!.length )) * 100 + '%'"></div>
        <div class="absolute inset-0 flex align-center items-center align-middle content-center" style="top: 8px;">
        </div>
        <div class="absolute inset-0 flex align-center items-center align-middle content-center">
          <div class="w-0 bg-[#B3B3B3] h-[1px] rounded" style="width: 100%;"></div>
        </div>
      </div>
    </div>

  </div>


  <ng-content></ng-content>
  <div class="border-t-[0.5px] border-[#B3B3B3] mx-auto mt-16  {{full?'w-full':'max-w-screen-md'}}">

    <div class=" mx-auto  {{full?'':'max-w-screen-md'}}">
      <div class="flex gap-8 items-center justify-between py-10">
        <span class="flex justify-center gap-3 items-center">
          <span>{{prevText}}</span>
          <app-rounded-icon-buttom (btnClick)="prev()"
            [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass: current > 0 ? 'bg-[#4A4C57]' : '!bg-transparent', iconClass: current > 0 ? 'rotate-180' : '!invisible'}" />

        </span>
        <span *ngIf="finalStep !== true && nextQuiz" class="flex justify-center gap-3 items-center">
          <span>{{nextText}}</span>
          <app-rounded-icon-buttom (btnClick)="next()"
            [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass: '!bg-[#2CC784]'}" />

        </span>

      </div>
    </div>
  </div>

</div>

<!-- <app-back-and-forward (nextClick)="next()" (prevClick)="prev()" /> -->