import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RoundedIconButtonInterface } from 'src/app/models/ComponentInput';
import { SideBarComponent } from './side-bar/side-bar.component';
import { CartService } from 'src/app/services/cart.service';
import { Order } from 'src/app/models/Order';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss'],
})
export class UserLayoutComponent implements OnInit {
  navMenu: RoundedIconButtonInterface[];
  sideMenu: any[];
  orders: Order[] = []
  url?:string;
  @ViewChild('sidebar', { static: false }) sidebar!: SideBarComponent;

  toggleSidebar() {
    if(this.sidebar)this.sidebar.toggleSidebar();
  }
  constructor(private _router: Router, private cartService:CartService) {
    this.url =_router.url
    this.navMenu = [
      { iconUrl: 'assets/img/icon/bell.svg', isDotVisible: true },
      {action:()=> this.toggleSidebar(), iconUrl: 'assets/img/icon/cart.svg' },
    ];
    this.sideMenu = [
      { route: '/', name: 'Home', baseIcon: 'assets/img/sideIcon/menuHomeBlank.svg', activeIcon: 'assets/img/sideIcon/menuHomeGlow.svg' },
      { route: '/chat', name: 'Chat', baseIcon: 'assets/img/sideIcon/menuRobotBlank.svg', activeIcon: 'assets/img/sideIcon/menuRobotGlow.svg' },
      { route: '/portfolio', name: 'Form', baseIcon: 'assets/img/sideIcon/menuRobotBlank.svg', activeIcon: 'assets/img/sideIcon/menuRobotGlow.svg' },
    ];

  }
  chatFooter:boolean = false
  ngOnInit(): void {
    this.chatFooter=!this._router.url.includes('/assistant/chat');
    this.cartService.onYourOrdersChange().subscribe((newValue: Order[]) => {
      // This function will be triggered whenever yourVariable changes
      console.log('YourVariable has changed:', newValue);
      // Call any other function or perform any desired actions here
      this.orders = this.cartService.getOrders()
      this.toggleSidebar()
    });
  }

  onMenuClick(i: number) {
    this._router.navigate([this.sideMenu[i].route]);
  }
  logout() {
    localStorage.removeItem("AUTH_CREDS");
    this._router.navigate(['/auth/login']); 
  }

}
