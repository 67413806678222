import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-two-d-table',
  templateUrl: './two-d-table.component.html',
  styleUrls: ['./two-d-table.component.scss']
})
export class TwoDTableComponent {
  @Input() content: any;
  get numColumns(): number {
    return this.content.columns ? this.content.columns.length+1 : 1;
  }
}
