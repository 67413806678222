<div>
    
    <div class="flex bg-[#FFFFFF10] p-4  mb-4 mx-auto  flex-col rounded">

        <p class="font-regular text-[15px] mb-4">{{cardTitle}}</p>

        <div class="grid grid-cols-3 gap-6">
            <div *ngFor="let iten of items, let i = index">

                <div
                    (click)="onBtnClick(i)"
                    class="px-2 py-2  mr-3 flex flex-row justify-center items-center  text-gray-100 transition bg-[#FFFFFF05] rounded-md   border-solid border hover:border-[#978EF5] {{current != i? 'border-transparent':'border-[#978EF5]'}}">
                    <img *ngIf="itenIcons"  height="90" mt-4 [src]="iten.icon" alt="strategie.title" />
                    <p class="text-[13px] ml-4 text-gray-100 transition ">{{iten.title}}</p>

                </div>

            </div>
        </div>


    </div>
</div>