import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import SolidGauge from 'highcharts/modules/solid-gauge';
import * as Highcharts from 'highcharts';

SolidGauge(Highcharts);

@Component({
  selector: 'app-gauge-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent {

  width1: any;
  width2: any;

  dynamicId = `component_${Math.floor(Math.random() * 10000)}`


  ngOnInit() { 
    this.width1 = 35;
    this.width2 = 65;
  }

  ngAfterViewInit() {
    this.renderChart();
  }

  renderChart() {

    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'solidgauge',
        height: '110%',
        backgroundColor: 'transparent',
      },
      title: {
        text: ''
      },

      credits: {
        enabled: false
      },

      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '20px',
        },
        valueSuffix: '%',
        pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
        positioner: function (labelWidth) {
          return {
            x: (this.chart.chartWidth - labelWidth) / 2,
            y: (this.chart.plotHeight / 2) + 15
          };
        }
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{ // Track 
          outerRadius: '80%',
          innerRadius: '77%',
          backgroundColor: '#98F2B933',
          // backgroundColor: 'transparent',
          borderWidth: 0
        },
        { // Track
          outerRadius: '68%',
          innerRadius: '65%',
          backgroundColor: '#978EF533',
          // backgroundColor: 'transparent',
          borderWidth: 0,
        }]
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        }
      },
      series: [{
        name: 'Move',
        data: [{
          color: '#98F2B9',
          radius: '80%',
          innerRadius: '77%',
          y: 80
        }]
      }, {
        name: 'Stand',
        data: [{
          color: '#978EF5',
          radius: '68%',
          innerRadius: '65%',
          y: 68
        }]
      } as any]
    };

    const chart: Highcharts.Chart = Highcharts.chart(this.dynamicId, chartOptions);


    const text = chart.renderer.text('Total Earnings', 0, 0)
      .css({
        color: '#E1E1E1',
        fontSize: '19.85px',
        textAlign: 'center',
        fontWeight: 'bold',
      })
      .attr({
        zIndex: 999
      })
      .add();

    const subtext = chart.renderer.text('$10,120', 0, 0)
      .css({
        color: '#E1E1E1',
        fontSize: '43.31px',
        textAlign: 'center',
        fontWeight: 'bold',
        fontFamily: 'Poppins',
      })
      .attr({
        zIndex: 999
      })
      .add();

    // Calculate and set the position of the text labels
    const textWidth = text.getBBox().width;
    const textHeight = text.getBBox().height;
    const subtextWidth = subtext.getBBox().width;
    const subtextHeight = subtext.getBBox().height;

    // Calculate the vertical position for center alignment
    const centerY = chart.plotTop + chart.plotHeight / 2;

    // Adjust the margin between text and subtext
    const margin = 0;

    text.attr({
      x: chart.plotLeft + (chart.plotWidth / 2) - (textWidth / 2),
      y: centerY - (textHeight + subtextHeight + margin) / 2 + textHeight
    });

    subtext.attr({
      x: chart.plotLeft + (chart.plotWidth / 2) - (subtextWidth / 2),
      y: centerY + (textHeight + subtextHeight + margin) / 2
    });



  }
}
