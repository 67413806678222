<div class="flex flex-col items-center xl:flex-row gap-[1rem] mt-[2rem] mb-[5rem]">
    <div *ngFor="let prompt of prompts; let i = index"
        class="hidden xl:flex rounded-xl flex-col p-[1rem] w-[19.25rem] bg-[#FFFFFF08] gap-[1rem] min-h-[28rem]">
        <div class="flex items-center gap-[1rem]">
            <app-rounded-icon-buttom
                [buttonData]="{ iconUrl: prompt[0].img, btnClass: ' !bg-[#978EF533] !h-[4rem] !w-[4rem] ' }" />
            <div class="text-[1.375rem]">{{prompt[0].title}}</div>
        </div>
        <div [routerLink]="p.url" [queryParams]="p" *ngFor="let p of prompt; let in2 = index"
            class="bg-[#FFFFFF1A] rounded-2xl py-4 px-8 min-h-[7rem] flex items-center cursor-pointer">
            <div class="text-center text-[0.875rem] text-[#F9F9F9]">{{ p.prompt }}</div>
        </div>
    </div>
    <div class="flex xl:hidden rounded-xl flex-col p-[1rem] w-[19.25rem] bg-[#FFFFFF08] gap-[0.5rem] ">
        <div class="flex items-center gap-[1rem]">
            <app-rounded-icon-buttom
                [buttonData]="{ iconUrl: prompts[currentIndex][0].img, btnClass: ' !bg-[#978EF533] !h-[4rem] !w-[4rem] ' }" />
            <div class="text-[1.375rem]">{{prompts[currentIndex][0].title}}</div>
        </div>
        <div [routerLink]="p.url" [queryParams]="p" *ngFor="let p of prompts[currentIndex]; let in2 = index"
            class="bg-[#FFFFFF1A] rounded-2xl p-4 px-8 min-h-[60px] flex items-center cursor-pointer">
            <div class="text-left">{{ p.prompt }}</div>
        </div>
    </div>
    <div class="flex xl:hidden items-center gap-2 mt-2">
        <div *ngFor="let prompt of prompts; let i = index" (click)="changeIndex(i)"
             [class.bg-green-500]="currentIndex === i"
             class="w-4 h-4 rounded-full cursor-pointer bg-gray-400"></div>
      </div>
      

</div>