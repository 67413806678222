import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import Course from "src/app/models/Course";
import Education from "./EducationRepo";
import Module from "src/app/models/Module";
import { KeycloakService } from "keycloak-angular";
import Lecture from "src/app/models/Lecture";
import Quizz from "src/app/models/Quizz";
import Answer from "src/app/models/Answer";
import StudentProgress from "src/app/models/StudentProgress";
import EmptyModel from "src/app/models/EmptyModel";

@Injectable()
export class EducationRepoRest implements EducationRepoRest {

    url = "https://dev.yieldvestor.com/fixed-income";
    urlBadgeRewards = "https://dev-cms.yieldvestor.com/api/rewards"
    urlBadge = "https://dev-cms.yieldvestor.com"
    constructor(private http: HttpClient) {

    }


    getCourses(language: string): Observable<Course[]> {
        const headers = new HttpHeaders();

        return this.http.get<Course[]>(this.url + `/api/v1/courses/language/${language}`, { headers }).pipe(
            map((response: any) => {
                return response.map((course: any) => {
                    return {
                        id: course.id,
                        title: course.courseTranslationDto.title,
                        language: course.courseTranslationDto.languageCode,
                        isPublished: course.isPublished,
                    } as Course;
                });
            })
        );
    }
    getCoursesByStudent(languageCode: string): Observable<Course[]> {
        const headers = new HttpHeaders();

        return this.http.get<Course[]>(this.url + `/api/v1/courses/student/language/${languageCode}`, { headers })
            .pipe(
                map((response: any) => {
                    return response.map((course: any) => {
                        return {
                            id: course.id,
                            title: course.title,
                            language: course.languageCode,
                            isPublished: course.isPublished || '',
                        } as Course;
                    });
                })
            );
    }

    getCoursesByID(courseID: string, language: string): Observable<Course> {
        const headers = new HttpHeaders();

        return this.http.get<Course>(this.url + `/api/v1/courses/${courseID}/language/${language}`, { headers })
            .pipe(
                map((response: any) => {
                    return {
                        id: response.id,
                        title: response.courseTranslationDto.title,
                        language: response.courseTranslationDto.language,
                        languageID: response.translationId,
                        isPublished: response.isPublished,
                        order: response.order
                    } as Course;
                })
            );
    }


    getModules(courseID: string, language: string): Observable<Module[]> {
        const headers = new HttpHeaders();
        return this.http.get<Module[]>(this.url + `/api/v1/content_module/course/${courseID}/language/${language}`, { headers })
            .pipe(
                map((response: any) => {
                    return response.map((module: any) => {
                        return {
                            id: module.id,
                            title: module.moduleTranslationDto.title,
                            intro: module.moduleTranslationDto.intro,
                            language: module.moduleTranslationDto.languageCode,
                            order: module.order.toString().padStart(2, '0'),
                            minimumPassingScore: module.minimumPassingScore,
                            isPublished: module.isPublished
                        } as Module;
                    });

                })
            );
    }



    getModulesByID(moduleID: string, language: string): Observable<Module> {
        const headers = new HttpHeaders();
        return this.http.get<Module>(this.url + `/api/v1/content_module/${moduleID}/language/${language}`, { headers }).pipe(
            map((response: any) => {
                return {
                    id: response.id,
                    title: response.moduleTranslationDto.title,
                    intro: response.moduleTranslationDto.intro,
                    language: response.moduleTranslationDto.languageCode,
                    languageID: response.moduleTranslationDto.id,
                    order: response.order.toString().padStart(2, '0'),
                    minimumPassingScore: response.minimumPassingScore
                } as Module;
            })
        );
    }


    getLectures(contentModuleId: string, language: string): Observable<Lecture[]> {
        const headers = new HttpHeaders();
        return this.http.get<Lecture[]>(this.url + `/api/v1/content-lectures/content-modules/${contentModuleId}/language/${language}`, { headers })
            .pipe(
                map((response: any) => {
                    return response.map((lecture: any) => {
                        return {
                            id: lecture.id,
                            isPublished: lecture.isPublished,
                            language: lecture.lectureTranslationDto.languageCode,
                            title: lecture.lectureTranslationDto.title,
                            content: lecture.lectureTranslationDto.content,
                            lectureID: lecture.lectureTranslationDto.lectureId,
                            order: lecture.order.toString().padStart(2, '0')

                        } as Lecture;
                    });
                })
            );
    }

    getQuizz(moduleID: string, language: string): Observable<Quizz> {
        const headers = new HttpHeaders();
        return this.http.get<Quizz>(this.url + `/api/v1/quiz/content-modules/${moduleID}/language/${language}`, { headers }).pipe(
            map((response: any) => {
                return response.map((quiz: any) => {
                    return {
                        quizID: quiz.quizId,
                        title: quiz.title,
                        points: quiz.points,
                        level: quiz.level,
                        order: quiz.order.toString().padStart(2, '0'),
                        moduleID: quiz.contentModuleId,
                        languageID: quiz.quizTranslationId,
                        language: quiz.languageCode
                    } as Quizz;
                });
            })
        );
    }

    getAnswers(quizID: string, language: string): Observable<Answer> {
        const headers = new HttpHeaders();
        return this.http.get<Answer>(this.url + `/quiz-answer/quizzes/${quizID}/language/${language}`, { headers }).pipe(
            map((response: any) => {
                return response.map((lecture: any) => {
                    return {
                        id: lecture.id,
                        text: lecture.content,
                        isCorrect: lecture.isCorrect,
                        languageID: lecture.translationID

                    } as Answer;
                });
            })


        );
    }

    getQuizLevel(): Observable<StudentProgress> {
        const headers = new HttpHeaders();
        return this.http.get<StudentProgress>(this.url + `/api/v1/quiz-level`, { headers });
    }

    getQuizLevelByID(id: string): Observable<StudentProgress> {
        const headers = new HttpHeaders();
        return this.http.get<StudentProgress>(this.url + `/api/v1/quiz-level/${id}`, { headers });
    }

    //#Student APIS
    createStudentProgress(lectureId: string): Observable<StudentProgress> {
        const headers = new HttpHeaders();
        return this.http.post<StudentProgress>(this.url + `/api/v1/student_progress/lecture/${lectureId}`, null, { headers });
    }
    createStudentAnswer(quizId: string, answerId: string, languageCode: string): Observable<Answer> {
        const headers = new HttpHeaders();
        return this.http.post<Answer>(this.url + `/api/v1/user-quiz-answers/quiz/${quizId}/answer/${answerId}/language/${languageCode}`, null, { headers }).pipe(
            map((module: any) => {
                return {
                    id: module.yourAnswer.id || '',
                    isCorrect: module.yourAnswer.isCorrect || false,
                    isPassed: module.isPassed || false,
                    isLastQuizForModule: module.isLastQuizForModule || false,
                    correctAnswer: module.correctAnswer?.content || '',

                } as Answer
            })
        );
    }
    getStudentProgress(): Observable<StudentProgress> {
        const headers = new HttpHeaders();
        return this.http.get<StudentProgress>(this.url + `/api/v1/student_progress`, { headers });
    }

    getStudentProgressByID(id: string): Observable<StudentProgress> {
        const headers = new HttpHeaders();
        return this.http.get<StudentProgress>(this.url + `/api/v1/student_progress/${id}`, { headers });
    }
    getStudentBadges(): Observable<string> {
        const headers = new HttpHeaders();
        return this.http.get<string>(this.url + `/api/v1/badge/student`, { headers });
    }
    getStudentBadgesByID(id: string): Observable<EmptyModel> {
        const headers = new HttpHeaders();
        return this.http.get<any>(this.urlBadgeRewards + `/${id}`, { headers })
            .pipe(
                map((response: any) => {
                    //console.log(response, "response")
                    return {
                        id: response[0].id,
                        title: response[0].title,
                        reward_identifier: response[0].reward_identifier,
                        status_image: this.urlBadge + `${response[0].status_image}`,
                    } as any;
                })
            );
    }

    getStudentQuizAnswer(): Observable<Answer[]> {
        const headers = new HttpHeaders();
        return this.http.get<Answer[]>(this.url + `/api/v1/user-quiz-answers`, { headers }).pipe(
            map((response: any) => {
                return response.map((module: any) => {
                    return {
                        id: module.yourAnswer.id,
                        isCorrect: module.yourAnswer.isCorrect,
                        language: module.yourAnswer.translationID,
                        isPassed: module.isPassed,
                        isLastQuizForModule: module.isLastQuizForModule,
                        correctAnswer: module.correctAnswer.content
                    } as Answer;
                });

            })
        );
    }
    getStudentQuizAnswerByModule(moduleID: string): Observable<Answer[]> {
        const headers = new HttpHeaders();
        return this.http.get<Answer[]>(this.url + `/api/v1/user-quiz-answers/module/${moduleID}`, { headers })
            .pipe(
                map((response: any) => {
                    return response.map((module: any) => {
                        return {
                            id: module.yourAnswer.id,
                            isCorrect: module.yourAnswer.isCorrect,
                            language: module.yourAnswer.translationID,
                            isPassed: module.isPassed,
                            isLastQuizForModule: module.isLastQuizForModule,
                            correctAnswer: module.correctAnswer?.content || ''
                        } as Answer;
                    });

                })
            );
    }

    getStudentQuiz(contentModuleId: string, language: string): Observable<Quizz> {
        const headers = new HttpHeaders();
        return this.http.get<Quizz>(this.url + `/api/v1/quiz/content-modules/${contentModuleId}/studnet/language/${language}`, { headers })
            .pipe(
                map((response: any) => {
                    return response.map((quizz: any) => {
                        return {
                            quizID: quizz.id,
                            title: quizz.content,
                            order: quizz.order.toString().padStart(2, '0')
                        } as Quizz;
                    }).sort((a: any, b: any) => a.order.localeCompare(b.order));
                })
            );

    }

    getStudentQuizByID(contentModuleId: string, language: string, quizId: string): Observable<Quizz> {
        const headers = new HttpHeaders();
        return this.http.get<Quizz>(this.url + `/api/v1/quiz/content-modules/${contentModuleId}/studnet/language/${language}/quiz/${quizId}`, { headers });
    }

    getStudentModules(courseID: string, language: string): Observable<Module[]> {
        const headers = new HttpHeaders();
        return this.http.get<Module[]>(this.url + `/api/v1/content_module/course/${courseID}/student/language/${language}`, { headers })
            .pipe(
                map((response: any) => {
                    return response.map((module: any) => {
                        const formattedLectures = module.lectures.map((lecture: any) => {
                            return {
                                id: lecture?.id || "",
                                title: lecture?.title || "",
                                order: lecture?.order.toString().padStart(2, '0') || ""
                            };
                        });
                        formattedLectures.sort((a: any, b: any) => a.order.localeCompare(b.order));


                        return {
                            id: module?.id || "",
                            title: module?.title || "",
                            haveAccess: module?.haveAccess || "",
                            order: module?.order.toString().padStart(2, '0') || "",
                            lectures: formattedLectures
                        } as Module;
                    });
                })
            );
    }

    getStudentLecturesByID(moduleID: string, language: string): Observable<Lecture> {
        const headers = new HttpHeaders();
        return this.http.get<Lecture>(this.url + `/api/v1/content-lectures/${moduleID}/student/language/${language}`, { headers });
    }

    getStudenProgressByModule(moduleId: string): Observable<StudentProgress[]> {
        const headers = new HttpHeaders();
        return this.http.get<StudentProgress[]>(this.url + `/api/v1/student_progress/module/${moduleId}`, { headers });
    }

}
