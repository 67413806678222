<div *ngIf="aiChat===false" class=" text-white text-left {{ listClass }} md:mt-[30px] md:mb-[40px] flex-grow">
  <div *ngFor="let row of details; let i = index"
    class="lg:flex p-2 {{ row.rowClass }} {{ i % 2 == 0 ? '' : 'bg-[#ffffff11]' }}">
    <div class="flex-grow font-normal text-[16px] text-[#E1E1E1] {{ row.keyClass }}" style="font-family: 'Poppins'">
      {{ row.key }}
    </div>
    <div class="text-right text-[16px] text-[#F9F9F9] {{ row.valueClass }} {{valueClass}}"
      style="font-family: 'Poppins'">
      {{ row.value }}
    </div>
  </div>
</div>

<div *ngIf="aiChat === true" class="overflow-auto text-white text-left w-full {{ listClass }} md:mt-[30px] md:mb-[40px] flex-grow">
  <div *ngFor="let row of details; let i = index"
    class="lg:flex p-2 {{row.bond ?'justify-between gap-[5rem]':''}} {{ row.rowClass }} {{ i % 2 == 0 ? '' : 'bg-[#FFFFFF08]' }}">
    <div class="{{row.bond ?'':'hidden'}} flex-grow font-normal text-[16px] text-[#E1E1E1] {{ row.keyClass }}"
      style="font-family: 'Poppins'">
      {{ row?.bond }}
    </div>
    <div *ngIf="row.bond" class="lg:flex flex-col sm:flex-row sm:min-w-[10rem] justify-between">
      <div class="{{row.bond?'':'flex-grow'}} font-normal text-[16px] text-[#E1E1E1] {{ row.keyClass }}"
        style="font-family: 'Poppins'">
        {{ row.key }}
      </div>
      <div class="text-right text-[16px] text-[#F9F9F9] {{ row.valueClass }} {{valueClass}}"
        style="font-family: 'Poppins'">
        {{ row.value }}
      </div>

    </div>
    <div *ngIf="!row.bond"  class="flex-grow font-normal text-[16px] text-[#E1E1E1] {{ row.keyClass }}" style="font-family: 'Poppins'">
      {{ row.key }}
    </div>
    <div *ngIf="!row.bond"  class="text-right text-[16px] text-[#F9F9F9] {{ row.valueClass }} {{valueClass}}"
      style="font-family: 'Poppins'">
      {{ row.value }}
    </div>
  </div>
</div>