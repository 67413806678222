
<app-stepper-counter *ngIf="steps[0]?.title !== 'init'" [current]="currentStep" [steps]="steps"
  [nextText]="getNextTitle(currentStep)" [finalStep]="getFinalStep()" (nextClick)="changeStep(1, currentStep)"
  [lecture]="true" (prevClick)="changeStep(-1, currentStep)" (fontReset)="resetFontSize()"
  (fontIncrease)="changeFontSize('+')" (fontDecrease)="changeFontSize('-')" [fontSize]="fontSize"
  [courseTitle]="serviceData?.title"[courseDone]="serviceData?.courseDone" [lectureView]="true" [courseTotal]="serviceData?.courseTotal"
  >
  <ng-container *ngIf="!steps[steps.length - 1].checked" [ngSwitch]="currentStep">


    <ng-container *ngFor="let item of content, let i = index">
      <div *ngSwitchCase="i" class="p-6 md:p-10  rounded-2xl ">
        <div class="font-[500] flex justify-center" [style.fontSize.rem]="fontSize">{{ item?.title }}</div>
        
        <div #targetElement class="text-justify"  [style.fontSize.rem]="fontSize">
            
        </div>
        <div id="loadingBar" *ngIf="isLoading" class=" text-white">
          <img class="" src="assets/img/icon/logoLoading.svg" />
        </div>
      </div>
    </ng-container>



  </ng-container>

  <div *ngIf="steps[steps.length - 1].checked" class="w-full bg-[#FFFFFF1A] rounded-2xl">
    <div class="flex flex-col gap-10 p-10 items-center">
      <div class="flex gap-4 items-center font-bold text-[32px]">
        <img src="assets/img/icon/great.svg" /> Great Job!!!
      </div>

      <div class="text-[#E1E1E1] font-normal text-[24px]">You've completed the basics of {{serviceData?.modules?.title}}
      </div>
      
      <div *ngIf="serviceData?.modules?.quiz?.length>0 && serviceData?.modules?.quiz[0]?.title">
        <app-button (click)="openQuizAction()"
          [buttonData]="{textButton:'Answer quizzes to earn rewards'}"></app-button>
      </div>
      <div *ngIf="!serviceData?.modules?.quiz || serviceData?.modules?.quiz?.length<=0||serviceData?.modules?.quiz[0]?.length<=0">
        <app-button [buttonData]="{textButton:'Continue',classButton:'mx-auto'}"
                    (click)="goToEducation()"></app-button>
      </div>
    </div>
  </div>




</app-stepper-counter>