// graphql.module.ts
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions } from '@apollo/client/core';
import { CustomCache } from './custom-cache'; // Import the CustomCache class

const uri = 'https://dev-cms.yieldvestor.com/graphql/';

// Define your custom cache merge logic here
function customCacheMerge(existing: any, incoming: any) {
  // Customize the merge logic based on your requirements
  // For example, you might want to handle conflicts or merge in a specific way
  return { ...existing, ...incoming };
}

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create({ uri }),
    cache: new CustomCache({
      typePolicies: {
        ...createTypePolicies('CourseEntity'),
        ...createTypePolicies('QuizEntity'),
        ...createTypePolicies('ContentModuleEntity'),
      },
    }), // Use your custom cache here
  };
}

function createTypePolicies(entityName: string) {
  return {
    [entityName]: {
      fields: {
        attributes: {
          merge: customCacheMerge,
        },
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
