<div class="md:w-1/4 my-8">
    <app-filter [filters]="content.filters" />
</div>
<div class="text-[1.1rem]">{{content.label}}</div>
<app-cylinder-chart></app-cylinder-chart>
<div class="flex justify-evenly mr-[1rem] px-[5rem] font-[700]">
    <div>
        {{content.left}}
    </div>
    <div>
        {{content.rigth}}
    </div>

</div>