import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';

@Component({
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent],
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
})
export class VideoCardComponent {
  @Input() photo: string | null = 'https://picsum.photos/200/100';
  @Input() home:boolean = false;
}
