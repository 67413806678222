
<div class="bg-[#181C31] rounded-[1.5rem] mb-[1.5rem] lg:px-[1.5rem] lg:py-0 px-[1rem] py-[1rem] border border-[#181C31] ">
    <div *ngFor="let notification of notifications; let i = index"
     [ngClass]="{'mb-[2.5rem]': i === notifications.length - 1, 'mt-[2.5rem]': i ===0}"
     [id]="notification.serviceNotification" class="flex flex-col gap-[1rem]">
        <div class="text-[1.375rem] font-[700]">{{ notification.title }}</div>
        <div class="text-[1rem] text-[#FFFFFF99] font-[400]">{{ notification.description }}</div>

        <div id="emailSwitch" class="flex items-center justify-between">
            <label class="text-[1rem] font-[400]">
                Email notification
            </label>
            <label class="switch">
                <input type="checkbox" [checked]="getCheckboxValue(notification.serviceNotification, 'email')"
                    (change)="updateCheckbox(notification.serviceNotification, 'email', $event)" />
                <span class="slider round"></span>
            </label>
        </div>

        <div id="appSwitch" class="flex items-center justify-between">
            <label class="text-[1rem] font-[400]">
                In app notification
            </label>
            <label class="switch">
                <input type="checkbox" [checked]="getCheckboxValue(notification.serviceNotification, 'appPush')"
                    (change)="updateCheckbox(notification.serviceNotification, 'appPush', $event)" />
                <span class="slider round"></span>
            </label>
        </div>

        <div id="smsSwitch" class="flex items-center justify-between">
            <label class="text-[1rem] font-[400]">
                SMS notification
            </label>
            <label class="switch">
                <input type="checkbox" [checked]="getCheckboxValue(notification.serviceNotification, 'sms')"
                    (change)="updateCheckbox(notification.serviceNotification, 'sms', $event)" />
                <span class="slider round"></span>
            </label>
        </div>
        <div *ngIf="i !== notifications.length - 1"
        class="border-t-[0.5px] p-1 w-full border-[#4A4C57] mx-[5%] md:mx-auto my-[2%]"></div>
    </div>
</div>

<!-- <div class="bg-[#181C31] rounded-[1.5rem] mb-[1.5rem] lg:px-[1.5rem] lg:py-0 px-[1rem] py-[1rem]  ">
    <div class="lg:block lg:p-[2rem] ">
        <div class="pb-[1.5rem]">
            <span class="pb-[2rem] font-[700] text-[1rem] lg:text-[1.375rem] ">How do you want
                to receive notifications?</span>
        </div>
        <div class=" lg:flex lg:justify-start gap-[1.75rem]">
            <div class="bg-[#2F3346] rounded-md items-center h-[3.25rem] w-[15rem] lg:mb-0 mb-[1rem] p-[1rem]">
                <input type="radio" id="dashboard" name="dashboard" />
                <label for="dashboard" class="font-[300] text-[1.1rem]"> Only Dashboard</label>
            </div>

            <div class="bg-[#2F3346] rounded-md items-center h-[3.25rem] w-[15rem]  lg:mb-0 mb-[1rem]  p-[1rem]">
                <input type="radio" id="messangers" name="messangers" />
                <label for="messangers" class="font-[300] text-[1.1rem]"> Messangers</label>
            </div>

            <div class="bg-[#2F3346] rounded-md items-center h-[3.25rem] w-[15rem]  lg:mb-0 mb-[1rem]  p-[1rem]">
                <input type="radio" id="email" name="email" />
                <label for="email" class="font-[300] text-[1.1rem]"> Email</label>
            </div>
        </div>

    </div>
       
</div>

<div class="bg-[#181C31] pb-[1.25rem] rounded-[1.5rem] lg:px-[1.5rem] lg:py-0 px-[1rem] py-[1rem]">
    <div class="lg:flex lg:gap-[3rem] lg:p-[2rem]">
        <div class="">
            <div class="pb-[1.5rem]">
                <span class="pb-[2rem] font-[700] text-[1.25rem] md:text-[1.375rem] max-w-lg">Dashboard
                    theme</span>
            </div>
            <div class="lg:flex lg:justify-start lg:gap-[2rem]">

                <div class="flex justify-start gap-[0.5rem] bg-[#3C3B6B] rounded-md items-center lg:w-[17rem] lg:p-[1rem] p-[0.5rem] lg:mb-0 mb-[1rem] max-w-xs">
                    <app-rounded-icon-buttom
                        [buttonData]='{iconUrl:"assets/img/icon/theme-dark.svg",btnClass:"!bg-transparent border-[0.001rem]"}' />
                    <span>Only Dashboard</span>
                </div>

                <div class="flex justify-start gap-[0.5rem] bg-[#2F3346] rounded-md items-center lg:w-[17rem] lg:p-[1rem] p-[0.5rem] lg:mb-0 mb-[1rem] max-w-xs">
                    <app-rounded-icon-buttom
                        [buttonData]='{iconUrl:"assets/img/icon/theme-light.svg",btnClass:"!bg-transparent border-[0.001rem] "}' />
                    <span>Light Theme</span>
                </div>



            </div>
        </div>

    </div>
</div> -->