import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { EducationService } from 'src/app/services/education.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
  slideConfig = {
    dots: false,
    arrows: false,
    "slidesToShow": 3, "slidesToScroll": 1,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  @Input() content?: any[] = [
    {
      title: "Introduction to Fixed Income",
      modules: [
        {
          title: "Introduction to Fixed Income",
          quizzes: [{ title: "q1" }, { title: "q2" }, { title: "q2" }]
        }, {
          title: "Introduction to Interest Rates",
          quizzes: [{ title: "q1" }, { title: "q2" }, { title: "q2" }, { title: "q2" }]
        },
        {
          title: "Introduction to Bond Valuation",
          quizzes: [{ title: "q1" }, { title: "q2" }]
        },
        {
          title: "Bond Valuation and Interest Rates",
          quizzes: [{ title: "q1" }, { title: "q2" }, { title: "q2" }, { title: "q2" }]
        }]
    },
    { title: "Part Two", modules: [{ title: "Mini 1", quizzes: [{ title: "q1" }, { title: "q2" }] }, { title: "Mini2", quizzes: [{ title: "q1" }, { title: "q2" }, { title: "q3" }] }, { title: "Mini3", quizzes: [{ title: "q1" }, { title: "q2" }, { title: "q2" }, { title: "q2" }] }, { title: "Mini4", quizzes: [{ title: "q1" }, { title: "q2" }, { title: "q2" }, { title: "q2" }] }] }

  ]
  currentSlideIndex: number
  @Input() currentIndex: number = 0
  @Input() moduleProgress: number[] = []
  @Input() quizProgress: number[] = []
  isModuleFinished: boolean[] = [];
  totalModuleProgress: number = 0;
  totalQuizProgress: number = 0;

  constructor(
    private router: Router,
    private educationService: EducationService
  ) {
    this.currentSlideIndex = 0
    this.totalModuleProgress = this.moduleProgress.reduce((acc, val) => acc + val, 0);
    this.totalQuizProgress = this.quizProgress.reduce((acc, val) => acc + val, 0);
  }


  goToLecture(courseID: string, moduleID: string, language: string, lectureID: string, courseDone: any, courseTotal: any) {

    const filtered = this.content?.filter(item => item.id === courseID);
    if (filtered && filtered.length > 0) {
      const selectedModule = filtered[0].modules.find((module: { id: string }) => module.id === moduleID);
      const updatedFiltered = {
        ...filtered[0],
        modules: selectedModule,
        courseDone: courseDone,
        courseTotal: courseTotal
      };

      this.educationService.saveContent(updatedFiltered)
    }

    this.router.navigate(['education/lecture', moduleID, language, lectureID]);
    this.updateIsModuleFinished();
    this.educationService.setLectureID(lectureID);
  }

  ngOnInit() {
    if (this.content && this.content.length > 0) {
      this.updateIsModuleFinished();
    }
  }

  updateIsModuleFinished() {
    this.isModuleFinished = this.content?.map((_, i) => i <= this.currentIndex) ?? [];
    this.isLoading(false)
  }
  calculateProgressWidth(itemModule: any): string {
    if (itemModule?.quiz?.length === 0) {
      return itemModule?.progress + '%';
    }
    if (!itemModule.progress) { return itemModule.total + '%' } else {

      const lastQuizProgress = itemModule?.lastQuizProgress || [];
      const quizLength = itemModule?.quiz?.length || 1; // Avoid division by zero

      const shouldUseLastQuizProgress = lastQuizProgress.length >= quizLength;

      let percentage;

      if (shouldUseLastQuizProgress) {
        const lastQuizProgressItem = lastQuizProgress[lastQuizProgress.length - 1];
        const isLastQuizForModule = lastQuizProgressItem?.isLastQuizForModule || false;

        percentage = (
          itemModule?.progress * 0.8 +
          (isLastQuizForModule
            ? (quizLength / quizLength * 100) * 0.2
            : ((quizLength - 1) / quizLength * 100) * 0.2)
        ) + '%';
      } else {
        const lastQuizProgressItem = lastQuizProgress[lastQuizProgress.length - 1];
        const isLastQuizForModule = lastQuizProgressItem?.isLastQuizForModule || false;

        percentage = (
          itemModule?.progress * 0.8 +
          (isLastQuizForModule
            ? (quizLength / quizLength * 100) * 0.2
            : (lastQuizProgress.length / quizLength * 100) * 0.2)
        ) + '%';
      }

      return percentage;
    }
  }




  calculateProgressPercentage(itemModule: any): number {
    if (itemModule?.quiz?.length === 0) {
      return itemModule?.progress;
    }
    if (!itemModule.progress) { return itemModule.total } else {

      const lastQuizProgress = itemModule?.lastQuizProgress || [];
      const quizLength = itemModule?.quiz?.length || 1; // Avoid division by zero

      const shouldUseLastQuizProgress = lastQuizProgress.length >= quizLength;

      let percentage;

      if (shouldUseLastQuizProgress) {
        const lastQuizProgressItem = lastQuizProgress[lastQuizProgress.length - 1];
        const isLastQuizForModule = lastQuizProgressItem?.isLastQuizForModule || false;

        percentage =
          itemModule?.progress * 0.8 +
          (isLastQuizForModule
            ? (quizLength / quizLength * 100) * 0.2
            : ((quizLength - 1) / quizLength * 100) * 0.2)
          ;
      } else {
        const lastQuizProgressItem = lastQuizProgress[lastQuizProgress.length - 1];
        const isLastQuizForModule = lastQuizProgressItem?.isLastQuizForModule || false;

        percentage =
          itemModule?.progress * 0.8 +
          (isLastQuizForModule
            ? (quizLength / quizLength * 100) * 0.2
            : (lastQuizProgress.length / quizLength * 100) * 0.2)
          ;
      }

      return percentage;
    }
  }


  /*   calculateProgressPercentageToString(itemModule: any): string {
      if (itemModule?.quiz?.length === 0) {
        return itemModule?.progress.toString();
      }
    
      const percentage = itemModule?.total
        ? itemModule?.total
        : (
          itemModule?.quiz && itemModule?.lastQuizProgress?.length !== 0
            ? (itemModule?.progress * 0.8 + ((itemModule?.lastQuizProgress?.length / itemModule?.quiz?.length) * 100) * 0.2)
            : (itemModule?.progress * 0.8)
        );
    
      return percentage.toString();
    } */

  calculateCompletedCourses(itemModule: any, boolean: boolean = true): string {
    // Perform your progress calculation logic here and return it as a string.
    const objectsWithAccess = itemModule?.modules?.filter((obj: any) => {
      // Check if quiz.length is greater than 0 and progress is 100
      return (this.calculateProgressPercentage(obj)===100);
    });

    if (boolean) {
      return objectsWithAccess?.length.toString();
    } else {
      const total = (objectsWithAccess?.length / itemModule?.modules?.length) * 100;
      return total.toString() + "%";
    }
  }


  isHovered: boolean[] = [];
  onButtonMouseEnter(index: number) {
    this.isHovered[index] = true;
  }

  onButtonMouseLeave(index: number) {
    this.isHovered[index] = false;
  }
  loading: boolean = true
  isLoading(loadingStatus: boolean) {
    this.loading = loadingStatus
  }
}
