<app-user-layout>

    <div class="flex flex-col ">



        <div class="flex flex-col mx-auto w-full px-32">
            <app-tabs
                [tabsData]="{active:'!bg-[#2CC784] shadow-lg shadow-[0px_0px_20px_5px_#81FFAF80]',class:'gap-0 bg-[#FFFFFF11] md:min-w-0 !p-2 mb-9 rounded-full',classTab:'border-0'}"
                [tabs]="[{name:'Performance',iconUrl:''},
                 {name:'Allocations',iconUrl:''}]" />


            <div *ngIf="!isCheckScnarioTest">
                <p class="font-regular text-[18px] pt-2 mb-8">The following graph shows your investment growth</p>


                <div class="flex flex-col md:flex-row mb-8">
                    <div class="w-full md:w-1/2">


                        <span class="text-[12px] text-[#E1E1E1]">Select Investment Strategy</span>
                        <app-button (click)="handleScenario()"
                            [buttonData]="{textButton:strategies[currentPosition].title ,iconRightUrl:'assets/img/icon/input_arrow.svg',iconRight:true,   classButton:'!bg-[#FFFFFF10] !h-[2.8rem] w-full ',classText:'!text-[#FFFFFF]  mr-auto !text-[13px] !font-[400]'}" />

                    </div>
                    <div class="w-full md:w-1/2">
                        <span class="text-[12px] text-[#E1E1E1] ml-3">Select benchmark</span>
                        <app-button (click)="handleBenchmark()"
                            [buttonData]="{textButton:'US Treasury Bond',iconRightUrl:'assets/img/icon/input_arrow.svg',iconRight:true,   classButton:'!bg-[#FFFFFF10] text-left !h-[2.8rem] ml-3 w-full ',classText:'!text-[#FFFFFF] mr-auto   !text-[13px] !font-[400]'}" />

                    </div>
                </div>
                <div *ngIf="showOptions">
                    <app-performance-card [cardTitle]="cardTitle" (currentPosition)="curentIndex($event)"
                        [items]="strategies" [itenIcons]="true"></app-performance-card>

                </div>

                <div *ngIf="showOptionsBenchmark">

                    <app-performance-card [cardTitle]="cardTitle2" [items]="benchmarks"
                        [itenIcons]="false"></app-performance-card>

                </div>

                <app-line-chart></app-line-chart>

                <div *ngIf="activateScenarioTest" class="flex">
                    <div class="mr-auto">

                        <app-button (click)="handleCheckScenarioTes()"
                            [buttonData]="{textButton:'Check Scenario Test',iconRightUrl:'assets/img/icon/arrow-green.svg',iconRight:true,   classButton:'!bg-transparent !h-[2.5rem]',classText:'!text-[#FFFFFF] !text-[13px] !font-[400]'}" />

                    </div>

                    <!-- Item à direita -->
                    <div class="ml-auto">
                        <app-button (click)="handleContinue()"
                            [buttonData]="{textButton:'Continue',  classButton:'!bg-[#6761A8] !h-[2.5rem]',classText:'!text-[#FFFFFF] !text-[13px] !font-[400]'}" />
                    </div>
                </div>

            </div>
            <div *ngIf="isCheckScnarioTest" class="flex  flex-col mx-auto w-full ">
                <app-scenario-test></app-scenario-test>
            </div>
        </div>
    </div>
</app-user-layout>