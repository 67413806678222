import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
@Component({
  selector: 'app-landpage',
  templateUrl: './landpage.component.html',
  styleUrls: ['./landpage.component.scss']
})
export class LandpageComponent implements OnInit {
  authenticated:boolean = false;
  constructor(private keycloakService:KeycloakService){}
  ngOnInit(): void {
    this.isAuthenticaded();
  }
  async isAuthenticaded(){
    this.authenticated = await this.keycloakService.isLoggedIn();
  }
  goToLogin() {
    this.keycloakService.login();
  }
  goToRegister() {
    this.keycloakService.register();
  }
}
