<div class="flex justify-start gap-4 my-4">
    <div *ngIf="showLogo">
        <app-rounded-icon-buttom 
        [buttonData]="{
            iconUrl:'assets/img/icon/logo.svg',
            btnClass:'!bg-white hidden md:inline-flex',
            iconClass:'w-5'
        }" />
    </div>
    <div class="flex-grow bg-[#FFFFFFaa] rounded-[30px] rounded-tl-[8px] p-8">
        <ng-content></ng-content>
    </div>
</div>