<div class="
  
   rounded-3xl justify-between {{!vertical&&aiChat?'!bg-yv-5%-gray max-w-[19rem] sm:max-w-[25rem] md:max-w-[35rem] lg:max-w-full':''}} w-full h-auto bg-yv-primary-70  {{
    assetsData.assetsCardClass
  }}" style="
    padding-top: 1.2rem;
    padding-right: 1rem;
    padding-bottom: 1.2rem;
    padding-left: 1rem;
  ">
  <div class="{{!vertical?'lg:flex':''}}">
    <div class="px-4 pt-4">
      <div class="flex justify-between'">
        <div class="flex-grow">
          <app-rounded-icon-buttom (btnClick)="onLogoClick()"
            [buttonData]="{ iconUrl: assetsData.logo }"></app-rounded-icon-buttom>
        </div>
        <div class="{{!vertical?'hidden':'inline-flex'}}  justify-between space-x-3">
          <app-rounded-icon-buttom (btnClick)="onLikeClick()" [buttonData]="{
            iconUrl: assetsStyle.iconLike,
            btnClass: assetsStyle.iconLikeClass
          }"></app-rounded-icon-buttom>
          <app-rounded-icon-buttom (btnClick)="onShareClick()" [buttonData]="{
            iconUrl: assetsStyle.iconShare,
            btnClass: assetsStyle.iconShareClass
          }"></app-rounded-icon-buttom>
          <app-rounded-icon-buttom (btnClick)="onPlayClick()" [buttonData]="{
            iconUrl: assetsStyle.iconPlay,
            btnClass: assetsStyle.iconPlayClass
          }"></app-rounded-icon-buttom>
        </div>
      </div>
  
      <div id="currencyText" class="{{!vertical&&aiChat?'max-w-[13rem] text-left':''}} {{!vertical?'':'flex'}} mt-5">
        <span class="text-white text-left" style="
          font-family: Poppins;
          font-weight: 400;
          font-size: 1.25rem;
          line-height: 1.875rem;
        ">{{ assetsData.firstText }}
        </span>
        <span class="ms-1" style="
          font-family: Poppins;
          font-weight: 400;
          font-size: 1.125rem;
          line-height: 1.875rem;
          color: #b3b3b3;
        ">
          {{ assetsData.currencyType }}</span>
      </div>
  
      <div id="codeText" class="flex mb-3">
        <span class="" style="
          color: #978ef5;
          font-family: Poppins;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
        ">{{ assetsData.codeText }}</span>
      </div>
    </div>
    <div class="flex-grow ">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="border-t-2 border-solid border-gray-700 mb-[2rem]"></div>

  <div class="{{!vertical?'hidden': 'flex'}} justify-between items-center mb-5">
    <button (click)="onDetailsClick()" class="font-bold z-10" style="
        font-size: 1rem;
        line-height: 1.25rem;
        color: #978ef5;
        letter-spacing: 0.1rem;
      ">
      Details
    </button>

    <button (click)="onOrderClick()" class="bg-yv-primary rounded-lg text-white" style="
        padding: 1rem, 1.2rem, 1rem, 1.2rem;
        width: 95px;
        height: 52px;
        font-family: Poppins;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
      ">
      Order
    </button>
  </div>

  <div id="aiChatOptions" class="{{!vertical&&aiChat || true?'flex flex-col px-4  lg:flex-row gap-[2rem] lg:gap-0  lg:items-center lg:justify-between': 'hidden'}}">
    <!-- <div id="3buttons">
      <div class="inline-flex w-full justify-between space-x-[1rem]">
        <app-rounded-icon-buttom (btnClick)="onLikeClick()" [buttonData]="{
          iconUrl: assetsStyle.iconLike,
          btnClass: assetsStyle.iconLikeButton, iconClass: assetsStyle.iconLikeClass
        }"></app-rounded-icon-buttom>
        <app-rounded-icon-buttom (btnClick)="onShareClick()" [buttonData]="{
          iconUrl: assetsStyle.iconShare,
          btnClass: assetsStyle.iconShareButton, iconClass: assetsStyle.iconShareClass
        }"></app-rounded-icon-buttom>
        <app-rounded-icon-buttom (btnClick)="onPlayClick()" [buttonData]="{
          iconUrl: assetsStyle.iconPlay,
          btnClass: assetsStyle.iconPlayButton, iconClass: assetsStyle.iconPlayClass
        }"></app-rounded-icon-buttom>
      </div>
    </div> -->
    <div id="" class="flex gap-[1rem] items-center justify-end">
      <button (click)="onDetailsClick()" class="font-bold z-10 flex gap-3" style="
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: 0.1rem;
        ">
        <div style="color: #978ef5;">Asks </div>
        <div class="text-white">$99.24</div>
        
      </button>
  
      <button (click)="onOrderClick()" class="bg-yv-primary-70 rounded-lg text-yv-primary" style="
          padding: 1rem, 1.2rem, 1rem, 1.2rem;
          width: 95px;
          height: 52px;
          font-family: Poppins;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.25rem;
        ">
        Sell
      </button>
    </div>
    <div id="detailOrderButtons" class="flex gap-[1rem] items-center justify-end">
      <button (click)="onDetailsClick()" class="font-bold z-10 flex gap-3" style="
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: 0.1rem;
        ">
        <div style="color: #978ef5;">Ask </div>
        <div class="text-white">$99.24</div>
        
      </button>
  
      <button (click)="onOrderClick()" class="bg-yv-primary rounded-lg text-white" style="
          padding: 1rem, 1.2rem, 1rem, 1.2rem;
          width: 95px;
          height: 52px;
          font-family: Poppins;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.25rem;
        ">
        Buy
      </button>
    </div>
  </div>
</div>