import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss']
})
export class AssistantComponent implements OnInit {
  samples:any[] = [];
  currentPrompt:string = ""
  constructor(private router: Router) { }

  ngOnInit() {
   
  }
  goToChat(){
    this.router.navigate(['assistant/chat'],{queryParams:{prompt:this.currentPrompt}});
  }

}
