// custom-cache.ts
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';

export class CustomCache extends InMemoryCache {
  merge(existing: NormalizedCacheObject, incoming: NormalizedCacheObject): NormalizedCacheObject {
    // Your custom merge logic goes here
    // You can implement your own merging strategy based on your application's requirements
    // For example, you might want to merge data in a specific way or ignore certain fields

    // As a basic example, let's say you want to merge the incoming data into the existing cache
    const mergedData = { ...existing, ...incoming };

    return mergedData;
  }
}
