import { Component, Input } from '@angular/core';
import { DetailsData } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  @Input() title?:"Top Ten Sector Allocation (%)2"
  @Input() content?: DetailsData[]=[
        { "key": "Instrument type", "value": "Sovereign Bond" },
        { "key": "Maturity", "value": "2025" },
        { "key": "Interest rate", "value": "8.75%" },
        { "key": "Yield", "value": "5.99%"}
      ]

}
