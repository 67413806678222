import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-yield-details',
  templateUrl: './yield-details.component.html',
  styleUrls: ['./yield-details.component.scss']
})
export class YieldDetailsComponent {
  @Input() content?:any
  currentTab:string = "h";
  chartTabs = [{name:'Historical data',action:()=>this.setChartTab("h")},
  {name:'Spread',action:()=>this.setChartTab("s")},
  {name:'Liquidity Indicator',action:()=>this.setChartTab("li")},
  {name:'Bid-Ask',action:()=>this.setChartTab("ba")}];
  
  constructor(){console.log("content",this.content)}
  setChartTab(tab:string){
    this.currentTab = tab;
  }

  getContentValue(key: string): string {
    
    if (this.content && this.content.list) {
      console.log("content",this.content)
      const item = this.content.list.find((item:any) => item.key === key);
      if (item) {
        return item.value;
      }
    }
    return '';
  }
  

}
