<div class="block bg-[#181C31] pb-[1.25rem]  rounded-[1.5rem]" style="
padding-top: 1.2rem;
padding-bottom: 1.2rem;
padding-right: 1.5rem;
padding-left: 1.5rem;
">
    <div class=" pb-[8%] lg:pb-[2%]">
        <div class="lg:flex lg:justify-between">
            <app-select-form [selectData]="{label:'Asset',selectFormClass:'lg:w-[20rem] '}" [items]='items' />
            <div class=" pt-[1.25rem]">
                <app-button
                    [buttonData]="{textButton:'Download all documents',classButton:'!bg-[#978EF51A] !border-[#181C31] !border-[0.1rem] w-full ',classText:'!text-[#978EF5]'}" />
            </div>
        </div>

    </div>
     <app-my-bonds-table [columns]="tableColumns" [data]="tableData" />
</div>