import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent {
  currentTab = "graph"
  @Input() content?: any
  tabs = [{name:'Graphic comparison',action:()=>this.selectTab('graph')},
  {name:'Table view comparison',action:()=>this.selectTab('table')},
  {name:'Yield comparison',action:()=>this.selectTab('yield')}]

  selectTab(tab:string) {
    this.currentTab = tab
  }
}
