import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EducationService } from './education.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // List of URLs that should not have the Authorization header
  private excludedUrls: string[] = ['https://dev-cms.yieldvestor.com/graphql/','https://dev.yieldvestor.com/roboadv'];
  constructor(private educationService:EducationService){}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the request URL is in the excludedUrls list
    const isExcludedUrl = this.excludedUrls.some((url) =>
      request.url.includes(url)
    );

    // If the URL is in the excluded list, clone the request without the Authorization header
    if (isExcludedUrl) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'TENANT_ID': '111',
          Authorization: 'Basic ' + btoa("developer" + ':' + '_c\=q![p2iC!i":?@B')// 'Bearer '+this.getToken(), // Remove the Authorization header
          //,...request.headers
        },
      });
    }

    return next.handle(request);
  }
  getToken(){
    return this.educationService.tokken
  }
}