import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-spline-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spline-chart.component.html',
  styleUrls: ['./spline-chart.component.scss']
})
export class SplineChartComponent {

  dynamicId = `component_${Math.floor(Math.random() * 10000)}`

  ngAfterViewInit() {
    this.renderChart();
  }

  renderChart() {

    const gradientColor = {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1
      },
      stops: [
        [0, 'rgba(44, 199, 132, 0.4)'],
        [1, 'rgba(44, 199, 132, 0)']
      ]
    };

    const chartOptions: Highcharts.Options = {


      chart: {
        type: 'spline',
        backgroundColor: 'transparent',
      },

      title: {
        text: '',
      },

      legend: {
        enabled: false
      },

      credits: {
        enabled: false
      },

      xAxis: {
        title: {
          text: ''
        },
        gridLineWidth: 0, // Remove horizontal grid lines
        lineWidth: 0, // Remove x-axis line
        labels: {
          enabled: false,// Hide x-axis labels
        }
      },

      yAxis: {
        title: {
          text: ''
        },
        gridLineWidth: 0, // Remove vertical grid lines
        lineWidth: 0, // Remove y-axis line
        labels: {
          enabled: false // Hide y-axis labels
        }
      },

      plotOptions: {
        area: {
          lineWidth: 1, // Adjust line width for smoother lines
          linecap: 'round', // Set linecap to round for smoother line ends
          color: '#98F2B9',
          marker: {
            enabled: false
          },
        },
        spline: {
          lineWidth: 4,
          color: '#98F2B9',
          marker: {
            enabled: false
          },
          
        },
      },

      series: [
        {
        data: [
         50, 5, 150, 120, 300, 250],
          type: 'spline',
      },
      {
        data: [
          50, 5, 150, 120, 300, 250],
          type: 'area',
          color: gradientColor
      } as any]



    }

    Highcharts.chart(this.dynamicId, chartOptions);

  }

}
