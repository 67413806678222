<app-button [routerLink]="['/education']" [buttonData]="{textButton:'Join the courses',classButton:'ml-[4rem] mt-5'}" />

<button  ></button>

<!-- <div class="max-w-full ">

    <div id="topCounter" class="flex item">
        <div class="text-xs md:text-base py-6">
            <div class="">
                <span class="font-[300] text-[28px] ">{{ (currentIndex+1) | number: '2.0' }}</span>
                <span class="font-[300] text-[14px]  text-[#6761A8]"> / {{content!.length| number: '2.0'}}</span>
                <div class="font-[400] text-[19px] text-[#FFFFFF] whitespace-nowrap">
                    {{content![currentIndex].title}}</div>
            </div>
        </div>
    </div>
    <div id="topCounterLine" class=" border-t-[0.5px] border-[#B3B3B3] mx-auto mt-20"
        style="border-color: transparent; margin-top: 0;">
        <div class="relative">
            <div class="w-0 bg-[#2CC784] py-1 rounded"
                [style.width]="((currentIndex + 1) / (content!.length )) * 100 + '%'"></div>
            <div class="absolute inset-0 flex align-center items-center align-middle content-center" style="top: 8px;">
            </div>
            <div class="absolute inset-0 flex align-center items-center align-middle content-center">
                <div class="w-0 bg-[#B3B3B3] h-[1px] rounded" style="width: 100%;"></div>
            </div>
        </div>
    </div>



    <ngx-slick-carousel class="carousel w-full md:p-8 min-w-[100%] md:min-w-0" #slickModal="slick-carousel"
        [config]="slideConfig" (afterChange)="currentSlideIndex = $event.currentSlide">
        <div ngxSlickItem *ngFor="let item of content,let i = index" class="slide grid mx-1 md:mx-4 ">
            <div class="bg-[#FFFFFF1A] rounded-2xl py-8 p-4 min-h-[46rem] flex flex-col">
                <div class="font-bold text-[18px] text-[#978EF5]">Module {{i+1}}: </div>
                <div class="font-normal text-[24px] mb-8">{{item.title}}</div>

                <div>
                    <div *ngFor="let topic of item.topics,let j = index" class="flex gap-4 items-center py-4">
                        <div>
                            <div class="h-[12px] w-[12px] rounded-full bg-[#81FFAF]"></div>
                        </div>
                        <div class="text-[16px] text-[#E1E1E1]">{{topic}}</div>
                    </div>
                </div>
                <div class="flex-grow"></div>
                <div class="items-center" (click)="isModuleFinished[i] && goToLecture()">
                    <hr class="border-[#E1E1E133]" />
                    <div class="flex flex-col items-center gap-[1rem]">
                        <app-button
                            [buttonData]="{ textButton: 'Start learning', classButton: !isModuleFinished[i] ? 'mx-auto mt-8 opacity-25' : 'mx-auto mt-8 mb-9' }"></app-button>
                        <span class="text-[#FF6F65] text-[0.85rem]" *ngIf="!isModuleFinished[i]">You should finish
                            previous Module</span>
                    </div>
                </div>


            </div>
        </div>
    </ngx-slick-carousel>
    <div class="flex gap-8 items-center justify-center p-8">
        <app-rounded-icon-buttom (btnClick)="slickModal.slickPrev()"
            [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass:'bg-transparent border rotate-180'}" />
        <div class="custom-dots w-full">
            <span *ngFor="let slide of content; let i = index"
                class="dot flex-grow {{currentSlideIndex === i?'active':''}}" (click)="slickModal.slickGoTo(i)"></span>
        </div>
        <app-rounded-icon-buttom (btnClick)="slickModal.slickNext()"
            [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg'}" />

    </div>
</div> -->