<app-user-layout>
    <div class="bg-yv text-white min-h-screen">

        <div class="w-full text-center pt-[2.5rem]">
            <div class="font-[360] text-[2.188rem] md:text-[4.25rem] font-poppins">Identity Verification</div>
            <div class="font-normal text-[1.125rem]"></div>
        </div>

        <app-stepper-counter [full]="false" [current]="currentStep" [steps]="steps" (nextClick)="changeStep(1, currentStep)"
            (prevClick)="changeStep(-1, currentStep)">
            <ng-container [ngSwitch]="currentStep">
                <div *ngSwitchCase="0" class="max-w-screen-md mx-auto">
                    <div>
                        <span
                            class="font-bold text-[1.25rem] md:text-[1.5rem] max-w-lg mx-auto text-center block">First,
                            where are you located?</span>
                        <span
                            class="pb-[2.5rem] font-[300] text-[1.188rem] md:text-[1.25rem] max-w-md mx-auto text-center block"
                            style="padding-top: 0;">We tailor our offering and onboarding experience based on your
                            location</span>
                    </div>

                    <div class="max-w-screen-md mx-auto flex justify-center">
                        <app-input-form
                            [inputData]="{label:'Country',inputType:'text',placeholder:'2',inputFormClass:'md:w-[20rem] sm:w-[22.6rem]'}"></app-input-form>
                    </div>
                </div>

                <div *ngSwitchCase="1" class="max-w-screen-xl mx-auto">
                    <div
                        class="pb-[2rem] font-normal text-[1.438rem] md:text-[1.8rem]  max-w-lg mx-auto text-center block">
                        <span class="">Things to know before Investing</span>
                    </div>
                    <div class="grid md:grid-cols-3 gap-[2rem]  justify-evenly md:gap-[4rem] p-[1rem] md:p-0">
                        <div *ngFor="let disclaimersInfo of disclaimersInfos, let i = index" class="rounded  ">
                            <div class="flex sm:grid items-center">
                                <app-rounded-icon-buttom
                                    [buttonData]="{iconUrl:disclaimersInfo.icon,btnClass:'!ml-[1rem]  !w-[4rem] !h-[4rem]'}" />

                                <div class="  ml-[0.75rem] sm:mt-[0.5rem] font-[200] text-[1.125rem] ">
                                    {{disclaimersInfo.desc}}</div>
                            </div>
                        </div>

                    </div>

                    <div class="font-bold text-[1.375rem] p-[2.5rem] max-w-lg mx-auto text-center">Do you understand
                        the
                        risk of
                        investing?</div>
                    <div class="">
                        <div class="max-w-lg mx-auto mt-[5rem] flex justify-around"
                            style="border-color: transparent; margin-top: 0;">
                            <label for="{{ disclaimersConfirmation.title }}"
                                *ngFor="let disclaimersConfirmation of disclaimersConfirmations; let i = index">
                                <input type="radio" id="{{ disclaimersConfirmation.title }}"
                                    name="disclaimersConfirmation" />
                                <label for="{{ disclaimersConfirmation.title }}" class="font-[300] text-[1.25rem]">{{
                                    disclaimersConfirmation.desc }}</label>
                            </label>
                        </div>
                    </div>
                </div>

                <div *ngSwitchCase="2" class="max-w-screen-md mx-auto">
                    <div>
                        <span class="font-bold text-[1.5rem]  max-w-lg mx-auto text-center block"
                            style="padding-bottom: 0;">What is your phone number?</span>
                        <span
                            class="pb-[2rem] px-[0.5rem] md:px-[1rem] font-[300] text-[1.125rem] md:text-[1.188rem]  max-w-lg mx-auto text-center block"
                            style="padding-top: 0;">You’ll use this number to verify your account and
                            transactions</span>
                    </div>
                    <div class=" flex justify-center gap-[1rem] mb-[1rem]  px-[1.25rem]">
                        <app-input-form
                            [inputData]="{label:'Country Code',inputType:'text',placeholder:'+238'}"></app-input-form>
                        <app-input-form
                            [inputData]="{label:'Phone',inputType:'text',placeholder:'564 44 65',}"></app-input-form>
                    </div>
                    <div class=" ml-[0.75rem]">
                        <div class="max-w-lg mx-auto mt-[5rem] flex justify-around"
                            style="border-color: transparent; margin-top: 0;">
                            <label for="{{ phoneConfirmation.title }}"
                                *ngFor="let phoneConfirmation of phoneConfirmations; let i = index">
                                <input type="radio" id="{{ phoneConfirmation.title }}" name="disclaimersConfirmation" />
                                <label for="{{ phoneConfirmation.title }}"
                                    class="font-[300] md:text-[1rem] ml-[0.25rem]">{{
                                    phoneConfirmation.desc }}</label>
                            </label>
                        </div>
                    </div>
                </div>

                <div *ngSwitchCase="3" class="max-w-screen-md mx-auto">
                    <div>
                        <span class="font-bold text-[1.5rem]  max-w-lg mx-auto text-center block"
                            style="padding-bottom: 0;">Verification</span>
                        <span
                            class="pb-[2rem] px-[0.5rem] md:px-[1rem] font-[300] text-[1.125rem] md:text-[1.188rem]  max-w-lg mx-auto text-center block"
                            style="padding-top: 0;">Enter the 6-digit number that we sent to your mobile nubmer ending
                            in
                            +44*******123</span>
                    </div>
                    <div class=" flex justify-center gap-[1rem] mb-[1rem] ">
                        <app-input-form
                            [inputData]="{inputType:'text',placeholder:'2',inputFormClass:'md:w-[3rem] w-[2.2rem]'}"></app-input-form>
                        <app-input-form
                            [inputData]="{inputType:'text',placeholder:'2',inputFormClass:'md:w-[3rem] w-[2.2rem]'}"></app-input-form>
                        <app-input-form
                            [inputData]="{inputType:'text',placeholder:'2',inputFormClass:'md:w-[3rem] w-[2.2rem]'}"></app-input-form>
                        <app-input-form
                            [inputData]="{inputType:'text',placeholder:'2',inputFormClass:'md:w-[3rem] w-[2.2rem]'}"></app-input-form>
                        <app-input-form
                            [inputData]="{inputType:'text',placeholder:'2',inputFormClass:'md:w-[3rem] w-[2.2rem]'}"></app-input-form>
                        <app-input-form
                            [inputData]="{inputType:'text',placeholder:'',inputFormClass:'md:w-[3rem] w-[2.2rem]'}"></app-input-form>
                    </div>
                    <div class="flex justify-center gap-[0.25rem] pt-[1rem]">
                        <span class="">Haven’t recieve?</span>
                        <span class="text-green-500">Resent code</span>

                    </div>
                </div>

                <div *ngSwitchCase="4" class="max-w-screen-md mx-auto">
                    <div>
                        <span
                            class="pb-[2rem] font-bold text-[1.125rem] md:text-[1.5rem]  max-w-xl mx-auto text-center block">Are
                            you
                            signing up as an individual or a company?</span>
                    </div>
                    <div class="p-[1.25rem] md:p-0 ">
                        <div class=" md:grid md:grid-cols-2 justify-evenly  md:gap-[2rem] ">
                            <div *ngFor="let signUp of signUps, let i = index"
                                class=" {{i%2 != 0?'bg-[#6761A880] bg-opacity-20':'bg-[#4946681A] bg-opacity-20'}} rounded-lg  md:p-[1.75rem] flex mb-[1rem] md:mb-0 sm:grid p-[1rem] ">
                                <app-rounded-icon-buttom
                                    [buttonData]="{iconUrl:signUp.icon,btnClass:'!w-[3.5rem] !h-[3.5rem] md:!w-[4rem] md:!h-[4rem] mt-[0.75rem] ml-[0.25rem]',iconClass:'!w-[1.5rem] !h-[1.5rem] md:!h-[2rem] md:!w-[2rem]'}" />
                                <div class="ml-[1rem] mt-0">

                                    <span
                                        class="text-[1.25rem] md:text-[1.375rem] font-bold pb-[0.25rem] ">{{signUp.title}}</span>
                                    <div class=" text-[1rem] md:text-[1.25rem] ">{{signUp.desc}}</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div *ngSwitchCase="5" class="max-w-screen-md mx-auto">
                    <div>
                        <span class="font-bold text-[1.5rem]  max-w-xl mx-auto text-center block"
                            style="padding-bottom: 0;">Personal enter your name as it appears on your photo ID.</span>
                    </div>
                    <div class="flex flex-col space-y-8 pt-[1.25rem] px-[4rem] md:px-[14rem]">
                        <app-input-form
                            [inputData]="{label:'Full Name',inputType:'text',placeholder:'John Braun'}"></app-input-form>
                        <app-input-form
                            [inputData]="{label:'Middle name  (Optional)',inputType:'text',placeholder:'Middle name (s)'}"></app-input-form>
                        <app-input-form
                            [inputData]="{label:'Last name',inputType:'text',placeholder:'lLast name'}"></app-input-form>
                        <app-input-form
                            [inputData]="{label:'Email',inputType:'text',placeholder:'564 44 65',}"></app-input-form>
                    </div>
                    <div class="mt-[1.5rem] mb-0">
                        <div class="max-w-lg mx-auto flex justify-around"
                            style="border-color: transparent; margin-top: 0;">
                            <label for="{{ emailConfirmation.title }}"
                                *ngFor="let emailConfirmation of emailConfirmations; let i = index">
                                <input type="radio" id="{{ emailConfirmation.title }}" name="disclaimersConfirmation" />
                                <label for="{{ emailConfirmation.title }}"
                                    class="font-[300]text-[1rem] md:text-[1.188rem] ml-[0.25rem]">{{
                                    emailConfirmation.desc }}</label>
                            </label>
                        </div>
                    </div>
                    <div class=" pt-[1.25rem] px-[1rem] md:px-40">
                        <app-input-form
                            [inputData]="{inputType:'text',placeholder:'Incorrectly filled data. Try again',inputClass:''}"></app-input-form>
                    </div>
                </div>

                <div *ngSwitchCase="6" class="max-w-screen-md mx-auto">
                    <div>
                        <span class="font-bold text-[1.5rem]  max-w-md mx-auto text-center block"
                            style="padding-bottom: 0;">Residental address</span>
                        <span
                            class="pb-[2rem] px-[0.5rem] md:px-[1rem] font-[300] text-[1.188rem] md:text-[1.188rem] max-w-md mx-auto text-center block"
                            style="padding-top: 0;">We use your address as part of your identity verification. This
                            should
                            be where you pay utilities or recieve financial statements.</span>
                    </div>
                    <div class=" flex justify-center gap-[1rem] mb-[1rem]  px-[1.25rem]">
                        <app-input-form
                            [inputData]="{label:'Post Code',inputType:'text',placeholder:'Your postCode'}"></app-input-form>
                        <app-input-form
                            [inputData]="{label:'.',inputType:'text',placeholder:'Find address',inputFormClass:'!text-transparent'}"></app-input-form>
                    </div>

                </div>

                <div *ngSwitchCase="7" class="max-w-screen-md mx-auto">
                    <div>
                        <span class="font-bold text-[1.5rem]  max-w-md mx-auto text-center block"
                            style="padding-bottom: 0;">Tax details</span>
                        <span
                            class="pb-[2rem] px-[0.5rem] md:px-[1rem] font-[300] text-[1.188rem] md:text-[1.188rem] text-center max-w-lg mx-auto block"
                            style="padding-top: 0;">Almost there we need some information to verify your identity and
                            tax
                            status so you can begin investing.</span>
                    </div>
                    <div class="flex flex-col space-y-8 pt-[1.25rem] px-[4rem] md:px-[14rem]">
                        <app-input-form
                            [inputData]="{label:'Nationality',inputType:'text',placeholder:'United Kingdom'}"></app-input-form>

                        <div>

                            <div class="flex justify-between pb-0 mb-0">

                                <span class="mr-auto">UK National Insurance</span>
                                <span class="text-green-500">What is it?</span>
                            </div>
                            <app-input-form [inputData]="{inputType:'text',placeholder:'QQ123HGJHJ',}"></app-input-form>
                        </div>


                    </div>

                </div>

                <div *ngSwitchCase="8" class="max-w-screen-md mx-auto">
                    <div>
                        <span class="font-bold text-[1.5rem]  max-w-md mx-auto text-center block"
                            style="padding-bottom: 0;">Identity documents details</span>
                    </div>
                    <div class="flex flex-col space-y-8 pt-[1.25rem] px-[1.5rem] md:px-[12rem]">
                        <div>

                            <div class="flex justify-between pb-0 mb-0">

                                <span class="mr-auto">Nationality</span>
                                <span class="text-green-500">What is it?</span>
                            </div>
                            <app-input-form
                                [inputData]="{inputType:'text',placeholder:'United Kingdom',}"></app-input-form>
                        </div>


                    </div>

                </div>

                <div *ngSwitchCase="9" class="max-w-screen-md mx-auto">
                    <div>
                        <span class="font-bold text-[1.5rem]  max-w-lg mx-auto text-center block"
                            style="padding-bottom: 0;">Take a selfie</span>
                        <span
                            class="pb-[2rem] px-[0.5rem] md:px-[1rem] font-[300] text-[1.125rem] md:text-[1.188rem]  max-w-lg mx-auto text-center block"
                            style="padding-top: 0;">Please take a photo of your face so we can compare to your document
                            photo</span>
                    </div>
                    <div class="p-[1.25rem] md:p-0 ">
                        <div class=" md:grid md:grid-cols-2 justify-evenly  md:gap-[2rem] ">
                            <div *ngFor="let facialsInfo of facialsInfos, let i = index"
                                class="md:p-[1.75rem] flex mb-[1rem] md:mb-0 sm:grid p-[1rem] ">
                                <app-rounded-icon-buttom
                                    [buttonData]="{iconUrl:facialsInfo.icon,btnClass:'!w-[3.5rem] !h-[3.5rem] md:!w-[4rem] md:!h-[4rem] mt-[0.75rem] ml-[0.25rem]',iconClass:'!w-[1.5rem] !h-[1.5rem] md:!h-[2rem] md:!w-[2rem]'}" />
                                <div class="ml-[1rem] md:mt-[0.5rem] mt-0">

                                    <span
                                        class="text-[1.25rem] md:text-[1.375rem] font-bold pb-[0.25rem]">{{facialsInfo.title}}</span>
                                    <div class=" text-[1rem] md:text-[1.188rem] md:mt-[0.5rem]">{{facialsInfo.desc}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex justify-center">
                        <app-button [buttonData]="{textButton:'Open Camera',classButton:'!h-5/6'}"></app-button>
                    </div>
                </div>

                <div *ngSwitchCase="10" class="max-w-screen-md mx-auto">
                    <div>
                        <span class="pt-[1rem] font-bold text-[1.5rem]  max-w-lg mx-auto text-center block"
                            style="padding-bottom: 0;">Bank verification</span>
                        <span
                            class=" px-[0.5rem] md:px-[1rem] font-[300] text-[1.125rem] md:text-[1.188rem]  max-w-lg mx-auto text-center block"
                            style="padding-top: 0;">We need to capture you bank details to help the identification.
                        </span>
                        <span
                            class="text-red-500 px-[0.5rem] md:px-[1rem] font-[300] text-[1.125rem] md:text-[1.188rem]  max-w-lg mx-auto text-center block"
                            style="padding-top: 0;">No payment will be taken.
                        </span>
                    </div>
                    <div class="flex flex-col space-y-8 pt-[1.25rem] px-[4rem] md:px-[14rem]">
                        <app-input-form
                            [inputData]="{label:'Account number',inputType:'text',placeholder:'Type number'}"></app-input-form>
                        <app-input-form
                            [inputData]="{label:'Sort code',inputType:'text',placeholder:'34-34-20'}"></app-input-form>
                        <app-input-form
                            [inputData]="{label:'Account holder name',inputType:'text',placeholder:'Type name'}"></app-input-form>
                    </div>
                </div>
                <div *ngSwitchCase="11" class="max-w-screen-md mx-auto">
                    <div>
                        <span
                            class="pb-[2rem] font-bold text-[1.125rem] md:text-[1.5rem]  max-w-xl mx-auto text-center block">Type
                            of
                            Account</span>
                    </div>
                    <div class="p-[1.25rem] md:p-0 ">
                        <div class=" md:grid md:grid-cols-2 justify-evenly  md:gap-[2rem] ">
                            <div *ngFor="let account of accounts; let i = index"
                                class="bg-[#4946681A] bg-opacity-10 rounded-lg md:p-[1.75rem] flex mb-[1rem] md:mb-0 sm:grid p-[1rem]">
                                <app-rounded-icon-buttom
                                    [buttonData]="{iconUrl:account.icon, btnClass:'!w-[3.5rem] !h-[3.5rem] md:!w-[4rem] md:!h-[4rem] mt-[0.75rem] ml-[0.25rem]', iconClass:'!w-[1.5rem] !h-[1.5rem] md:!h-[2rem] md:!w-[2rem]'}" />
                                <div class="ml-[1rem] mt-0">
                                    <span
                                        class="text-[1.25rem] md:text-[1.375rem] font-bold pb-[0.25rem]">{{account.title}}</span>
                                    <div *ngIf="account.desc !== '.'" class="text-[1rem] md:text-[1.25rem]">
                                        {{account.desc}}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </ng-container>
        </app-stepper-counter>

    </div>
</app-user-layout>