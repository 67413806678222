import { Component } from '@angular/core';
import { RoboService } from 'src/app/services/robo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent {



  constructor(private router: Router,private roboService:RoboService){}

  handleContinue(){
    this.roboService.post("/ra/calculate",this.roboService.currentPlayload).subscribe({
      next:(data)=>{
      alert("SUCCESS: \n" + "RiskProfile ->" + data.data.riskProfile)
      }
    })
  }
  getInfo = () => (this.roboService.currentPlayload)
  calculatePercentage =(risk:number) => risk * 40
  solveRiskProfile = (risk:string) => {
    switch (risk) {
    case 'Low':
      return 10
      break;
    
    case 'High':
      return 120
      break;

    case 'Agressive':
      return 180
      break;

    default:
      return 200
      break;
  }}
  redoRisk(){
    this.router.navigate(['/robo']);
  }


}
