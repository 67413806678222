import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialButton } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-social-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './social-button.component.html',
  styleUrls: ['./social-button.component.scss'],
})
export class SocialButtonComponent {
  @Input() buttonData: SocialButton = {
    iconUrl: 'assets/img/icon/google.svg',
    text: 'Google',
    textClass: '',
    buttonClass: '',
  };
}
