<div class="flex md:justify-end justify-center pt-10 md:pt-0">
    <div class="flex gap-2 items-center">
        <span class="text-[#B3B3B3]">Sort by:</span>
        <app-select-form 
        [default]="default"
        [items]="items"
        [selectData]="{selectFormClass:'!bg-none !border-none w-[8.5rem]',selectClass:'!bg-transparent !p-0 !my-0 !h-auto'}" />
    </div>
    <div class="md:flex gap-4 mx-4 hidden ">
        <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/grid.svg'}" />
        <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/list.svg'}"/>
    </div>
</div>