
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import User from "./UserRepo";
import { CommonRestRequests } from "../CommonRestRequests";

@Injectable()
export class UserServiceRepoRest extends CommonRestRequests implements User {
    onboardUrl = "https://dev.yieldvestor.com/onboard";
    url: string;
    constructor(private http: HttpClient) {
        super(http, "https://dev.yieldvestor.com/onboard/api/v1")
        this.url = "https://dev.yieldvestor.com/onboard/api/v1";
    }
    async getAsync(type: string): Promise<any | any[]> {
        return this.http.get<any | any[]>(this.url + `${type}`).pipe(
            map((response: any) => {
                return response
            })
        );
    }
    getUserNotificationSettings(): Observable<any> {
        const headers = new HttpHeaders();
        return this.http.get<any>(this.onboardUrl + `/api/v1/investor/notification`, { headers })
    }
    changeUserNotificationSettings(notification: any): Observable<any> {
        const headers = new HttpHeaders();
        return this.http.put<any>(this.onboardUrl + `/api/v1/investor/add_notification`, notification, { headers });
    }
}