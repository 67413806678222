<div class="w-full max-w-screen-md mx-auto flex flex-row">


   

    <div class="w-full py-6 max-w-screen-md mx-auto">
        <div class="flex  flex-col items-center  justify-center">
            <p *ngIf="current > 0" class="p-10 max-w-lg mx-auto text-center text-[14px] text-current !text-[#FFFFFF40]">{{onboardingQuestions?.at(current-1)}}</p>
            <app-rounded-icon-buttom (btnClick)="prev()"
                [buttonData]="{iconUrl:'assets/img/icon/arrow-down.svg', btnClass: current > 0 ? 'bg-[#4A4C57] hover:!bg-[#2CC784]' : '!bg-transparent', iconClass: current > 0 ? 'rotate-180' : '!invisible'}" />
    
        </div>
        <div class="flex flex-row items-center justify-center item">
            
          
            <ng-content></ng-content>
            
    
        </div>
    
     
    
    <div class=" mx-auto {{full?'':'max-w-screen-md'}}">
        <div class="flex flex-col items-center  justify-center mt-8 ">
           
            <app-rounded-icon-buttom (btnClick)="next()"
                [buttonData]="{iconUrl:'assets/img/icon/arrow-down.svg', btnClass:'!bg-[#FFFFFF10] hover:!bg-[#2CC784]'}" />
    
            <p class="p-10 max-w-lg mx-auto text-center text-current text-[14px] !text-[#FFFFFF40]">{{onboardingQuestions?.at(current+1)}}</p>
        </div>
    </div>

    </div>

    <div class="flex flex-col mt-[30%] ml-16">
        <div *ngFor="let step of steps, let i = index" class="text-xs md:text-base">
            <div class="relative left-[50%]">
                <div class="{{current >= i?'font-[300] text-[14px] text-[#978EF5]':'font-[300] text-[14px] text-[#4A4C57]'}}">
                    {{i+1}}</div>
            </div>
        </div>
    </div>

</div>

