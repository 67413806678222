import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more'; // load highcharts-more


HC_more(Highcharts); // init highcharts-more


@Component({
  selector: 'app-gouge-chart-indicator',
  templateUrl: './gouge-chart-indicator.component.html',
  styleUrls: ['./gouge-chart-indicator.component.scss']
})
export class GougeChartIndicatorComponent {
  width1: any;
  width2: any;
  @Input() value = 100
  @Input() desc = "No Desc"
  dynamicId = `component_${Math.floor(Math.random() * 10000)}`


  ngOnInit() {
    this.width1 = 35;
    this.width2 = 65;
  }

  ngAfterViewInit() {
    this.renderChart();
  }

  renderChart() {

    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'gauge',
        height: '75%',
        backgroundColor: 'transparent',
        plotBorderWidth: 0,
        plotShadow: false,
        className:"chart"
      },
      
      title: {
        text: ''
      },

      credits: {
        enabled: false
      },

      pane: {
        startAngle: -150,
        endAngle: 150,
        background: [
          {
            // Background da área do ponteiro
            backgroundColor: 'transparent',
            borderWidth: 0,
            shape: 'circle',
            outerRadius: '109%',
          },
          {
            // Background da área circular do gráfico
            backgroundColor: 'transparent',
            borderWidth: 0,
            outerRadius: '100%',
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 200,
        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: 'transparent',
        tickPixelInterval: 30,
        tickWidth: 0,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#000',
       
        plotBands: [{
          from: 0,
          to: this.value,
          color: '#6761A8' // green
        },
        {
          from: 0,
          to: 200,
          color: '#FFFFFF10' // para ser fixo
        }],
        labels: {
          enabled: false, // Desativar as etiquetas do eixo y
        },
      },
      plotOptions: {
        gauge: {
          dataLabels: {
            enabled: true, // Desativar a exibição dos rótulos
          },
          pivot: {
            radius: 7, // Tamanho do ponto no centro
           
            backgroundColor: 'white', // Cor de fundo do ponto no centro
          },
        },
      },
      series: [{
        name: '',
        data: [this.value],
        tooltip: {
          valueSuffix: ''
        },
        dataLabels: {
          format: '<div style="text-align:center "><span style=" font-size:25px;color:' +
            '{point.color} margin-top:50px"></span><br/><br/><br/><br/>' +
            '<span style="font-size:15px;color:silver margin-top:50px !important;">'+this.desc+'</span></div>',
          // Alterando o rótulo do indicador
          borderWidth: 0,
          style: {
            fontSize: '16px',
            color: 'white', // Cor do rótulo
            marginTop:'30px'
          },
        },
        dial: {
          radius: '50%', // Tamanho do ponteiro
          backgroundColor: 'white', // Cor de fundo do ponteiro
          baseWidth: 6,// Largura da base do ponteiro
          topWidth: 0.5,// Largura do topo do ponteiro
        }
      } as any]
    };

    const chart: Highcharts.Chart = Highcharts.chart(this.dynamicId, chartOptions);






  }

}
