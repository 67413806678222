export const samples = [
    {
      "type": "text",
      "content":["Hello John Doe!","How can I help you today?"],
      "prompts":[]
    },
    {
      "type": "text",
      "content":["Looks like this is your first time here...here are some popular suggestions:"],
      "prompts":[{"prompt":"I would like investment advice"},{"prompt":"Suggest investment options  of $2,000.00 in Municipal bonds for the next 5 years"},{"prompt":"Give me the top 10 yielding Assets"}]
    },
    {
      "type": "you",
      "content": ["Give me the top 10 yielding assets "],
      "perfil": "assets/img/temp/profile.svg"
    },
    {
      "type": "text",
      "content":["Sure! these are top 10 yielding assets:"],
      "prompts":[]
    },
    {
      "type": "bonds",
      "content": {
        "list":[
    {
      "id": "US071734AH05",
      "name": "BAUSCH HEALTH COMPANIES INC 144A",
      "isin": "US071734AH05",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "BAUSCH HEALTH COMPANIES INC 144A",
      "issue_volume": 0,
      "issue_price": 39.5,
      "issue_date": "2019-12-30",
      "maturity": "2028-01-30",
      "grade": null,
      "sector": "Consumer Non-Cyclical",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 39.5,
      "coupon": 5,
      "ytm": 31.66,
      "real_ytm": 31.66,
      "par": 5977000,
      "created_at": null
    },
    {
      "id": "US156700BD72",
      "name": "LUMEN TECHNOLOGIES INC 144A",
      "isin": "US156700BD72",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "LUMEN TECHNOLOGIES INC 144A",
      "issue_volume": 0,
      "issue_price": 30,
      "issue_date": "2020-11-27",
      "maturity": "2029-01-15",
      "grade": null,
      "sector": "Communications",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 30,
      "coupon": 4.5,
      "ytm": 33.82,
      "real_ytm": 33.82,
      "par": 5322000,
      "created_at": null
    },
    {
      "id": "US23131LAE74",
      "name": "CURO GROUP HOLDINGS CORP 144A",
      "isin": "US23131LAE74",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "CURO GROUP HOLDINGS CORP 144A",
      "issue_volume": 0,
      "issue_price": 39.75,
      "issue_date": "2023-02-01",
      "maturity": "2028-08-01",
      "grade": null,
      "sector": "Finance Companies",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 39.75,
      "coupon": 7.5,
      "ytm": 33.56,
      "real_ytm": 33.56,
      "par": 10012000,
      "created_at": null
    },
    {
      "id": "US29365DAA72",
      "name": "AUDACY CAPITAL CORP 144A",
      "isin": "US29365DAA72",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "AUDACY CAPITAL CORP 144A",
      "issue_volume": 0,
      "issue_price": 1.52,
      "issue_date": "2019-04-30",
      "maturity": "2027-05-01",
      "grade": null,
      "sector": "Communications",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 1.52,
      "coupon": 6.5,
      "ytm": 50,
      "real_ytm": 50,
      "par": 4001000,
      "created_at": null
    },
    {
      "id": "US29365DAB55",
      "name": "AUDACY CAPITAL CORP 144A",
      "isin": "US29365DAB55",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "AUDACY CAPITAL CORP 144A",
      "issue_volume": 0,
      "issue_price": 1.9,
      "issue_date": "2021-03-25",
      "maturity": "2029-03-31",
      "grade": null,
      "sector": "Communications",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 1.9,
      "coupon": 6.75,
      "ytm": 50,
      "real_ytm": 50,
      "par": 5123000,
      "created_at": null
    },
    {
      "id": "US55910RAE36",
      "name": "MCAFEE / MAGENTA BUYER LLC TL-B",
      "isin": "US55910RAE36",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "MCAFEE / MAGENTA BUYER LLC TL-B",
      "issue_volume": 0,
      "issue_price": 44.67,
      "issue_date": "2021-07-27",
      "maturity": "2029-07-27",
      "grade": null,
      "sector": "Corporates",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 44.67,
      "coupon": 13.88,
      "ytm": 39.43,
      "real_ytm": 39.43,
      "par": 40000,
      "created_at": null
    },
    {
      "id": "US750098AA35",
      "name": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
      "isin": "US750098AA35",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
      "issue_volume": 0,
      "issue_price": 32.03,
      "issue_date": "2020-12-01",
      "maturity": "2028-12-01",
      "grade": null,
      "sector": "Technology",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 32.03,
      "coupon": 5.38,
      "ytm": 34.45,
      "real_ytm": 34.45,
      "par": 5648000,
      "created_at": null
    },
    {
      "id": "US75041VAA26",
      "name": "RADIOLOGY PARTNERS INC 144A",
      "isin": "US75041VAA26",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "RADIOLOGY PARTNERS INC 144A",
      "issue_volume": 0,
      "issue_price": 38.71,
      "issue_date": "2020-01-31",
      "maturity": "2028-02-01",
      "grade": null,
      "sector": "Consumer Non-Cyclical",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 38.71,
      "coupon": 9.25,
      "ytm": 40.28,
      "real_ytm": 40.28,
      "par": 8708000,
      "created_at": null
    },
    {
      "id": "US767754CK89",
      "name": "RITE AID CORPORATION 144A",
      "isin": "US767754CK89",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "RITE AID CORPORATION 144A",
      "issue_volume": 0,
      "issue_price": 63.44,
      "issue_date": "2020-02-05",
      "maturity": "2025-07-01",
      "grade": null,
      "sector": "Consumer Cyclical",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 63.44,
      "coupon": 7.5,
      "ytm": 38.3,
      "real_ytm": 38.3,
      "par": 308000,
      "created_at": null
    },
    {
      "id": "US855030AP77",
      "name": "STAPLES INC 144A",
      "isin": "US855030AP77",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "STAPLES INC 144A",
      "issue_volume": 0,
      "issue_price": 58.33,
      "issue_date": "2019-04-16",
      "maturity": "2027-04-15",
      "grade": null,
      "sector": "Consumer Cyclical",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 58.33,
      "coupon": 10.75,
      "ytm": 31.05,
      "real_ytm": 31.05,
      "par": 14270000,
      "created_at": null
    }
  ]}
    },  
    {
      "type": "you",
      "content": ["Only list assets with a yield of 5% or higher"],
      "perfil": "assets/img/temp/profile.svg"
    },
    {
      "type": "text",
      "content": ["From the list, these are the assets with a yield of 5% or higher:"]
    },
  
    {
      "type": "bonds",
      "content": {
        "list":[
    {
      "id": "US071734AH05",
      "name": "BAUSCH HEALTH COMPANIES INC 144A",
      "isin": "US071734AH05",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "BAUSCH HEALTH COMPANIES INC 144A",
      "issue_volume": 0,
      "issue_price": 39.5,
      "issue_date": "2019-12-30",
      "maturity": "2028-01-30",
      "grade": null,
      "sector": "Consumer Non-Cyclical",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 39.5,
      "coupon": 5,
      "ytm": 31.66,
      "real_ytm": 31.66,
      "par": 5977000,
      "created_at": null
    },
    {
      "id": "US156700BD72",
      "name": "LUMEN TECHNOLOGIES INC 144A",
      "isin": "US156700BD72",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "LUMEN TECHNOLOGIES INC 144A",
      "issue_volume": 0,
      "issue_price": 30,
      "issue_date": "2020-11-27",
      "maturity": "2029-01-15",
      "grade": null,
      "sector": "Communications",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 30,
      "coupon": 4.5,
      "ytm": 33.82,
      "real_ytm": 33.82,
      "par": 5322000,
      "created_at": null
    },
    {
      "id": "US23131LAE74",
      "name": "CURO GROUP HOLDINGS CORP 144A",
      "isin": "US23131LAE74",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "CURO GROUP HOLDINGS CORP 144A",
      "issue_volume": 0,
      "issue_price": 39.75,
      "issue_date": "2023-02-01",
      "maturity": "2028-08-01",
      "grade": null,
      "sector": "Finance Companies",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 39.75,
      "coupon": 7.5,
      "ytm": 33.56,
      "real_ytm": 33.56,
      "par": 10012000,
      "created_at": null
    },
    {
      "id": "US29365DAA72",
      "name": "AUDACY CAPITAL CORP 144A",
      "isin": "US29365DAA72",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "AUDACY CAPITAL CORP 144A",
      "issue_volume": 0,
      "issue_price": 1.52,
      "issue_date": "2019-04-30",
      "maturity": "2027-05-01",
      "grade": null,
      "sector": "Communications",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 1.52,
      "coupon": 6.5,
      "ytm": 50,
      "real_ytm": 50,
      "par": 4001000,
      "created_at": null
    },
    {
      "id": "US29365DAB55",
      "name": "AUDACY CAPITAL CORP 144A",
      "isin": "US29365DAB55",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "AUDACY CAPITAL CORP 144A",
      "issue_volume": 0,
      "issue_price": 1.9,
      "issue_date": "2021-03-25",
      "maturity": "2029-03-31",
      "grade": null,
      "sector": "Communications",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 1.9,
      "coupon": 6.75,
      "ytm": 50,
      "real_ytm": 50,
      "par": 5123000,
      "created_at": null
    },
    {
      "id": "US55910RAE36",
      "name": "MCAFEE / MAGENTA BUYER LLC TL-B",
      "isin": "US55910RAE36",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "MCAFEE / MAGENTA BUYER LLC TL-B",
      "issue_volume": 0,
      "issue_price": 44.67,
      "issue_date": "2021-07-27",
      "maturity": "2029-07-27",
      "grade": null,
      "sector": "Corporates",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 44.67,
      "coupon": 13.88,
      "ytm": 39.43,
      "real_ytm": 39.43,
      "par": 40000,
      "created_at": null
    },
    {
      "id": "US750098AA35",
      "name": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
      "isin": "US750098AA35",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
      "issue_volume": 0,
      "issue_price": 32.03,
      "issue_date": "2020-12-01",
      "maturity": "2028-12-01",
      "grade": null,
      "sector": "Technology",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 32.03,
      "coupon": 5.38,
      "ytm": 34.45,
      "real_ytm": 34.45,
      "par": 5648000,
      "created_at": null
    },
    {
      "id": "US75041VAA26",
      "name": "RADIOLOGY PARTNERS INC 144A",
      "isin": "US75041VAA26",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "RADIOLOGY PARTNERS INC 144A",
      "issue_volume": 0,
      "issue_price": 38.71,
      "issue_date": "2020-01-31",
      "maturity": "2028-02-01",
      "grade": null,
      "sector": "Consumer Non-Cyclical",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 38.71,
      "coupon": 9.25,
      "ytm": 40.28,
      "real_ytm": 40.28,
      "par": 8708000,
      "created_at": null
    },
    {
      "id": "US767754CK89",
      "name": "RITE AID CORPORATION 144A",
      "isin": "US767754CK89",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "RITE AID CORPORATION 144A",
      "issue_volume": 0,
      "issue_price": 63.44,
      "issue_date": "2020-02-05",
      "maturity": "2025-07-01",
      "grade": null,
      "sector": "Consumer Cyclical",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 63.44,
      "coupon": 7.5,
      "ytm": 38.3,
      "real_ytm": 38.3,
      "par": 308000,
      "created_at": null
    },
    {
      "id": "US855030AP77",
      "name": "STAPLES INC 144A",
      "isin": "US855030AP77",
      "sebol": null,
      "type": "Fixed Income",
      "country": "United States",
      "issuer": "STAPLES INC 144A",
      "issue_volume": 0,
      "issue_price": 58.33,
      "issue_date": "2019-04-16",
      "maturity": "2027-04-15",
      "grade": null,
      "sector": "Consumer Cyclical",
      "payments_per_year": 2,
      "currency": "USD",
      "price": 58.33,
      "coupon": 10.75,
      "ytm": 31.05,
      "real_ytm": 31.05,
      "par": 14270000,
      "created_at": null
    }
  ]}
    },
  
    {
      "type": "you",
      "content": ["Compare the first two assets"],
      "perfil": "assets/img/temp/profile.svg"
    },
    {
      "type": "text",
      "content": ["Here's the comparison between the first two assets"]
    },
    
    {
      "type":"comparison",
      "content":{
        "columns":["1","2"],
        "assets":["key","value","Issue Date","Interest rate","High/Low Price","Total trade amount"],
        "data": {
          "1": {"key": "Instrument type", "value": "Sovereign Bond","Issue Date":"51,500,000.00","Interest rate":"5.00%","High/Low Price":"105.33","Total trade amount":"980,000.00"},
          "2": { "key": "Maturity", "value": "2025","Issue Date":"51,500,000.00","Interest rate":"5.00%","High/Low Price":"105.33","Total trade amount":"980,000.00" }
        }
      }
    },
    {
      "type": "text",
      "content": ["The chart shows that selected assets have traded in a relatively tight range historically, however in the last couple of months Bond A has underperformed and widened out."," ","They now trade ~30bps apart when they used to trade within 5-8bps. There may be valid reasons for this but it highlights this may be an attractive buying opportunity for Bond A, or Bond B is a sale candidate."]
    },
    {
      "type": "you",
      "content": ["Create a projection for an investment of $799.00 in these assets"],
      "perfil": "assets/img/temp/profile.svg"
    },
    {
      "type":"text",
      "content":[". . ."]
    },
    {
      "type":"text",
      "content":["Sure, list of your Top 10 Holdings"]
    },
    {
      "type":"list",
      "content":[
        { "key": "JPMORGAN CHASE & CO", "value": " 1,8%" },
        { "key": "BANK OF AMERICA CORP MTN", "value": "2025" },
        { "key": "CITIGROUP INC", "value": "1,0%" },
        { "key": "GOLDMAN SACHS GROUP INC/THE", "value": "0,9%"}
      ]
    },
    {
      "type":"text",
      "content":["Your maturity breakdown:"]
    },
    {
      "type":"list",
      "content":[
        { "key": "<1", "value": " 1,8%" },
        { "key": "1-3", "value": "2025" },
        { "key": "3-5", "value": "1,0%" },
        { "key": "5-7", "value": "0,9%"}
      ]
    },
  
  
    {
      "type": "text",
      "content": ["From the list, these are the assets with a yield of 5% or higher:"]
    },
      {
        "type":"list",
        "content":[
          { "key": "Instrument type", "value": "Sovereign Bond" },
          { "key": "Maturity", "value": "2025" },
          { "key": "Interest rate", "value": "8.75%" },
          { "key": "Yield", "value": "5.99%"}
        ]
      },
      {
        "type": "text",
        "content": ["Your maturity breakdow"]
      },
      {
        "type":"list",
        "content":[
          { "key": "Instrument type", "value": "Sovereign Bond" },
          { "key": "Maturity", "value": "2025" },
          { "key": "Interest rate", "value": "8.75%" },
          { "key": "Yield", "value": "5.99%"}
        ]
      },
      
      {
        "type":"2d-table",
        "content":{
          "columns":["1","2","3"],
          "assets":["key","value"],
          "data": {
            "key": {"1": "Instrument type", "2": "Sovereign Bond","3": "Sovereign Bond"},
            "value": { "1": "Maturity", "2": "2025" ,"3":"2025"}
          }
        }
      },
      {
        "type":"you",
        "content":["What are some attractive BBB bonds?"],
        "perfil": "assets/img/temp/profile.svg"
      },
      {
        "type": "text",
        "content": ["In order to compare bonds of similar risk, please define the investment universe more narrowly:"]
      },
      {
        "type": "scatter",
        "content": {}
      },
      {
        "type": "text",
        "content": ["Your maturity breakdown:"]
      },
      
      {
        "type": "sceenario",
        "content": {
          "filters":[{"type":"select","title":"Scenario:","default":"Great Financial Crisis","items":[{"value":"A001","name":"Covid 19"}]}],
          "left":"Current",
        "rigth":"Model"
    }
      },
      {
        "type":"text",
        "content":"Would you like to know how shift in credit spread may affect your portfolio?"
      },
      {
        "type":"you",
        "content": ["Yes, I do!"],
        "perfil": "assets/img/temp/profile.svg"
      },
      {
        "type": "sceenario",
        "content": {
          "filters":[{"type":"select","title":"In Credit Spreads:","default":"-1000bps",
              "items":[{"value": "A0950", "name": "-950bps"},  {"value": "A0900", "name": "-900bps"},  {"value": "A0850", "name": "-850bps"},  {"value": "A0800", "name": "-800bps"},  {"value": "A0750", "name": "-750bps"},  {"value": "A0700", "name": "-700bps"},  {"value": "A0650", "name": "-650bps"},  {"value": "A0600", "name": "-600bps"},  {"value": "A0550", "name": "-550bps"},  {"value": "A0500", "name": "-500bps"},  {"value": "A0450", "name": "-450bps"},  {"value": "A0400", "name": "-400bps"},  {"value": "A0350", "name": "-350bps"},  {"value": "A0300", "name": "-300bps"},  {"value": "A0250", "name": "-250bps"},  {"value": "A0200", "name": "-200bps"},  {"value": "A0150", "name": "-150bps"},  {"value": "A0100", "name": "-100bps"},  {"value": "A0075", "name": "-75bps"},  {"value": "A0050", "name": "-50bps"},  {"value": "A0025", "name": "-25bps"},  {"value": "A0000", "name": "0bps"},  {"value": "A0025", "name": "25bps"},  {"value": "A0050", "name": "50bps"},  {"value": "A0075", "name": "75bps"},  {"value": "A0100", "name": "100bps"},  {"value": "A0150", "name": "150bps"},  {"value": "A0200", "name": "200bps"},  {"value": "A0250", "name": "250bps"},  {"value": "A0300", "name": "300bps"},  {"value": "A0350", "name": "350bps"},  {"value": "A0400", "name": "400bps"},  {"value": "A0450", "name": "450bps"},  {"value": "A0500", "name": "500bps"},  {"value": "A0550", "name": "550bps"},  {"value": "A0600", "name": "600bps"},  {"value": "A0650", "name": "650bps"},  {"value": "A0700", "name": "700bps"},  {"value": "A0750", "name": "750bps"},  {"value": "A0800", "name": "800bps"},  {"value": "A0850", "name": "850bps"},  {"value": "A0900", "name": "900bps"},  {"value": "A0950", "name": "950bps"},  {"value": "A1000", "name": "1000bps"}]
              }],"left":"Current",
        "rigth":"Model"
    }
      },
      {
        "type": "you",
        "content": ["I am not sure what to invest in, please, can you help me?"],
        "perfil": "assets/img/temp/profile.svg"
      },
      {
        "type": "text",
        "content":["To present you with the best portfolio, answer the following questions:"],
        "prompts":[]
      },
      {
        "type": "objectives"
      },
      {
        "type": "text",
        "content":["Your Implied Risk Tolerance is MEDIUM", "Which means..."],
        "prompts":[]
      },
      {
        "type": "model",
        "content": {}
      },
      {
        "type": "text",
        "content":["Welcome John!","You have been enrolled in the Module 1 of the fixed income investment course"],
        "prompts":[]
      },
        {
          "type": "module-list",
          "content": [
            {"title":"Introduction to Fixed Income", "topics":[
              "A fundamental explanation of what fixed income is",
              "Why it's important in an investment portfolio",
              "The nature of bonds, and the meaning of terms like principal, interest, and maturity",
              "High level on size of bond market, who are issuers, players"
              ]
            },
            {"title":"Types of Fixed Income Securities", "topics":[
              "Discuss the various types of fixed income instruments (government bonds, municipal bonds, corporate bonds, certificates of deposit (CDs), preferred stocks, and collateralized debt obligations (CDOs), structured)",
              "Fixed vs Floating",
              "Convertible",
              "Callable/putable",
              "Zero coupon"
              ]
            },
            {"title":"Bond Valuation and Interest Rates", "topics":[
              "Explain how bond prices are influenced by changes in interest ",
              "Duration, convexity",
              "Discuss concepts like yield to maturity, current yield, and the inverse relationship between bond prices and interest rates"
              ]
            }]
        },
        {
          "type": "you",
          "content": ["Start learning"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type": "lecture",
          "content": ["Your maturity breakdown:","Your maturity breakdown:","Your maturity breakdown:"]
        },
        {
          "type":"text",
          "content":["Welcome to Fixed Income Quizzes!","Note: The answers provided below are sample answers.  The actual correct answers may vary based on specific context and information."]
        },
        {
          "type": "quiz",
          "content": ["Your maturity breakdown:","Your maturity breakdown:","Your maturity breakdown:"]
        },
        {
          "type": "text",
          "content":["Hello John Doe!","How can I help you today?"],
          "prompts":[]
        },
        {
          "type": "you",
          "content": ["What are the highest/lowest yielding municipal bonds?"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type": "text",
          "content":["Sure! these are highest/lowest yielding municipal bonds?"],
          "prompts":[]
        },
        {
          "type": "bonds",
          "content": {
            "list":[
        {
          "id": "US071734AH05",
          "name": "BAUSCH HEALTH COMPANIES INC 144A",
          "isin": "US071734AH05",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "BAUSCH HEALTH COMPANIES INC 144A",
          "issue_volume": 0,
          "issue_price": 39.5,
          "issue_date": "2019-12-30",
          "maturity": "2028-01-30",
          "grade": null,
          "sector": "Consumer Non-Cyclical",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 39.5,
          "coupon": 5,
          "ytm": 31.66,
          "real_ytm": 31.66,
          "par": 5977000,
          "created_at": null
        },
        {
          "id": "US156700BD72",
          "name": "LUMEN TECHNOLOGIES INC 144A",
          "isin": "US156700BD72",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "LUMEN TECHNOLOGIES INC 144A",
          "issue_volume": 0,
          "issue_price": 30,
          "issue_date": "2020-11-27",
          "maturity": "2029-01-15",
          "grade": null,
          "sector": "Communications",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 30,
          "coupon": 4.5,
          "ytm": 33.82,
          "real_ytm": 33.82,
          "par": 5322000,
          "created_at": null
        },
        {
          "id": "US23131LAE74",
          "name": "CURO GROUP HOLDINGS CORP 144A",
          "isin": "US23131LAE74",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "CURO GROUP HOLDINGS CORP 144A",
          "issue_volume": 0,
          "issue_price": 39.75,
          "issue_date": "2023-02-01",
          "maturity": "2028-08-01",
          "grade": null,
          "sector": "Finance Companies",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 39.75,
          "coupon": 7.5,
          "ytm": 33.56,
          "real_ytm": 33.56,
          "par": 10012000,
          "created_at": null
        },
        {
          "id": "US29365DAA72",
          "name": "AUDACY CAPITAL CORP 144A",
          "isin": "US29365DAA72",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "AUDACY CAPITAL CORP 144A",
          "issue_volume": 0,
          "issue_price": 1.52,
          "issue_date": "2019-04-30",
          "maturity": "2027-05-01",
          "grade": null,
          "sector": "Communications",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 1.52,
          "coupon": 6.5,
          "ytm": 50,
          "real_ytm": 50,
          "par": 4001000,
          "created_at": null
        },
        {
          "id": "US29365DAB55",
          "name": "AUDACY CAPITAL CORP 144A",
          "isin": "US29365DAB55",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "AUDACY CAPITAL CORP 144A",
          "issue_volume": 0,
          "issue_price": 1.9,
          "issue_date": "2021-03-25",
          "maturity": "2029-03-31",
          "grade": null,
          "sector": "Communications",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 1.9,
          "coupon": 6.75,
          "ytm": 50,
          "real_ytm": 50,
          "par": 5123000,
          "created_at": null
        },
        {
          "id": "US55910RAE36",
          "name": "MCAFEE / MAGENTA BUYER LLC TL-B",
          "isin": "US55910RAE36",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "MCAFEE / MAGENTA BUYER LLC TL-B",
          "issue_volume": 0,
          "issue_price": 44.67,
          "issue_date": "2021-07-27",
          "maturity": "2029-07-27",
          "grade": null,
          "sector": "Corporates",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 44.67,
          "coupon": 13.88,
          "ytm": 39.43,
          "real_ytm": 39.43,
          "par": 40000,
          "created_at": null
        },
        {
          "id": "US750098AA35",
          "name": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
          "isin": "US750098AA35",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
          "issue_volume": 0,
          "issue_price": 32.03,
          "issue_date": "2020-12-01",
          "maturity": "2028-12-01",
          "grade": null,
          "sector": "Technology",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 32.03,
          "coupon": 5.38,
          "ytm": 34.45,
          "real_ytm": 34.45,
          "par": 5648000,
          "created_at": null
        },
        {
          "id": "US75041VAA26",
          "name": "RADIOLOGY PARTNERS INC 144A",
          "isin": "US75041VAA26",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "RADIOLOGY PARTNERS INC 144A",
          "issue_volume": 0,
          "issue_price": 38.71,
          "issue_date": "2020-01-31",
          "maturity": "2028-02-01",
          "grade": null,
          "sector": "Consumer Non-Cyclical",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 38.71,
          "coupon": 9.25,
          "ytm": 40.28,
          "real_ytm": 40.28,
          "par": 8708000,
          "created_at": null
        },
        {
          "id": "US767754CK89",
          "name": "RITE AID CORPORATION 144A",
          "isin": "US767754CK89",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "RITE AID CORPORATION 144A",
          "issue_volume": 0,
          "issue_price": 63.44,
          "issue_date": "2020-02-05",
          "maturity": "2025-07-01",
          "grade": null,
          "sector": "Consumer Cyclical",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 63.44,
          "coupon": 7.5,
          "ytm": 38.3,
          "real_ytm": 38.3,
          "par": 308000,
          "created_at": null
        },
        {
          "id": "US855030AP77",
          "name": "STAPLES INC 144A",
          "isin": "US855030AP77",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "STAPLES INC 144A",
          "issue_volume": 0,
          "issue_price": 58.33,
          "issue_date": "2019-04-16",
          "maturity": "2027-04-15",
          "grade": null,
          "sector": "Consumer Cyclical",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 58.33,
          "coupon": 10.75,
          "ytm": 31.05,
          "real_ytm": 31.05,
          "par": 14270000,
          "created_at": null
        }
      ]}
        },
        {
          "type": "you",
          "content": ["Compare Bond A and Bond B performance over last six months."],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type": "text",
          "content": ["Here's the comparison between Alaska, 5.342% 08/2027, USD and Texas, 5% 04/2029, USD"]
        },
        {
          "type":"comparison",
          "content":{
            "columns":["1","2"],
            "assets":["key","value","Issue Date","Interest rate","High/Low Price","Total trade amount"],
            "data": {
              "1": {"key": "Instrument type", "value": "Sovereign Bond","Issue Date":"51,500,000.00","Interest rate":"5.00%","High/Low Price":"105.33","Total trade amount":"980,000.00"},
              "2": { "key": "Maturity", "value": "2025","Issue Date":"51,500,000.00","Interest rate":"5.00%","High/Low Price":"105.33","Total trade amount":"980,000.00" }
            }
          }
        },
        {
          "type": "text",
          "content": ["The chart shows that selected assets have traded in a relatively tight range historically, however in the last couple of months Bond A has underperformed and widened out."," ","They now trade ~30bps apart when they used to trade within 5-8bps. There may be valid reasons for this but it highlights this may be an attractive buying opportunity for Bond A, or Bond B is a sale candidate."]
        },
        {
          "type": "you",
          "content": ["What are some attractive BBB bonds? "],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type": "text",
          "content":["In order to compare bonds of similar risk, please define the investment universe more narrowly:"],
          "prompts":[{"prompt":"5 years Technology bonds"},{"prompt":"BBB Technology bonds"}]
        },
        {
          "type": "you",
          "content": ["BBB Technology bonds"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type": "scatter",
          "content": {}
        },
        {
          "type": "text",
          "content":["The chart shows that Bond A offers a higher yield than most bonds in the BBB+ category and may be an attractive investment opportunity"],
          "prompts":[]
        },
        {
          "type": "you",
          "content": ["What part of the curve (which maturities) should I invest in?"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type": "text",
          "content":["Are you interesting in evaluating:"],
          "prompts":[{"prompt":"Corporates"},{"prompt":"Municipals"},{"prompt":"Treasuries"},{"prompt":"Sukuks"}]
        },
        {
          "type": "you",
          "content": ["Corporates"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type":"comparison",
          "content":{
            "columns":["1","2"],
            "assets":["key","value","Issue Date","Interest rate","High/Low Price","Total trade amount"],
            "data": {
              "1": {"key": "Instrument type", "value": "Sovereign Bond","Issue Date":"51,500,000.00","Interest rate":"5.00%","High/Low Price":"105.33","Total trade amount":"980,000.00"},
              "2": { "key": "Maturity", "value": "2025","Issue Date":"51,500,000.00","Interest rate":"5.00%","High/Low Price":"105.33","Total trade amount":"980,000.00" }
            }
          }
        },
        {
          "type": "you",
          "content": ["What are some low risk investment options?"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type": "text",
          "content":["There are several types of risk present when investing in bonds. Which risks are you most concerned with?"],
          "prompts":[{"prompt":"Interest rate risk(price or market value risk)"},{"prompt":"Credit (default) risk"},{"prompt":"Inflation risk"},{"prompt":"Reinvestment risk"},{"prompt":"Liquidity risk"},{"prompt":"Call risk"},{"prompt":"Currency risk"}]
        },
        {
          "type": "text",
          "content":["All bonds contain some level of default risk, however bonds rated AAA, AA and A \rhave shown to have default rates of less than 2%. "],
          "prompts":[]
        },
        {
          "type": "text",
          "content":["Here is a list of bonds with low probabilities of default based on credit rating"],
          "prompts":[]
        },
        {
          "type": "bonds",
          "content": {
            "list":[
        {
          "id": "US071734AH05",
          "name": "BAUSCH HEALTH COMPANIES INC 144A",
          "isin": "US071734AH05",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "BAUSCH HEALTH COMPANIES INC 144A",
          "issue_volume": 0,
          "issue_price": 39.5,
          "issue_date": "2019-12-30",
          "maturity": "2028-01-30",
          "grade": null,
          "sector": "Consumer Non-Cyclical",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 39.5,
          "coupon": 5,
          "ytm": 31.66,
          "real_ytm": 31.66,
          "par": 5977000,
          "created_at": null
        },
        {
          "id": "US156700BD72",
          "name": "LUMEN TECHNOLOGIES INC 144A",
          "isin": "US156700BD72",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "LUMEN TECHNOLOGIES INC 144A",
          "issue_volume": 0,
          "issue_price": 30,
          "issue_date": "2020-11-27",
          "maturity": "2029-01-15",
          "grade": null,
          "sector": "Communications",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 30,
          "coupon": 4.5,
          "ytm": 33.82,
          "real_ytm": 33.82,
          "par": 5322000,
          "created_at": null
        },
        {
          "id": "US23131LAE74",
          "name": "CURO GROUP HOLDINGS CORP 144A",
          "isin": "US23131LAE74",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "CURO GROUP HOLDINGS CORP 144A",
          "issue_volume": 0,
          "issue_price": 39.75,
          "issue_date": "2023-02-01",
          "maturity": "2028-08-01",
          "grade": null,
          "sector": "Finance Companies",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 39.75,
          "coupon": 7.5,
          "ytm": 33.56,
          "real_ytm": 33.56,
          "par": 10012000,
          "created_at": null
        },
        {
          "id": "US29365DAA72",
          "name": "AUDACY CAPITAL CORP 144A",
          "isin": "US29365DAA72",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "AUDACY CAPITAL CORP 144A",
          "issue_volume": 0,
          "issue_price": 1.52,
          "issue_date": "2019-04-30",
          "maturity": "2027-05-01",
          "grade": null,
          "sector": "Communications",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 1.52,
          "coupon": 6.5,
          "ytm": 50,
          "real_ytm": 50,
          "par": 4001000,
          "created_at": null
        },
        {
          "id": "US29365DAB55",
          "name": "AUDACY CAPITAL CORP 144A",
          "isin": "US29365DAB55",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "AUDACY CAPITAL CORP 144A",
          "issue_volume": 0,
          "issue_price": 1.9,
          "issue_date": "2021-03-25",
          "maturity": "2029-03-31",
          "grade": null,
          "sector": "Communications",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 1.9,
          "coupon": 6.75,
          "ytm": 50,
          "real_ytm": 50,
          "par": 5123000,
          "created_at": null
        },
        {
          "id": "US55910RAE36",
          "name": "MCAFEE / MAGENTA BUYER LLC TL-B",
          "isin": "US55910RAE36",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "MCAFEE / MAGENTA BUYER LLC TL-B",
          "issue_volume": 0,
          "issue_price": 44.67,
          "issue_date": "2021-07-27",
          "maturity": "2029-07-27",
          "grade": null,
          "sector": "Corporates",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 44.67,
          "coupon": 13.88,
          "ytm": 39.43,
          "real_ytm": 39.43,
          "par": 40000,
          "created_at": null
        },
        {
          "id": "US750098AA35",
          "name": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
          "isin": "US750098AA35",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
          "issue_volume": 0,
          "issue_price": 32.03,
          "issue_date": "2020-12-01",
          "maturity": "2028-12-01",
          "grade": null,
          "sector": "Technology",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 32.03,
          "coupon": 5.38,
          "ytm": 34.45,
          "real_ytm": 34.45,
          "par": 5648000,
          "created_at": null
        },
        {
          "id": "US75041VAA26",
          "name": "RADIOLOGY PARTNERS INC 144A",
          "isin": "US75041VAA26",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "RADIOLOGY PARTNERS INC 144A",
          "issue_volume": 0,
          "issue_price": 38.71,
          "issue_date": "2020-01-31",
          "maturity": "2028-02-01",
          "grade": null,
          "sector": "Consumer Non-Cyclical",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 38.71,
          "coupon": 9.25,
          "ytm": 40.28,
          "real_ytm": 40.28,
          "par": 8708000,
          "created_at": null
        },
        {
          "id": "US767754CK89",
          "name": "RITE AID CORPORATION 144A",
          "isin": "US767754CK89",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "RITE AID CORPORATION 144A",
          "issue_volume": 0,
          "issue_price": 63.44,
          "issue_date": "2020-02-05",
          "maturity": "2025-07-01",
          "grade": null,
          "sector": "Consumer Cyclical",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 63.44,
          "coupon": 7.5,
          "ytm": 38.3,
          "real_ytm": 38.3,
          "par": 308000,
          "created_at": null
        },
        {
          "id": "US855030AP77",
          "name": "STAPLES INC 144A",
          "isin": "US855030AP77",
          "sebol": null,
          "type": "Fixed Income",
          "country": "United States",
          "issuer": "STAPLES INC 144A",
          "issue_volume": 0,
          "issue_price": 58.33,
          "issue_date": "2019-04-16",
          "maturity": "2027-04-15",
          "grade": null,
          "sector": "Consumer Cyclical",
          "payments_per_year": 2,
          "currency": "USD",
          "price": 58.33,
          "coupon": 10.75,
          "ytm": 31.05,
          "real_ytm": 31.05,
          "par": 14270000,
          "created_at": null
        }
      ]}
        },
        {
          "type": "you",
          "content": ["Do Texas, 5% 04/2029, USD bonds look cheap or expensive?"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type": "text",
          "content":["There are several ways to perform valuation analysis. You can also compare a bond  \ragainst bonds of similar quality, maturity and industry. Try out XX Tool perform this analysis"],
          "prompts":[]
        },
        {
          "type": "you",
          "content": ["What is my largest exposure? What are my top 10 holdings?"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type": "text",
          "content":["Sure, list of your Top 10 Holdings"],
          "prompts":[]
        },
        {
          "type":"list",
          "content":[
            { "key": "JPMORGAN CHASE & CO", "value": "1.8%" },
            { "key": "BANK OF AMERICA CORP MTN", "value": "1.4%"},
            { "key": "CITIGROUP INC", "value": "1.2%"},
            { "key": "APPLE INC", "value": "1.0%"},
            { "key": "AT&T INC,", "value": "0.9%"},
            { "key": "GOLDMAN SACHS GROUP INC/THE", "value": "0.9%"},
            { "key": "MORGAN STANLEY MTN", "value": "0.9%"},
            { "key": "VERIZON COMMUNICATIONS INC", "value": "0.9%"},
            { "key": "HSBC HOLDINGS PLC", "value": "0.8%"},
            { "key": "BLK CSH FND TREASURY SL AGENCY", "value": "0.8%"}
          ]
        },
        {
          "type": "you",
          "content": ["When is my next largest maturity?"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type": "text",
          "content":["Your maturity breakdown:"],
          "prompts":[]
        },
        {
          "type":"list",
          "content":[
            { "key": "<1", "value": "1.6%" },
            { "key": "1-3", "value": "19.5%"},
            { "key": "3-5", "value": "19.5%"},
            { "key": "5-7", "value": "11.4%"},
            { "key": "7-10", "value": "16.0%"},
            { "key": "10-15", "value": "5.2%"},
            { "key": "15-20", "value": "7.6%"},
            { "key": "20+", "value": "19.4%"}
          ]
        },
        {
          "type": "text",
          "content":["Would you like to see which states you have invested in?"],
          "prompts":[]
        },
        {
          "type": "you",
          "content": ["Yes, please"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type":"list",
          "content":[
            { "key": "New York", "value": "14.3%" },
            { "key": "California", "value": "9.5%"},
            { "key": "Pennsylvania", "value": "8.9%"},
            { "key": "Illinois", "value": "7.4%"},
            { "key": "Texas", "value": "6.0%"},
            { "key": "Georgia", "value": "4.4%"},
            { "key": "Colorado", "value": "3.6%"},
            { "key": "New Jersey", "value": "3.0%"},
            { "key": "Alabama", "value": "2.8%"},
            { "key": "Florida", "value": "2.5%"}
          ]
        },
        {
          "type": "you",
          "content": ["Show me the top 10 sectors in my portfolio"],
          "perfil": "assets/img/temp/profile.svg"
        },
        {
          "type":"list",
          "content":[
            { "key": "Transportation", "value": "22.7" },
            { "key": "Tax Obligation / Limited", "value": "18.9"},
            { "key": "Health Care", "value": "14.8"},
            { "key": "Utilities", "value": "10.4"},
            { "key": "Tax Obligation / General", "value": "9.9"},
            { "key": "Education and Civic OrgNIZATIONS", "value": "7.2"},
            { "key": "Mutual Funds", "value": "3.8"},
            { "key": "Long Term Care", "value": "2.5"},
            { "key": "Housing / Multifamily", "value": "1.8"},
            { "key": "Industrials", "value": "1.3"}
          ]
        },
        {
          "type": "text",
          "content":["Your Sector Allocation For Corporate Bonds:"],
          "prompts":[]
        },
        {
          "type":"table",
          "content":{
            "columns":["Sector","Portfolio","Benchmark","UW/OW"],
            "data":[
              {"Sector":"Banking",                           "Portfolio":"22.5%", "Benchmark":"20.2%",  "UW/OW":"2.0%"},
              {"Sector":"Basic Industry",                    "Portfolio":"3.2%",  "Benchmark":"4.1%",  "UW/OW":"(0.9%)"},
              {"Sector":"Brokerage/Asset Managers/Exchanges","Portfolio":"1.4%",  "Benchmark":"1.5%",  "UW/OW":"(0.1%)"},
              {"Sector":"Capital Goods",                     "Portfolio":"4.8%",  "Benchmark":"5.3%",   "UW/OW":"(0.5%)"},
              {"Sector":"Cash and/or Derivatives",           "Portfolio":"1.3%",  "Benchmark":"1.0%",  "UW/OW":"0.3%"},
              {"Sector":"Communications",                    "Portfolio":"7.4%",  "Benchmark":"9.3%",  "UW/OW":"(1.9%)"},
              {"Sector":"Consumer Cyclical",                 "Portfolio":"6.8%",  "Benchmark":"9.8%",  "UW/OW":"(3.0%)"},
              {"Sector":"Electric",                          "Portfolio":"7.4%",  "Benchmark":"5.0%",  "UW/OW":"2.4%"},
              {"Sector":"Energy",                            "Portfolio":"6.5%",  "Benchmark":"2.0%",  "UW/OW":"4.5%"},
              {"Sector":"Finance Companies",                 "Portfolio":"1.3%",  "Benchmark":"2.5%",  "UW/OW":"(1.2%)"},
              {"Sector":"Financial Other",                   "Portfolio":"0.1%",  "Benchmark":"0.0%",  "UW/OW":"0.1%"},
              {"Sector":"Government Sponsored",              "Portfolio":"0.1%",  "Benchmark":"0.0%",  "UW/OW":"0.1%"},
              {"Sector":"Industrial Other",                  "Portfolio":"0.6%",  "Benchmark":"0.5%",  "UW/OW":"0.1%"},
              {"Sector":"Insurance",                         "Portfolio":"6.0%",  "Benchmark":"5.4%",  "UW/OW":"0.6%"},
              {"Sector":"Local Authority",                   "Portfolio":"0.0%",  "Benchmark":"0.0%",  "UW/OW":"0.0%"},
              {"Sector":"Natural Gas",                       "Portfolio":"0.7%",  "Benchmark":"0.9%",  "UW/OW":"(0.2%)"},
              {"Sector":"Owned No Guarantee,",               "Portfolio":"2.7%",  "Benchmark":"0.0%",  "UW/OW":"2.7%"},
              {"Sector":"Reits",                             "Portfolio":"2.6%",  "Benchmark":"4.6%",  "UW/OW":"(2.0%)"},
              {"Sector":"Technology",                        "Portfolio":"8.5%",  "Benchmark":"10.1%",  "UW/OW":"1.6%"},
              {"Sector":"Transportation",                    "Portfolio":"2.3%",  "Benchmark":"2.1%",  "UW/OW":"0.2%"},
              {"Sector":"Utility Other",                     "Portfolio":"0.2%",  "Benchmark":"0.4%",  "UW/OW":"(0.2%)"},
              {"Sector":"Grand Total","Portfolio":"100.0%","Benchmark":"100.0%"}
              ] 
            }
        },
          {
            "type": "you",
            "content": ["How much California municipal bonds do I own?"],
            "perfil": "assets/img/temp/profile.svg"
          },
          {
            "type": "text",
            "content":["Your portfolio holds $3,980 worth of bonds issued from the State of California. This represents 9% of your total portfolio."],
            "prompts":[]
          },
          {
            "type": "you",
            "content": ["When is my next maturity?"],
            "perfil": "assets/img/temp/profile.svg"
          },
          {
            "type": "text",
            "content":["The bond maturing soonest from your portfolio is JPMORGAN CHASE & CO. on May 15, 2023. $10,102 will mature, or 10% of your portfolio."],
            "prompts":[]
          },
          {
            "type":"list",
            "title":"Maturity Breakdown (Years)",
            "content":[
              { bond: "JPMORGAN CHASE & CO", key: "<1", value: "1.8%" },
              { bond: "BANK OF AMERICA CORP MTN", key: "1-3", value: "19.3%" },
              { bond: "CITIGROUP INC", key: "3-5", value: "19.3%" },
              { bond: "APPLE INC", key: "5-7", value: "11.4%" },
              { bond: "AT&T INC,", key: "7-10", value: "16.0%" },
              { bond: "GOLDMAN SACHS GROUP INC/THE", key: "10-15", value: "5.2%" },
              { bond: "MORGAN STANLEY MTN", key: "15-20", value: "7.6%" },
              { bond: "VERIZON COMMUNICATIONS INC", key: "20+", value: "19.4%" },
              //{ bond: "HSBC HOLDINGS PLC", key: "<1", value: "0.8%" },
             // { bond: "BLK CSH FND TREASURY SL AGENCY", key: "1-3", value: "0.8%" }
          ]
          },
          {
            "type": "you",
            "content": ["What is my lowest/highest yielding investment?"],
            "perfil": "assets/img/temp/profile.svg"
          },
          {
            "type": "text",
            "content":["Your lowest yielding investment is:","Alaska, 5.342% 08/2027, USD"],
            "prompts":[]
          },
          {
            "type": "bonds",
            "content": {
              "list":[
          {
            "id": "US071734AH05",
            "name": "BAUSCH HEALTH COMPANIES INC 144A",
            "isin": "US071734AH05",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "BAUSCH HEALTH COMPANIES INC 144A",
            "issue_volume": 0,
            "issue_price": 39.5,
            "issue_date": "2019-12-30",
            "maturity": "2028-01-30",
            "grade": null,
            "sector": "Consumer Non-Cyclical",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 39.5,
            "coupon": 5,
            "ytm": 31.66,
            "real_ytm": 31.66,
            "par": 5977000,
            "created_at": null
          },
          {
            "id": "US156700BD72",
            "name": "LUMEN TECHNOLOGIES INC 144A",
            "isin": "US156700BD72",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "LUMEN TECHNOLOGIES INC 144A",
            "issue_volume": 0,
            "issue_price": 30,
            "issue_date": "2020-11-27",
            "maturity": "2029-01-15",
            "grade": null,
            "sector": "Communications",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 30,
            "coupon": 4.5,
            "ytm": 33.82,
            "real_ytm": 33.82,
            "par": 5322000,
            "created_at": null
          },
          {
            "id": "US23131LAE74",
            "name": "CURO GROUP HOLDINGS CORP 144A",
            "isin": "US23131LAE74",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "CURO GROUP HOLDINGS CORP 144A",
            "issue_volume": 0,
            "issue_price": 39.75,
            "issue_date": "2023-02-01",
            "maturity": "2028-08-01",
            "grade": null,
            "sector": "Finance Companies",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 39.75,
            "coupon": 7.5,
            "ytm": 33.56,
            "real_ytm": 33.56,
            "par": 10012000,
            "created_at": null
          },
          {
            "id": "US29365DAA72",
            "name": "AUDACY CAPITAL CORP 144A",
            "isin": "US29365DAA72",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "AUDACY CAPITAL CORP 144A",
            "issue_volume": 0,
            "issue_price": 1.52,
            "issue_date": "2019-04-30",
            "maturity": "2027-05-01",
            "grade": null,
            "sector": "Communications",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 1.52,
            "coupon": 6.5,
            "ytm": 50,
            "real_ytm": 50,
            "par": 4001000,
            "created_at": null
          },
          {
            "id": "US29365DAB55",
            "name": "AUDACY CAPITAL CORP 144A",
            "isin": "US29365DAB55",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "AUDACY CAPITAL CORP 144A",
            "issue_volume": 0,
            "issue_price": 1.9,
            "issue_date": "2021-03-25",
            "maturity": "2029-03-31",
            "grade": null,
            "sector": "Communications",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 1.9,
            "coupon": 6.75,
            "ytm": 50,
            "real_ytm": 50,
            "par": 5123000,
            "created_at": null
          },
          {
            "id": "US55910RAE36",
            "name": "MCAFEE / MAGENTA BUYER LLC TL-B",
            "isin": "US55910RAE36",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "MCAFEE / MAGENTA BUYER LLC TL-B",
            "issue_volume": 0,
            "issue_price": 44.67,
            "issue_date": "2021-07-27",
            "maturity": "2029-07-27",
            "grade": null,
            "sector": "Corporates",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 44.67,
            "coupon": 13.88,
            "ytm": 39.43,
            "real_ytm": 39.43,
            "par": 40000,
            "created_at": null
          },
          {
            "id": "US750098AA35",
            "name": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
            "isin": "US750098AA35",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
            "issue_volume": 0,
            "issue_price": 32.03,
            "issue_date": "2020-12-01",
            "maturity": "2028-12-01",
            "grade": null,
            "sector": "Technology",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 32.03,
            "coupon": 5.38,
            "ytm": 34.45,
            "real_ytm": 34.45,
            "par": 5648000,
            "created_at": null
          },
          {
            "id": "US75041VAA26",
            "name": "RADIOLOGY PARTNERS INC 144A",
            "isin": "US75041VAA26",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "RADIOLOGY PARTNERS INC 144A",
            "issue_volume": 0,
            "issue_price": 38.71,
            "issue_date": "2020-01-31",
            "maturity": "2028-02-01",
            "grade": null,
            "sector": "Consumer Non-Cyclical",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 38.71,
            "coupon": 9.25,
            "ytm": 40.28,
            "real_ytm": 40.28,
            "par": 8708000,
            "created_at": null
          },
          {
            "id": "US767754CK89",
            "name": "RITE AID CORPORATION 144A",
            "isin": "US767754CK89",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "RITE AID CORPORATION 144A",
            "issue_volume": 0,
            "issue_price": 63.44,
            "issue_date": "2020-02-05",
            "maturity": "2025-07-01",
            "grade": null,
            "sector": "Consumer Cyclical",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 63.44,
            "coupon": 7.5,
            "ytm": 38.3,
            "real_ytm": 38.3,
            "par": 308000,
            "created_at": null
          },
          {
            "id": "US855030AP77",
            "name": "STAPLES INC 144A",
            "isin": "US855030AP77",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "STAPLES INC 144A",
            "issue_volume": 0,
            "issue_price": 58.33,
            "issue_date": "2019-04-16",
            "maturity": "2027-04-15",
            "grade": null,
            "sector": "Consumer Cyclical",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 58.33,
            "coupon": 10.75,
            "ytm": 31.05,
            "real_ytm": 31.05,
            "par": 14270000,
            "created_at": null
          }
        ]}
          },
          {
            "type": "you",
            "content": ["How risky is my portfolio? What is my riskiest holding?"],
            "perfil": "assets/img/temp/profile.svg"
          },
          {
            "type": "text",
            "content":["There are several types of risk present when investing in bonds. Which risks are you most concerned with?"],
            "prompts":[{"prompt":"Interest rate risk(price or market value risk)"},{"prompt":"Credit (default) risk"},{"prompt":"Inflation risk"},{"prompt":"Reinvestment risk"},{"prompt":"Liquidity risk"},{"prompt":"Call risk"},{"prompt":"Currency risk"}]
          },
          {
            "type": "you",
            "content": ["Credit (default) risk"],
            "perfil": "assets/img/temp/profile.svg"
          },
          {
            "type": "text",
            "content":["All bonds contain some level of default risk, however higher rated bonds have been shown to have lower default risk. Here is a list of bonds in your portfolio with higher probabilities of default based on credit rating."],
            "prompts":[]
          },
          {
            "type": "bonds",
            "content": {
              "list":[
          {
            "id": "US071734AH05",
            "name": "BAUSCH HEALTH COMPANIES INC 144A",
            "isin": "US071734AH05",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "BAUSCH HEALTH COMPANIES INC 144A",
            "issue_volume": 0,
            "issue_price": 39.5,
            "issue_date": "2019-12-30",
            "maturity": "2028-01-30",
            "grade": null,
            "sector": "Consumer Non-Cyclical",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 39.5,
            "coupon": 5,
            "ytm": 31.66,
            "real_ytm": 31.66,
            "par": 5977000,
            "created_at": null
          },
          {
            "id": "US156700BD72",
            "name": "LUMEN TECHNOLOGIES INC 144A",
            "isin": "US156700BD72",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "LUMEN TECHNOLOGIES INC 144A",
            "issue_volume": 0,
            "issue_price": 30,
            "issue_date": "2020-11-27",
            "maturity": "2029-01-15",
            "grade": null,
            "sector": "Communications",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 30,
            "coupon": 4.5,
            "ytm": 33.82,
            "real_ytm": 33.82,
            "par": 5322000,
            "created_at": null
          },
          {
            "id": "US23131LAE74",
            "name": "CURO GROUP HOLDINGS CORP 144A",
            "isin": "US23131LAE74",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "CURO GROUP HOLDINGS CORP 144A",
            "issue_volume": 0,
            "issue_price": 39.75,
            "issue_date": "2023-02-01",
            "maturity": "2028-08-01",
            "grade": null,
            "sector": "Finance Companies",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 39.75,
            "coupon": 7.5,
            "ytm": 33.56,
            "real_ytm": 33.56,
            "par": 10012000,
            "created_at": null
          },
          {
            "id": "US29365DAA72",
            "name": "AUDACY CAPITAL CORP 144A",
            "isin": "US29365DAA72",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "AUDACY CAPITAL CORP 144A",
            "issue_volume": 0,
            "issue_price": 1.52,
            "issue_date": "2019-04-30",
            "maturity": "2027-05-01",
            "grade": null,
            "sector": "Communications",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 1.52,
            "coupon": 6.5,
            "ytm": 50,
            "real_ytm": 50,
            "par": 4001000,
            "created_at": null
          },
          {
            "id": "US29365DAB55",
            "name": "AUDACY CAPITAL CORP 144A",
            "isin": "US29365DAB55",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "AUDACY CAPITAL CORP 144A",
            "issue_volume": 0,
            "issue_price": 1.9,
            "issue_date": "2021-03-25",
            "maturity": "2029-03-31",
            "grade": null,
            "sector": "Communications",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 1.9,
            "coupon": 6.75,
            "ytm": 50,
            "real_ytm": 50,
            "par": 5123000,
            "created_at": null
          },
          {
            "id": "US55910RAE36",
            "name": "MCAFEE / MAGENTA BUYER LLC TL-B",
            "isin": "US55910RAE36",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "MCAFEE / MAGENTA BUYER LLC TL-B",
            "issue_volume": 0,
            "issue_price": 44.67,
            "issue_date": "2021-07-27",
            "maturity": "2029-07-27",
            "grade": null,
            "sector": "Corporates",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 44.67,
            "coupon": 13.88,
            "ytm": 39.43,
            "real_ytm": 39.43,
            "par": 40000,
            "created_at": null
          },
          {
            "id": "US750098AA35",
            "name": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
            "isin": "US750098AA35",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "RACKSPACE TECHNOLOGY GLOBAL INC 144A",
            "issue_volume": 0,
            "issue_price": 32.03,
            "issue_date": "2020-12-01",
            "maturity": "2028-12-01",
            "grade": null,
            "sector": "Technology",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 32.03,
            "coupon": 5.38,
            "ytm": 34.45,
            "real_ytm": 34.45,
            "par": 5648000,
            "created_at": null
          },
          {
            "id": "US75041VAA26",
            "name": "RADIOLOGY PARTNERS INC 144A",
            "isin": "US75041VAA26",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "RADIOLOGY PARTNERS INC 144A",
            "issue_volume": 0,
            "issue_price": 38.71,
            "issue_date": "2020-01-31",
            "maturity": "2028-02-01",
            "grade": null,
            "sector": "Consumer Non-Cyclical",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 38.71,
            "coupon": 9.25,
            "ytm": 40.28,
            "real_ytm": 40.28,
            "par": 8708000,
            "created_at": null
          },
          {
            "id": "US767754CK89",
            "name": "RITE AID CORPORATION 144A",
            "isin": "US767754CK89",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "RITE AID CORPORATION 144A",
            "issue_volume": 0,
            "issue_price": 63.44,
            "issue_date": "2020-02-05",
            "maturity": "2025-07-01",
            "grade": null,
            "sector": "Consumer Cyclical",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 63.44,
            "coupon": 7.5,
            "ytm": 38.3,
            "real_ytm": 38.3,
            "par": 308000,
            "created_at": null
          },
          {
            "id": "US855030AP77",
            "name": "STAPLES INC 144A",
            "isin": "US855030AP77",
            "sebol": null,
            "type": "Fixed Income",
            "country": "United States",
            "issuer": "STAPLES INC 144A",
            "issue_volume": 0,
            "issue_price": 58.33,
            "issue_date": "2019-04-16",
            "maturity": "2027-04-15",
            "grade": null,
            "sector": "Consumer Cyclical",
            "payments_per_year": 2,
            "currency": "USD",
            "price": 58.33,
            "coupon": 10.75,
            "ytm": 31.05,
            "real_ytm": 31.05,
            "par": 14270000,
            "created_at": null
          }
        ]}
          },
          {
            "type": "you",
            "content": ["I would like to know how my investments will behave under various market scenarios"],
            "perfil": "assets/img/temp/profile.svg"
          },
          {
            "type": "sceenario",
            "content": {
            "filters":[{"type":"select","title":"Scenario:","default":"Great Financial Crisis","items":[{"value":"A001","name":"Covid 19"}]}],
            "left":"Current",
            "rigth":"Model"
        }
          },
          {
            "type": "text",
            "content":["Would you like to know how shift in credit spread may affect your portfolio?"],
            "prompts":[]
          },
          {
            "type": "you",
            "content": ["Yes, i do"],
            "perfil": "assets/img/temp/profile.svg"
          },
          {
            "type": "sceenario",
            "content": {
              "filters":[{"type":"select","title":"In Credit Spreads:","default":"-1000bps",
              "items":[{"value": "A0950", "name": "-950bps"},  {"value": "A0900", "name": "-900bps"},  {"value": "A0850", "name": "-850bps"},  {"value": "A0800", "name": "-800bps"},  {"value": "A0750", "name": "-750bps"},  {"value": "A0700", "name": "-700bps"},  {"value": "A0650", "name": "-650bps"},  {"value": "A0600", "name": "-600bps"},  {"value": "A0550", "name": "-550bps"},  {"value": "A0500", "name": "-500bps"},  {"value": "A0450", "name": "-450bps"},  {"value": "A0400", "name": "-400bps"},  {"value": "A0350", "name": "-350bps"},  {"value": "A0300", "name": "-300bps"},  {"value": "A0250", "name": "-250bps"},  {"value": "A0200", "name": "-200bps"},  {"value": "A0150", "name": "-150bps"},  {"value": "A0100", "name": "-100bps"},  {"value": "A0075", "name": "-75bps"},  {"value": "A0050", "name": "-50bps"},  {"value": "A0025", "name": "-25bps"},  {"value": "A0000", "name": "0bps"},  {"value": "A0025", "name": "25bps"},  {"value": "A0050", "name": "50bps"},  {"value": "A0075", "name": "75bps"},  {"value": "A0100", "name": "100bps"},  {"value": "A0150", "name": "150bps"},  {"value": "A0200", "name": "200bps"},  {"value": "A0250", "name": "250bps"},  {"value": "A0300", "name": "300bps"},  {"value": "A0350", "name": "350bps"},  {"value": "A0400", "name": "400bps"},  {"value": "A0450", "name": "450bps"},  {"value": "A0500", "name": "500bps"},  {"value": "A0550", "name": "550bps"},  {"value": "A0600", "name": "600bps"},  {"value": "A0650", "name": "650bps"},  {"value": "A0700", "name": "700bps"},  {"value": "A0750", "name": "750bps"},  {"value": "A0800", "name": "800bps"},  {"value": "A0850", "name": "850bps"},  {"value": "A0900", "name": "900bps"},  {"value": "A0950", "name": "950bps"},  {"value": "A1000", "name": "1000bps"}]
              }],
              "label":"Change in Market Value for a given change in Credit Spreads ",
              "left":"Current Market Value",
              "rigth":"Estimate Market Value"
          }
          }
        
        
    ]
  