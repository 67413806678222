
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class CommonRestRequests {
  
    constructor(private client: HttpClient,private baseUrl:String) {
    }

    private solvePath(path:string[]){
        return path.join("/")
    }

    get(type: string): Observable<any | any[]> {
        return this.client.get<any | any[]>(this.baseUrl + `${type}`).pipe(
            map((response: any) => {
                return response
            })
        );
    }
    getByPath(path:string[]): Observable<any | any[]> {
        return this.client.get<any | any[]>(this.baseUrl + `/${this.solvePath(path)}`).pipe(
            map((response: any) => {
                return response
            })
        );
    }
    post(type:string,payload:any): Observable<any | any[]> {

        return this.client.post<any | any[]>(this.baseUrl + `${type}`,payload).pipe(
            map((response: any) => {
                return response
            })
        );
    }
}
