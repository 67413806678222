<div>
  <div>
    <app-filter [filters]="tempsFilters"/>
  </div>

<ngx-slick-carousel class="carousel w-full md:p-8 min-w-[180%] md:min-w-0" 
                        #slickModal="slick-carousel" 
                        [config]="slideConfig"
                        (afterChange)="currentSlideIndex = $event.currentSlide">
        <div ngxSlickItem *ngFor="let item of content" class="slide p-2">
            <app-assets-visualization 
            [vertical]="false" 
            [aiChat]="true"
            (btnDetailsClick)="showDetail(item)" 
            (btnOrderClick)="order(
              {
                title:item.name,
                id:item.id,
                currency:item.currency,
                income:20
              })" [assetsData]="solveAssets(item)">
                <app-details [details]="solveList(item)"></app-details>
              </app-assets-visualization>
        </div>
 </ngx-slick-carousel>
 <div class="flex gap-8 items-center justify-center p-8">
 <app-rounded-icon-buttom (btnClick)="slickModal.slickPrev()" [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass:'bg-transparent border rotate-180'}" />
 <div class="custom-dots w-full">
    <span *ngFor="let slide of content; let i = index" class="dot flex-grow {{currentSlideIndex === i?'active':''}}" (click)="slickModal.slickGoTo(i)"></span>
  </div>
  <app-rounded-icon-buttom (btnClick)="slickModal.slickNext()" [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg'}" />

</div>
</div>