import { Injectable } from '@angular/core';
import { RoboServiceRepoRest } from '../adapters/robo/RestAdapter';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RoboService extends RoboServiceRepoRest {
  constructor(private httpClient:HttpClient) {
    super(httpClient)
   }
   currentPlayload:any = {}
   suggestedSecurities:any[] = []
   setCurrentPayload = (payload:any) => this.currentPlayload = payload
   setCurrentSuggestedSecurities = (sS:any) => this.suggestedSecurities = sS


}
