<div class="w-full">
  <div class="">
    <div class="min-h-[56px] bg-[#978EF533] rounded-t-2xl grid grid-cols-5">
      <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow col-span-1"> Assets</div>
      <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow col-span-2" *ngFor="let column of columns">
          <app-bonds-company [bondsCompanyData]="{
              logo: 'assets/img/icon/logo-two.svg',
              firstText:'Brazil, Federative Republic 8.75% 05/25'}"/></div>
      </div>
  </div>
  <div *ngIf="data" class="text-[#E1E1E1] font-normal text-[16px]">
    <div class="rounded-[8px] grid grid-cols-5 items-center {{i%2!=0?'bg-[#FFFFFF08]':''}}" *ngFor="let asset of assets,let i = index">
      <div class="px-4 col-span-1">
          {{asset}} 
         </div>
      <div class="p-4 flex-grow col-span-2" *ngFor="let column of columns">
              {{ data[column][asset] }}</div>
    </div>
  </div>
</div>