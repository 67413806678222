<div class="w-full">
    <div class="">
      <div class="min-h-[56px] bg-[#978EF54D] rounded-t-2xl grid" [style.gridTemplateColumns]="'repeat(' + numColumns + ', 1fr)'">
        <div class="font-[400] p-4 text-[14px] text-[#B3B3B3] text-left flex-grow col-span-1"> Assets</div>
        <div class="font-[400] p-4 text-[14px] text-[#B3B3B3] text-left" *ngFor="let column of content.columns">
            {{column}}</div>
        </div>
    </div>
    <div *ngIf="content.data" class="text-[#E1E1E1] font-[400] text-[16px]">
      <div class="rounded-[8px] grid items-center {{i%2!=0?'bg-[#FFFFFF08]':''}}" *ngFor="let asset of content.assets,let i = index" [style.gridTemplateColumns]="'repeat(' + numColumns + ', 1fr)'">
        <div class="px-4 col-span-1">
            {{asset}}
           </div>
        <div class="p-4 flex-grow" *ngFor="let column of content.columns">
                {{ content.data[asset][column] }}</div>
      </div>
    </div>
</div>