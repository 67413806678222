<div class="font-bold text-[24px] mb-10">Objectives</div>
<div class="p-8 rounded-2xl bg-[#FFFFFF1A]">
    <div *ngIf="currentStep == 2"><app-filter [filters]="investments.filters" /></div>
    <div *ngIf="currentStep == 1" class="md:flex gap-20">
        <div class="">
            <div class="font-normal text-[17px] mb-4">Reinvest Maturities?</div>
            <div class="flex gap-4 max-w-xl mx-auto my-4">
                <label for="{{invested.title}}" *ngFor="let invested of investeds, let i = index" class="rounded-2xl p-4 flex items-start gap-2 min-h-[80px]">
                    <input type="radio" class="border-[#B3B3B3] border bg-transparent" id="{{invested.title}}" name="invested" />
                    <label for="{{invested.title}}" class="">{{invested.title}}</label>
                </label>
            </div>
        </div>

        <div class="">
            <div class="font-normal text-[17px] mb-4">Objectives</div>
            <div class="p-4 m-2 rounded-2xl bg-[#978EF51A] inline-block">Capitlal Preservation</div>
            <div class="p-4 m-2 rounded-2xl bg-[#978EF51A] inline-block">Capital Appreciation</div>
            <div class="p-4 m-2 rounded-2xl bg-[#978EF51A] inline-block">Income</div>

        </div>

    </div>
    <div *ngIf="currentStep == 0"><app-filter [filters]="target.filters" />
</div>

<app-back-and-forward (endClick)="showDetail()" (nextClick)="changeStep(1,currentStep)" [class]="'!w-full'" [current]="currentStep" (prevClick)="changeStep(-1,currentStep)" [totalSteps]="totalSteps" />
</div>