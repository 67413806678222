<div class="flex bg-transparent   {{ bondsCompanyData.bondsCompanyClass }}">
  <app-rounded-icon-buttom
    [buttonData]="{ iconUrl: bondsCompanyData.logo, btnClass:detailed?'w-[1.75rem] h-[1.75rem] mt-[0.4rem] md:mt-0 md:w-[2.75rem] md:h-[2.75rem] ':'hidden md:block'}"></app-rounded-icon-buttom>
    <div class="ms-3">
      <span
        class="block text-white text-[0.8rem] font-[400] md:text-[1rem] md:font-[600] {{ bondsCompanyData.firstTextClass }}  "
        style="
          font-family: 'Poppins';
          font-style: normal;
          line-height: 24px;
        "
        >{{ bondsCompanyData.firstText }}</span
      >
      <span
        class="block text-white text-[0.75rem]  md:font-[400] md:text-[1rem] {{ bondsCompanyData.secondTextClass }}"
        style="
          font-family: 'Poppins';
          font-style: normal;
          line-height: 24px;
        "
        >{{ bondsCompanyData.secondText }}</span
      >
    </div>
</div>