<div class="hidden lg:flex" [ngClass]="{'gap-8':items.length <=4, 'gap-4':items.length>=6}">
  <div *ngFor="let item of items; let i = index" class="flex-grow" [ngClass]="{
      'text-[1rem]': items.length > 4,
      'text-[2rem]': items.length <= 4,
      'text-[0.7rem]': items.length >= 6
    }">
    <div [ngClass]="{
      'text-[#B3B3B3] text-[1.125rem]': items.length < 6,
      'text-[#B3B3B3] text-[0.75rem]': items.length >= 6
    }">{{item.title}}</div>

    
    <div class="flex items-center" [ngClass]="{'gap-1':items.length >=6, 'gap-4':items.length<=5}">
      <app-rounded-icon-buttom [buttonData]="{iconUrl:item.iconUrl, btnClass:'bg-[#4A4C57] !w-8 !h-8 ' + item.btnClass, iconClass:'!w-5 !h-5 '}" />
      <div class="font-bold text-white {{item.classText}}">{{item.value}}</div>
    </div>
  </div>
</div>

<div class="lg:hidden block mt-[1rem] mb-[1.5rem]">
    <div class="grid grid-cols-2 gap-4">
      <div *ngFor="let item of items, let i = index" class="text-white">
        <div class="text-[#B3B3B3] text-[0.75rem]">{{ item.title }}</div>
        <div class="flex gap-2 items-center">
          <app-rounded-icon-buttom [buttonData]="{ iconUrl: item.iconUrl, btnClass: 'bg-[#4A4C57] !w-7 !h-7 ' + item.btnClass, iconClass: '!w-4 !h-4 ' }"></app-rounded-icon-buttom>
          <div class="text-[0.75rem] font-bold text-white">{{ item.value }}</div>
        </div>
      </div>
    </div>
  </div>
  