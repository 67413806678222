<app-user-layout>
    <app-responses [content]="getSamples()" />
    <div class=" w-full fixed bottom-0 right-0 pb-8 border-gray-50">
        <p class="pb-[0.4rem] w-full text-right md:pr-[2rem] md:pt-[1rem] flex pr-5 gap-[0.5rem]  justify-end" (click)="handlePayment()" [ngClass]="{ 'hidden': selectedTab }">
            <app-rounded-icon-buttom [buttonData]="{
                iconUrl: 'assets/img/icon/downArrow.svg',
                btnClass: '!bg-white !h-[1rem] !w-[1.01rem]  mt-[0.3rem]',
                iconClass:'rotate-90'
              }" />
            Suggestions:
        </p>
        <ng-container [ngSwitch]="selectedTab">
            <span *ngSwitchCase="'youCanAsk'" class="">

                <p class="md:fixed md:right-[0.2rem] md:bottom-[9rem] pb-[0.4rem] md:w-[10rem] text-[0.8rem] text-right  md:pt-[1rem] pr-5 flex gap-[0.3rem] justify-end"
                    (click)="handlePayment()">
                    <app-rounded-icon-buttom [buttonData]="{
                        iconUrl: 'assets/img/icon/downArrow.svg',
                        btnClass: '!bg-white !h-[0.8rem] !w-[0.8rem] mt-[0.2rem]',
                        iconClass:'-rotate-90'
                      }" /> Suggestions:
                </p>
                <div class="md:fixed md:bottom-[5rem] md:right-[0.2rem] md:flex md:justify-end  md:pb-[0.5rem] pr-5 md:w-[50rem]">
                    <div class="w-screen flex overflow-scroll scroll-smooth  gap-[0.75rem] 
                    md:overflow-auto md:justify-end md:items-center "
                        >
                        <div *ngFor="let p of getPrompts(), let i = index" class="bg-[#0C1026CC] min-w-[14rem] md:w-[14rem] md:h-[3.5rem] text-left  text-[0.7rem] border rounded-2xl p-[0.5rem] cursor-pointer " (click)="onSend(p.prompt)">{{ p.prompt }}</div>
                    </div>
                </div>


            </span>
        </ng-container>
        
        <div class="md:fixed md:right-[0.2rem] md:bottom-[2rem] flex justify-end pr-3  md:w-[45rem]  ">
            <app-input-chat [chatData]="{classContainer:'',classInput:'md:!w-[45rem] !h-[3rem]'}" [(inputValue)]="currentPrompt" (btnClick)="onSend(currentPrompt)" />
        </div>
    </div>
</app-user-layout>