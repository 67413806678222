import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sceenario',
  templateUrl: './sceenario.component.html',
  styleUrls: ['./sceenario.component.scss']
})
export class SceenarioComponent implements OnInit{
  @Input() content:any = []
  @Input() label:string =""
  constructor(){
    console.log("content",this.content)
  }
  ngOnInit(): void {
    console
    .log("content",this.content)
  }

}
