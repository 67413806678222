<div class=" lg:flex lg:justify-evenly bg-[#181C31] pb-[1.25rem]  rounded-[1.5rem]">
    <div class="lg:w-5/12 block  mt-[2rem] pt-[1rem] md:pt-0 ">
        <app-input-form
            [inputData]="{value:name,label:'Full Name',inputType:'text',placeholder:'John Braun',isEditable:true,inputClass:'!font-bold',labelClass:'ml-[1rem]'}" />
        <div class=" border-t-[0.5px] border-[#4A4C57] max-w-lg  mx-[5%] md:mx-auto my-[5%]"></div>
        <app-input-form
            [inputData]="{label:'Country',inputType:'text',placeholder:'United States of America',isEditable:true,inputClass:'!font-bold',labelClass:'ml-[1rem]'}" />
        <div class="flex justify-end my-[3%] pr-[0.5rem]">
            <app-button
                [buttonData]="{textButton:'Add Address',classButton:'!bg-[#978EF51A] !border-[#181C31] !border-[0.1rem] ',classText:'!text-[#978EF5]'}" />
        </div>
        <div class=" border-t-[0.5px] border-[#4A4C57] max-w-lg  mx-[5%] md:mx-auto my-[4%]"></div>
        <app-input-form
            [inputData]="{label:'Phone',inputType:'text',placeholder:'+410 564 44 65',isEditable:true,inputClass:'!font-bold',labelClass:'ml-[1rem]'}" />
        <div [ngClass]="{'hidden lg:block': 'mt-[1rem]'}" class="">
            <app-upload-photo></app-upload-photo>
        </div>

    </div>
    <div class="lg:w-5/12  mt-[2rem] ">
        <div class=" border-t-[0.5px] border-[#4A4C57] max-w-lg  mx-[5%] md:mx-auto my-[4%] lg:hidden"></div>
        <div class="flex justify-between items-center">
            <app-input-form
            [isInputDisabled]="true"
                [inputData]="{isEditable:false,value:email,label:'Email ',inputType:'text',placeholder:'shishovadiz@gmail.com',inputClass:'!bg-transparent !font-bold text-[0.79rem] sm:text-[0.8rem] md:text-[1rem]',labelClass:'ml-[1rem]'}" />
            <div
                class=" w-[30%] text-[0.68rem] sm:w-[35%] sm:text-[0.69rem] md:w-[40%] md:text-[0.70rem] flex pr-[0.5rem] ">
                <span class="">If you need to edit your email,
                    please <span class="text-[#978EF5]"> Contact Us.</span></span>
            </div>
        </div>
        <div class=" border-t-[0.5px] border-[#4A4C57] max-w-lg  mx-[5%] md:mx-auto my-[4%]"></div>
        <div class="flex pr-[0.5rem] md:justify-between md:items-center">
            <app-input-form
                [inputData]="{label:'Password',inputType:'text',placeholder:'***********',inputClass:'!bg-transparent !font-bold',labelClass:'ml-[1rem]'}" />
            <div class="  md:w-[36%]  text-[0.70rem] flex">
                <app-button
                    [buttonData]="{textButton:'Set Password',classButton:'!bg-[#978EF51A] !border-[#181C31] !border-[0.1rem] ',classText:'!text-[#978EF5]'}" />
            </div>
        </div>
        <div class=" border-t-[0.5px] border-[#4A4C57] max-w-lg  mx-[5%] md:mx-auto lg:my-[4%] lg:hidden"></div>
        <div class="mt-[1rem] lg:hidden px-[1rem]">
            <app-upload-photo></app-upload-photo>
        </div>
    </div>

</div>