import { gql } from "apollo-angular";

export const LOGIN = gql `mutation login(
    $user: String!
    $password: String!
  ) {
    login(input: { identifier: $user, password: $password }) {
      jwt
      user {
        id
        username
      }
    }
  }
`