import { Component, Input } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-objectives',
  templateUrl: './objectives.component.html',
  styleUrls: ['./objectives.component.scss']
})
export class ObjectivesComponent {
  @Input() content?: any = {}
  @Input() addObj?: Function;
  investments = {
    filters: [
      {
        type: "slider",
        title: "Investment Horizon (Years)?",
        data: [
          { value: 0, legend: "0" },
          { value: 3, legend: "3" },
          { value: 5, legend: "5" },
          { value: 10, legend: "10" },
          { value: 15, legend: "15" },
          { value: 20, legend: "20" },
          { value: 25, legend: "25" },
          { value: 30, legend: "30" },
        ]
      },
      { type: "amount", title: "Initial Investment" }
    ]
  }
  objectives = {
    objectives: ["", "", ""]
  }
  investeds: any[] = [
    { title: "Yes", desc: "Yes," },
    { title: "No", desc: "No," },
  ]
  target = {
    filters: [
      { type: "slider", title: "Target Yield ",data: [
        { value: 0, legend: "1.00%" },
        { value: 3, legend: "2.00%" },
        { value: 5, legend: "3.00%" },
        { value: 10, legend: "4.00%" },
        { value: 15, legend: "5.00%" },
        { value: 20, legend: "6.00%" },
        { value: 25, legend: "7.00%" }
      ] },
      { type: "level", title: "Self-Described Risk Tolerance" },
    ]
  }



  currentStep: number;
  totalSteps: number = 3; // Set the total number of steps

  constructor(private chatService: ChatService) {
    this.currentStep = 0;
  }
   objSample = {
    type: "text",
  content:["Your Implied Risk Tolerance is MEDIUM", "Which means..."],
  };
   objSample2= {
  type: "model",
  content: {}
  };
  showDetail() {
    this.chatService.pushObj(this.objSample);
    this.chatService.pushObj(this.objSample2);
  }
  changeStep(val: number, pos: number) {
    let res = this.currentStep + val;

    if (res >= 0 && res < this.totalSteps && pos < this.totalSteps) {
      this.currentStep = res;
    }
  }

  
}
