<div class=" overflow-hidden">

  <app-mobile-menu />
  <div class="hidden md:flex items-center mb-4 screen">
    <!-- <app-yieldvestor
      [yieldVestorData]="{
        firstText: 'Yield',
        secondText: 'Vestor',
        logo: 'assets/img/icon/logo.svg',
        firstTextClass: '!text-[#4A4C57]',
        secondTextClass: '!text-[#2CC784]'
      }"
    ></app-yieldvestor> -->
    <img src="assets/img/logo.svg" alt="logo" />
    <div class="flex items-center menu flex-grow justify-center">
      <div class="menu-item ml-auto flex-grow"></div>
      <div class="menu-item cursor-pointer ml-auto" [routerLink]="'/home'">
        Home
      </div>

      <div class="menu-item cursor-pointer" [routerLink]="'/assistant'">
        Assistant
      </div>
      <div class="menu-item cursor-pointer" [routerLink]="'/portfolio'">
        Portifolio
      </div>
      <div class="menu-item mr-auto flex-grow"></div>
      <div class="menu-item" *ngIf="!authenticated">
        <app-button 
          (click)="goToLogin()"
          [buttonData]="{
            textButton: 'Login',
            classButton: '!bg-transparent !text-black',
            classText: '!text-yv-base !text-[1rem]',
          }"
        />
      </div>
      <div class="menu-item !p-0" *ngIf="!authenticated">
        <app-button 
          (click)="goToRegister()"
          [buttonData]="{
            textButton: 'Sign Up',
            classButton: '!bg-yv !h-[30px] !pt-6 !pb-6',
            classText: '!text-[1rem]'
          }"
        />
      </div>
    </div>
  </div>
  <div
    class="rounded-3xl bg-[#0C1026] grid md:flex text-white screen bg-land-banner bg-no-repeat bg-cover mb-20 overflow-hidden"
  >
    <div class="p-10 md:px-20 md:w-1/2 md:text-left md:block  flex flex-col items-center text-center max-w-full">
      <app-yieldvestor
        [yieldVestorData]="{
          firstText: 'Yield',
          secondText: 'vestor',
          logo: '',
          firstTextClass: '!text-white text-[2.5rem]',
          secondTextClass: '!text-[#2CC784] text-[2.5rem]'
        }"
      ></app-yieldvestor>
      <h2
        class="text-[#978EF5] md:text-[48px] text-[11.5vw] font-[800] italic mt-[40px]"
      >
      AI-Powered Tools
      </h2>
      <h1 class="uppercase font-normal md:text-[2vw] md:my-5 md:whitespace-nowrap text-[13.5vw] -my-4">
        for every retail investor
      </h1>

      <h2
        class="font-[300] md:text-[2.5rem] text-[7vw] bottom-4 md:w-[35rem]"
        style="line-height: 3rem"
      >
        Experience the future of fixed income.
      </h2>
      <div class="h-[80px]"></div>
      <app-button
        [buttonData]="{
          iconUrl: 'assets/img/icon/chat-arrow.svg',
          classIcon: '!bg-[#FFFFFF08] rounded-full',
          IconLeft: true,
          iconRight: true,
          classText: 'font-[600] text-[.8rem] uppercase tracking-widest',
          textButton: 'Start chatting',
          classButton: 'md:pt-24 md:pr-24 md:pb-24 md:pl-20 !mb-4',
          iconRightUrl: 'assets/img/icon/arrow-tree.svg'
        }"
      />
    </div>
    <div class="md:w-1/2 w-full flex flex-col md:flex-row items-center overflow-hidden">
      <img
        src="assets/img/phone_pattern.png"
        class="absolute max-w-full md:translate-y-40"
      />
      <img
        src="assets/img/phone_pattern.png"
        class="absolute max-w-full -translate-y-56 translate-x-60"
      />

      <img
        src="assets/img/phone_pattern.png"
        class="absolute max-w-full md:translate-y-40"
      />
      <img
        src="assets/img/phone_pattern.png"
        class="absolute max-w-full -translate-y-56 translate-x-60"
      />

      <img src="assets/img/phone.svg" class="md:absolute md:translate-x-20" />
    </div>
  </div>
  <div class="screen"><app-video-carousel></app-video-carousel></div>
  <div></div>
</div>

<app-footer
  [footerClass]="'!bg-white'"
  [pClass]="
    'text-[#4A4C57] text-center  text-[0.875rem] font-normal leading-[1.125rem] mb-8'
  "
></app-footer>
