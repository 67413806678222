import { Component } from '@angular/core';

@Component({
  selector: 'app-scenario-test',
  templateUrl: './scenario-test.component.html',
  styleUrls: ['./scenario-test.component.scss']
})
export class ScenarioTestComponent {

  default: string = "Parallel Shift"
  itemsScenario: any[] = [{ value: 'A001', name: 'Parallel Shift' },
  { value: 'A001', name: 'Non-Parallel Shift' },
  { value: 'A001', name: 'Credit Spread' },
  { value: 'A001', name: 'Covid Pandemic' },
  { value: 'A001', name: 'Dotcom Bubble' }];

  parallelShift = true
  currentPosition: number = 0;
  cardTitle = "Strategies"

  showScenarioStrategie=false

  cardTitle2 = "Scenario Analysis"
  strategies: any[] = [{
    title: "Ladder", icon: "assets/img/icon/ladder_icon.svg"
  }, {
    title: "Basic", icon: "assets/img/icon/basic_icon.svg"
  }, {
    title: "Index", icon: "assets/img/icon/index_icon.svg"
  }]

  scenarioAnalysis: any[] = [{
    title: "Parallel Shift", icon: "assets/img/icon/ladder_icon.svg"
  }, {
    title: "Non-Parallel Shift", icon: "assets/img/icon/basic_icon.svg"
  }, {
    title: "Credit Spreads", icon: "assets/img/icon/index_icon.svg"
  },
  {
    title: "Covid Pandemic", icon: "assets/img/icon/index_icon.svg"
  }, {
    title: "Great Financial Crisis", icon: "assets/img/icon/index_icon.svg"
  }, {
    title: "Dotcom Bubble", icon: "assets/img/icon/index_icon.svg"
  }]

  rangeData: any[] = [{ value: 20, legend: "" }, { value: 40, legend: "" }]


  handleScenario(){

    this.showScenarioStrategie = true

  }

  curentIndex(index: number) {
    this.currentPosition = index;
    this.showScenarioStrategie = false
  }

  handleContinue() { 

  }
  reset() { }
}
