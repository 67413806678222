import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ResponseService } from './response.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  samples: any[] = [];
  data: any[] = []
  loading: boolean = true;

  constructor(private http: HttpClient, private responseService: ResponseService) { }
  ///CODE TO BE REMOVED
  findMostSimilarString(array: any[], assignedString: string) {
    let bestScore = 0;
    let mostSimilarObject = null;

    for (let i = 0; i < array.length; i++) {
      const object = array[i];
      const score = this.calculateSimilarityScore(object.q, assignedString);

      if (score > bestScore) {
        bestScore = score;
        mostSimilarObject = object;
      }
    }

    return mostSimilarObject || { q: "", a: [] };
  }

  calculateSimilarityScore(string1: string, string2: string) {
    // Implement the string similarity calculation algorithm here.
    // You can use algorithms like Levenshtein, Jaro-Winkler, etc.
    // In this example, we will use the Jaccard similarity coefficient.

    const set1 = new Set(string1.toLowerCase().split(''));
    const set2 = new Set(string2.toLowerCase().split(''));

    const intersection = new Set([...set1].filter(element => set2.has(element)));
    const union = new Set([...set1, ...set2]);

    return intersection.size / union.size;
  }
  prompts: any[] = [
    { prompt: "How much California municipal bonds do I own?", url: 'assets/samples3.json' },
    { prompt: "I would like investment advice", url: 'assets/samples2.json' },
    { prompt: "Give me the top 10 yielding assets", url: 'assets/samples.json' },
  ];
  array = [
    { q: "Give me thes top 10 yielding assets", a: [3, 4] },
    { q: "Only list assets with a yield of 5% or higher", a: [6, 7] },
    { q: "Compare the first two assets", a: [9, 10, 11] },
    { q: "Create a projection for an investment of $799.00 in these assets", a: [13, 14, 15, 16, 17] },
    { q: "From the list, these are the assets with a yield of 5% or higher:", a: [19, 20, 21, 22] },
    { q: "What are some attractive BBB bonds", a: [24, 25, 26, 27, 28] },
    { q: "Yes, I do!", a: [30] },
    { q: "I am not sure what to invest in, please, can you help me?", a: [32, 33/* ,34,35 */] },
    { q: "I would like investment advice", a: [32, 33/* ,34,35 */] },
    { q: "I would like to learn more about fixed income", a: [37] },
    { q: "Start learning", a: [39,/* 40,41 */] },
    { q: "Show me Alaska municipal bonds with the highest yields?", a: [44, 45] },
    { q: "Compare Bond A and Bond B performance over last six months.", a: [47, 48, 49] },
    { q: "What are some attractive BBB bonds? ", a: [51] },
    { q: "BBB Technology bonds", a: [53, 54] },
    { q: "What part of the curve (which maturities) should I invest in?", a: [56, 57, 58] },
    { q: "What are some low risk investment options?", a: [60, 61, 62, 63] },
    { q: "Do Texas, 5% 04/2029, USD bonds look cheap or expensive?", a: [65] },
    { q: "What is my largest exposure? What are my top 10 holdings?", a: [67, 68] },
    { q: "When is my next largest maturity?", a: [70, 71, 72] },
    { q: "Yes, please", a: [74] },
    { q: "Show me the top 10 sectors in my portfolio", a: [76, 78] },
    { q: "How much California municipal bonds do I own?", a: [80] },
    { q: " When is my next maturity?", a: [82, 83] },
    { q: "What is my lowest/highest yielding investment?", a: [85, 86] },
    { q: "How risky is my portfolio? What is my riskiest holding?", a: [88] },
    { q: "Credit (default) risk", a: [90, 91] },
    { q: "I would like to know how my investments will behave under various market scenarios", a: [93, 94] },
    { q: "Yes, i do", a: [96] }
  ]

  private processing: boolean = false;
  isProcessing(): boolean {
    return this.processing;
  }
  async onSendDeprecated(prompt?: string) {
    if (this.processing) {
      return;
    }
    this.processing = true;
    this.samples.push({ type: "you", content: [prompt], perfil: "assets/img/temp/profile.svg" })
    const mostSimilarObject = this.findMostSimilarString(this.array, prompt || "")
    const mostSimilarLoop = mostSimilarObject.a;
    for (let i = 0; i < mostSimilarLoop.length;) {
      this.loading = true;

      const element = mostSimilarLoop[i];
      let dataToPush = Object.assign({}, this.data[element])
      dataToPush.vote = undefined
      this.samples.push(dataToPush)
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'auto'
      })
      if (dataToPush.type === "text") {
        await this.waitForResponse()
        dataToPush.typed = true
        i++;
      } else {
        i++;

        console.log(i);
        //break;
      }
    }
    this.loading = false;
    this.processing = false;
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'auto'
    })
  }
  async waitForResponse() {
    return new Promise((resolve) => {
      this.responseService.response$.subscribe(() => {
        resolve(null);
      });
    });
  }

   onSend(prompt?: string) {
    this.samples.push({ type: "you", content: [prompt], perfil: "assets/img/temp/profile.svg" });
    this.loading = true;
    this.http.post<any>("https://smart-custom.sintaxy.com/api/v1/chat", { "question": prompt })
      .subscribe(
        async data => {
          console.log("this.is My data", data.data);

          data.data.vote = undefined
          if (data.data.type == 'bonds') {
            this.samples.push(...[{ ...data.data, type: "html" }, data.data.content?.list?.length > 0 ? data.data : {}]);
          } else {
            this.samples.push(data.data);
          }

          if (data.data.type === "text") {
            await this.waitForResponse();
            data.data.typed = true;
          }

          this.loading = false;
        },
        error => {
          console.log('Error:', error);
          this.loading = false;
        }
      );
  }

  pushObj(obj: any) {
    this.samples.push(obj)
  }

  setData(data: any[]) {
    this.data = data;
  }
  updateVote(index: number, vote: string) {
    if (index >= 0 && index < this.samples.length) {
      this.samples[index].vote = vote;
    }
  }
  resetChatAndSendNewPrompt(index: number, newPrompt: string) {
    if (index >= 0 && index < this.samples.length) {
      // Remove items after the given index
      this.samples = this.samples.slice(0, index);

      // Call onSend with the new prompt
      this.onSend(newPrompt);
    }
  }
}
