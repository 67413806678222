<app-user-layout>


  <div class="grid grid-cols-2 gap-4">
    <div>
      <!-- 2 Exporter -->
      <app-input-form
        [control]="exporter"
        [inputData]="{
          label: '2 Exporter',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
      <app-input-form
        [control]="exporterNo"
        [inputData]="{
          label: 'No.',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
    </div>
    <div class="grid grid-cols-3 gap-2">
      <!-- 1 Declaration -->
      <app-input-form
        [control]="declarationCv"
        [inputData]="{
          label: '1 DECLARATION',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: 'CVBV1'
        }"
      >
      </app-input-form>
      <app-input-form
        [control]="declarationAlf"
        [inputData]="{
          inputType: 'text',
          labelClass: 'hidden',
          placeholder: 'Alf. de Sal-Rei'
        }"
      >
      </app-input-form>

      <!-- Forms -->
      <app-input-form
        [control]="forms"
        [inputData]="{
          label: '3 Forms',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: '1'
        }"
      >
      </app-input-form>

      <!-- Load List -->
      <app-input-form
        [control]="loadList"
        [inputData]="{
          label: '4 Load List',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: '1'
        }"
      >
      </app-input-form>

      <!-- Manifest -->
      <app-input-form
        [control]="manifest"
        [inputData]="{
          label: 'Manifest',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- Items -->
      <app-input-form
        [control]="items"
        [inputData]="{
          label: '5 Items',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: '1'
        }"
      >
      </app-input-form>

      <!-- Nbr package -->
      <app-input-form
        [control]="nbrPackage"
        [inputData]="{
          label: '6 Nbr package',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: '1'
        }"
      >
      </app-input-form>

      <!-- Reference e number -->
      <app-input-form
        [control]="referenceNumber"
        [inputData]="{
          label: '7 Reference e number',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
    </div>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <div>
      <!-- 8 Consignee -->
      <app-input-form
        [control]="consignee"
        [inputData]="{
          label: '8 Consignee',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <app-input-form
        [control]="consigneeNo"
        [inputData]="{
          label: 'No.',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
    </div>

    <div class="grid grid-cols-3 gap-2">
      <app-input-form
        [control]="financial"
        [inputData]="{
          label: '9 Financial',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
      <app-input-form
        [control]="financialNo"
        [inputData]="{
          label: 'No.',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- City's last con., Trading city, Value details, C.A.P. -->
      <app-input-form
        [control]="citysLastCon"
        [inputData]="{
          label: 'City\'s last con.',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
      <app-input-form
        [control]="tradingCity"
        [inputData]="{
          label: '11 Trading city',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
      <app-input-form
        [control]="valueDetails"
        [inputData]="{
          label: '12 Value details',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
      <app-input-form
        [control]="cap"
        [inputData]="{
          label: '13 C.A.P.',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
    </div>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <div>
        <!-- 14 Declarant -->
    <app-input-form
    [control]="declarant"
    [inputData]="{
      label: '14 Declarant',
      inputType: 'text',
      labelClass: 'text-[0.8rem] text-[#E1E1E1]',
      placeholder: ''
    }"
  >
  </app-input-form>
  <app-input-form
    [control]="declarantNo"
    [inputData]="{
      label: 'No.',
      inputType: 'text',
      labelClass: 'text-[0.8rem] text-[#E1E1E1]',
      placeholder: ''
    }"
  >
  </app-input-form>

    </div>


    <div class="grid grid-cols-3 gap-2">
      <!-- 15 Country of Export -->
      <app-input-form
        [control]="countryOfExport"
        [inputData]="{
          label: '15 Country of export',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- 15 C.E. -->
      <app-input-form
        [control]="ceA"
        [inputData]="{
          label: '15 C.E. a',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
      <app-input-form
        [control]="ceB"
        [inputData]="{
          label: 'b',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- 17 C.D. -->
      <app-input-form
        [control]="cdA"
        [inputData]="{
          label: '17 C.D. a',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
      <app-input-form
        [control]="cdB"
        [inputData]="{
          label: 'b',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- 15 Country of Origin -->
      <app-input-form
        [control]="countryOfOrigin1"
        [inputData]="{
          label: '15 Country of origin',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
      <app-input-form
        [control]="countryOfOrigin2"
        [inputData]="{
          label: '15 Country of origin',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
    </div>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <div>
      <app-input-form
        [control]="identityMeansOfTransportArrival"
        [inputData]="{
          label: '18 Identity and nationality of means of transport at arrival',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <app-input-form
        [control]="identityActiveMeansOfTransportCrossing"
        [inputData]="{
          label:
            '21 Identity and nationality of active means of transport crossing...',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
    </div>
    <div class="grid grid-cols-3 gap-2"></div>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <div></div>
    <div class="grid grid-cols-3 gap-2">
      <!-- 28 Financial and Banking Data -->
      <app-input-form
        [control]="financialAndBankingData"
        [inputData]="{
          label: '28 Financial and banking data',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- Terms of Payment -->
      <div class="grid grid-cols-3 gap-4">
        <app-input-form
          [control]="termsOfPayment1"
          [inputData]="{
            label: 'Terms of payment',
            inputType: 'text',
            labelClass: 'text-[0.8rem] text-[#E1E1E1]',
            placeholder: ''
          }"
        >
        </app-input-form>
        <app-input-form
          [control]="termsOfPayment2"
          [inputData]="{
            label: '',
            inputType: 'text',
            labelClass: 'hidden',
            placeholder: ''
          }"
        >
        </app-input-form>
        <app-input-form
          [control]="termsOfPayment3"
          [inputData]="{
            label: '',
            inputType: 'text',
            labelClass: 'hidden',
            placeholder: ''
          }"
        >
        </app-input-form>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <div class="grid grid-cols-3 gap-2">
      <!-- Marks and numbers, Item number -->
      <app-input-form
        [control]="marksNumbersContainers"
        [inputData]="{
          label: 'Marks and numbers - Containers No(s) - Number and kind',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <app-input-form
        [control]="itemNumber"
        [inputData]="{
          label: '32 Item No.',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- Licence No, D.Val, D.Qty -->
      <app-input-form
        [control]="licenceNumber"
        [inputData]="{
          label: 'Licence No',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
      <app-input-form
        [control]="dVal"
        [inputData]="{
          label: 'D.Val',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: 'Enter value'
        }"
      >
      </app-input-form>

      <!-- dQty input field -->
      <app-input-form
        [control]="dQty"
        [inputData]="{
          label: 'D.Qty',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: 'Enter quantity'
        }"
      >
      </app-input-form>
    </div>

    <div class="grid grid-cols-3 gap-2">
      <!-- Commodity Code -->
      <app-input-form
        [control]="commodityCode"
        [inputData]="{
          label: '33 Commodity code',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- City, Origin, a and b -->
      <div class="grid grid-cols-2 gap-4">
        <app-input-form
          [control]="cityOrigA"
          [inputData]="{
            label: '34 City, orig. c... a',
            inputType: 'text',
            labelClass: 'text-[0.8rem] text-[#E1E1E1]',
            placeholder: ''
          }"
        >
        </app-input-form>
        <app-input-form
          [control]="cityOrigB"
          [inputData]="{
            label: 'b',
            inputType: 'text',
            labelClass: 'text-[0.8rem] text-[#E1E1E1]',
            placeholder: ''
          }"
        >
        </app-input-form>
      </div>

      <!-- Gross Mass, Prefer., Procedure, etc. -->
      <!-- Follow the same pattern for each of the new fields -->

      <!-- Summary Declaration -->
      <app-input-form
        [control]="summaryDeclaration"
        [inputData]="{
          label: '40 Summary declaration / Previous documents/L',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- Supplementary... -->
      <app-input-form
        [control]="supplementary"
        [inputData]="{
          label: '41 Supplementary...',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- Item Price -->
      <app-input-form
        [control]="itemPrice"
        [inputData]="{
          label: '42 Item Price',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>

      <!-- V.M. co... -->
      <app-input-form
        [control]="vmCo"
        [inputData]="{
          label: '43 V.M. co...',
          inputType: 'text',
          labelClass: 'text-[0.8rem] text-[#E1E1E1]',
          placeholder: ''
        }"
      >
      </app-input-form>
    </div>
  </div>


<app-button
(click)="null"
[buttonData]="{
  loading: false,
  textButton: 'Submit',
  classButton: 'mt-5 !bg-[#6761A8]',
  classText: '!text-[white]'
}"
></app-button>

  <!-- Continue with the same pattern for each of the new fields -->
</app-user-layout>



<div
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center text-white z-40"
  *ngIf="isModalOpen"
>
  <div class="bg-yv p-8 rounded shadow-md">
    <p *ngIf="froudAlert" class="text-2xl text-red-500 font-semibold mb-4">
      WARNING
    </p>
    <p *ngIf="!froudAlert" class="text-2xl text-green-500 font-semibold mb-4">
      SUCCESS
    </p>
    <p>{{ content }}</p>
    <!-- Close button -->
    <button
      (click)="closeModal()"
      class="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
    >
      Close
    </button>
  </div>
</div>
