import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { AssetsVisualization } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-assets-visualization',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent],
  templateUrl: './assets-visualization.component.html',
  styleUrls: ['./assets-visualization.component.scss'],
})
export class AssetsVisualizationComponent {
  @Input() vertical:boolean = true;
  @Input() aiChat:boolean = false;
  @Input() flex:boolean = true;
  @Input() assetsData: AssetsVisualization = {
    logo: 'assets/img/icon/logo-two.svg',
    assetsCardClass: '',
    firstText: 'Alaska, 5.342% 08/2027,',
    codeText: 'US011770U778',
    currencyType: 'USD',
  }
  @Input() assetsStyle: any = {
    iconLike: 'assets/img/icon/heart.svg',
    iconLikeButton: '!bg-yv-5%-gray',
    iconLikeClass: '!bg-yv-5%-gray',
    iconShare: 'assets/img/icon/arrow-two.svg',
    iconShareButton: '!bg-yv-5%-gray',
    iconShareClass: '!bg-yv-5%-gray',
    iconPlay: 'assets/img/icon/playground.svg',
    iconPlayButton: '!bg-yv-5%-gray',
    iconPlayClass: '!bg-yv-5%-gray',
  };

  @Output() btnLogoClick = new EventEmitter<void>();
  @Output() btnLikeClick = new EventEmitter<void>();
  @Output() btnShareClick = new EventEmitter<void>();
  @Output() btnPlayClick = new EventEmitter<void>();
  @Output() btnDetailsClick = new EventEmitter<void>();
  @Output() btnOrderClick = new EventEmitter<void>();

  onLogoClick() {
    this.btnLogoClick.emit();
  }
  onLikeClick() {
    this.btnLikeClick.emit();
  }
  onShareClick() {
    this.btnShareClick.emit();
  }

  onPlayClick() {
    this.btnPlayClick.emit();
  }

  onDetailsClick() {
    this.btnDetailsClick.emit();
  }

  onOrderClick() {
    this.btnOrderClick.emit();
  }
}
