<!-- <p class="text-primary bg-yv-50">example works!</p> -->

<div class="bg-yv min-h-screen min-w-screen ps-5">

  <app-yield-details />
  <app-wallet-card />
  <app-summary />
  <app-tabs />
  <br />
  <app-filter />
  <app-rounded-icon-buttom
    (btnClick)="handleButtonClick()"
    [buttonData]="iconButton"
  ></app-rounded-icon-buttom>
  <br />

  <br />

  <app-upload-photo></app-upload-photo>

  <br />

  <app-input-chat></app-input-chat>

  <br />
  <br />
  <br />

  <app-button></app-button>

  <br />

  <app-bonds-watchlist-assets>
    <app-details></app-details>
  </app-bonds-watchlist-assets>
  <br />

  <br />
  <app-bonds-company></app-bonds-company>

  <br />
  <app-assets-visualization>
    <app-details></app-details>
  </app-assets-visualization>

  <br />
  <app-card-chat>
    <div>
      <span class="text-left text-white block"
        >Lorem Ipsum is simply dummy text of the
      </span>
      <span class="text-left text-white block"
        >Lorem Ipsum is simply dummy text of the
      </span>
      <span class="text-left text-white block"
        >Lorem Ipsum is simply dummy text of the
      </span>
    </div>
  </app-card-chat>

  <br />

  <app-select-form></app-select-form>

  <br />

  <app-input-form></app-input-form>

  <br />
  <app-social-button></app-social-button>

  <br />
  <app-home-card (btnClick)="handleButtonClick()">
    <span>Claudio</span>
  </app-home-card>

  <app-details />
</div>
