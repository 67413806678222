<ngx-slick-carousel
  class="carousel absolute top-[8rem] left-3"
  #slickModal="slick-carousel"
  [config]="slideConfig"
  (afterChange)="currentSlideIndex = $event.currentSlide"
>
  <div ngxSlickItem *ngFor="let slide of slides">
    <div class="flex items-center justify-center">
      <img src="{{ slide.img }}" alt="" class="w-[35rem] h-[39rem]" />
    </div>
    <span class="block text-[3rem] font-[400] text-center ml-[-1.5rem]"
      >Fixed Income</span
    >
    <span class="block text-[1.5rem] font-[400] text-center ml-[-1.5rem]"
      >investing marketplace</span
    >
  </div>
</ngx-slick-carousel>

<div class="flex gap-12 items-center justify-center p-8 mt-[6.5rem]">
  <app-rounded-icon-buttom
    (btnClick)="slickModal.slickPrev()"
    [buttonData]="{
      iconUrl: 'assets/img/icon/right-arrow.svg',
      btnClass: 'bg-transparent border rotate-180'
    }"
  />
  <div class="custom-dots">
    <span
      *ngFor="let slide of slides; let i = index"
      class="dot {{ currentSlideIndex === i ? 'active' : '' }}"
      (click)="slickModal.slickGoTo(i)"
    ></span>
  </div>
  <app-rounded-icon-buttom
    (btnClick)="slickModal.slickNext()"
    [buttonData]="{ iconUrl: 'assets/img/icon/right-arrow.svg' }"
  />
</div>

<!-- <div class="fixed bottom-0 inset-x-0"></div> -->
