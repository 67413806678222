<div
  class="rounded-3xl justify-between bg-yv-primary-70 w-full"
  style="
    padding-top: 1.2rem;
    padding-right: 1rem;
    padding-bottom: 1.2rem;
    padding-left: 1rem;
  "
>
  <div class="flex mb-5">
    <app-rounded-icon-buttom
      [buttonData]="{
        iconUrl: bondsData.iconHeader,
        iconClass: bondsData.iconHeaderClass,
      }"
      class="me-5"
    ></app-rounded-icon-buttom>
    <div>
      <span
        class="block text-[#98F2B9]  {{ bondsData.firstTextHeaderClass }}"
        style="
          font-family: Poppins;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.125rem;
          opacity: 0.8;
        "
        >{{ bondsData.firstTextHeader }}</span
      >
      <span
        class="block text-white  {{ bondsData.secondTextHeaderClass }}"
        style="
          font-family: Poppins;
          font-weight: 400;
          font-size: 1.25rem;
          line-height: 1.875rem;
        "
        >{{ bondsData.secondTextHeader }}</span
      >
    </div>
  </div>

  <div
    class="border-t-2 border-solid border-gray-700 mb-[1.875rem] mt-[1.875rem]"
  ></div>

  <app-bonds-company
    [bondsCompanyData]="{
      logo: bondsData.iconBonds,
      firstText: bondsData.firstTextBonds,
      secondText: bondsData.secondTextBonds,
      firstTextClass: bondsData.firstTextBondsClass,
      secondTextClass: bondsData.secondTextBondsClass,
    }"
  ></app-bonds-company>

  <ng-content></ng-content>

  <div class="flex mt-5 mb-5 justify-between">
    <app-rounded-icon-buttom
      (btnClick)="onLikeClick()"
      [buttonData]="{ iconUrl: 'assets/img/icon/heart.svg' }"
      class="me-5"
    ></app-rounded-icon-buttom>

    <div class="space-x-4">
      <button
        style="
          font-weight: 700;
          font-size: 1rem;
          letter-spacing: 0.1rem;
          line-height: 1.25rem;
          color: #978ef5;
        "
        (click)="onDetailsClick()"
      >
        Details
      </button>

      <button
        class="bg-yv-primary rounded-lg text-white"
        style="
          padding: 1rem, 1.5rem, 1rem, 1.5rem;
          width: 8.688rem;
          height: 3.25rem;
          font-family: Poppins;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.25rem;
        "
        (click)="onAddToCartClick()"
      >
        Add to cart
      </button>
    </div>
  </div>
</div>
