import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
interface Steps {
  title: string,
  checked?: boolean | null,
}
@Component({
  selector: 'app-stepper-counter',
  templateUrl: './stepper-counter.component.html',
  styleUrls: ['./stepper-counter.component.scss']
})
export class StepperCounterComponent implements OnInit {
  @Input() full = true
  @Input() quiz: boolean = false;
  @Input() lecture: boolean = false;
  @Input() steps: Steps[] = [{ title: 'None', checked: false }];
  @Input() quizAnswer: boolean | null = null
  @Input() current: number = 1;
  @Input() finalStep: boolean = false;
  @Input() nextQuiz: boolean = true;
  @Input() lectureView: boolean = false;
  @Input() courseDone: string = "8";
  @Input() courseTotal: string = "12";
  @Input() courseTitle: string = "Introduction to Fixed Income";
  @Output() nextClick = new EventEmitter<void>();
  @Output() prevClick = new EventEmitter<void>();
  @Output() fontDecrease = new EventEmitter<void>();
  @Output() fontIncrease = new EventEmitter<void>();
  @Output() fontReset = new EventEmitter<void>();
  @Input() prevText: string = ""
  @Input() nextText: string = ""
  @Input() fontSize: number = 1

  ngOnInit(): void {

    setTimeout(() => {
      //console.log(this.steps, "content stepper")
    }, 900);
  }

  next() {
    this.nextClick.emit();
  }

  prev() {
    this.prevClick.emit();
  }
  handleFontIncrease() {
    this.fontIncrease.emit();
  }

  handleFontDecrease() {
    this.fontDecrease.emit();
  }
  handleFontReset() {
    this.fontReset.emit();
  }
  allStepsChecked(): boolean {
    //console.log(this.steps,"content Stepper checks")
    return this.steps.every(step => step.checked === true);
  }

}
