import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { COURSES, MODULES, MODULES_BY_CONTENT } from './queries'
import { Observable, Subject, map } from 'rxjs';
import { LOGIN } from './queries/login';
import { EducationRepoRest } from '../adapters/education/RestAdapter';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class EducationService extends EducationRepoRest implements OnInit {
  tokken: string = "";
  selectedCourse?: number;
  selected?: any = {
    type: "lecture"
  };
  private selectedSubject: Subject<any> = new Subject<any>();
  constructor(private apolloClient: Apollo, private httpClient: HttpClient) {
    super(httpClient);
    this.loadContent()
  }
  ngOnInit(): void {

  }

  login(then: any) {
    //console.log("thisDAta: ")
    this.apolloClient
      .mutate<any>({
        mutation: LOGIN,
        variables: {
          "user": "Yieldvestor",
          "password": "Yieldvestor"
        },
      }).subscribe((data) => {
        this.tokken = data.data.login.jwt
        if (then) then();
      })
  }


  select(lecture: any) {
    this.selected = lecture
    this.selectedSubject.next(this.selected);
  }
  onYourSelectedChange(): Observable<any> {
    return this.selectedSubject.asObservable();
  }

  private content: any[] = [];
  private contentSubject = new Subject<any>();
  private contentLecture: any[] = [];
  private currentStep: number = 0;

  saveContent(data: any) {
    this.content = data;
    this.contentLecture = data.modules.lectures
    localStorage.setItem('content', JSON.stringify(data));
    localStorage.setItem('lecture', JSON.stringify(data.modules.lectures));
    this.selected = {
      type: "lecture",
      module: 1,
      id: 1,
      content: this.contentLecture
    };
    this.contentSubject.next(this.content);
    //console.log("EducationService SavedContent", this.content);
    // console.log("EducationService SavedContent Lecture", this.contentLecture);
    // console.log("EducationService SavedContent Selected", this.selected);
  }
  getContentSubjectObservable(): Observable<string> {
    return this.contentSubject.asObservable();
  }
  loadContent() {
    const storedData = localStorage.getItem('content');
    const storedDataLecture = localStorage.getItem('lecture');
    if (storedData) {
      this.content = JSON.parse(storedData);
    }
    if (storedDataLecture) {
      this.contentLecture = JSON.parse(storedDataLecture);
      this.selected = {
        type: "lecture",
        module: 1,
        id: 1,
        content: this.contentLecture
      };
    }
  }

  getContentData() {
    return this.content;
  }
  getContentLectureData() {
    return this.contentLecture;
  }
  getCurrentStep() {
    return this.currentStep;
  }

  private lectureID?: string;
  private lectureIDSubject = new Subject<string>();

  setLectureID(newLectureID: string): void {
    this.lectureID = newLectureID;
    this.lectureIDSubject.next(this.lectureID);
  }

  getLectureIDObservable(): Observable<string> {
    return this.lectureIDSubject.asObservable();
  }
  private progressPercentage?: string;
  private progressPercentageSubject = new Subject<string>();

  setProgressPercentage(progress: string): void {
    this.progressPercentage = progress;
    this.progressPercentageSubject.next(this.progressPercentage);
  }

  getProgressPercentageObservable(): Observable<string> {
    return this.progressPercentageSubject.asObservable();
  }
  private progressArray?: any[];
  private progressArraySubject = new Subject<any[]>();

  setProgressArray(progress: any[]): void {
    this.progressArray = progress;
    this.progressArraySubject.next(this.progressArray);
  }

  getProgressArrayObservable(): Observable<any[]> {
    return this.progressArraySubject.asObservable();
  }
  private quizArray?: any[];
  private quizArraySubject = new Subject<any[]>();

  setQuizArray(progress: any[]): void {
    this.quizArray = progress;
    this.quizArraySubject.next(this.quizArray);
  }

  getQuizArrayObservable(): Observable<any[]> {
    return this.quizArraySubject.asObservable();
  }



}
