import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExampleComponent } from './views/example/example.component';
import { HomeComponent } from './views/home/home.component';

import { HighchartsChartModule } from 'highcharts-angular';
import { CarouselComponent } from './views/auth/login/carousel/carousel.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { ButtonComponent } from './components/button/button.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { InputFormComponent } from './components/input-form/input-form.component';
import { RoundedIconButtomComponent } from './components/rounded-icon-buttom/rounded-icon-buttom.component';
import { SocialButtonComponent } from './components/social-button/social-button.component';
import { SharedModule } from './shared/shared.module';
import { LandpageComponent } from './views/landpage/landpage.component';
import { ScatterChartComponent } from './components/charts/scatter-chart/scatter-chart.component';
import { ColumnChartStackingComponent } from './components/charts/column-chart-stacking/column-chart-stacking.component';
import { ColumnRangeChartComponent } from './components/charts/column-range-chart/column-range-chart.component';
import { GaugeChartComponent } from './components/charts/gauge-chart/gauge-chart.component';
import { CylinderChartComponent } from './components/charts/cylinder-chart/cylinder-chart.component';
import { SplineChartComponent } from './components/charts/spline-chart/spline-chart.component';
import { AreaChartComponent } from './components/charts/area-chart/area-chart.component';
import { FirstStepsComponent } from './views/first-steps/first-steps.component';
import { HomeCardComponent } from './components/home-card/home-card.component';
import { DetailsComponent } from './components/details/details.component';
import { CardChatComponent } from './components/card-chat/card-chat.component';
import { BondsComponent } from './views/home/bonds/bonds.component';
import { AssistantComponent } from './views/assistant/assistant.component';
import { RecentPromptsComponent } from './views/assistant/recent-prompts/recent-prompts.component';
import { PortfolioComponent } from './views/portfolio/portfolio.component';
import { YieldStateCardComponent } from './components/yield-state-card/yield-state-card.component';
import { AssetsVisualizationComponent } from './components/assets-visualization/assets-visualization.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { BondsCompanyComponent } from './components/bonds-company/bonds-company.component';
import { BondsWatchlistAssetsComponent } from './components/bonds-watchlist-assets/bonds-watchlist-assets.component';
import { IdentityVerificationComponent } from './views/auth/identity-verification/identity-verification.component';
import { InputChatComponent } from './components/input-chat/input-chat.component';
import { ResponsesComponent } from './views/assistant/responses/responses.component';
import { YieldListComponent } from './views/assistant/responses/views/yield-list/yield-list.component';
import { TextComponent } from './views/assistant/responses/views/text/text.component';
import { ListComponent } from './views/assistant/responses/views/list/list.component';
import { ComparisonComponent } from './views/assistant/responses/views/comparison/comparison.component';
import { ModuleListComponent } from './views/assistant/responses/views/module-list/module-list.component';
import { ChatComponent } from './views/assistant/chat/chat.component';
import { LectureComponent } from './views/assistant/responses/views/lecture/lecture.component';
import { QuizComponent } from './views/assistant/responses/views/quiz/quiz.component';
import { ChartsExampleComponent } from './views/charts-example/charts-example.component';
import { SelectFormComponent } from './components/select-form/select-form.component';
import { UploadPhotoComponent } from './components/upload-photo/upload-photo.component';
import { SceenarioComponent } from './views/assistant/responses/views/sceenario/sceenario.component';
import { ModelComponent } from './views/assistant/responses/views/model/model.component';
import { ProfileComponent } from './views/user/profile/profile.component';
import { PersonalInformationComponent } from './views/user/profile/personal-information/personal-information.component';
import { DocumentsComponent } from './views/user/profile/documents/documents.component';
import { PaymentMethodComponent } from './views/user/profile/payment-method/payment-method.component';
import { SettingsComponent } from './views/user/profile/settings/settings.component';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { LevelComponent } from './components/level/level.component';
import { ObjectivesComponent } from './views/assistant/responses/views/objectives/objectives.component';
import { BackAndForwardComponent } from './components/back-and-forward/back-and-forward.component';
import { AdviceComponent } from './views/assistant/advice/advice.component';
import { SummaryComponent } from './components/summary/summary.component';
import { WalletCardComponent } from './components/wallet-card/wallet-card.component';
import { YieldDetailsComponent } from './views/assistant/responses/views/yield-details/yield-details.component';
import { SortViewComponent } from './components/sort-view/sort-view.component';
import { ChatWidgetComponent } from './views/assistant/chat-widget/chat-widget.component';
import { BarsChartComponent } from './components/charts/bars-chart/bars-chart.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import Keycloak from 'keycloak-js';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './services/guard';
import { ChatDemoComponent } from './views/assistant/chat/chat-demo.component';
import { EducationComponent } from './views/education/education.component';
import { LecturePageComponent } from './views/education/lecture/lecture.component';
import { GraphQLModule } from './graphql.module';
import { CourseComponent } from './views/education/course/course.component';
import { AuthInterceptor } from './services/http-interceptor.service';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { StepperVerticalComponent } from './shared/stepper-vertical/stepper-vertical.component';
import { ResultComponent } from './components/onboarding/result/result.component';
import { GougeChartIndicatorComponent } from './components/charts/gouge-chart-indicator/gouge-chart-indicator.component';
import { PerformanceComponent } from './components/performance/performance.component';
import { PerformanceCardComponent } from './components/performance/performance-card/performance-card.component';
import { ScenarioTestComponent } from './components/performance/scenario-test/scenario-test.component';
import { ParametersComponent } from './components/performance/parameters/parameters.component';


@NgModule({
  declarations: [
    AppComponent,
    ExampleComponent,
    HomeComponent,
    LandpageComponent,

    CarouselComponent,

    FirstStepsComponent,
    BondsComponent,
    AssistantComponent,
    RecentPromptsComponent,
    PortfolioComponent,
    IdentityVerificationComponent,
    ResponsesComponent,
    YieldListComponent,
    TextComponent,
    ListComponent,
    ComparisonComponent,
    ModuleListComponent,
    ChatComponent,
    ChatDemoComponent,
    LectureComponent,
    QuizComponent,
    ChartsExampleComponent,
    EducationComponent,
    SceenarioComponent,
    ModelComponent,
    ProfileComponent,
    PersonalInformationComponent,
    DocumentsComponent,
    PaymentMethodComponent,
    SettingsComponent,
    ObjectivesComponent,
    AdviceComponent,
    YieldDetailsComponent,
    ChatWidgetComponent,
    LecturePageComponent,
    CourseComponent,
    OnboardingComponent,
    StepperVerticalComponent,
    ResultComponent,
    GougeChartIndicatorComponent,
    PerformanceComponent,
    PerformanceCardComponent,
    ScenarioTestComponent,
    ParametersComponent
  ],
  imports: [
    KeycloakAngularModule,
    SortViewComponent,
    HighchartsChartModule,
    HttpClientModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    RoundedIconButtomComponent,
    SlickCarouselModule,
    ButtonComponent,
    InputFormComponent,
    SocialButtonComponent,
    LineChartComponent,
    ScatterChartComponent,
    ColumnChartStackingComponent,
    ColumnRangeChartComponent,
    GaugeChartComponent,
    CylinderChartComponent,
    SplineChartComponent,
    AreaChartComponent,
    BarsChartComponent,
    HomeCardComponent,
    DetailsComponent,
    CardChatComponent,
    YieldStateCardComponent,
    AssetsVisualizationComponent,
    DetailsComponent,
    TabsComponent,
    BondsCompanyComponent,
    BondsWatchlistAssetsComponent,
    InputChatComponent,
    SelectFormComponent,
    UploadPhotoComponent,
    RangeSliderComponent,
    BackAndForwardComponent,
    SummaryComponent,
    WalletCardComponent,
    GraphQLModule,
  ],
  providers: [
    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}