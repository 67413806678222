import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { SelectForm, SelectItems } from 'src/app/models/ComponentInput';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-select-form',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent,ReactiveFormsModule],
  templateUrl: './select-form.component.html',
  styleUrls: ['./select-form.component.scss'],
})
export class SelectFormComponent {
  
  @Input() control: FormControl = new FormControl()
  @Input() selectData: SelectForm = {
    selectFormClass: '',
    label: 'Label',
    labelClass: '',
    selectClass: '',
    id: '',
    name: '',
    optionClass:""
  };

  @Input() default: string = "Select"
  @Input() items: SelectItems[] = [{ value: 'A001', name: 'Cláudio Gomes' }];
  @Input() showMode:boolean = false

  @Output() selectChange = new EventEmitter<void>();

  onSelectChange() {
    this.selectChange.emit();
  }
}
