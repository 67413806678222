
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import Robo from "./RoboRepo";

@Injectable()
export class RoboServiceRepoRest implements Robo {

    url = "https://dev.yieldvestor.com/roboadv/inv/api/v1";
    constructor(private http: HttpClient) {

    }


    get(type: string): Observable<any | any[]> {
        return this.http.get<any | any[]>(this.url + `${type}`).pipe(
            map((response: any) => {
                return response
            })
        );
    }
    post(type:string,payload:any): Observable<any | any[]> {

        return this.http.post<any | any[]>(this.url + `${type}`,payload).pipe(
            map((response: any) => {
                return response
            })
        );
    }
}
