import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import {samples} from './sample'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  currentPrompt: string = "";
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private chatService: ChatService,
    ) { }

  ngOnInit() {


  
    // const assignedString = "What is the color of the sky?";
    
    // const mostSimilarObject = this.findMostSimilarString(this.array, assignedString);
    // console.log(mostSimilarObject);
  
      
      //const url = 'assets/samples2.json'; // Replace with your desired URL
      this.route.queryParams.subscribe(params => {
        const prompt = params['prompt'];
        const headers = new HttpHeaders();
        headers.append('Authorization', 'Basic  ' + btoa("developer" + ':' + '_c\=q![p2iC!i":?@B'));
  
      this.fetchData({q:prompt,samples:samples,q_a:[]},headers);
      });
    }
    fetchData(p: any,headers: HttpHeaders) {
      this.chatService.setData(samples)
           // Access the JSON array
          samples.forEach((item,i) => {
           //console.log(i + "=> " +item.content);
          });
        if(p.q)this.onSend(p.q)
        else this.chatService.loading = false;

    }


  selectedTab: string = 'youCanAsk';
  handlePayment() {
    if (this.selectedTab === '') {
      this.selectedTab = 'youCanAsk';
    } else {
      this.selectedTab = '';
    }
  }
  handleSuggestions() {
    if (this.selectedTab !== 'youCanAsk') {
      this.selectedTab = 'youCanAsk';
    }
  }
  onSend(prompt?:string){
    if(prompt?.trim() != ""){
    this.viewportScroller.scrollToAnchor("footer")
    this.chatService.onSend(prompt)
    this.currentPrompt = ""
    //this.handlePayment()
    }
  }

  scrollToBottom() {
  }

  getSamples() {
    return this.chatService.samples
  }
  getPrompts() {
    return this.chatService.prompts
  }

}
