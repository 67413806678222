import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent {
  creditInfos: any[] = [
    { number: "**** **** **** 8945", expiration: "Expiry 10 / 26", cardIcon: "assets/img/icon/credit-master.svg" },
    { number: "**** **** **** 8945", expiration: "Expiry 10 / 26", cardIcon: "assets/img/icon/credit-visa.svg" },
  ]
  selectedTab: string = 'paymentMethod';
  paymentStep: string = 'cardData';
  steps = [
    { title: 'Card Data', checked: true },
    { title: 'Address', checked: false }
  ];
  current: number = 0;

  handlePayment() {
    if (this.selectedTab === '') {
      this.selectedTab = 'paymentMethod';
    } else {
      this.selectedTab = '';
    }
  }

  next() {
    if (this.paymentStep === 'cardData') {
      this.steps[this.current+1].checked = true;
      this.paymentStep = 'address';
      this.current = 1;
    }
  }

  prev() {
    if (this.paymentStep === 'address') {
      this.steps[this.current].checked = false
      this.paymentStep = 'cardData';
      this.current = 0;
      
    }
  }


}