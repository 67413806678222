import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-carousel',
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss'],
})
export class VideoCarouselComponent {
  @Input() home:boolean = false;
  slides = [
    { img: 'assets/img/icon/coroucel001.svg' },
    { img: 'assets/img/icon/coroucel002.svg' },
    { img: 'assets/img/icon/coroucel002.svg' },
    { img: 'assets/img/icon/coroucel002.svg' },
  ];
  slideConfig = { arrows: false, slidesToShow: 3, slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  // photoTest: string = 'assets/img/icon/coroucel001.svg';

  addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e: any) {
    console.log('slick initialized');
  }

  breakpoint(e: any) {
    console.log('breakpoint');
  }

  afterChange(e: any) {
    console.log('afterChange');
  }

  beforeChange(e: any) {
    console.log('beforeChange');
  }
}
