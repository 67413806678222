<div
  class=" w-auto lg:w-[400px] h-auto bg-yv-primary-70 rounded-3xl justify-center text-center"
  style="padding-top: 1.5rem; padding-bottom: 1.5rem"
>
  <div
    class="bg-[#6761A81A] space-x-2 rounded-full justify-between items-center inline-flex mb-8 p-[0.5rem] gap-4"
  >
    <div class="flex justify-center items-center mr-4">
      <app-rounded-icon-buttom
        [buttonData]="{
          iconUrl: 'assets/img/icon/person.svg',
          iconClass: ''
        }"
        class="me-5"
      ></app-rounded-icon-buttom>
      <span class="text-white">xxx.png</span>
    </div>
    <app-rounded-icon-buttom
      [buttonData]="{
        iconUrl: 'assets/img/icon/trash.svg',
        iconClass: ''
      }"
      class="me-5"
    ></app-rounded-icon-buttom>
  </div>

  <span
    class="block text-yv-primary h-[2rem]"
    style="
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
    "
    >Click to Upload</span
  >
  <span class="block text-white text-info">or drag and drop</span>
  <span class="block text-white text-info">PNG or JPD (max 1 GB)</span>
</div>
