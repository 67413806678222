<app-tabs [tabsData]="{active:'!bg-[#978EF5]',class:'gap-0 bg-[#FFFFFF11] !p-1 mb-9 rounded-full',classTab:'border-0'}"

[tabs]="tabs"/>
<ng-container [ngSwitch]="currentTab">

<app-comparisons-table *ngSwitchCase="'table'" [columns]="content.columns" [assets]="content.assets" [data]="content.data" />
<app-area-chart *ngSwitchCase="'graph'"></app-area-chart>
<app-column-range-chart *ngSwitchCase="'yield'"></app-column-range-chart>
</ng-container>

