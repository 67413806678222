<div class="md:flex rounded-2xl gap-10 bg-[#181C31] p-6 -mx-6 my-10">
    <div class="md:w-5/12 ">
        <div class="text-[#E1E1E1] font-normal text-[32px] pb-4">Bonds</div>
        <div class="text-[#B3B3B3] font-normal text-[18px] max-w-xs">Discover municipal, corporate and government bonds.
        </div>
        <div class="flex py-10 gap-8">
            <app-button [buttonData]="{textButton:'Explore Investments'}" />
            <app-button [buttonData]="{textButton:'Learn  more',
                classButton: '!bg-transparent !text-[black]',
                classText: '!text-[#978EF5]'
              }"
            />
        </div>
    </div>
    <div class="md:w-7/12 rounded-2xl">
        <app-assets-visualization [vertical]="false" [flex]="false">
            <app-details [details]='[
            {"key":"Issue Date","value":"04/2020"},
            {"key":"Issue Amount ","value":"51,500,000.00"},
            {"key":"Interest rate","value":"5.00%"},
            {"key":"Yield","value":"5.99%"},
            {"key":"Instrument type","value":"Municipal Bonds"},
            {"key":"Sector","value":"Transportation"},
            {"key":"Issuer","value":"Texas"}
    ]' />
          </app-assets-visualization>
    </div>
</div>