import { Component } from '@angular/core';

@Component({
  selector: 'app-recent-prompts',
  templateUrl: './recent-prompts.component.html',
  styleUrls: ['./recent-prompts.component.scss']
})
export class RecentPromptsComponent {
  prompts: any[] = [
    [
    { prompt: "Could you explain what fixed income investments are and how they work?", url: '/assistant/chat',title: "Learn", img: "assets/img/icon/assistantCircuit.svg"  },
    { prompt: "Can you explain the key differences between fixed income investments and equities?", url: '/assistant/chat' },
    { prompt: "Could you help me sign up for a course on fixed income investments?", url: '/assistant/chat' },
    ]
    ,
    [
    { prompt: "Can you help me find out which municipal bonds offer the highest and lowest yields?", url: '/assistant/chat', title: "Discover", img: "assets/img/icon/assistantBar.svg"  },
    { prompt: "Can you suggest some good BBB-rated bonds to consider?", url: '/assistant/chat' },
    { prompt: "Please show me the top 10 sectors in my portfolio.", url: '/assistant/chat' },
    ]
    ,
    [
    { prompt: "Could you help me with some advice on choosing the right investment?", url: '/assistant/chat', title: "Invest", img: "assets/img/icon/assistantRobot.svg"  },
    { prompt: "Can you help me find out how much of California I own?", url: '/assistant/chat' },
    { prompt: "Could you help me assess how much risk is in my investment portfolio?", url: '/assistant/chat' },
    ]
    ,

  ]
  // Assuming this is inside your Angular component class
  currentIndex: number = 0; // Set the initial index

  changeIndex(newIndex: number) {
    this.currentIndex = newIndex;
  }

}
