
<div class="bg-yv text-white min-h-screen">
    <div class="flex pt-10 pb-10 justify-center" >
        <img [routerLink]="'/'" src="assets/img/icon/fulllogo.svg" class="cursor-pointer"  /></div>

<div class="w-full text-center">
    <div class="font-normal text-[48px]">Welcome John Doe</div>
    <div class="font-normal text-[18px]">Ready to start investing?</div>
</div>
<app-stepper [current]="currentStep" [steps]="steps" (nextClick)="changeStep(1,currentStep)" (prevClick)="changeStep(-1,currentStep)">
<div *ngIf="currentStep == 0" class="max-w-screen-md mx-auto">
    <div class="font-bold text-[22px] p-10 max-w-lg mx-auto text-center">How would you describe your overall investment experience?</div>
    <div class="">
        <div class=" grid md:grid-cols-3 gap-8">
            <div (click)="setR('experienceLevelId',i);changeStep(1,currentStep)" *ngFor="let level of levels, let i = index" class=" {{result.experienceLevelId == i?'bg-[#3C3B6B]':'bg-[#FFFFFF1A]'}} hover:bg-[#3C3B6B] cursor-pointer duration-100 rounded-2xl p-4">
                <app-rounded-icon-buttom [buttonData]="{iconUrl:level.icon}" />
                
                <div class="text-[20px] font-bold py-2">{{level.title}}</div>
                <div class="">{{level.desc}}</div>
            </div>
        </div>

    </div>

</div>

<div *ngIf="currentStep == 1" class="max-w-screen-md mx-auto">
    <div class="font-bold text-[22px] p-10 max-w-lg mx-auto text-center">Have you invested in fixed income securities before?</div>
    <div class="">
        <div class="grid md:grid-cols-2 gap-8 max-w-xl mx-auto">
            <label for="{{invested.title}}" (click)="setR('investmentExperienceId',i);changeStep(1,currentStep)" *ngFor="let invested of investeds, let i = index" class=" {{result.investmentExperienceId == i?'bg-[#3C3B6B]':'bg-[#FFFFFF1A]'}}  hover:bg-[#3C3B6B] cursor-pointer duration-100 rounded-2xl p-4 flex items-start gap-4 min-h-[80px]">
                <input type="radio" class="border-[#B3B3B3] border bg-transparent m-1" id="{{invested.title}}" name="invested" />
                <label for="{{invested.title}}" class="">{{invested.desc}}</label>
            </label>
        </div>

    </div>

</div>

<div *ngIf="currentStep == 2" class="max-w-screen-md mx-auto">
    <div class="font-bold text-[22px] p-10 max-w-xl mx-auto text-center">What is your investment objective when considering fixed income securities?</div>
    <div class="">
        <div class="grid md:grid-cols-2 gap-8">
            <div (click)="setR('investmentObjectiveId',i);
            'objectives[i].active = !objectives[i].active'" 
            *ngFor="let obj of objectives, let i = index" class=" {{result.investmentObjectiveId == i?'bg-[#3C3B6B]':'bg-[#FFFFFF1A]'}} cursor-pointer duration-100 rounded-2xl p-4 flex gap-8 min-h-[104px]">
                <app-rounded-icon-buttom [buttonData]="{iconUrl:obj.icon}" />
                <div class="">{{obj.desc}}</div>
            </div>
        </div>

    </div>

</div>
</app-stepper>

<app-footer />
</div>