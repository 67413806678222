<body
    class="
     md:hidden
      antialiased
      bg-gradient-to-r
      from-pink-300
      via-purple-300
      to-indigo-400
    "
  >
  <header>
     <nav
        class="
          flex flex-wrap
          items-center
          justify-between
          w-full
          py-4
          md:py-0
          px-4
          text-lg text-gray-700
          {{dark?'bg-yv':'bg-white'}}
        "
        id="m-nav"
      >
       <div id="login" class="flex flex-grow md:hidden justify-between ">
        <div [routerLink]="'/'" class="p-4 z-50"><app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/logo.svg',btnClass:''}" /></div>
        <div class="flex-grow"></div>
        <app-button
        *ngIf="!dark &&  !authenticated"
          (click)="goToLogin()"
          [buttonData]="{
            textButton: 'Login',
            classButton: '!bg-transparent  !text-black',
            classText: dark?'text-white':'text-yv-base',
          }"
        />
        <div [routerLink]="'/user/profile'" *ngIf="dark" class="p-4">
          <app-rounded-icon-buttom  [buttonData]="{iconUrl:'assets/img/icon/person.svg',iconClass:'h-[1.5rem] ',btnClass:'mr-[1rem] z-50'}" />
        </div>
        </div>
          <div id="logsign" class="!hidden">
          <div class="flex gap-8 my-4">
            <app-rounded-icon-buttom [routerLink]="'/'" [buttonData]="{iconUrl:'assets/img/icon/logo.svg',btnClass:'z-50'}" />
            <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/search.svg',btnClass:''}" />
            <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/cart.svg',btnClass:''}" />
            <div class="flex-grow"></div>
            <span id="close-menu" ><app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/cross.svg',btnClass:''}" /></span>

          </div>
          <div class="inline-flex mt-10">
            <app-button
            *ngIf="!authenticated"
            (click)="goToLogin()"
            [buttonData]="{
              textButton: 'Login',
              classButton: '!bg-transparent !text-black',
              classText: '!text-white',
            }"
          />
          <app-button
          *ngIf="!authenticated"
            (click)="goToRegister()"
            [buttonData]="{
              textButton: 'Sign Up',
              classButton: '',
              classText: ' ',
            }"
          />
          </div>
          
        </div>
       
        <app-rounded-icon-buttom id="menu-button" [buttonData]="{iconUrl:'assets/img/icon/menu.svg',iconClass:'h-[1.5rem] ' +!dark?'brightness-100 invert':''}" />
       
       <div class="hidden w-full md:items-center md:w-auto" id="menu">
          <ul
            class="
              pt-4
              text-base text-gray-700
              md:flex
              md:justify-between 
              md:pt-0"
          >
                <li
                *ngFor="let item of sideMenu; let i = index"
                class="flex py-8 {{i%2 != 0?'border-y border-[#4A4C5780] ':''}} items-center gap-4 cursor-pointer"
                (click)="onMenuClick(i)"
              >
                <img *ngIf="!item.ative" src="assets/img/icon/menu-icon.svg" alt="" />
                <img
                  *ngIf="item.ative"
                  src="assets/img/icon/menu-icon-ative.svg"
                  alt=""
                />
                <div
                  [ngClass]="item.ative ? 'text-[#978EF5]' : 'text-[#F9F9F9]'"
                  class="text-[14px] font-normal"
                >
                  {{ item.name }}
                </div>
            </li>
          </ul>
        </div>
    </nav>
  </header>
</body>
