import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input()
  filters:any = [
    {type:"slider",title:"Investment Horizon F(Years)?",data:[]},
    {type:"level"},
    {type:"amount",title:"Initial Investment"},
    {type:"sort",title:""},
    {type:"select",title:"",default:"",items:[]}
  ]
  ngOnInit(): void {
    //
  }
  getSelectDefault(filter: any): string {
    if (filter.type === "select") {
      return filter.default || ""; // Return the default value if present, or an empty string if not
    }
    return "";
  }
}
