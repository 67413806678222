import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { CardChat } from 'src/app/models/ComponentInput';
import { ChatService } from 'src/app/services/chat.service';
import { ButtonComponent } from '../button/button.component';
import { FormsModule } from '@angular/forms';
import { ResponseService } from 'src/app/services/response.service';

@Component({
  selector: 'app-card-chat',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent, ButtonComponent, FormsModule],
  templateUrl: './card-chat.component.html',
  styleUrls: ['./card-chat.component.scss'],
})
export class CardChatComponent implements OnInit {
  @Input() CardChatData: CardChat = {
    cardClass: '',
  };
  @Input() text?: any
  @Input() ogText?: any
  @Input() type?: any
  @Input() index?: any
  @Input() prompts?: any[]
  @Input() content!: any[]
  @Input() iconLeftUrl: string = 'assets/img/icon/logo_alt.svg';
  @Input() iconLeftClass: string = '!bg-yv-5%-gray';
  @Input() iconRightUrl: string = '';
  @Input() iconRightClass?: string = undefined;
  editMessage: boolean = false;
  saveMessage: boolean = false;
  isResponseTriggered: boolean = false;

ngOnInit(): void {
    if(this.type ==="you"){
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'auto'
      })
    }
    this.responseService.response$.subscribe(() => {
      // Set a flag to indicate that the response has been triggered
      this.isResponseTriggered = true;
    });
}

  constructor(private chatService: ChatService, private responseService: ResponseService) { }
  handleVote(index: number, vote: string) {
    this.chatService.updateVote(index, vote);
  }
  handleEditMessage() {
    this.editMessage = !this.editMessage
    this.text = this.ogText[0]
  }
  handleSaveMessage(index: number, prompt: string) {
    if (typeof (prompt) === "object") { this.chatService.resetChatAndSendNewPrompt(index, prompt[0]) }
    if (typeof (prompt) === "string" && prompt !== "") { this.chatService.resetChatAndSendNewPrompt(index, prompt),this.textAreaPlaceholder ="" }
    //this.chatService.resetChatAndSendNewPrompt(index, prompt);
    if (prompt !== "") {
     // console.log(index, "content", typeof (prompt), prompt, "data")
      this.editMessage = !this.editMessage
      this.saveMessage = !this.saveMessage
      setTimeout(() => {
        this.saveMessage = !this.saveMessage
      }, 1000);

    } else{this.textAreaPlaceholder ="Cannot Send an Empty Message!"}
  }
  textAreaContent: string = this.text || '';
  textAreaPlaceholder: string = '';

  // Function to adjust the height of the textarea
  adjustTextAreaHeight(event: any): void {
    const textarea = event.target;

    // Set the minimum height for the textarea (e.g., 48px)
    const minHeight = 72;

    // Calculate the new height based on the content and minimum height
    textarea.style.height = 'auto'; // Reset the height to auto
    textarea.style.height = Math.max(minHeight, textarea.scrollHeight) + 'px';
  }

  handleChatEdit(index: number, prompt: string) {
    this.chatService.resetChatAndSendNewPrompt(index, prompt);
  }
}
