import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { Order } from 'src/app/models/Order';
import { CartService } from 'src/app/services/cart.service';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-responses',
  templateUrl: './responses.component.html',
  styleUrls: ['./responses.component.scss']
})
export class ResponsesComponent {
  constructor(private chatService: ChatService, private cartService: CartService, private renderer: Renderer2) {

  }
  @ViewChild('chatContainer', { static: false }) chatContainer!: ElementRef;

  ngAfterViewChecked() {
    //console.log(this.content, "content")
  }
  /*   scrollToBottom() {
      // Check if chatContainer exists and is scrollable
      if (this.chatContainer && this.chatContainer.nativeElement) {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
      }
    } */
  @Input() content: any[] = [];
  @Input() widget: boolean = false;
  

  runPrompt(prompt: string) {
    this.chatService.onSend(prompt)
  }
  addObj(obj: string) {
    this.chatService.pushObj(obj)
  }
  addOrder(order: Order) {
    this.cartService.add(order)
  }

  isChatLoading() {
    return this.chatService.loading;
  }
}
