<div class="{{ selectData.selectFormClass }}">
  <label
    class="block text-yv-white-50 font-poppins mb-1 {{ selectData.labelClass }}"
    for=""
    >{{ selectData.label }}</label
  >

  <select
  
  [formControl]="control"
  [style]='!showMode?"
  background-image: url(../../../assets/img/icon/select_arrow.svg);":""'
    class="block {{showMode && 'pointer-events-none'}} !w-full text-yv-white-50 bg-yv-primary-70 border border-transparent focus:border-blue-500 rounded-lg font-poppins leading-6 text-base {{
      selectData.selectClass
    }} focus:border-[#978EF5] duration-500
    focus:shadow-[0px_0px_30px_0px_#978EF54D]"

    name="cars"
    id="cars"
    style="padding: 0.75rem 0.5rem 0.75rem 1rem; width: 25rem; height: 3.25rem"
  >
    <option class="{{selectData.optionClass}}" value="">{{default}}</option>
    <option class="{{selectData.optionClass}}" *ngFor="let item of items" value="{{ item.value }}">
      {{ item.name }}
    </option>
  </select>
</div>
