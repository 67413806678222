import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-my-bonds-table',
  templateUrl: './my-bonds-table.component.html',
  styleUrls: ['./my-bonds-table.component.scss']
})

export class MyBondsTableComponent {

  @Input() columns?: any[];
  @Input() data?: any[];
  @Input() bonds?: Array<object> = [{
    "iconClass": 'hidden md:block',
    "logo": 'assets/img/temp/bondsLogoAlaska.svg',
    "firstText": 'Alaska, 5.342% 08/2027',
    "secondText": 'US011770U778'
  }, {
    "iconClass": 'hidden md:block',
    "logo": 'assets/img/icon/logo-two.svg',
    "firstText": 'Brazil, Federative Republic 8.75% 05/25',
    "secondText": 'US105756BF62'
  },  {
    "iconClass": 'hidden md:block',
    "logo": 'assets/img/temp/bondsLogoAT&T.svg',
    "firstText": 'AT & T Corp. 6,5% 99/29',
    "secondText": 'US001957AW94'
  }, {
    "iconClass": 'hidden md:block',
    "logo": 'assets/img/temp/bondsLogoWarner.svg',
    "firstText": 'Warner Media LLC 7,625% 01/31',
    "secondText": 'US00184AAC99'
  }, {
    "iconClass": 'hidden md:block',
    "logo": 'assets/img/temp/bondLogoQatar.svg',
    "firstText": 'Qatar 4,5% 18/28',
    "secondText": 'XS1807174393'
  }];

  getBondsName = (bond:any)=> bond.firstText
  getBondsColabs = (bond:any)=> bond.colabs
  changeBondsColabs=(bond:any)=> bond.colabs = !bond.colabs
}
