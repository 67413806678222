<button
  class="bg-yv-primary-50 rounded-lg min-w-52  h-[4rem] flex justify-center items-center gap-2 hover:bg-yv-primary-60 focus:bg-yv-primary-60 {{
    buttonData.classButton
  }}"
  style="padding: 1.25rem 1.25rem"
  [disabled]="buttonData.disabled"
  [ngClass]="buttonData.disabled ? 'bg-yv-primary-60 opacity-30' : ''"
>
  <img
    class="{{ buttonData.classIcon }} me-1"
    [src]="buttonData.iconUrl"
    alt="icon left"
    *ngIf="buttonData.IconLeft && !buttonData.loading"
  />
  <span
    *ngIf="!buttonData.loading && !buttonData.loading"
    class="text-white font-poppins not-italic font-semibold text-lg leading-[1.5rem] tracking-wider {{
      buttonData.classText
    }}"
    >{{ buttonData.textButton }}</span
  >
  <img
    class="w-[2rem]"
    src="assets/img/icon/loading.gif"
    alt="loading"
    *ngIf="buttonData.loading"
  />
  <img
    class="ms-1"
    [src]="buttonData.iconRightUrl"
    alt="icon right"
    *ngIf="buttonData.iconRightUrl && !buttonData.loading"
  />
</button>
