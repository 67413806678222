import { Component, EventEmitter, Input, Output } from '@angular/core';


interface Steps {
  title:string,
  checked:boolean,
}


@Component({
  selector: 'app-stepper-vertical',
  templateUrl: './stepper-vertical.component.html',
  styleUrls: ['./stepper-vertical.component.scss']
})
export class StepperVerticalComponent {

  @Input() full = false
  @Input() steps?:Steps[];
  @Input() current:number = 0;
  @Output() nextClick = new EventEmitter<void>();
  @Output() prevClick = new EventEmitter<void>();
  @Input() onboardingQuestions?:any[];
  
  next() {
    this.nextClick.emit();
  }

  prev() {
    this.prevClick.emit();
  }


}
