import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import EmptyModel from 'src/app/models/EmptyModel';
import Module from 'src/app/models/Module';
import Quizz from 'src/app/models/Quizz';
import StudentProgress from 'src/app/models/StudentProgress';
import { EducationService } from 'src/app/services/education.service';

@Component({
  selector: 'app-lecture-page',
  templateUrl: './lecture.component.html',
  styleUrls: ['./lecture.component.scss']
})
export class LecturePageComponent implements OnInit,OnDestroy {
  lectures$?: Observable<any>;
  selectedLecture$?: Observable<any>;
  quizzes$?: Observable<any>;
  course$?: Observable<any>;
  course: any
  module$?: Observable<any>;
  progress$?: Observable<any>;
  progress: any = []
  progressPercentage: string = ""
  module: any
  lectures: any[] = [];
  quizzes: any[] = [];
  quiz: boolean = false;
  selected: any = {}
  selectedLecture: any = {}
  lectureId: string = ""
  serviceData: any
  constructor(private educationService: EducationService, private route: ActivatedRoute) {

    this.serviceData = this.educationService.getContentData()
  }

  ngOnInit(): void {
   // this.educationService.login(() => { });

    if (this.serviceData) {
      setTimeout(() => {

        this.route.params.subscribe((params) => {
          const moduleID = params['moduleID'];
          const lectureID = params['lectureID'];
          const language = params['language'];
          this.lectureId = lectureID

        /*   this.lectures$ = this?.educationService.getLectures(moduleID, language);
          this.lectures$.subscribe((data) => {
            if (data) {
              this.lectures = data
              //console.log(data,"data of GetLectures")
            } else this.lectures = [""]
          }); */



          this.progress$ = this?.educationService.getStudenProgressByModule(moduleID)
          this?.progress$.subscribe((data) => {
            if (data.length > 0) {
              this.progress = data;
              this.educationService.setProgressArray(data)
              const hasTargetValue = this.progress.some((obj: StudentProgress) => obj.completed === true);
              if (hasTargetValue === true) {
                this.quizzes$ = this?.educationService.getStudentQuiz(moduleID, language)
                this?.quizzes$.subscribe((data) => {
                  this.quizzes = data;
                  this.quizzes.forEach((module: Quizz) => {
                    this.educationService.getStudentQuizByID(moduleID, language, module.quizID).subscribe((progressData) => {
                      //module.answers = progressData.answers
                      if (progressData.answers) {
                        module.answers = progressData.answers.map((answer) => ({
                          answered: false,
                          isCorrect: null,
                          id: answer.id,
                          text: answer.conten,
                        }));
                      }
                    });
                  });
                  //this.educationService.setQuizArray(this.quizzes)
                  //console.log('MainLecture quizzes:', this.quizzes);
                });
              }
              let completionPercentage = this.progress[0].progressPercentage
              if (completionPercentage > 100) {
                completionPercentage = 100;
              }
              this.progressPercentage = completionPercentage
            } else {
              this.progress = data
              this.progressPercentage = "0"
            }
            //console.log( this.progress,"THISPROGRESS")
          });

          /*  this.course$ = this?.educationService.getCoursesByID(courseID, language)
           this?.course$.subscribe((data) => {
             this.course = data;
           }); */


          /*   this.module$ = this?.educationService.getModulesByID(moduleID, language)
            this?.module$.subscribe((data) => {
              this.module = data;
            }); */

          this.selectedLecture$ = this?.educationService.getStudentLecturesByID(lectureID, language)
          this?.selectedLecture$.subscribe((data) => {
            this.selectedLecture = data;
            console.log('selectedLecture:', data);
          });


        });
      }, 300);
      this.educationService.getQuizArrayObservable().subscribe((progress) => {
        console.log("ARRAY_FROM_progress INLecture", progress)
        this.quizzes = progress
        console.log("ARRAY_FROM_progress Content INLecture", this.quizzes)
      });
    }
  }
  ngOnDestroy() {
    localStorage.removeItem('content');
    localStorage.removeItem('lecture');
  }
  getCurrentLecture() {
    return this.educationService.selected;
  }

  openQuiz() {
    this.quiz = true;
  }
  closeQuiz() {
    this.quiz = false;
  }
}
