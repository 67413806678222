import { Component, Input, OnInit } from '@angular/core';
import { Order } from 'src/app/models/Order';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  isOpen = false;
  @Input()
  orders: Order[] = []
  state:boolean = false;
  constructor(private cartService: CartService){

  }

  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }
  closeSideBar(){
    this.isOpen = false
  }

  ngOnInit(): void {
  }

  noBox = (state:boolean) => state?"!pointer-events-none !bg-transparent !border-0":" "

}
