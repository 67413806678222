<div class="max-w-full ">
    <div *ngFor="let item of content, let i = index" class="bg-yv-5%-gray rounded-[2rem] py-2 px-[1.5rem] my-[2.5rem]">
        <div id="topCounter" class="flex justify-around w-full text-xs md:text-base py-6 mt-[1.3rem]">
            <div class="w-[80%] gap-[1rem]">
                <span class="font-[600] text-[5vw] md:text-[2rem] text-[#FFFFFF] "> {{ item.title}}</span>
                <div id="coursesProgressLine" class="mx-auto mt-[1rem]">
                    <div class="w-[full] bg-[#81FFAF33] h-[0.6rem] rounded-xl">
                        <div class="w-[2px] bg-[#2CC784] h-full rounded"
                            [style.width]="calculateCompletedCourses(item,false)"></div>
                    </div>
                </div>
            </div>
            <div id="completedCourses" class="max-w-[15rem]">
                <div class="w-full flex justify-end">
                    <span class="font-[400] text-[1rem] text-[#B3B3B3] text-right xl:text-justify">Completed
                        Courses</span>
                </div>
                <div class="w-full flex justify-end gap-[0.5rem] mt-[0.75rem]">
                    <span class="font-[300] text-[1rem] md:text-[1.75rem]">{{ calculateCompletedCourses(item) | number:
                        '2.0' }} </span>
                    <span class="font-[300] text-[1rem] md:text-[1.75rem]  text-[#6761A8]"> of {{
                        item.modules?.length | number: '2.0'
                        }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="loading === false">
            <ngx-slick-carousel class="carousel w-full md:p-8 py-8 min-w-[100%] md:min-w-0" #slickModal="slick-carousel"
                [config]="slideConfig" (afterChange)="currentSlideIndex = $event.currentSlide">
                <div ngxSlickItem *ngFor="let itemModule of item.modules, let i = index"
                    class="slide grid mx-1 md:mx-4">
                    <div class="bg-yv-5%-gray rounded-2xl py-8 p-4 min-h-[27.188rem] flex flex-col">
                        <div class="font-bold text-[18px] text-[#978EF5]">Module {{ i + 1 }}: </div>
                        <div style="
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        " class="font-normal text-[24px] mb-8 w-full">{{ itemModule?.title }}</div>

                        <div class="flex-grow"></div>
                        <div *ngIf="itemModule?.haveAccess" id="progressBar" class="mb-[2rem]">
                            <div id="progressLine" class="border-t-[0.5px] border-[#B3B3B3] mx-auto mt-20"
                                style="border-color: transparent; margin-top: 0;">
                                <div class="w-full bg-[black] h-[0.5rem] rounded">
                                    <div class="w-[2px] bg-[#2CC784] h-full rounded"
                                        [style.width]="calculateProgressWidth(itemModule)"></div>
                                </div>
                            </div>

                            <div class="flex justify-between mt-[1rem]">
                                <div class="text-[0.75rem] text-[#2CC784]">
                                    {{ calculateProgressPercentage(itemModule) | number:'1.1-1' }}% Completed
                                </div>

                                <!--  <div class="text-[0.75rem] text-[#FFFFFF]">0 of 10 points</div> -->
                            </div>
                        </div>


                        <div class="items-center"
                            (click)="itemModule?.haveAccess && itemModule?.lectures.length > 0 && goToLecture(item.id, itemModule.id, item.language,itemModule.lectures[0].id,calculateCompletedCourses(item),item.modules?.length)">
                            <hr class="border-[#E1E1E133]" />
                            <div class="flex flex-col items-center gap-[1rem]">
                                <app-button [buttonData]="{
                                    iconUrl: isHovered[i]&& itemModule?.lectures.length > 0 ? 'assets/img/icon/lockOpen.svg' : 'assets/img/icon/lock.svg',
                                    classText: !itemModule?.haveAccess ? 'text-[#55548b]' : 'text-[white]',
                                    IconLeft: calculateProgressPercentage(itemModule) <= 0 || calculateProgressPercentage(itemModule) === 100,
                                    textButton: calculateProgressPercentage(itemModule) === 100 ? 'Completed' : (calculateProgressPercentage(itemModule) > 0 ? 'Continue' : itemModule?.lectures.length <= 0 ? 'Not Available' : 'Start Learning'),
                                    classButton: calculateProgressPercentage(itemModule) <= 0  ? 'mx-auto mt-8 !bg-[#323351] hover:!text-white hover:bg-yv-primary-50 mb-9' : 'mx-auto mt-8 mb-9'
                                  }" (mouseenter)="onButtonMouseEnter(i)" (mouseleave)="onButtonMouseLeave(i)">
                                  </app-button>
                                  
                                  <span class="text-[#FF6F65] text-[0.85rem] -mt-9"
                                  *ngIf="(calculateProgressPercentage(itemModule) <= 0 && isHovered[i]) || (itemModule?.lectures.length <= 0)">
                              {{ (calculateProgressPercentage(itemModule) <= 0 && isHovered[i] && itemModule?.lectures.length > 0) ? 'You should finish previous Modules' : 'Current Module Not Available' }}
                            </span>
                            
                            </div>
                        </div>



                    </div>
                </div>
            </ngx-slick-carousel>

            <div class="hidden md:flex gap-8 items-center justify-center p-8">
                <app-rounded-icon-buttom (btnClick)="slickModal.slickPrev()"
                    [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass:'!bg-[#F9F9F91A] rotate-180'}" />
                <div class="custom-dots w-full">
                    <span *ngFor="let slide of content; let i = index"
                        class="dot flex-grow {{currentSlideIndex === i?'active':''}}"
                        (click)="slickModal.slickGoTo(i)"></span>
                </div>
                <app-rounded-icon-buttom (btnClick)="slickModal.slickNext()"
                    [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass:'!bg-transparent !border !border-white'}" />
            </div>
        </div>
        <div *ngIf="loading === true" class="">

            <img class="" src="assets/img/icon/logoLoading.svg" />
        </div>

    </div>
</div>