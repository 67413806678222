import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  private responseSubject = new Subject<void>();

  
  response$ = this.responseSubject.asObservable();

  triggerResponse() {
    this.responseSubject.next();
  }
}
