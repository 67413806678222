<app-stepper-counter *ngIf="content ===[]" [quizAnswer]="wrongTitle" [current]="currentStep" [steps]="steps"
    (nextClick)="changeStep(1, currentStep)" [quiz]="true" (prevClick)="changeStep(-1, currentStep)">

    <ng-container *ngIf="!steps[steps.length - 1].checked" [ngSwitch]="currentStep">

        <div *ngFor="let item of contenta; let i = index">
            <div *ngSwitchCase="i" class="w-full bg-[#FFFFFF1A] rounded-2xl">
                <div class="font=[400] text-[24px] p-10">{{item.title}}</div>
                <div class="max-w-screen-lg pb-10">
                    <div class=" mb-8 ml-8 bg-[#978EF51A] max-w-[45%] rounded-2xl px-8 min-h-[100px] inline-flex items-center"
                        [ngClass]="{
                    'outline outline-[#FF6F65]': question.answered && !question.isCorrect,
                    'outline outline-[#978EF5] shadow-[0px_0px_30px_0px_#978EF54D]': question.answered && question.isCorrect
                  }" *ngFor="let question of item.questions; let j = index" (click)="toggleAnswered(question,item)">
                        <div class="text-left">{{ question.text }}</div>
                    </div>
                    <div *ngIf="wrong" class="ml-8 p-5 bg-[#FF6F651A] max-w-[55%] rounded-2xl px-2">
                        <div class="text-left text-[#FF6F65]">{{wrongText}}</div>
                    </div>
                </div>
            </div>
        </div>






    </ng-container>

    <div *ngIf="steps[steps.length - 1].checked" class="w-full bg-[#FFFFFF1A] rounded-2xl">
        <div class="flex flex-col gap-10 p-10 items-center">
            <div class="flex gap-4 items-center font-bold text-[32px]">
                <img src="assets/img/icon/great.svg" /> Great Job!!!
            </div>

            <div class="text-[#E1E1E1] font-normal text-[24px]">You've completed sucessfully the Quiz for basics of
                Fixed Income</div>
            <div>

                <div class=" my-8 py-4 px-8 bg-[#98F2B933] rounded-2xl items-center flex text-[18px] font[600]"> <span
                        class="text-[#81FFAF] text-[48px] font[400] mr-4">XX</span> Earned Points</div>
                <app-button [buttonData]="{textButton:'Continue',classButton:'mx-auto'}"></app-button>
            </div>
        </div>
    </div>




</app-stepper-counter>
<app-stepper-counter *ngIf="content !=[]" 
(fontIncrease)="changeFontSize('+')" (fontDecrease)="changeFontSize('-')" [fontSize]="fontSize" (fontReset)="resetFontSize()"
[nextText]="getNextTitle(currentStep)" [lectureView]="true"  [finalStep]="getFinalStep()" [nextQuiz]="checkAnswerStatus(content,currentStep)"
[courseTitle]="serviceData?.title"[courseDone]="serviceData?.courseDone" [lectureView]="true" [courseTotal]="serviceData?.courseTotal"
[quizAnswer]="wrongTitle" [current]="currentStep" [steps]="steps"
    (nextClick)="changeStep(1, currentStep)" [quiz]="true" (prevClick)="changeStep(-1, currentStep)">

    <ng-container *ngIf="!steps[steps.length - 1].checked" [ngSwitch]="currentStep">

        <div *ngFor="let item of content; let i = index">
            <div id="questionBoard" *ngSwitchCase="i" class="relative w-full bg-[#FFFFFF1A] rounded-2xl px-[2rem]">
                <div class="font=[500] text-[1.5rem] py-10">{{item?.title}} <!-- - {{item?.quizID}}  --></div>
                <div class="max-w-screen-lg pb-10 flex flex-col gap-[1rem]">
                    <div class="cursor-pointer bg-[#978EF51A] rounded-2xl px-8 min-h-[6.25rem] flex items-center"
                        [ngClass]="{
                    'outline outline-[#FF6F65]': answer?.answered && !answer?.isCorrect,
                    'outline outline-[#978EF5] shadow-[0px_0px_30px_0px_#978EF54D]': answer?.answered && answer?.isCorrect
                  }" *ngFor="let answer of item?.answers; let j = index"
                        (click)="handleAnswers(answer,item,item?.quizID,answer?.id,i)">
                        <div class="font-normal text-justify" [style.fontSize.rem]="fontSize">{{ answer?.text }}</div>
                        <!-- <div class="text-left">{{ answer?.id }}</div> -->
                    </div>
                    <div *ngIf="wrong" class="ml-8 p-5 bg-[#FF6F651A] max-w-[55%] rounded-2xl px-2">
                        <div class="text-left text-[#FF6F65]">{{wrongText}}</div>
                    </div>
                </div>
                <div id="loadingBarQuiz" *ngIf="isLoading" class="absolute inset-0 flex items-center justify-center  text-white">
                    <img class="" src="assets/img/icon/logoLoading.svg" />
                  </div>
            </div>
        </div>






    </ng-container>

    <div *ngIf="steps[steps.length - 1].checked" class="w-full bg-[#FFFFFF1A] rounded-2xl">
        <div class="flex flex-col gap-10 p-10 items-center">
            <div class="flex gap-4 items-center font-bold text-[32px]">
                <img src="assets/img/icon/great.svg" /> Great Job!!!
            </div>
            <div class="text-[#E1E1E1] font-normal text-[24px]">
                <div class="flex items-center w-full justify-center">You've sucessfully completed the Quiz for  {{serviceData?.modules?.title}} </div>
              <!--   <div class="flex items-center w-full justify-center"> {{serviceData?.modules?.title}} </div> -->
            </div>
            <div>

              <!--   <div class=" my-8 py-4 px-8 bg-[#98F2B933] rounded-2xl items-center flex text-[18px] font[600]">
                    <span class="text-[#81FFAF] text-[48px] font[400] mr-4">XX</span> Earned Points
                </div> -->
                <app-button [buttonData]="{textButton:'Continue',classButton:'mx-auto'}"
                    (click)="goToEducation()"></app-button>
            </div>
        </div>
    </div>




</app-stepper-counter>