import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.scss']
})
export class ChatWidgetComponent implements OnInit{
  open = false
  currentPrompt: string = "";
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private chatService: ChatService){
    }
    
    
  ngOnInit() {


  
    // const assignedString = "What is the color of the sky?";
    
    // const mostSimilarObject = this.findMostSimilarString(this.array, assignedString);
    // console.log(mostSimilarObject);
  
      
      //const url = 'assets/samples2.json'; // Replace with your desired URL
      this.route.queryParams.subscribe(params => {
        const prompt = params['prompt'];
  
      this.fetchData({q:prompt,url:"assets/samples.json",q_a:[]});
      });
    }
    fetchData(p: any) {
      this.http.get<any[]>(p.url).subscribe(
        data => {
          this.chatService.setData(data)
          // Access the JSON array
          data.forEach((item,i) => {
            console.log(i + "=> " +item.content);
          });
  
        if(p.q)this.onSend(p.q)
        },
        error => {
          console.log('Error:', error);
        }
      );
    }


// Function to scroll to the bottom of the chat container
scrollToBottom() {
  
  var chatContainer = document.getElementById('widget');
  if(chatContainer ) chatContainer.scrollTop = chatContainer.scrollHeight;
}

// Example usage:
// After adding new content to the chat container, call the scrollToBottom function
    
  onSend(prompt?:string){
    this.viewportScroller.scrollToAnchor("footer")
    this.chatService.onSend(prompt)
    this.scrollToBottom()
  }

  handleToggle(){
    this.open = !this.open
  }
  getSamples() {
    return this.chatService.samples
  }

}
