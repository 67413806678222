import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss']
})
export class ModuleListComponent implements OnInit {
  slideConfig = {
    dots: false,
    arrows: false,
    "slidesToShow": 3, "slidesToScroll": 1,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  @Input() content?: any[]
  currentSlideIndex: number
  @Input() currentIndex: number = 0
  isModuleFinished: boolean[] = [];

  constructor(private chatService: ChatService,
    private viewportScroller: ViewportScroller,
    private router: Router
    ) {
    this.currentSlideIndex = 0

  }
  runPrompt(prompt: string) {
    this.chatService.onSend(prompt)
    this.viewportScroller.scrollToAnchor("footer")
    this.currentIndex = this.currentIndex+1
    this.updateIsModuleFinished()
  }
  goToLecture() {
    this.router.navigate(["education/lecture"]);
    this.viewportScroller.scrollToAnchor("footer")
    this.currentIndex = this.currentIndex+1
    this.updateIsModuleFinished()
  }
  ngOnInit() {
    console.log(this.content, "content");
    if (this.content && this.content.length > 0) {
      this.updateIsModuleFinished();
    }
  }
  updateIsModuleFinished() {
    this.isModuleFinished = this.content?.map((_, i) => i <= this.currentIndex) ?? [];
  }

}
