import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { RoboService } from 'src/app/services/robo.service';


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],

})
export class OnboardingComponent implements OnInit{
  payload:any = []
  qAs:any[]=[]
  ngOnInit(): void {
    this.roboService.get("/ra/get").subscribe({
      next:(data)=>{
        this.qAs  = data.data?.qa
        console.log(data)
      }
    })
  }
  getQaByA(ans:any) {
    let QA = this.qAs.find(((q:any) =>q.questions.find(((a:any)=>a.answer == ans))))
  return ({
    categoryID:QA.categoryID,
    id:QA.questions.questions.find(((a:any)=>a.answer == ans)).questionId,
    responde:QA.questions.questions.find(((a:any)=>a.answer == ans)).answer
  })}
  
  percentages: any[] = [
    { title: "< 2%", value: "1" },
    { title: "2% - 5%", value: "5" },
    { title: "5% - 15%", value: "15" },
    { title: "15% - 30%", value: "30" },
    { title: "30% - 50%", value: "50" }
  ]

  levelofRisk: any[] = [
    { title: "Risk-averse", icon: "assets/img/icon/risk-averse-bg.svg" },
    { title: "Cautious", icon: "assets/img/icon/cautious.svg" },
    { title: "Balanced", icon: "assets/img/icon/balanced.svg" },
    { title: "Positive view on risk", icon: "assets/img/icon/possitive.svg" },
    { title: "Risk-seeking", icon: "assets/img/icon/risk-seeking.svg" },
  ]

  invertmenetObjective: any[] = [
    { title: "Capital Preservation", icon: "assets/img/icon/capital-preservation.svg" },
    { title: "Stable Growth", icon: "assets/img/icon/stable-growth.svg" },
    { title: "Capital Appreciation", icon: "assets/img/icon/capital-appreciation.svg" },
    { title: "Income", icon: "assets/img/icon/income.svg" },
    { title: "Total Return", icon: "assets/img/icon/total-return.svg" },
  ]


  onboardingQuestions: any[] = [
    "How many years do you plan to invest this money?",
    "What percentage of your investment are you willing and able to tolerate as a loss?",
    "What annual return are you aiming for with this investment?",
    "How would you describe your comfort level with investment risk?",
    "What is your primary investment objective?"
  ]


  steps = [{ title: 'Experience', checked: true },
  { title: 'Investment', checked: false },
  { title: 'Investment objective', checked: false },
  { title: 'Investment objective', checked: false },
  { title: 'Investment objective', checked: false }]
  currentStep: number;
  currentPercentage: any = { answer: "<2%", value: "1" };
  currentRisk:any
  currentObjective:any

  radius = 54;
  circumference = 2 * Math.PI * this.radius;
  dashoffset: number = 0;

  constructor(private router: Router, private roboService:RoboService) {
    this.currentStep = 0
    this.progress(2);
  }

  private progress(value: number) {
    const progress = value / 100;
    this.dashoffset = this.circumference * (1 - progress);
  }

  changePercentage(currentPercentage: any) {
    this.currentPercentage = currentPercentage;
    this.progress(currentPercentage.answer == "<2%"?"2":currentPercentage.answer.split("-")[0]);
  }


  changeRisk(risk: any) {
    this.currentRisk = risk;
  }

  changeObjetive(objective: any) {
    this.currentObjective = objective
   }

  changeStep(val: number, pos: number) {

    let res = this.currentStep + val
    if ((val >= 0) && res < this.steps.length) this.steps[res].checked = true
    else if (val < 0) this.steps[pos].checked = false

    //temp
    if (res > this.steps.length - 1) {
      let payload:any = 
      {"data":{
        "qa": this.payload || [
          {
            "categoryID": 1,
            "questions": [
              {
                "questionID": 1,
                "response": 8
              }
            ]
          },
          {
            "categoryID": 2,
            "questions": [
              {
                "questionID": 2,
                "responseID": 1
              }
            ]
          },
          {
            "categoryID": 3,
            "questions": [
              {
                "questionID": 3,
                "response": 5
              }
            ]
          }
          ]
      }
      }
       this.roboService.post("/ra/calculate",payload).subscribe({
      next:(data)=>{
        payload.impliedRiskTolerance = data.data?.impliedRiskTolerance
        payload.riskProfile = data.data?.riskProfile
        this.roboService.setCurrentPayload(payload)
        console.log(data)
        console.log(this.roboService.currentPlayload)
        this.router.navigate(['/robo/result']);
      }
    })
    }

    if (res >= 0 && res < this.steps.length && pos < this.steps.length) this.currentStep = res;

  }

  buildPayload(qa:any){
    
    if(this.payload.find(((a:any)=> a.categoryID == qa.categoryID))){
    this.payload.find(((a:any)=> a.categoryID == qa.categoryID)).questions = []
    this.payload.find(((a:any)=> a.categoryID == qa.categoryID)).questions.push({
      "questionID": qa.id,
      "responseID":qa.responseID,
      "response": qa.event? (qa.event.target as HTMLInputElement).value :qa.response
    })
  }
    else this.payload.push({
      "categoryID": qa.categoryID,
      "questions": [
      {
      "responseID":qa.responseID,
      "questionID": qa.id,
      "response": qa.event? (qa.event.target as HTMLInputElement).value:qa.response
      }
    ]
  },)
  
  console.log(this.payload)
  }
}
