<app-user-layout>
  <div class="grid grid-cols-2 gap-5">
    <app-input-form
      [control]="ship"
      [inputData]="{
            label:'Shipment',
        inputType: 'Shipment',
        labelClass: 'text-[0.8rem] text-[#E1E1E1]',
        placeholder: 'Shipment Price',}"
    />
    <app-input-form
      [control]="weight"
      [inputData]="{
            label:'Weight',
        inputType: 'number',
        labelClass: 'text-[0.8rem] text-[#E1E1E1]',
        placeholder: 'Weight (Kg)',}"
    />
    <app-select-form
      [control]="origem"
      [default]="'Select'"
      [items]="[
        { value: 'USA', name: 'USA' },
        { value: 'FRANÇA', name: 'FRANÇA' },
        { value: 'OUTROS', name: 'OUTROS' }
      ]"
      [selectData]="{
        label: 'Origin',
        selectFormClass: '!bg-none w-full',
        selectClass:
          '!bg-[#FFFFFF10] text-[#B3B3B380] !bg-[url(assets/img/icon/input_arrow.svg)] !py-[13.5px] !px-4 !mr-3  !h-auto',
        labelClass: 'text-[12px] '
      }"
    />
    <app-select-form
      [control]="estancia"
      [default]="'Select'"
      [items]="[
        { value: 'SV', name: 'SV' },
        { value: 'PRAIA', name: 'PRAIA' },
        { value: 'OUTROS', name: 'OUTROS' }
      ]"
      [selectData]="{
        label: 'Destination',
        selectFormClass: '!bg-none w-full',
        selectClass:
          '!bg-[#FFFFFF10] text-[#B3B3B380] !bg-[url(assets/img/icon/input_arrow.svg)] !py-[13.5px] !px-4 !mr-3  !h-auto',
        labelClass: 'text-[12px] '
      }"
    />

    <app-button
      (click)="get()"
      [buttonData]="{
        loading: loading,
        textButton: 'Get',
        classButton: 'mt-5 !bg-[#6761A8]',
        classText: '!text-[white]'
      }"
    ></app-button>
  </div>


  <!-- Continue with the same pattern for each of the new fields -->
</app-user-layout>
<div
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center text-white z-40"
  *ngIf="isModalOpen"
>
  <div class="bg-yv p-8 rounded shadow-md">
    <p *ngIf="froudAlert" class="text-2xl text-red-500 font-semibold mb-4">
      WARNING
    </p>
    <p *ngIf="!froudAlert" class="text-2xl text-green-500 font-semibold mb-4">
      SUCCESS
    </p>
    <p>{{ content }}</p>
    <!-- Close button -->
    <button
      (click)="closeModal()"
      class="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
    >
      Close
    </button>
  </div>
</div>
