import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  loading: boolean = false
  constructor(private router:Router,private http:HttpClient){}
  goToChatDemo(){
    this.router.navigate(['assistant/chatdemo'])

  }
  ship = new FormControl
  weight = new FormControl
  origem = new FormControl('USA')
  estancia = new FormControl('SV')
  // Individual FormControl for each field
  declarationCv = new FormControl('');
  declarationAlf = new FormControl('');
  forms = new FormControl('');
  loadList = new FormControl('');
  manifest = new FormControl('');
  items = new FormControl('');
  nbrPackage = new FormControl('');
  referenceNumber = new FormControl('');

  // New FormControl definitions for the additional fields
  financial = new FormControl('');
  financialNo = new FormControl('');
  citysLastCon = new FormControl('');
  tradingCity = new FormControl('');
  valueDetails = new FormControl('');
  cap = new FormControl('');


  // New FormControl definitions
  countryOfExport = new FormControl('');
  ceA = new FormControl('');
  ceB = new FormControl('');
  cdA = new FormControl('');
  cdB = new FormControl('');
  countryOfOrigin1 = new FormControl('');
  countryOfOrigin2 = new FormControl('');


  deliveryTerms = new FormControl('');
  currencyTotalAmount = new FormControl('');
  exchangeRate = new FormControl('');
  natureOfTransaction = new FormControl('');


  // New FormControl definitions
  financialAndBankingData = new FormControl('');
  termsOfPayment1 = new FormControl('');
  termsOfPayment2 = new FormControl('');
  termsOfPayment3 = new FormControl('');


  // New FormControl definitions
  commodityCode = new FormControl('');
  cityOrigA = new FormControl('');
  cityOrigB = new FormControl('');
  grossMass = new FormControl('');
  prefer = new FormControl('');
  procedure = new FormControl('');
  netMass = new FormControl('');
  quota = new FormControl('');
  summaryDeclaration = new FormControl('');
  supplementary = new FormControl('');
  itemPrice = new FormControl('');
  vmCo = new FormControl('');


  // New FormControl definitions for the additional fields
  exporter = new FormControl('');
  exporterNo = new FormControl('');
  consignee = new FormControl('');
  consigneeNo = new FormControl('');
  declarant = new FormControl('');
  declarantNo = new FormControl('');


  // New FormControl definitions for the additional fields
  identityMeansOfTransportArrival = new FormControl('');
  identityActiveMeansOfTransportCrossing = new FormControl('');
  modeTransportAtBorder = new FormControl('');
  inlandModeTransport = new FormControl('');
  placeOfDischarge = new FormControl('');
  modeTransport = new FormControl('');
  locationOfGoods = new FormControl('');
  marksNumbersContainers = new FormControl('');
  itemNumber = new FormControl('');
  licenceNumber = new FormControl('');
  dVal = new FormControl('');
  dQty = new FormControl('');
  goToOnboarding(){
    this.router.navigate(['robo'])
  }
  isModalOpen = false;
  froudAlert = false;

  content = ""
  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }
  get(){
    this.loading = true
    this.http.post<any>("https://smart-custom.sintaxy.com/api/v1/fraud-detector", {
      "cat_estancia":this.estancia.value,
      "peso": this.weight.value,"cat_origem":this.origem.value,"frete":this.ship.value})
      .subscribe({
        next:(data)=>{
          if(data.data?.message?.startsWith("FRAUD ALERT")) this.froudAlert =true;
          else this.froudAlert =false
          this.content = data.data.message
          this.openModal()
            this.loading = false
        },
        error:()=>{
            this.loading = false
          this.content = "Some Error As Ocoured"
          this.openModal()
        }
      })

  }

}
