import { Component, Input, AfterViewInit, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-bars-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bars-chart.component.html',
  styleUrls: ['./bars-chart.component.scss']
})
export class BarsChartComponent implements AfterViewInit {

  dynamicId = `component_${Math.floor(Math.random() * 10000)}`;

  @Input() category: string;
  @Input() markerValue: number | undefined;

  constructor(private elementRef: ElementRef) {
    this.category = '';
  }

  ngAfterViewInit() {
    this.renderChart();
  }

  renderChart() {

    const gradientColors = [
      '#FF6F65',
      'rgba(255, 193, 101, 0.76)',
      '#FCFF65',
      '#71FD65',
      '#6582B9',
      '#E065FF'
    ];

    const chartOptions: Highcharts.Options = {

      chart: {
        type: 'bar',
        backgroundColor: 'transparent',

      },

      title: {
        text: '',
        style: {
          color: '#ffffff99'
        }
      },

      legend: {
        enabled: false,
      },

      xAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        visible: false,
      },

      yAxis: {
        gridLineWidth: 0,
        plotLines: [
          {
            value: this.markerValue,
            color: '#F9F9F9',
            width: 3,
            zIndex: 5,
            dashStyle: 'ShortDash',
            
          }
        ],
        labels: {
          enabled: false
        },
        title: {
          text: ''
        }
      },

      plotOptions: {
        bar: {
          dataLabels: {
            enabled: false,
          },
          pointWidth: 30,
          borderRadius: 0,
          borderWidth: 0,
          color: {
            linearGradient: {
              x1: 0,
              y1: 1,
              x2: 0,
              y2: 0
            },
            stops: [
              [0, gradientColors[0]],
              [0.2448, gradientColors[1]],
              [0.4583, gradientColors[2]],
              [0.651, gradientColors[3]],
              [0.8177, gradientColors[4]],
              [1, gradientColors[5]]
            ]
          },



        }
      },

      tooltip: {
        enabled: false,
      },

      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Year 1990',
          data: [100]
        } as any
      ]

    }

    Highcharts.chart(this.dynamicId, chartOptions);

  }

}
