<div
  class="text-[#131515] rounded-3xl bg-[#E2EAED] md:flex bg-gradient-to-bl from-[#E2EAED] via-[#E2EAED] to-[#E2EAED]"
>
  <div
    class="md:w-1/2 h-[50vh] md:h-auto flex items-center rounded-3xl bg-[url('assets/img/dash-banner.png')] bg-cover from-transparent via-[#E2EAED] to-[#D124FD55]"
  >
    
  </div>

  <div class="md:px-10 mx-auto pr-4 -translate-y-20 md:translate-y-0 relative md:pt-10 md:w-1/2">
    <app-rounded-icon-buttom 
    [buttonData]="{
        iconUrl:'assets/img/icon/logo.svg',
        btnClass:'!bg-white md:hidden inline-flex mx-5',
        iconClass:'w-5'
    }" />
    <app-chat-box>
      <div class="font-bold md:text-[64px] text-[7vw]">Welcome to</div>
      <app-yieldvestor [logo]="false" class="text-[40px]" />
      <p class="">Discover, learn and invest in fixed income using our AI guided tools.</p>
    </app-chat-box>
    <app-chat-box class="">
      Learn and invest in bonds and money markets.
    </app-chat-box>
    <div class=" flex md:hidden w-full justify-center pt-8">
      <app-button
        [buttonData]="{
          iconUrl: 'assets/img/icon/chat-arrow.svg',
          classIcon: '!bg-[#FFFFFF08] rounded-full',
          IconLeft: true,
          classButton: 'py-4',
          classText: 'font-[600] text-[16px] uppercase tracking-widest',
          textButton: 'Start chat'
        }"
      />
    </div>
    <div class="relative hidden md:block -right-10 bg-yv">
      <div class="bg-[#E2EAED] h-8 rounded-br-3xl"></div>
      <div class="relative right-10 bg-yv">
        <div class="flex bg-[#E2EAED]">
          <div class="bg-yv relative flex-grow">
            <div
              class="bg-[#E2EAED] h-full flex-grow rounded-br-3xl relative -top-4 pr-8"
            >
              <app-chat-box>
                <div class="text-[40px] -my-4 font-[500]">...</div>
              </app-chat-box>
            </div>
          </div>
          <div class="bg-yv flex justify-end rounded-tl-3xl pl-8 pt-8 w-64">
            <app-button
              [routerLink]="'/assistant'"
              [buttonData]="{
                iconUrl: 'assets/img/icon/chat-arrow.svg',
                classIcon: '!bg-[#FFFFFF08] rounded-full',
                IconLeft: true,
                classButton: 'py-4',
                classText: 'font-[600] text-[16px] uppercase tracking-widest',
                textButton: 'Start chat'
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
