import { Component, Input, OnInit } from '@angular/core';
import {Bonds} from 'src/app/models/ChatResponses';
import { YieldList } from 'src/app/models/ComponentInput';
import { Order } from 'src/app/models/Order';
import { CartService } from 'src/app/services/cart.service';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-yield-list',
  templateUrl: './yield-list.component.html',
  styleUrls: ['./yield-list.component.scss']
})
export class YieldListComponent implements OnInit {

  ///Temp codes

  ngOnInit(): void {
    console.log("THis IS MY CONTENT",this.content)
  }
  tempsFilters: any = [
    {
      type: "slider", title: "Quantity", data: [
        { value: 20, legend: "1" },
        { value: 30, legend: "4" },
        { value: 80, legend: "6" },
        { value: 90, legend: "8" },
        { value: 100, legend: "10" },
      ]
    },

    {
      type: "slider", title: "Call risk", data: [
        { value: 20, legend: "Very low" },


        { value: 80, legend: "" },
        { value: 100, legend: "Very high" },]
    },

    { type: "sort", title: "" },
  ]
  ///END TEMP CODES

  @Input() runPrompt?: Function;
  @Input() addObj?: Function;
  @Input() addOrder?: Function;
  @Input() content?: Bonds[]
  @Input() buttonData: YieldList = {
    classContainer: ''
  };
  /* objSample = {
    type: "yield-details",
    content: ""
  } */

  slideConfig = {
    dots: false,
    arrows: false,
    "slidesToScroll": 1,
    slidesToShow: 2,
    responsive: [
      
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  currentSlideIndex: number

  constructor(private chatService: ChatService, private cartService: CartService) {
    this.currentSlideIndex = 0
  }
  showDetail(item: any) {
    if (this.addObj) {
      const objSample = {
        type: "yield-details",
        content: item
      };
      this.addObj(objSample);
    }
  }
  

  order(order: Order) {
    if (this.addOrder) this.addOrder(order)
  }
  solveAssets =(item:Bonds) => ({
    logo: 'assets/img/icon/logo-two.svg',
    assetsCardClass: '',
    firstText: item.name,
    codeText: item.id,
    currencyType: item.currency,
  });

  solveList =(item:Bonds) => ([
    {"key":"Issue Date","value":item.issue_date},
    {"key":"Issue Amount","value":item.national_value},
    {"key":"Interest rate","value":item.coupon},
    {"key":"Yield","value":item.ytm},
    {"key":"Instrument type","value":item.type},
    {"key":"Sector","value":item.sector},
    {"key":"Issuer","value":item.issuer}
  ]);
}
