import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';



@Component({
  selector: 'app-line-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent {

  dynamicId = `component_${Math.floor(Math.random() * 10000)}`

  ngAfterViewInit() {
    this.renderChart();
  }

  renderChart() {

    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'area',
        backgroundColor: 'transparent',
      },

      legend: {
        align: 'left',
        itemStyle: {
          color: '#ffffff99', // Change the color of the legend items
        },
        itemHoverStyle: {
          color: '#ffffff' // Change the color of the legend items on hover
        },
        symbolWidth: 15, // Change the width of the legend symbols
        symbolHeight: 15, // Change the height of the legend symbols
        symbolPadding: 5 // Change the padding of the legend symbols
      },

      credits: {
        enabled: false
      },

      title: {
        text: '',
        style: {
          color: '#ffffff99'
        }
      },

      xAxis: {
        gridLineColor: '#ffffff33',
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1.3,
        tickmarkPlacement: 'on', // Display tick marks on the x-axis
        tickInterval: 20, // Display every label on the x-axis
        lineColor: '#ffffffcc',
        lineWidth: 2,
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        labels: {
          style: {
            color: '#ffffff99' // Set the color of the xAxis categories text
          },
        },
      },

      yAxis: {
        gridLineColor: '#ffffff33',
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1.3,
        lineColor: '#ffffffcc',
        lineWidth: 2,
        labels: {
          style: {
            color: '#ffffff99' // Set the color of the xAxis categories text
          },
          formatter: function () {
            return this.value + ' $'; // Add a dollar sign to the y-axis labels
          }
        },
      },

      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
        }
      },

      series: [
        {
          name: 'Series 1',
          data: [
            160, 170, 180, 175, 165, 155, 148, 155, 165, 170,
            180, 185, 175, 168, 160, 155, 148, 152, 165, 170,
            175, 180, 190, 195, 200, 198, 192, 188, 185, 190,
            195, 200, 205, 208, 210, 205, 200, 195, 190, 185,
            180, 175, 170, 165, 160, 155, 150, 145, 140, 135,
            160, 170, 180, 175, 165, 155, 148, 155, 165, 170,
            180, 185, 175, 168, 160, 155, 148, 152, 165, 170,
            175, 180, 190, 195, 200, 198, 192, 188, 185, 190,
            195, 200, 205, 208, 210, 205, 200, 195, 190, 185,
            180, 175, 170, 165, 160, 155, 150, 145, 140, 135
          ],
          type: 'line',
          lineWidth: 2,
          color: '#E1E1E1',
        },
        {
          name: 'Series 2',
          data: [
            200, 105, 98, 90, 55, 105, 112, 92, 82, 72,
            120, 115, 125, 130, 135, 132, 138, 140, 142,
            138, 135, 140, 145, 150, 90, 85, 100, 80, 50,
            170, 172, 175, 90, 182, 300, 174, 176, 80, 185,
            190, 195, 192, 198, 200, 205, 210, 215, 217,
            218, 220, 200, 105, 98, 90, 55, 105, 112, 92, 82,
            72, 120, 115, 125, 130, 135, 132, 138, 140, 142, 138,
            135, 140, 145, 150, 90, 85, 100, 80, 50, 170, 172,
            175, 90, 182, 300, 174, 176, 80, 185, 190, 195, 192,
            198, 200, 205, 210, 215, 217, 218, 220
          ],
          type: 'line',
          lineWidth: 2,
          color: '#98F2B9', 
        } as any
      ]
    };

    Highcharts.chart(this.dynamicId, chartOptions);
  }


}
