import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Button } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  isBtnDisabled: boolean = true;
  @Input() buttonData: Button = {
    iconUrl: 'assets/img/icon/arrow.svg',
    iconRight: false,
    IconLeft: false,
    disabled: false,
    loading: false,
    classButton: '',
    classText: '',
    classIcon: '',
    textButton: 'Get Started',
    iconRightUrl: '',
  };
}
