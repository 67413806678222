import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-first-steps',
  templateUrl: './first-steps.component.html',
  styleUrls: ['./first-steps.component.scss']
})
export class FirstStepsComponent {
  result:any = {
      "experienceLevelId": null,
      "investmentObjectiveId": null,
      "investmentExperienceId": null
  }
  setR(field:string,value:any){
    this.result[field] = value;
    console.log(this.result)
  }
  levels:any[] = [
    {title:"Beginner",desc:"I have limited or no \n investment\n experience.",icon:"assets/img/icon/chart.svg"},
    {title:"Intermediate",desc:"I have some experience with different investment products.",icon:"assets/img/icon/like.svg"},
    {title:"Advanced",desc:"I have extensive experience in investing across various asset classes.",icon:"assets/img/icon/fire.svg"},
  ]
  investeds:any[] = [
    {title:"yes:",desc:"Yes, I have invested in fixed income securities."},
    {title:"no:",desc:"No, I have not invested in fixed income securities."},
  ]
  objectives:any[] = [
    {title:"A:",desc:"Capital preservation: Seeking to preserve the initial investment amount.",icon:"assets/img/icon/chart.svg",active:false},
    {title:"B:",desc:"Income generation: Seeking regular income from interest payments.",icon:"assets/img/icon/like.svg",active:false},
    {title:"C:",desc:"Total return: Seeking a combination of income and potential capital appreciation.",icon:"assets/img/icon/fire.svg",active:false},
    {title:"D:",desc:"Diversification: Seeking to diversify the investment portfolio.",icon:"assets/img/icon/fire.svg",active:false}
  ]

  steps = [{title:'Experience',checked:true},
  {title:'Investment',checked:false},{title:'Investment objective',checked:false}]
  currentStep:number
  constructor(private router: Router, private userService:UserService){
    this.currentStep = 0
  }
  changeStep(val:number,pos:number) {

    let res = this.currentStep + val
    if((val >= 0) && res < this.steps.length )this.steps[res].checked = true
    else if(val < 0) this.steps[pos].checked = false

    //temp
    if(res > this.steps.length - 1){
      if(!this.levels[this.result.experienceLevelId]?.title) this.currentStep = 0
      else if(!this.investeds[this.result.investmentExperienceId]?.desc) this.currentStep = 1
    else if(!this.objectives[this.result.investmentObjectiveId]?.desc) this.currentStep = 2
  else
      this.userService.post("/investor",{
        //"id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "experienceLevel": this.levels[this.result.experienceLevelId]?.title.toUpperCase(),
        "investment": this.investeds[this.result.investmentExperienceId]?.desc,
        "investmentObjective": this.objectives[this.result.investmentObjectiveId]?.desc
      }).subscribe({next:
          (data) => {
            this.router.navigate(['/home']);
          },
        error:(e)=> {

          this.router.navigate(['/home']);
        }
    }
    )
  }
    
    if(res>=0 && res <this.steps.length &&  pos < this.steps.length)this.currentStep =  res;

  }
}
