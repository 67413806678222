import { ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, take } from 'rxjs';
import Quizz from 'src/app/models/Quizz';
import { ChatService } from 'src/app/services/chat.service';
import { EducationService } from 'src/app/services/education.service';

@Component({
  selector: 'app-lecture',
  templateUrl: './lecture.component.html',
  styleUrls: ['./lecture.component.scss']
})
export class LectureComponent implements OnInit {
  @Input()
  content: any[] = []
  @Input()
  lecture: any = {}
  lecture$?: Observable<any>;
  @Input() progress: any[] = []
  progress$?: Observable<any>;
  @Input() latestProgress: any = {}
  latestProgress$?: Observable<any>;
  @Input() addObj?: Function;
  currentStep: number
  serviceData: any
  @Output()
  openQuiz = new EventEmitter<boolean>();
  quizzes$?: Observable<any>;
  @Input()
  quizzes: any[] = [];
  dynamicHtml: string = ""
  isLoading: boolean = false;
  constructor(private ruter: ChatService, private route: ActivatedRoute, private router: Router, private educationService: EducationService,
    private renderer: Renderer2,private cdRef: ChangeDetectorRef  
  ) {
    this.currentStep = 0
    this.serviceData = this.educationService.getContentData()
    this.content = this.educationService.selected.content
    if (this.content) {
      this.content
      console.log("CONTENT")
    } else { console.log("NOTHING") }
    this.educationService.getLectureIDObservable().subscribe((lectureID: string) => {
      const matchingStepIndex = this.steps.findIndex(step => step.id === lectureID);
      if (matchingStepIndex !== -1) {
        this.currentStep = matchingStepIndex;
        this.lecture$ = this?.educationService.getStudentLecturesByID(lectureID, this.lecture.languageCode)
        this?.lecture$.subscribe((data) => {
          this.lecture = data;
        });
        this.updateGeneratedInnerHtmlData()
      }
    });
  }
  ngOnInit(): void {


    if (this.router.url.split("/")[1] == "education") {
      this.steps = []
      this.educationService.selected.content.forEach((element: any) => {
        this.steps.push({ ...element, checked: false })
      });

    }

    this.educationService.onYourSelectedChange().subscribe((newValue: any) => {
      // This function will be triggered whenever yourVariable changes
      console.log('YourVariable has changed:', newValue);
      // Call any other function or perform any desired actions here

      this.steps = []
      this.educationService.selected.content.forEach((element: any) => {
        this.steps.push({ ...element, checked: false })
      });
    });
    this.route.params.subscribe((params) => {
      const moduleID = params['moduleID'];
      this.latestProgress$ = this?.educationService.getStudenProgressByModule(moduleID)
      this.latestProgress$
        .pipe(
          take(1) // Add take(1) to automatically unsubscribe after one value is received
        )
        .subscribe((data) => {
          this.progress = data;
          console.log("onInit", moduleID, this.progress)
        });
    });
    console.log(this.quizzes.length, "Quizz Length In Lecture onInit")
  }
  @ViewChild('targetElement', { static: false }) targetElement!: ElementRef;

  ngAfterViewInit() {
    this.generateInnerHTMLData()
    this.cdRef.detectChanges(); 
  }

  updateGeneratedInnerHtmlData(): any {
    if(!this.steps[this.steps.length - 1].checked){
    this.isLoading = true;
    setTimeout(() => {
      this.generateDynamicHtml(this.lecture);
      this.targetElement.nativeElement.innerHTML = this.dynamicHtml;

      this.isLoading = false;
    }, 1000);  }
  }
  generateInnerHTMLData(): any {
    this.isLoading = true;
    setTimeout(() => {
      const div = this.renderer.createElement('div');
      this.generateDynamicHtml(this.lecture);
      div.innerHTML = this.dynamicHtml
      this.renderer.appendChild(this.targetElement.nativeElement, div);
      this.isLoading = false;
    }, 1000);
  }
  generateDynamicHtml(lecture: any): any {
    if (lecture.content) {
      return this.dynamicHtml = `
      ${lecture.content}
      `;
    }
  }

  sowLe() {

    console.log(this.content)
  }
  steps = [
    { title: 'init', checked: false, id: "string" }
  ]

  objSample = {
    show: false,
    type: "text",
    content: ["Welcome to Fixed Income Quizzes!", "Note: The answers provided below are sample answers.  The actual correct answers may vary based on specific context and information."]

  };

  objSample2 = {
    show: false,
    type: "quiz"
  };
  showDetail() {
    this.objSample.show = true
    this.objSample2.show = true
  }
  goToEducation() {
    this.router.navigate(["/education"])
  }
  changeStep(val: number, pos: number) {
    const isValueFound = this.progress.some(obj => obj.lectureId === this.content[this.currentStep].id);
    if (!isValueFound) {
      if (val > 0) {
        console.log(isValueFound, "IF", this.progress)
        this.educationService.createStudentProgress(this.content[this.currentStep].id).subscribe((data) => {
          this.educationService.setProgressPercentage(data.progressPercentage);
          this.progress.push(data)
          this.educationService.setProgressArray(this.progress)
          this.educationService.setLectureID(this.content[this.currentStep].id);
          /* this.lecture$ = this?.educationService.getStudentLecturesByID(this.content[this.currentStep].id, this.lecture.languageCode)
          this?.lecture$.subscribe((data) => {
            this.lecture = data;
          }); */
          console.log(this.progress, "progress afterPush")
          if (data.completed) {
            this.getQuiz()
            console.log('Completed is true');
          } else {
            console.log('Lectures Not Completed Yet');
          }
        });
        let res = this.currentStep + val;


        if ((val >= 0) && pos < this.steps.length) {
          this.steps[pos].checked = true;
        } else if (val < 0) {
          this.steps[pos].checked = false;
        }

        for (let i = 0; i < this.steps.length; i++) {
          if (i < res) {
            this.steps[i].checked = true;
          } else if (i > res) {
            this.steps[i].checked = false;
          }
        }

        // Update the current step if it falls within the valid range
        if (res >= 0 && res < this.steps.length && pos < this.steps.length) {
          this.currentStep = res;
        }
      } else {
        let res = this.currentStep + val;


        if ((val >= 0) && pos < this.steps.length) {
          this.steps[pos].checked = true;
        } else if (val < 0) {
          this.steps[pos].checked = false;
        }

        for (let i = 0; i < this.steps.length; i++) {
          if (i < res) {
            this.steps[i].checked = true;
          } else if (i > res) {
            this.steps[i].checked = false;
          }
        }

        // Update the current step if it falls within the valid range
        if (res >= 0 && res < this.steps.length && pos < this.steps.length) {
          this.currentStep = res;
        }

        this.educationService.setLectureID(this.content[this.currentStep].id);
        /* this.lecture$ = this?.educationService.getStudentLecturesByID(this.content[this.currentStep].id, this.lecture.languageCode)
        this?.lecture$.subscribe((data) => {
          this.lecture = data;
        }); */
        this.updateGeneratedInnerHtmlData()
      }
    }
    else {
      console.log(isValueFound, "ELSE", this.progress)
      let res = this.currentStep + val;


      if ((val >= 0) && pos < this.steps.length) {
        this.steps[pos].checked = true;
      } else if (val < 0) {
        this.steps[pos].checked = false;
      }

      for (let i = 0; i < this.steps.length; i++) {
        if (i < res) {
          this.steps[i].checked = true;
        } else if (i > res) {
          this.steps[i].checked = false;
        }
      }

      // Update the current step if it falls within the valid range
      if (res >= 0 && res < this.steps.length && pos < this.steps.length) {
        this.currentStep = res;
      }

      this.educationService.setLectureID(this.content[this.currentStep].id);
      /* this.lecture$ = this?.educationService.getStudentLecturesByID(this.content[this.currentStep].id, this.lecture.languageCode)
      this?.lecture$.subscribe((data) => {
        this.lecture = data;
      }); */
      this.updateGeneratedInnerHtmlData()
    }

  }

  openQuizAction() {
    //this.getQuiz()
    this.openQuiz.emit();
  }

  getQuiz() {
    this.isLoading=true
    this.route.params.subscribe((params) => {
      const moduleID = params['moduleID'];
      const language = params['language'];
      this.quizzes$ = this?.educationService.getStudentQuiz(moduleID, language)
      this?.quizzes$.subscribe((data) => {
        this.quizzes = data;
        this.quizzes.forEach((module: Quizz) => {
          this.educationService.getStudentQuizByID(moduleID, language, module.quizID).subscribe((progressData) => {
            if (progressData.answers) {
              module.answers = progressData.answers.map((answer) => ({
                answered: false,
                isCorrect: null,
                id: answer.id,
                text: answer.conten,
              }));
            }
          });
        });
        const savedContent = localStorage.getItem('content');
        if (savedContent !== null) {
          const parsedContent = JSON.parse(savedContent);
          if (!Array.isArray(parsedContent.modules.quiz)) {
            parsedContent.modules.quiz = [];
          }
          parsedContent.modules.quiz.push(data);
          localStorage.setItem('content', JSON.stringify(parsedContent));
          this.educationService.saveContent(parsedContent);
        }
        this.educationService.setQuizArray(this.quizzes)
      });
    })
    console.log("got Quizz")
    
    this.isLoading=false
  }
  fontSize = 1;
  changeFontSize(operation: '+' | '-'): void {
    if (operation === '+') {
      if (this.fontSize < 2.0) {
        this.fontSize = Math.min(this.fontSize + 0.1, 2.0); // Increase font size by 0.1 rem, with a maximum of 2.0 rem
      }
    } else if (operation === '-') {
      if (this.fontSize > 1.0) {
        this.fontSize = Math.max(this.fontSize - 0.1, 1.0); // Decrease font size by 0.1 rem, with a minimum of 1.0 rem
      }
    }
  }
  resetFontSize(): void {
    this.fontSize = 1
  }

  getNextTitle(currentStep: number) {
    if (this.currentStep + 1 < this.serviceData?.modules?.lectures.length) {
      let thisStep = this.serviceData?.modules?.lectures[currentStep + 1]?.title
      return thisStep
    } else {
      return "Congratulations"
    }
  }
  getFinalStep() {

    if (this.steps[this.steps.length - 1].checked) {
      return true
    } else {
      return false
    }
  }
}
