import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  constructor(private keycloakService: KeycloakService) {
  }
  ngOnInit(): void {
    this.keycloakService.getToken().then((token: string) => {
      const jwtData = token.split('.')[1];
      const decodedJwt = JSON.parse(atob(jwtData));
      const tokenDetails = this.keycloakService.getKeycloakInstance().tokenParsed;
      console.log(decodedJwt, "decodedJwt")
      console.log(decodedJwt.name, "decodedJwt")
      console.log(decodedJwt.email, "decodedJwt")
  this.name =decodeURIComponent(escape( decodedJwt.name))
  this.email = decodedJwt.email
    });
  }
  name?: string
  email?: string

  profileMenu: any[] = [
    {
      name: "Personal Information",
      action: () => {
        this.selectedTab = 'personalInformation';
      }
    },
    {
      name: "Documents",
      action: () => {
        this.selectedTab = 'documents';
      }
    },
    {
      name: "Payment Method",
      action: () => {
        this.selectedTab = 'paymentMethod';
      }
    },
    {
      name: "Settings",
      action: () => {
        this.selectedTab = 'settings';
      }
    }
  ];

  profileLogOutButton: any[] = [{ name: "Log Out", action: () => this.keycloakService.logout() }]
  profileLogOut: any = { classTab: "!bg-[#181C31] !border-transparent" }

  selectedTab: string = 'personalInformation'
}
