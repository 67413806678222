<div [class.sidebarOpen]="isOpen" class="sidebar fixed top-0 right-0 h-screen max-w-[70%] bg-gray-900 text-white z-40 transition-transform duration-300 transform translate-x-full">
    <!-- Sidebar content goes here -->
    <div class="flex items-center mb-10 px-5">
    <div class="text-white font-normal text-[2rem] flex-grow ">Order Ticket</div>
    <app-rounded-icon-buttom (btnClick)="toggleSidebar()" [buttonData]="{
        iconUrl:'assets/img/icon/cross.svg'
    }" />
    </div>

    <div class="max-h-[60%] overflow-auto px-5 text-white">
    <div class="grid grid-cols-2 gap-5">
        <app-select-form [showMode]="state" [selectData]="{
            labelClass:' ' || 'text-[0.8rem] !text-[#131515]',
            optionClass:'!text-white',
        selectClass: noBox(state) || '!bg-[#F9F9F9] !text-[#131515]',label:'Name your Portfolio',}" [items]="[{value:'',name:'Type Or Choose'}]" />
    <app-input-form
      [inputData]="{
        inputType: 'text',
        labelClass: ' ' || 'text-[0.8rem] !text-[#131515]',
        inputClass: noBox(state) || '!bg-[#F9F9F9] !text-[#131515]',
        inputFormClass: '',
        label: 'Investment amount',
        placeholder: '$ 5,000.00',
      }"
    />
    <app-select-form  [showMode]="state"  [selectData]="{
        
        optionClass:'!text-white',
        selectClass: noBox(state) ||'!bg-[#F9F9F9] !text-[#131515]',
        labelClass: ' ' ||'text-[0.8rem] !text-[#131515]',label:' Select options',}" [items]="[{value:'',name:'Percentage'}]" />
    </div>

    <!-- INIT ORDER-->
    
    <div class="font-normal text-[1rem] md:text-[2rem] pt-10 mb-10">Self selected</div>
    <div class="">
        <div class="h-[56px] bg-[#978EF533] rounded-t-2xl md:grid grid-cols-9 hidden">
          <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow col-span-2"> Bond</div>
          <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow" >Yield</div>          
          <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow" >Interest paid</div>
          <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow" >Maturity</div>
          <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow" >Bid (USD)</div>
          <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow" >Ask (USD)</div>
          <div class="font-normal p-4 text-[14px] text-[#B3B3B3] text-left flex-grow"></div>
        </div>
      </div>
        <div class=" text-white ">
            <ng-container  *ngFor="let order of orders,let i = index">
        <div *ngIf="order.type != 'ready'" class="grid grid-cols-9 rounded-2xl p-4 mb-4 place-content-center"
       
        >
            <div class="col-span-2 flex items-center justify-center">
                <span class="font-[600] text-[0.875rem]">{{order.title}} </span> <br />
                {{order.id}}
                </div>

            <div class="flex items-center justify-center">N/A</div>
            <div class="flex items-center justify-center">5.34%</div>
            <div class="flex items-center justify-center">08/2027</div>
            <div class="flex items-center justify-center">99.314</div>
            <div class="flex items-center justify-center">99.387</div>

            <div class="grid grid-cols-2">
            <div class="flex items-center justify-center">
                <span class="bg-[#FFFFFF1A] p-4 rounded-2xl">{{order.income}}%</span>

            </div>
            <div class="flex items-center justify-end">
                <img src="assets/img/icon/trash.svg" />
            </div>
        </div>
        </div>
    </ng-container>
    </div>
    <!--CLOSE ORDERS-->


    <ng-container  *ngFor="let order of orders,let i = index">
    <div *ngIf="order.type == 'ready'" class=" text-white my-10">
        <div class="grid grid-cols-5 bg-[#0C1026] rounded-2xl p-4 place-content-center">
            <div class="col-span-3 flex items-center">
                <span class="font-[600] text-[0.875rem]">{{order.title}}</span>
                </div>
            <div class="flex items-center justify-center">
                <span class="bg-[#FFFFFF1A] p-4 rounded-2xl">{{order.income}}</span>

            </div>
            <div class="p-4 flex items-center justify-end">
                <img src="assets/img/icon/trash.svg" />
            </div>
        </div>

    </div>
</ng-container>
    </div>

    <!--TOTAL END-->
    <div class="text-white absolute right-0 bg-yv bottom-0 w-full p-10">
        <!-- <div class="">
            <div class="flex gap-2 items-center my-4"><span class="flex-grow">Allocated:</span><span class="font-bold text-[1.125rem]">$3.500.00</span><span class="rounded-full bg-[#978EF51A] text-[#6761A8] p-2">70%</span></div>
            <div class="flex gap-2 items-center my-4"><span class="flex-grow">Remaining:</span><span class="font-bold text-[1.125rem]">$1.500.00</span><span class="rounded-full bg-[#978EF51A] text-[#6761A8] p-2">30%</span></div>


        </div> -->
        <hr  class="my-4 mt-10 border-[#FFFFFF11]"/>
        <div class="flex justify-end">
            <app-rounded-icon-buttom (btnClick)="state = true" [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass:'!bg-[#2CC784]'}" />
        </div>
    </div>
    
  </div>
<div [class.sidebarOpen]="isOpen" (click)="toggleSidebar()" class="overlay fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-50 z-30 transition-opacity duration-300"></div>