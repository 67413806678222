<div class="bg-[#FFFFFF1A] px-2 py-1 md:py-4 md:px-4 rounded-2xl block text-[#B3B3B3] lg:overflow-auto">
    <div class=" flex gap-4">
        <div class="{{flag?'bg-[#98F2B9]':'bg-[#FF6F65]'}} min-w-max p-4 md:w-14 w-10 h-10 md:h-14 rounded-2xl">
            <img width="100%" class="{{flag?'':'rotate-180'}}" src="assets/img/icon/arrow-base.svg" />
        </div>
    </div>
    
    <div class="md:text-[32px] mt-4 text-[5.5vw] text-white">
        {{cardData.value}}
    </div>
    <div class="flex justify-between border border-transparent">
        <div class="text-left border border-transparent">{{cardData.title}}</div>
        <div class="text-right border border-transparent">
            <div *ngIf="flag == null" class="p-2 text-[#F9F9F9] min-w-max">Jan 5 2023</div>
            <div *ngIf="flag" class="p-2 px-3 rounded-2xl text-[#978EF5] bg-[#978EF51A]">Buy</div>
            <div *ngIf="flag == false" class="p-2 px-3 rounded-2xl text-[#2CC784] bg-[#98F2B933]">Sell</div>
        </div>
    </div>
</div>