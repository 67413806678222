<div
  class="rounded-[24px] flex flex-col bg-[#FFFFFF0A] items-center mx-5"
>
  <div class="rounded-[24px] w-full flex justify-center">
    <img class="w-[100%] md:h-[290px] rounded-[24px]" [src]="photo" />
  </div>
  <div class="-top-7 relative flex">
    <div
      class="rounded-t-full rounded-bl-full h-10 w-10 left-[1px] relative"
    ></div>
    <div class="rounded-t-full h-10 w-10 bg-transparent flex justify-center">
      <app-rounded-icon-buttom
        [buttonData]="{
          iconUrl: home?'assets/img/icon/play3.svg':'assets/img/icon/play2.svg',
          btnClass: 'top-1 !bg-transparent'
        }"
      />
    </div>
    <div
      class="rounded-t-full rounded-br-full h-10 w-10  -left-[1px] relative"
    ></div>
  </div>
  <div class="md:px-8 px-6 md:py-4 pb-10">
    <div class="font-[500] text-[20px]">
      What are some of the risks of investing in municipal bonds?
    </div>
    <div class="font-normal {{home?'':'text-[#B3B3B3]'}} text-[14px]">
      What are some of the risks of investing in municipal bonds?
    </div>
  </div>
</div>
