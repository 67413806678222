import { Component } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  currentSlideIndex: number;

  constructor() {
    this.currentSlideIndex = 0;
  }

  slides = [
    { img: 'assets/img/slide-login/img001.svg' },
    { img: 'assets/img/slide-login/img001.svg' },
    { img: 'assets/img/slide-login/img001.svg' },
  ];
  // slides = [
  //   { img: 'http://placehold.it/350x150/000000' },
  //   { img: 'http://placehold.it/350x150/111111' },
  //   { img: 'http://placehold.it/350x150/333333' },
  //   { img: 'http://placehold.it/350x150/666666' },
  // ];
  slideConfig = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
}
