

<div class="w-full">
    <div>
      <div class="min-h-[56px] bg-[#978EF54D] rounded-t-2xl grid" [style.gridTemplateColumns]="'repeat(' + numColumns + ', 1fr)'">
        <div class="font-[400] p-4 text-[14px] text-[#B3B3B3] text-left" *ngFor="let column of columns">{{ column }}</div>
      </div>
    </div>
    <div class="text-[#E1E1E1] font-[400] text-[16px]">
      <div class="rounded-[8px] grid items-center {{i%2!=0?'bg-[#FFFFFF08]':''}}"  [style.gridTemplateColumns]="'repeat(' + numColumns + ', 1fr)'" *ngFor="let row of data,let i = index">
        <div class="p-4 flex-grow" *ngFor="let column of columns">{{ row[column] }}</div>
      </div>
    </div>
</div>