import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);


@Component({
  selector: 'app-column-range-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './column-range-chart.component.html',
  styleUrls: ['./column-range-chart.component.scss']
})
export class ColumnRangeChartComponent {

  dynamicId = `component_${Math.floor(Math.random() * 10000)}`


  ngAfterViewInit() {
    this.renderChart();
  }

  renderChart() {

    const chartOptions: Highcharts.Options = {

      chart: {
        type: 'columnrange',
        backgroundColor: 'transparent',
      },

      legend: {
        enabled: false,
      },
      credits: {
        enabled: false
      },

      title: {
        text: '',
      },

      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        gridLineColor: '#ffffff33',
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1.3,
        lineColor: '#ffffffcc',
        lineWidth: 2,
        labels: {
          style: {
            color: '#ffffff99' // Set the color of the xAxis categories text
          },
        }
      },

      yAxis: {
        gridLineColor: '#ffffff33',
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1.3,
        lineColor: '#ffffffcc',
        lineWidth: 2,
        labels: {
          style: {
            color: '#ffffff99' // Set the color of the xAxis categories text
          }
        }
      },

      plotOptions: {
        columnrange: {
          pointWidth: 10,
          borderRadius: 0,
          borderWidth: 0,
          // groupPadding: 1
        }
      },

      series: [
        {
          name: 'Temperatures',
          data: [
            [-13.9, 5.2],
            [-16.7, 10.6],
            [-4.7, 11.6],
            [-4.4, 16.8],
            [-2.1, 27.2],
            [5.9, 29.4],
            [6.5, 29.1],
            [4.7, 25.4],
            [4.3, 21.6],],
          color: '#2CC784',
        },
        {
          name: 'other',
          data: [
            [-7.2, 3.5],
            [-9.5, 8.2],
            [-2.3, 9.8],
            [-3.1, 14.6],
            [-1.8, 21.9],
            [4.5, 23.7],
            [6.1, 25.3],
            [5.3, 22.8],
            [3.8, 19.4],
          ],
          color: '#978EF5'
        } as any
      ]

    }
    Highcharts.chart(this.dynamicId, chartOptions);
  }

}
